import { ImageRender, TagFilter } from '@/components'
import Button2 from '@/components/Button/Button2'
import {
  MaximizeIcon,
  RedirectIcon,
  ThreeDotsIcon,
  PinIcon,
  CrossIcon
} from '@/components/Icons/Icons'
import { Box, Fade, Menu, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchNew from '../ListFilter/SearchNew'
import {
  changeProjectInfo,
  contentSearch,
  postCollectionData
} from '@/store/api'
import _ from 'lodash'
import trackEvent from '@/utils/TrackEvent/TrackEvent'
import mixpanelEvents from '@/config/mixpanelEvents'
import AttachFilesUI from '@/components/AttachFilesUI/AttachFilesUI'
import { cn } from '@/lib/utils'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useSelector } from 'react-redux'
const ProjectResumes = ({
  currentProject,
  handleDownloadCustomPdf,
  handleMasterMappingDelete,
  viewImage,
  openPdf,
  s3Obj,
  isUserViewer = false,
  files = [],
  setFiles = () => {},
  projectId = '',
  projectTitle = '',
  setCurrentProject = () => {}
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredProjects, setFilteredProjects] = useState(currentProject?.data)
  const [contentSearching, setContentSearching] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredProjects(currentProject?.data)
      return
    }
    const timeout = setTimeout(() => {
      setContentSearching(true)
      trackEvent(
        mixpanelEvents.PROJECT_DOCUMENT_CONTENT_SEARCHED,
        'SUCCESS',
        {},
        {
          project_id: currentProject?.id,
          search_query: searchQuery
        }
      )

      contentSearch({
        group_id: currentProject?.id,
        search_keyword: searchQuery,
        search_type: 'project'
      })
        .then((res) => {
          console.log()
          const foundList = res.data.data
          setContentSearching(false)
          const filtered = currentProject.data.filter((project) => {
            return foundList.includes(project.id)
          })

          setFilteredProjects(filtered)
        })
        .catch((err) => {
          console.log(err)
          setContentSearching(false)
        })
    }, 1500)

    return () => clearTimeout(timeout)
  }, [searchQuery])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handlePinProject = async (project) => {
    if (isUserViewer) return
    const filteredProjectsClone = [...filteredProjects]
    const index = filteredProjectsClone.findIndex(
      (item) => item.proposal_id === project.proposal_id
    )
    if (index > -1) {
      filteredProjectsClone[index] = {
        ...filteredProjectsClone[index],
        is_project_pinned: !filteredProjectsClone[index].is_project_pinned
      }
      filteredProjectsClone.sort((a, b) => {
        return a.is_project_pinned === b.is_project_pinned
          ? 0
          : a.is_project_pinned
          ? -1
          : 1
      })
      setFilteredProjects(filteredProjectsClone)
      setCurrentProject({
        ...currentProject,
        data: filteredProjectsClone
      })
    }
    const result = await changeProjectInfo({
      project_group_id: projectId,
      project_title: projectTitle,
      pinned_resume_id: project.project_id
    })
    if (result.status === 200) {
      trackEvent(
        mixpanelEvents.PROJECT_PINNED,
        'SUCCESS',
        {},
        { project_id: projectId }
      )
    }
  }

  const [fileExternsionOptions, setFileExternsionOptions] = useState([])
  const [domainUserNames, setDomainUserNames] = useState([])
  const [tagsOptions, setTagsOptions] = useState(null)
  const [selectedTags, setSelectedTags] = useState(null)

  const tagsCenterStateTagsAll = useSelector((state) => state?.tagCenter?.tags)
  const [tagsCenterStateTags, setTagsCenterStateTags] = useState({})

  const [documentTypeTags, setDocumentTypeTags] = useState([])
  const [isFiltersApplied, setIsFiltersApplied] = useState(false)

  const [tagFilteredDocumentIds, setTagFilteredDocumentIds] = useState([])

  const [sortOrder, setSortOrder] = useState({
    created_at: 'desc'
  })

  const domainUsers = useSelector(
    (state) => state.authenticate.user?.domain?.domain_users || []
  )
  const user_name = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )
  const user_id = useSelector(
    (state) => state.authenticate.user?.domain?.id || ''
  )

  const [controllers, setControllers] = useState([])

  const createAbortController = () => {
    const abortController = new AbortController()
    setControllers((prevControllers) => [...prevControllers, abortController])
    return abortController
  }

  const handleTagsValueChange = (key, value, mode) => {
    let selectedTagsClone = {}
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
        selectedTagsClone = temp
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
          selectedTagsClone = {
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          }
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
          selectedTagsClone = {
            [key]: {
              values: value
            }
          }
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
        selectedTagsClone = {
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        }
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
        selectedTagsClone = {
          [key]: {
            condition: value
          }
        }
      }
    }

    handleChangeTags(selectedTagsClone)
  }

  const initFileOptions = () => {
    const fileTypes = [
      {
        value: 'proposal',
        label: 'Proposals'
      },
      {
        value: 'images',
        label: 'Images'
      },
      {
        value: 'videos',
        label: 'Videos'
      },
      {
        value: 'proposal_images',
        label: 'Proposal Images'
      },
      {
        value: 'document',
        label: 'Documents'
      }
    ]
    const fileExtensions = [
      {
        value: 'jpeg',
        label: 'JPEG'
      },
      {
        value: 'png',
        label: 'PNG'
      },
      {
        value: 'mp4',
        label: 'MP4'
      },
      {
        value: 'jpg',
        label: 'JPG'
      },
      {
        value: 'pdf',
        label: 'PDF'
      },
      {
        value: 'doc, docx',
        label: 'DOC'
      },
      {
        value: 'ppt, pptx',
        label: 'PPT'
      }
    ]
    const userNames = domainUsers.map((user) => {
      return {
        value: user.id,
        label: user.user_name,
        email: user.email
      }
    })

    userNames.push({
      value: user_id,
      label: user_name
    })

    setFileExternsionOptions(fileExtensions)
    setDomainUserNames(userNames)
  }

  useEffect(() => {
    initFileOptions()
  }, [])

  useEffect(() => {
    if (
      tagsCenterStateTagsAll &&
      !_.isEqual(
        Object.keys(tagsCenterStateTagsAll),
        Object.keys(tagsCenterStateTags)
      )
    ) {
      const filteredData = Object.keys(tagsCenterStateTagsAll || {}).reduce(
        (acc, key) => {
          const { key_type } = tagsCenterStateTagsAll[key] || {}
          if (key_type && key_type?.includes('document')) {
            acc[key] = tagsCenterStateTagsAll[key]
          }
          return acc
        },
        {}
      )
      setTagsCenterStateTags({ ...filteredData })
    }
  }, [tagsCenterStateTagsAll])

  useEffect(() => {
    const tagsValues = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        value_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      if (value_type?.includes('document_type')) {
        setDocumentTypeTags(data)
      }
      data.forEach((element) => {
        if (element.value) {
          if (tagsValues[key]?.values) {
            tagsValues[key].values.push({
              value: element.id,
              label: element.value
            })
          } else {
            if (!tagsValues[key]) tagsValues[key] = {}
            tagsValues[key].values = [
              {
                value: element.id,
                label: element.value
              }
            ]
          }
        }
        tagsValues[key] = {
          ...tagsValues[key],
          type,
          ...rest
        }
      })
    })

    setTagsOptions(tagsValues)
  }, [tagsCenterStateTags])

  const handleChangeTags = async (selectedTagsClone) => {
    const tag_values = []
    const abortController = createAbortController()
    selectedTagsClone &&
      Object.keys(selectedTagsClone).forEach((key) => {
        if (key === 'file_type') {
          const { condition, values = [] } = selectedTagsClone[key] || {}
          const value = values.map((item) => item.value)
          file_type = { condition: condition || 'is', values: value }
        } else if (key === 'file_extension') {
          const { condition, values = [] } = selectedTagsClone[key] || {}
          const fileExts = []
          values.forEach((item) => {
            if (item.value?.includes(',')) {
              fileExts.push(...item.value?.split(',').map((str) => str.trim()))
            } else {
              fileExts.push(item.value)
            }
          })
          file_extension = { condition: condition || 'is', values: fileExts }
        } else if (key === 'hidden_from_search') {
          const { condition, values = [] } = selectedTagsClone[key] || {}
          const value = values.map((item) => item.value)
          hidden_manually = {
            condition: condition || 'is',
            values: value?.[0]
          }
        } else {
          const tags = []
          let { condition, values = [] } = selectedTagsClone[key] || {}
          values = values || []
          values.forEach((item) => {
            if (item?.type?.includes('date')) {
              tag_dates.push({ ...item, condition: condition || 'is' })
            } else {
              tags.push(item.value)
            }
          })
          if (tags.length > 0) {
            tag_values.push({
              key,
              condition: condition || 'is',
              values: tags
            })
          }
        }
      })

    const knowledge_document_ids_to_filter = filteredProjects.map(
      (item) => item.proposal_id
    )

    const req = {
      page_num: 1,
      page_size: 15,
      type: 'all',
      keyword: '',
      tags: tag_values,
      tag_dates: [],
      file_type: null,
      file_extension: null,
      hidden_manually: null,
      sort_order: sortOrder,
      knowledge_document_ids_to_filter,
      is_knowledge_profile_filter: true
    }
    setContentSearching(true)
    if (tag_values.length > 0) {
      const res = await postCollectionData(req, {
        signal: abortController.signal
      })
      if (res.status === 200) {
        const { data } = res.data
        const newDocumentIds = data.map((item) => item.id)
        setTagFilteredDocumentIds(newDocumentIds)
        setIsFiltersApplied(true)
      }
    } else {
      setIsFiltersApplied(false)
      setTagFilteredDocumentIds([])
    }
    setContentSearching(false)
  }

  useEffect(() => {
    if (tagFilteredDocumentIds.length > 0) {
      const filtered = currentProject.data.filter((resume) => {
        return tagFilteredDocumentIds.includes(resume.proposal_id)
      })
      setFilteredProjects(filtered)
    } else {
      if (isFiltersApplied) {
        setFilteredProjects([])
      } else {
        setFilteredProjects(currentProject.data)
      }
    }
  }, [tagFilteredDocumentIds])

  if (currentProject?.data?.length === 0) {
    return (
      <div className="grid h-full place-content-center text-sm text-grey-500 justify-items-center">
        <div>No resumes found.</div>
        <div>Please try attaching a case study or project sheet.</div>
        {files && files.length === 0 && (
          <div className="h-60 w-full">
            <AttachFilesUI
              files={files}
              setFiles={setFiles}
              profileId={projectId}
              source="project"
              isUserViewer={isUserViewer}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      style={{
        padding: '10px 20px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: '6px',
          padding: '8px 0px',
          marginBottom: '5px'
        }}
      >
        <div>
          {selectedTags && Object.keys(selectedTags).length > 0 && (
            // <Box>
            <TagFilter
              tags={{
                // file_type: {
                //   values: fileTypeOptions,
                //   type: 'default',
                //   editable: false
                // },
                file_extension: {
                  values: fileExternsionOptions,
                  type: 'default',
                  editable: false,
                  label: 'Extension'
                },
                created_by: {
                  values: domainUserNames,
                  type: 'default',
                  editable: false
                },
                created_on: {
                  type: 'multidate',
                  editable: false
                },
                hidden_from_search: {
                  values: [
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ],
                  type: 'defaultsingle',
                  editable: false
                },
                ...tagsOptions
              }}
              showButton={false}
              showTags={true}
              disabled={contentSearching}
              selectedTags={selectedTags}
              onChange={handleTagsValueChange}
              clearFilter={<></>}
            />
            // </Box>
          )}
        </div>
        <div className="flex gap-2 items-center">
          <TagFilter
            tags={{
              file_extension: {
                values: fileExternsionOptions,
                type: 'default',
                editable: false,
                label: 'Extension'
              },
              created_by: {
                values: domainUserNames,
                type: 'default',
                editable: false
              },
              created_on: {
                type: 'multidate',
                editable: false
              },
              hidden_from_search: {
                values: [
                  { label: 'True', value: true },
                  { label: 'False', value: false }
                ],
                type: 'defaultsingle',
                editable: false
              },
              ...tagsOptions
            }}
            showButton={true}
            showTags={false}
            disabled={contentSearching}
            selectedTags={selectedTags}
            onChange={handleTagsValueChange}
            clearFilter={
              isFiltersApplied && (
                <Button2
                  secondary
                  onClick={() => {
                    if (!contentSearching) {
                      setSelectedTags(null)
                      setIsFiltersApplied(false)
                      setTagFilteredDocumentIds([])
                    }
                  }}
                >
                  <CrossIcon className="size-4 text-grey-500 " />
                  clear filter
                </Button2>
              )
            }
            filterIcon={
              !isFiltersApplied && (
                <Button2
                  secondary
                  noOutline
                  disabled={contentSearching}
                  style={{
                    padding: '6px 10px'
                  }}
                >
                  <FilterListIcon
                    style={{
                      height: '16px',
                      width: '16px'
                    }}
                  />{' '}
                  Filter
                </Button2>
              )
            }
          />
          <SearchNew
            value={searchQuery}
            onChange={(value) => {
              setSearchQuery(value)
            }}
            placeholder="Search Docs"
            onClear={() => {
              setSearchQuery('')
            }}
          />
        </div>
      </div>
      <div className="">
        {contentSearching ? (
          <div className="grid place-content-center">Seraching Content</div>
        ) : (
          <div className="grid gap-4 grid-col-5">
            {filteredProjects
              ?.sort((a, b) => b.is_project_pinned - a.is_project_pinned)
              ?.map((project, index) => {
                const {
                  proposal_id,
                  thumbnail_url = '',
                  screenshots = [],
                  page_number = 1,
                  proposal_name = '',
                  version = '',
                  screenShot,
                  client_group,
                  title,
                  is_project_pinned = false
                } = project
                const coverImage = _.isEmpty(screenshots)
                  ? thumbnail_url
                  : screenshots[0]?.src
                const imagePayload = _.isEmpty(screenshots)
                  ? thumbnail_url
                  : screenshots
                const displayName = proposal_name || proposal_id
                const sourceType =
                  client_group?.type === 'rfx' ? 'rfx' : 'proposal'

                if (!proposal_id) return null
                return (
                  <div
                    key={index}
                    style={{
                      position: 'relative',
                      width: '175px'
                    }}
                    className="group"
                  >
                    <div
                      style={{
                        position: 'absolute',
                        borderRadius: '50%',
                        width: 28,
                        height: 28,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1001,
                        left: 'calc(100% - 16px)',
                        bottom: 'calc(100% - 16px)'
                      }}
                      className={cn(
                        'border border-zinc-200 shadow-md',
                        isUserViewer ? 'invisible' : 'group-hover:visible',
                        is_project_pinned
                          ? 'visible bg-zinc-300'
                          : 'invisible bg-white'
                      )}
                    >
                      <Tooltip
                        title={
                          <Box
                            className={cn(
                              'flex items-center gap-1 align-center'
                            )}
                          >
                            {!isUserViewer &&
                              (is_project_pinned
                                ? 'Unpin this project'
                                : 'Pin this project to top')}
                          </Box>
                        }
                      >
                        <div
                          className="flex pointer"
                          onClick={() => handlePinProject(project)}
                        >
                          <PinIcon className="size-4 text-grey-700" />
                        </div>
                      </Tooltip>
                    </div>
                    <Box
                      key={index}
                      className="parent-card"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        border: '1px solid var(--grey-200)',
                        overflow: 'hidden',
                        borderRadius: '10px',
                        '&:hover img': {
                          opacity: '0.5'
                        },
                        '&:hover #overlayMenu': {
                          opacity: '0.8 !important'
                        }
                      }}
                    >
                      <Box
                        id={'project_' + index}
                        key={'project_' + index}
                        style={{
                          padding: '10px',
                          position: 'relative'
                        }}
                      >
                        <Box
                          sx={{
                            justifyContent: 'center',
                            width: '100%',
                            display: 'flex',
                            cursor: 'pointer'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <ImageRender
                              style={{
                                height: '100%',
                                width: '100%',
                                minWidth: '180px',
                                maxWidth: '200px',
                                minHeight: '220px',
                                objectFit: 'contain',
                                overflow: 'hidden',
                                transition: 'opacity 0.5s ease'
                              }}
                              src={thumbnail_url}
                              s3Obj={s3Obj}
                              // effect="blur"
                              overlay={
                                <Box
                                  id="overlayMenu"
                                  style={{
                                    position: 'absolute',
                                    top: '0%',
                                    right: '0%',
                                    opacity: 0,
                                    backgroundColor: 'var(--grey-700)',
                                    padding: '8px',
                                    width: '100%',
                                    height: '100%',
                                    boxSizing: 'border-box',
                                    zIndex: 1000,
                                    transition: 'opacity 0.3s ease'
                                  }}
                                >
                                  <div className="relative h-full">
                                    {thumbnail_url && (
                                      <div
                                        className="absolute"
                                        style={{
                                          top: '45%',
                                          left: '45%'
                                        }}
                                        onClick={() => {
                                          viewImage([{ src: thumbnail_url }])
                                        }}
                                      >
                                        <MaximizeIcon className="size-6 text-grey-100" />
                                      </div>
                                    )}

                                    {client_group?.id && (
                                      <Button2
                                        secondary
                                        disableHover
                                        onClick={() => {
                                          window.open(
                                            `/library/companies/${client_group?.id}`,
                                            '_blank'
                                          )
                                        }}
                                        style={{
                                          border: '1px solid var(--grey-100)',
                                          fontSize: '12px',
                                          position: 'absolute',
                                          bottom: '10px',
                                          width: '100%',
                                          color: 'var(--grey-100)',
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '8px'
                                        }}
                                      >
                                        View Client{' '}
                                        <RedirectIcon className="size-3" />
                                      </Button2>
                                    )}
                                  </div>
                                </Box>
                              }
                              fallback={
                                <>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      wordBreak: 'break-word'
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        '-webkit-line-clamp': 2,
                                        display: '-webkit-box',
                                        '-webkit-box-orient': 'vertical'
                                      }}
                                    >
                                      {displayName}
                                    </Box>
                                  </Box>
                                </>
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                      <div className="flex items-center justify-between p-3 bg-grey-100">
                        {proposal_name ? (
                          <Tooltip title={proposal_name}>
                            <p
                              className="m-0 text-xs font-medium cursor-pointer"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                              onClick={() => {
                                openPdf(proposal_id, page_number, sourceType)
                              }}
                            >
                              {proposal_name}
                            </p>
                          </Tooltip>
                        ) : (
                          <p className="m-0 text-sm font-medium">
                            Untitled Project
                          </p>
                        )}

                        <div className="relative w-16 h-full card-options">
                          <div className="flex justify-end rounded text-grey-700">
                            <span
                              onClick={(e) => handleClick(e, project)}
                              className="grid cursor-pointer place-content-center"
                            >
                              <ThreeDotsIcon
                                className="size-5"
                                strokeWidth={2}
                              />
                            </span>
                            <Menu
                              elevation={2}
                              TransitionComponent={Fade}
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleCloseMenu}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button'
                              }}
                              className="p-1"
                            >
                              <Button2
                                noOutline
                                secondary
                                style={{
                                  width: '100%'
                                }}
                                onClick={() =>
                                  handleDownloadCustomPdf(
                                    proposal_id,
                                    page_number - 1,
                                    proposal_name,
                                    thumbnail_url,
                                    sourceType
                                  )
                                }
                              >
                                Download Resume
                              </Button2>
                              {/* <Button2
                          noOutline
                          secondary
                          style={{
                            width: '100%'
                          }}
                          onClick={() => handleMove(resume)}
                        >
                          Move Resume
                        </Button2> */}
                              <Button2
                                noOutline
                                secondary
                                style={{
                                  width: '100%'
                                }}
                                onClick={() => {
                                  handleMasterMappingDelete(
                                    // project,
                                    index,
                                    'delete'
                                  )
                                }}
                              >
                                Remove Resume
                              </Button2>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </div>
                )
              })}
          </div>
        )}

        {filteredProjects?.length === 0 && (
          <div className="grid place-content-center">No results found</div>
        )}
      </div>
    </div>
  )
}

export default ProjectResumes
