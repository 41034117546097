import { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { useStyles } from './styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'

const MenuButton = (props) => {
  const {
    id = '',
    dark = false,
    options = [],
    menuElement = null,
    elementProps = {},
    buttonProps = {},
    iconProps = {},
    menuItemProps = {},
    dividerProps = {},
    disabled = false,
    menuOpenCallback = () => {},
    menuCloseCallback = () => {},
    menuProps = {},
    callBackData = {},
    showDivider = true,
    closeOnOptionClick = true
  } = props

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    event.stopPropagation()
    if (disabled) {
      return
    }
    menuOpenCallback(callBackData)
    setAnchorEl(event.currentTarget)
  }

  const handleOptionClick = (e, option) => {
    e.stopPropagation()
    if (disabled) {
      return
    }
    const { onClick } = option
    onClick && onClick(callBackData, e, option)
    if (closeOnOptionClick) {
      setAnchorEl(null)
      menuCloseCallback(callBackData)
    }
  }

  const handleClose = (e) => {
    e && e.stopPropagation()
    setAnchorEl(null)
    menuCloseCallback(callBackData)
  }

  const defaultMenuIcon = () => {
    return (
      <IconButton className={classes.iconButton} {...buttonProps}>
        <MoreVertIcon {...iconProps} />
      </IconButton>
    )
  }

  return (
    <Box id="menubutton">
      <Box {...elementProps} onClick={handleMenuOpen}>
        {menuElement || defaultMenuIcon()}
      </Box>
      <Menu
        TransitionComponent={Fade}
        className={dark ? classes.menuContainerDark : classes.menuContainer}
        disableScrollLock={true}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...menuProps}
      >
        {options.map((option, idx) => {
          const { disabled: optionDisabled = false, label, icon } = option
          return (
            <div key={idx}>
              <MenuItem
                disabled={optionDisabled || disabled}
                onClick={(e) => {
                  handleOptionClick(e, option)
                }}
                {...menuItemProps}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={label} />{' '}
              </MenuItem>
              {/* {showDivider && idx < options.length - 1 && (
                <Divider className={classes.dividerLine} {...dividerProps} />
              )} */}
            </div>
          )
        })}
      </Menu>
    </Box>
  )
}

export default MenuButton
