import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  LexicalEditor,
  $getRoot,
  $createTextNode,
  $createParagraphNode
} from 'lexical'
import { useEffect, useState, ChangeEvent } from 'react'
import Button from '../../ui/Button'
import { DialogActions } from '../../ui/Dialog'
import Loader from '../../../Loader'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import _, { set } from 'lodash'
import {
  getPeopleList,
  getAllProjectIds,
  getAnalytics
} from '../../../../store/api'
import { Auth } from 'aws-amplify'
import { $convertFromMarkdownString } from './markdown'
import { initalizeAWSSigner } from '../../../../utils/AWS'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Icon
} from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import EmailIcon from '@mui/icons-material/Email'
import RoofingIcon from '@mui/icons-material/Roofing'
import ConstructionIcon from '@mui/icons-material/Construction'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'

interface ProjectFields {
  project_summary: boolean
  location: boolean
  cost_details: boolean
  project_area: boolean
  timeline_durations: boolean
  team_members: boolean
}

interface customProjectFields {
  preferred_title: string
  formatting_preferences: string
  focus_area: string
  comments: string
  project_description: string
  project_team_members: string
  project_timeline: string
  project_value: string
  proejct_area: string
}

interface CoverLetterFields {
  sender_details: string
  recipient_details: string
  salutation: string
  date_of_letter: string
  formatting_preferences: string
  focus_area: string
}

interface PeopleFields {
  target_market_sector: string
  number_of_projects: number
  professional_summary: boolean
  educational_qualifications: boolean
  year_of_experience: boolean
  skills: boolean
  certifications: boolean
  contact_details: boolean
}

export const INSERT_RESUME_COMMAND: LexicalCommand<any> = createCommand(
  'INSERT_RESUME_COMMAND'
)

export function InsertResumeDailog({
  activeEditor,
  onClose,
  source = 'editor',
  handleChatInit = () => {},
  trackerId = ''
}: {
  activeEditor: LexicalEditor
  onClose: () => void
  source: string
  handleChatInit: any
  trackerId: string
}): JSX.Element {
  const [person, setPerson] = useState<null | {
    label: string
    value: string
  }>(null)
  const [keywords, setKeywords] = useState('')

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<any[]>([])
  const [signerObj, setSignerObj] = useState({})
  const [generatorType, setGeneratorType] = useState('')
  const [showGenerationOptions, setShowGenerationOptions] = useState(true)
  const [projectType, setProjectType] = useState('Existing Project')
  const [projectNames, setProjectNames] = useState<any[]>([])
  const [projectIdList, setProjectIdList] = useState<null | [value: string]>(
    null
  )
  const [proposalNames, setProposalNames] = useState<any[]>([])
  const [proposalIdList, setProposalIdList] = useState<null | [value: string]>(
    null
  )
  const [initialResumeLoad, setInitialResumeLoad] = useState(true)
  const [initialCoverLetterLoad, setInitialCoverLetterLoad] = useState(true)
  const [initialProjectLoad, setInitialProjectLoad] = useState(true)
  const [textareaValue, setTextareaValue] = useState('')
  const [aiInstructions, setAiInstructions] = useState('')
  const [outcomeHighligts, setOutcomeHighligts] = useState('')
  const [projectMarketSector, setProjectMarketSector] = useState('')
  const [projectTitle, setProjectTitle] = useState('')

  useEffect(() => {
    async function initalizeData() {
      const signer = await initalizeAWSSigner()
      setSignerObj(signer)
    }
    initalizeData()
  }, [])
  // useEffect(() => {
  //   const getPeople = async () => {
  //     const res = await getPeopleList();
  //     if (res.status === 200) {
  //       const people = res.data;
  //       const options = people.map((person: any) => {
  //         const resumeCount = person?.id?.length;
  //         const label = resumeCount ? `${person.name} (${resumeCount})` : person.name
  //         return {
  //           label: label,
  //           value: person.id,
  //           name: person.name
  //         }
  //       })
  //       setOptions(options);
  //     }
  //     setLoading(false);
  //   }
  //   getPeople();
  // }, []);

  useEffect(() => {
    if (initialResumeLoad && generatorType === 'Resume') {
      const getPeople = async () => {
        const res = await getPeopleList()
        if (res.status === 200) {
          const people = res.data
          const options = people.map((person: any) => {
            const resumeCount = person?.id_list?.length
            const label = resumeCount
              ? `${person.name} (${resumeCount})`
              : person.name
            return {
              label,
              value: person.id,
              name: person.name,
              people_ids: person.id_list
            }
          })
          setOptions(options)
        }
        setLoading(false)
      }
      getPeople()
      setInitialResumeLoad(false)
    } else if (initialCoverLetterLoad && generatorType === 'Cover Letter') {
      const getProposalId = async () => {
        setLoading(true)
        const res = await getAnalytics()
        if (res.status === 200) {
          let results = []
          try {
            results = JSON.parse(res?.data)
          } catch (e) {
            console.log(e)
          }
          const options = results.map((proposal: any) => {
            return {
              label: proposal?.document_name,
              value: proposal?.id,
              name: proposal?.document_name
            }
          })
          console.log(options)
          setProposalNames(options)
        }
        setLoading(false)
      }
      getProposalId()
      setInitialCoverLetterLoad(false)
    } else if (initialProjectLoad && generatorType === 'Project') {
      const getProjectsId = async () => {
        setLoading(true)
        const res = await getAllProjectIds()
        if (res.status === 200) {
          const results = res?.data?.project
          const options = results.map((project: any) => {
            return {
              label: project?.title,
              value: project?.content_ids,
              name: project?.id
            }
          })
          console.log(options)
          setProjectNames(options)
        }
        setLoading(false)
      }
      getProjectsId()
      setInitialProjectLoad(false)
    }
  }, [showGenerationOptions])

  const getResume = async (person: any, peopleFields: any) => {
    onClose()
    if (source === 'chat') {
      const data = {
        type: 'people',
        data: {
          name: person.name,
          id: person.value,
          people_ids: person.people_ids,
          user_instructions: {
            target_market_sector: peopleFields.target_market_sector,
            number_of_projects: peopleFields.number_of_projects,
            section_inclusion: {
              professional_summary: peopleFields.professional_summary,
              educational_qualifications:
                peopleFields.educational_qualifications,
              year_of_experience: peopleFields.year_of_experience,
              skills: peopleFields.skills,
              certifications: peopleFields.certifications,
              contact_details: peopleFields.contact_details
            },
            ai_instructions: aiInstructions
          }
        }
      }
      handleChatInit(
        'Generate a resume for - ' + person.name,
        trackerId,
        'resume',
        null,
        data
      )
      return
    }
    activeEditor.update(async () => {
      const root = $getRoot()
      const para = $createParagraphNode()
      root.append(para)
      let dots = 0
      para.append(
        $createTextNode(
          `Generating Resume for ${person.name} ${'.'.repeat(dots)}`
        )
      )

      const id = setInterval(() => {
        activeEditor.update(async () => {
          para.clear()
          para.append(
            $createTextNode(
              `Generating Resume for ${person.name} ${'.'.repeat(dots)}`
            )
          )
        })
        dots = (dots + 1) % 4
      }, 500)

      root.append($createParagraphNode())

      let isMessageEnd = false
      let completeText = ''
      let remainingText = ''

      const req1 = {
        people_name: person.name,
        people_id: person.value,
        user_instructions: {
          target_market_sector: peopleFields.target_market_sector,
          number_of_projects: peopleFields.number_of_projects,
          section_inclusion: {
            professional_summary: peopleFields.professional_summary,
            educational_qualifications: peopleFields.educational_qualifications,
            year_of_experience: peopleFields.year_of_experience,
            skills: peopleFields.skills,
            certifications: peopleFields.certifications,
            contact_details: peopleFields.contact_details
          },
          ai_instructions: aiInstructions
        }
      }
      const data = {
        forwardPayload: req1,
        is_resume: true,
        prompt: '',
        document_id: '',
        is_analytics: false,
        requestType: 'resume'
      }
      const url = process.env.REACT_APP_CHAT_URL || ''
      const apiUrl = new URL(url)
      const currentSession = await Auth.currentSession()
      const token = currentSession?.getAccessToken()?.getJwtToken()
      const signedUrl = await (signerObj as any).sign({
        method: 'POST',
        hostname: apiUrl.host,
        path: apiUrl.pathname,
        body: JSON.stringify(data),
        protocol: apiUrl.protocol,
        headers: {
          'Content-Type': 'application/json',
          host: apiUrl.hostname,
          userAuth: 'Bearer ' + token
        }
      })
      activeEditor.update(async () => {
        activeEditor.setEditable(false)
      })
      const response = await fetch(`${url}`, { ...signedUrl })
      clearInterval(id)
      if (response.status === 200 && response.body) {
        const reader = response.body.getReader()
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          const text = new TextDecoder().decode(value)
          if (text.includes('__END_OF_CHAT__')) {
            isMessageEnd = true
            remainingText = text.split('__END_OF_CHAT__')[0]
          }
          if (!isMessageEnd) {
            completeText = completeText + text
            activeEditor.dispatchCommand(INSERT_RESUME_COMMAND, {
              paragraph: para,
              text: completeText
            })
          } else {
            if (remainingText) {
              completeText = completeText + remainingText
              activeEditor.dispatchCommand(INSERT_RESUME_COMMAND, {
                paragraph: para,
                text: completeText
              })
            }
          }
        }
        if (!completeText) {
          activeEditor.update(async () => {
            para.clear()
            para.append($createTextNode('Resume Generation Failed'))
          })
        }
      } else {
        activeEditor.update(async () => {
          para.clear()
          para.append($createTextNode('Resume Generation Failed'))
        })
      }
      activeEditor.update(async () => {
        activeEditor.setEditable(true)
      })
    })
  }

  const getProject = async (project: any, type: string) => {
    onClose()
    if (source === 'chat') {
      const data = {
        type: 'project',
        data: {
          project_title: projectTitle,
          project_id_list: projectIdList,
          user_instructions: {
            target_market_sector: projectMarketSector,
            section_inclusion: {
              project_summary: projectFields.project_summary,
              location: projectFields.location,
              cost_details: projectFields.cost_details,
              project_area: projectFields.project_area,
              timeline_durations: projectFields.timeline_durations,
              team_members: projectFields.team_members
            },
            outcome_highlights: outcomeHighligts,
            ai_instructions: aiInstructions
          }
        }
      }
      handleChatInit(
        'Generate a resume for - ' + projectTitle,
        trackerId,
        'resume',
        null,
        data
      )
      return
    }
    activeEditor.update(async () => {
      const root = $getRoot()
      const para = $createParagraphNode()
      root.append(para)
      let dots = 0
      para.append(
        $createTextNode(
          `Generating Project for ${projectTitle} ${'.'.repeat(dots)}`
        )
      )

      const id = setInterval(() => {
        activeEditor.update(async () => {
          para.clear()
          para.append(
            $createTextNode(
              `Generating Project for ${projectTitle} ${'.'.repeat(dots)}`
            )
          )
        })
        dots = (dots + 1) % 4
      }, 500)

      root.append($createParagraphNode())

      const req = {
        project_title: projectTitle,
        project_id_list: projectIdList,
        user_instructions: {
          target_market_sector: projectMarketSector,
          section_inclusion: {
            project_summary: projectFields.project_summary,
            location: projectFields.location,
            cost_details: projectFields.cost_details,
            project_area: projectFields.project_area,
            timeline_durations: projectFields.timeline_durations,
            team_members: projectFields.team_members
          },
          outcome_highlights: outcomeHighligts,
          ai_instructions: aiInstructions
        }
      }
      let isMessageEnd = false
      let completeText = ''
      let remainingText = ''

      const data = {
        forwardPayload: req,
        is_resume: true,
        prompt: '',
        document_id: '',
        is_analytics: false,
        requestType: 'projectGenerate'
      }
      const url = process.env.REACT_APP_CHAT_URL || ''
      const apiUrl = new URL(url)
      const currentSession = await Auth.currentSession()
      const token = currentSession?.getAccessToken()?.getJwtToken()
      const signedUrl = await (signerObj as any).sign({
        method: 'POST',
        hostname: apiUrl.host,
        path: apiUrl.pathname,
        body: JSON.stringify(data),
        protocol: apiUrl.protocol,
        headers: {
          'Content-Type': 'application/json',
          host: apiUrl.hostname,
          userAuth: 'Bearer ' + token
        }
      })
      activeEditor.update(async () => {
        activeEditor.setEditable(false)
      })
      const response = await fetch(`${url}`, { ...signedUrl })
      clearInterval(id)
      if (response.status === 200 && response.body) {
        const reader = response.body.getReader()
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          const text = new TextDecoder().decode(value)
          if (text.includes('__END_OF_CHAT__')) {
            isMessageEnd = true
            remainingText = text.split('__END_OF_CHAT__')[0]
          }
          if (!isMessageEnd) {
            completeText = completeText + text
            activeEditor.dispatchCommand(INSERT_RESUME_COMMAND, {
              paragraph: para,
              text: completeText
            })
          } else {
            if (remainingText) {
              completeText = completeText + remainingText
              activeEditor.dispatchCommand(INSERT_RESUME_COMMAND, {
                paragraph: para,
                text: completeText
              })
            }
          }
        }
        if (!completeText) {
          activeEditor.update(async () => {
            para.clear()
            para.append($createTextNode('Project Generation Failed'))
          })
        }
      } else {
        activeEditor.update(async () => {
          para.clear()
          para.append($createTextNode('Project Generation Failed'))
        })
      }
      activeEditor.update(async () => {
        activeEditor.setEditable(true)
      })
    })
  }

  const getCoverLetter = async (coverLetter: any) => {
    onClose()
    activeEditor.update(async () => {
      const root = $getRoot()
      const para = $createParagraphNode()
      root.append(para)
      let dots = 0
      para.append(
        $createTextNode(
          `Generating Cover Letter for ${
            coverLetter.sender_details
          } ${'.'.repeat(dots)}`
        )
      )

      const id = setInterval(() => {
        activeEditor.update(async () => {
          para.clear()
          para.append(
            $createTextNode(
              `Generating Cover Letter for ${
                coverLetter.sender_details
              } ${'.'.repeat(dots)}`
            )
          )
        })
        dots = (dots + 1) % 4
      }, 500)

      root.append($createParagraphNode())

      let isMessageEnd = false
      let completeText = ''
      let remainingText = ''

      const req = {
        proposal_type: 'analytics',
        proposal_id: proposalIdList,
        sender_details: coverLetter.sender_details,
        recipient_details: coverLetter.recipient_details,
        salutation: coverLetter.salutation,
        date_of_letter: coverLetter.date_of_letter,
        additional_information: textareaValue,
        formatting_preferences: coverLetter.formatting_preferences,
        focus_area: coverLetter.focus_area
      }
      const data = {
        forwardPayload: req,
        is_resume: true,
        prompt: '',
        document_id: '',
        is_analytics: false,
        requestType: 'coverletterGenerate'
      }
      const url = process.env.REACT_APP_CHAT_URL || ''
      const apiUrl = new URL(url)
      const currentSession = await Auth.currentSession()
      const token = currentSession?.getAccessToken()?.getJwtToken()
      const signedUrl = await (signerObj as any).sign({
        method: 'POST',
        hostname: apiUrl.host,
        path: apiUrl.pathname,
        body: JSON.stringify(data),
        protocol: apiUrl.protocol,
        headers: {
          'Content-Type': 'application/json',
          host: apiUrl.hostname,
          userAuth: 'Bearer ' + token
        }
      })
      activeEditor.update(async () => {
        activeEditor.setEditable(false)
      })
      const response = await fetch(`${url}`, { ...signedUrl })
      clearInterval(id)
      if (response.status === 200 && response.body) {
        const reader = response.body.getReader()
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          const text = new TextDecoder().decode(value)
          if (text.includes('__END_OF_CHAT__')) {
            isMessageEnd = true
            remainingText = text.split('__END_OF_CHAT__')[0]
          }
          if (!isMessageEnd) {
            completeText = completeText + text
            activeEditor.dispatchCommand(INSERT_RESUME_COMMAND, {
              paragraph: para,
              text: completeText
            })
          } else {
            if (remainingText) {
              completeText = completeText + remainingText
              activeEditor.dispatchCommand(INSERT_RESUME_COMMAND, {
                paragraph: para,
                text: completeText
              })
            }
          }
        }
        if (!completeText) {
          activeEditor.update(async () => {
            para.clear()
            para.append($createTextNode('Cover Letter Generation Failed'))
          })
        }
      } else {
        activeEditor.update(async () => {
          para.clear()
          para.append($createTextNode('Cover Letter Generation Failed'))
        })
      }
      activeEditor.update(async () => {
        activeEditor.setEditable(true)
      })
    })
  }

  const onClick = () => {
    setLoading(true)
    if (person) {
      getResume(person, peopleFields)
    }
  }

  const handleGeneratorTypeChange = (event: any) => {
    setGeneratorType(event.target.value)
  }

  const handleProjectTypeChange = (event: any) => {
    setProjectType(event.target.value)
  }

  const generatorOptions = [
    {
      icon: <PersonAddIcon />,
      label: 'Resume',
      value: 'Resume'
    },
    // {
    //   icon: <EmailIcon />,
    //   label: 'Cover Letter',
    //   value: 'Cover Letter'
    // },
    {
      icon: <ConstructionIcon />,
      label: 'Project Resume',
      value: 'Project'
    }
  ]
  const customProjectOptions = ['Custom Project', 'Existing Project']

  const [projectFields, setProjectFields] = useState<ProjectFields>({
    project_summary: true,
    location: true,
    cost_details: true,
    project_area: true,
    timeline_durations: true,
    team_members: true
  })

  const projectFieldNames: Array<keyof ProjectFields> = [
    'project_summary',
    'location',
    'cost_details',
    'project_area',
    'timeline_durations',
    'team_members'
  ]

  const projectFieldPlaceholders: { [key in keyof ProjectFields]: string } = {
    project_summary: 'Project Summary',
    location: 'Location',
    cost_details: 'Cost Details',
    project_area: 'Project Area',
    timeline_durations: 'Timeline/Duration',
    team_members: 'Team Members'
  }

  const handleProjectFieldChange = (
    fieldName: keyof ProjectFields,
    value: string | boolean
  ) => {
    setProjectFields({ ...projectFields, [fieldName]: value })
  }

  const [customProjectFields, setCustomProjectFields] =
    useState<customProjectFields>({
      preferred_title: '',
      formatting_preferences: '',
      focus_area: '',
      comments: '',
      project_description: '',
      project_team_members: '',
      project_timeline: '',
      project_value: '',
      proejct_area: ''
    })

  const customProjectFieldNames: Array<keyof customProjectFields> = [
    'preferred_title',
    'formatting_preferences',
    'focus_area',
    'comments',
    'project_description',
    'project_team_members',
    'project_timeline',
    'project_value',
    'proejct_area'
  ]

  const customProjectFieldPlaceholders: {
    [key in keyof customProjectFields]: string
  } = {
    preferred_title: 'Project Title *',
    formatting_preferences: 'How should the project be formatted?',
    focus_area: 'What should the project focus on?',
    comments: 'Additional comments while generating the project',
    project_description: 'Project Description - location, summary',
    project_team_members: 'Team Members involved in the project',
    project_timeline: 'Project Timeline',
    project_value: 'Project Budget',
    proejct_area: 'Project Square Footage'
  }

  const handleCustomProjectFieldChange =
    (fieldName: keyof customProjectFields) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setCustomProjectFields({
        ...customProjectFields,
        [fieldName]: event.target.value
      })
    }

  const handleGenerateProject = () => {
    setLoading(true)
    if (projectType === 'Custom Project') {
      if (customProjectFields.preferred_title) {
        getProject(customProjectFields, projectType)
      }
    } else {
      // if (projectFields.preferred_title) {
      //   getProject(projectFields, projectType)
      // }
      getProject(projectFields, projectType)
    }
  }

  const [coverLetterFields, setCoverLetterFields] = useState<CoverLetterFields>(
    {
      sender_details: '',
      recipient_details: '',
      salutation: '',
      date_of_letter: '',
      formatting_preferences: '',
      focus_area: ''
    }
  )

  const coverLetterFieldNames: Array<keyof CoverLetterFields> = [
    'sender_details',
    'recipient_details',
    'salutation',
    'date_of_letter',
    'formatting_preferences',
    'focus_area'
  ]

  const coverLetterFieldPlaceholders: {
    [key in keyof CoverLetterFields]: string
  } = {
    sender_details: 'Correspondent Information',
    recipient_details: 'Recipient Information',
    salutation: 'Salutation',
    date_of_letter: 'Date of Letter',
    formatting_preferences: 'Formatting Preferences',
    focus_area: 'Focus Area'
  }

  const handleCoverLetterFieldChange =
    (fieldName: keyof CoverLetterFields) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setCoverLetterFields({
        ...coverLetterFields,
        [fieldName]: event.target.value
      })
    }

  const handleGenerateCoverLetter = () => {
    setLoading(true)
    if (proposalIdList) {
      getCoverLetter(coverLetterFields)
    }
  }

  const [peopleFields, setPeopleFields] = useState<PeopleFields>({
    target_market_sector: '',
    number_of_projects: 1,
    professional_summary: true,
    educational_qualifications: true,
    year_of_experience: true,
    skills: true,
    certifications: true,
    contact_details: true
  })

  const peopleFieldNames: Array<keyof PeopleFields> = [
    'target_market_sector',
    'number_of_projects',
    'professional_summary',
    'educational_qualifications',
    'year_of_experience',
    'skills',
    'certifications',
    'contact_details'
  ]

  const peopleFieldPlaceholders: { [key in keyof PeopleFields]: string } = {
    target_market_sector: 'Target Market Sector',
    number_of_projects: 'Number of Projects',
    professional_summary: 'Professional Summary',
    educational_qualifications: 'Educational Qualifications',
    year_of_experience: 'Years of Experience',
    skills: 'Skills',
    certifications: 'Certifications',
    contact_details: 'Contact Details'
  }

  const handlePeopleFieldChange = (
    fieldName: keyof PeopleFields,
    value: string | boolean
  ) => {
    setPeopleFields({ ...peopleFields, [fieldName]: value })
  }

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value)
  }

  const handleAiInstructionsChange = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAiInstructions(event.target.value)
  }

  return (
    <div data-dialog-parent={source}>
      {loading ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader loading={loading} />
        </div>
      ) : showGenerationOptions ? (
        <>
          <FormControl component="fieldset">
            <FormLabel component="legend">Generator Type</FormLabel>
            <RadioGroup
              aria-label="generator-type"
              name="generatorType"
              value={generatorType}
              onChange={handleGeneratorTypeChange}
              sx={{ flexDirection: 'column' }}
            >
              {generatorOptions.map((option) => (
                <FormControlLabel
                  key={option?.value}
                  value={option?.value}
                  control={<Radio />}
                  label={
                    <div style={{ display: 'flex' }}>
                      <Icon
                        sx={{
                          marginRight: '5px'
                        }}
                      >
                        {option.icon}
                      </Icon>
                      {option?.label}
                    </div>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
          <DialogActions>
            <Button
              disabled={generatorType === ''}
              onClick={() => {
                setShowGenerationOptions(false)
              }}
            >
              Next
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          {generatorType === 'Resume' && (
            <>
              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: '20px'
                }}
              >
                <Grid item xs={4} key={'person'}>
                  <Autocomplete
                    disablePortal
                    options={options}
                    onChange={(event, value) => setPerson(value)}
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        padding: '0px 3.5px',
                        maxWidth: '-webkit-fill-available'
                      },
                      '& .MuiOutlinedInput-input': {
                        fontSize: 'revert'
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select a Person *"
                        type="text"
                        variant="outlined"
                        label=""
                      />
                    )}
                  />
                </Grid>

                {peopleFieldNames.map((fieldName) => {
                  const fieldValue = peopleFields[fieldName]

                  let inputComponent
                  if (typeof fieldValue === 'boolean') {
                    inputComponent = (
                      <Grid item xs={4} key={fieldName}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={fieldValue}
                              onChange={(event) =>
                                handlePeopleFieldChange(
                                  fieldName,
                                  event.target.checked
                                )
                              }
                              sx={{}}
                            />
                          }
                          label={peopleFieldPlaceholders[fieldName]}
                        />
                      </Grid>
                    )
                  } else {
                    inputComponent = (
                      <Grid item xs={4} key={fieldName}>
                        <TextField
                          key={fieldName}
                          value={fieldValue}
                          onChange={(event) => {
                            handlePeopleFieldChange(
                              fieldName,
                              event.target.value
                            )
                          }}
                          variant="outlined"
                          fullWidth
                          multiline
                          maxRows={3}
                          inputProps={{
                            placeholder: peopleFieldPlaceholders[fieldName]
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              padding: '7.5px 7.5px'
                            },
                            '& .MuiOutlinedInput-input': {
                              fontSize: 'revert'
                            }
                          }}
                        />
                      </Grid>
                    )
                  }

                  return inputComponent
                })}
              </Grid>
              <TextField
                key={'ai_instructions'}
                value={aiInstructions}
                onChange={handleAiInstructionsChange}
                variant="outlined"
                fullWidth
                multiline
                minRows={3}
                inputProps={{
                  placeholder: 'Additional instructions to AI'
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '7.5px 7.5px'
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: 'revert'
                  }
                }}
              />
              <DialogActions>
                <Button onClick={() => setShowGenerationOptions(true)}>
                  Back
                </Button>
                <span style={{ width: '10px' }}></span>
                <Button disabled={!person} onClick={onClick}>
                  Generate
                </Button>
              </DialogActions>
            </>
          )}
          {generatorType === 'Cover Letter' && (
            <>
              <Autocomplete
                disablePortal
                options={proposalNames}
                onChange={(event, value) => setProposalIdList(value?.value)}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    padding: '0px 3.5px',
                    margin: '7.5px',
                    maxWidth: '-webkit-fill-available'
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: 'revert'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select a Proposal *"
                    type="text"
                    variant="outlined"
                    label=""
                  />
                )}
              />
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {coverLetterFieldNames.map((fieldName) => (
                  <TextField
                    key={fieldName}
                    value={coverLetterFields[fieldName]}
                    onChange={handleCoverLetterFieldChange(fieldName)}
                    variant="outlined"
                    fullWidth
                    multiline
                    maxRows={3}
                    inputProps={{
                      placeholder: coverLetterFieldPlaceholders[fieldName]
                    }}
                    sx={{
                      flex: '1 0 48%',
                      margin: '7.5px',
                      '& .MuiOutlinedInput-root': {
                        padding: '7.5px 7.5px'
                      },
                      '& .MuiOutlinedInput-input': {
                        fontSize: 'revert'
                      }
                    }}
                  />
                ))}
                <TextField
                  key={'additional_information'}
                  value={textareaValue}
                  onChange={handleTextareaChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={3}
                  inputProps={{
                    placeholder: 'Additional Information'
                  }}
                  sx={{
                    flex: '1 0 48%',
                    margin: '7.5px',
                    '& .MuiOutlinedInput-root': {
                      padding: '7.5px 7.5px'
                    },
                    '& .MuiOutlinedInput-input': {
                      fontSize: 'revert'
                    }
                  }}
                />
              </div>
              <DialogActions>
                <Button onClick={() => setShowGenerationOptions(true)}>
                  Back
                </Button>
                <span style={{ width: '10px' }}></span>
                <Button
                  disabled={!proposalIdList}
                  onClick={() => handleGenerateCoverLetter()}
                >
                  Generate
                </Button>
              </DialogActions>
            </>
          )}
          {generatorType === 'Project' && (
            <>
              {
                <>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginBottom: '20px'
                    }}
                  >
                    <Grid item xs={12} key={'project'}>
                      <Autocomplete
                        disablePortal
                        options={projectNames}
                        onChange={(event, value) => {
                          setProjectIdList(value?.value)
                          setProjectTitle(value?.label)
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            padding: '0px 3.5px',
                            maxWidth: '-webkit-fill-available'
                          },
                          '& .MuiOutlinedInput-input': {
                            fontSize: 'revert'
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a Project"
                            type="text"
                            variant="outlined"
                            label=""
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginBottom: '20px'
                    }}
                  >
                    <Grid item xs={12} key={'project_market_sector'}>
                      <TextField
                        key={'project_market_sector'}
                        value={projectMarketSector}
                        onChange={(event) => {
                          setProjectMarketSector(event.target.value)
                        }}
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={3}
                        inputProps={{
                          placeholder: 'Target Market Sector'
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            padding: '7.5px 7.5px'
                          },
                          '& .MuiOutlinedInput-input': {
                            fontSize: 'revert'
                          }
                        }}
                      />
                    </Grid>
                    {projectFieldNames.map((fieldName) => {
                      const fieldValue = projectFields[fieldName]

                      let inputComponent
                      if (typeof fieldValue === 'boolean') {
                        inputComponent = (
                          <Grid item xs={4} key={fieldName}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={fieldValue}
                                  onChange={(event) =>
                                    handleProjectFieldChange(
                                      fieldName,
                                      event.target.checked
                                    )
                                  }
                                  sx={{}}
                                />
                              }
                              label={projectFieldPlaceholders[fieldName]}
                            />
                          </Grid>
                        )
                      } else {
                        inputComponent = (
                          <Grid item xs={6} key={fieldName}>
                            <TextField
                              key={fieldName}
                              value={fieldValue}
                              onChange={(event) => {
                                handleProjectFieldChange(
                                  fieldName,
                                  event.target.value
                                )
                              }}
                              variant="outlined"
                              fullWidth
                              multiline
                              maxRows={3}
                              inputProps={{
                                placeholder: projectFieldPlaceholders[fieldName]
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  padding: '7.5px 7.5px'
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: 'revert'
                                }
                              }}
                            />
                          </Grid>
                        )
                      }

                      return inputComponent
                    })}
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginBottom: '20px'
                    }}
                  >
                    <Grid item xs={6} key={'project_market_sector1'}>
                      <TextField
                        key={'outcome_highligts'}
                        value={outcomeHighligts}
                        onChange={(event) => {
                          setOutcomeHighligts(event.target.value)
                        }}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={3}
                        inputProps={{
                          placeholder: 'Outcome Highlights'
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            padding: '7.5px 7.5px',
                            marginBottom: '20px'
                          },
                          '& .MuiOutlinedInput-input': {
                            fontSize: 'revert'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} key={'project_market_sector2'}>
                      <TextField
                        key={'ai_instructions'}
                        value={aiInstructions}
                        onChange={handleAiInstructionsChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={3}
                        inputProps={{
                          placeholder: 'Additional instructions to AI'
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            padding: '7.5px 7.5px',
                            marginBottom: '20px'
                          },
                          '& .MuiOutlinedInput-input': {
                            fontSize: 'revert'
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              }
              <DialogActions>
                <Button onClick={() => setShowGenerationOptions(true)}>
                  Back
                </Button>
                <span style={{ width: '10px' }}></span>
                <Button
                  disabled={false}
                  onClick={() => handleGenerateProject()}
                >
                  Generate
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default function ResumePlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  const insertPayload = async (payload: any) => {
    const { paragraph, text } = payload
    $convertFromMarkdownString(text, paragraph)
  }

  useEffect(() => {
    return editor.registerCommand<string>(
      INSERT_RESUME_COMMAND,
      (payload) => {
        insertPayload(payload)
        return true
      },
      COMMAND_PRIORITY_EDITOR
    )
  }, [editor])
  return null
}
