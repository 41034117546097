import React, { useState, useEffect, useMemo } from 'react'

import {
  LazyLoadImage,
  LazyLoadImageProps
} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { S3Object } from 'aws-sdk/clients/rekognition'
import { ThumbnailLocations } from '@/types/resources.types'
import { useBlurhash } from '@/hooks/use-blurhash'
import { checkExpiry } from '@/utils/AWS/getSignedUrl'
import _ from 'lodash'

type ImageResourceProps = LazyLoadImageProps & {
  thumbnails: {
    blurhash: string
    resolution: [number, number]
    thumbnail_location: ThumbnailLocations
  }
  s3Obj?: S3Object
  width?: string
  item?: any
}

const ImageResource: React.FC<ImageResourceProps> = ({
  thumbnails,
  s3Obj = {},
  width,
  item,
  ...props
}) => {
  const blurDataUrl = useBlurhash(thumbnails?.blurhash)
  const [error, setError] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const [signedUrls, setSignedUrls] = useState<Record<string, string>>({})

  // Memoize the thumbnail data to prevent unnecessary processing
  const thumbnailData = useMemo(() => {
    if (!_.isEmpty(thumbnails)) return thumbnails
    return null
  }, [thumbnails])

  useEffect(() => {
    // Only fetch signed URLs if we don't have them already or if we're retrying
    if (
      (_.isEmpty(signedUrls) || retryCount > 0) &&
      thumbnailData &&
      retryCount < 3
    ) {
      const fetchSignedUrls = async () => {
        try {
          const thumbnailUrls: Record<string, string> = {}
          const entries = Object.entries(thumbnailData.thumbnail_location) as [
            string,
            string
          ][]

          // Process thumbnail URLs in parallel for better performance
          const results = await Promise.all(
            entries.map(async ([size, url]) => {
              if (url) {
                try {
                  const signedUrl = await checkExpiry(url, s3Obj)
                  return { size, url, signedUrl }
                } catch (err) {
                  console.error(`Failed to get signed URL for ${size}:`, err)
                }
              }
              return null
            })
          )

          // Build the result object
          results.forEach((result) => {
            if (result) {
              thumbnailUrls[result.url] = result.signedUrl
            }
          })

          setSignedUrls(thumbnailUrls)
        } catch (error) {
          console.error('Error fetching signed URLs:', error)
        }
      }

      fetchSignedUrls()
    }
  }, [thumbnailData, s3Obj, retryCount])

  // Generate srcSet from available thumbnails
  const getSrcSet = () => {
    const { thumbnail_location } = thumbnailData || {}

    if (!thumbnail_location) return ''
    const srcSetParts: string[] = []

    // Add available thumbnail sizes
    Object.entries(thumbnail_location).forEach(([size, url]) => {
      if (
        url &&
        typeof url === 'string' &&
        signedUrls[url] &&
        size !== 'compressed' &&
        size !== 'raw'
      ) {
        srcSetParts.push(`${signedUrls[url]} ${size}`)
      }
    })

    // Add compressed WebP for larger sizes
    if (
      thumbnailData?.thumbnail_location?.compressed &&
      signedUrls[thumbnailData.thumbnail_location.compressed]
    ) {
      srcSetParts.push(
        `${signedUrls[thumbnailData.thumbnail_location.compressed]} 3840w` // very high value to handle maximum width
      )
    }

    return srcSetParts.join(', ')
  }

  const fallbackSrc = thumbnailData?.thumbnail_location?.raw
    ? signedUrls[thumbnailData.thumbnail_location.raw]
    : undefined

  const handleImageError = () => {
    if (retryCount < 3) {
      // Increment retry count and trigger a re-fetch of signed URLs
      setRetryCount((prevCount) => prevCount + 1)
    } else {
      // After 3 retries, show error state
      setError(true)
    }
  }

  return (
    <>
      <LazyLoadImage
        src={fallbackSrc}
        srcSet={getSrcSet()}
        sizes={width || '100vw'}
        placeholderSrc={error ? undefined : blurDataUrl}
        effect="blur"
        onError={handleImageError}
        {...props}
      />
      {error && (
        <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-gray-100">
          <p className="text-xs text-zinc-700 line-clamp-3 break-words px-4 text-center">
            {item.file_name}
          </p>
        </div>
      )}
    </>
  )
}

export default ImageResource
