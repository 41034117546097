import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useStyles } from './styles'
import Box from '@mui/material/Box'
import { toast } from 'react-toastify'
import { CloudUpload, FolderIcon } from '../Icons/Icons'
import Button from '../Button'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'
import GoogleIcon from '@mui/icons-material/Google'

const Dropzone = (props) => {
  const { helperText = '', typeSelector = '', dashboard = '' } = props
  const classes = useStyles()
  const { outerContainer } = classes

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex'
      }}
    >
      <Box className={outerContainer}>
        <>
          {dashboard && (
            <div
              style={{
                marginTop: '15px',
                fontSize: '14px',
                color: 'var(--grey-500)'
              }}
            >
              {dashboard}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              marginTop: '15px'
            }}
          >
            <div>{helperText}</div>
          </div>
          {typeSelector && (
            <div
              style={{
                marginTop: '15px',
                fontSize: '14px',
                color: 'var(--grey-500)'
              }}
            >
              {typeSelector}
            </div>
          )}
        </>
      </Box>
    </Box>
  )
}

export default Dropzone
