import _, { set } from 'lodash'
import { useStyles } from './styles'
import HoverVideoPlayer from 'react-hover-video-player'
import Box from '@mui/material/Box'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useState, useEffect, memo } from 'react'
import PlaceholderImage from '../../assets/images/placeholder.png'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { checkExpiry } from '../../utils/AWS/getSignedUrl'
import Checkbox from '@mui/material/Checkbox'
import Loader from '../../components/Loader'
import DescriptionIcon from '@mui/icons-material/Description'
import AssetViewSidePane from '../AssetViewSidePane'
import ImageResource from '@/sections/ResourceTable/image-resource'
import { cn } from '@/lib/utils'

export const AssetType = ({
  styleProps = {},
  keyIndex = 0,
  key = 0,
  asset = {},
  parentProps,
  allowDownload = true,
  srcType = ''
}) => {
  const {
    s3Obj,
    onClickFile = () => {},
    toDelete,
    setToDelete = () => {},
    isMultiSelect
  } = parentProps
  const { id, orginalFile, fileName, resultType, src } = asset
  const showCheckBox = true
  const [url, setUrl] = useState('')
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [error, setError] = useState(false)

  const isEditorDrag = srcType === 'editor'
  useEffect(() => {
    async function initalizeData() {
      let { url, thumbnailLocationUrl } = asset
      url = await checkExpiry(url, s3Obj)
      url = url + '&cacheblock=true'
      setUrl(url)
      if (thumbnailLocationUrl) {
        thumbnailLocationUrl = await checkExpiry(thumbnailLocationUrl, s3Obj)
        thumbnailLocationUrl = thumbnailLocationUrl + '&cacheblock=true'
        setThumbnailUrl(thumbnailLocationUrl)
      }
    }
    initalizeData()
  }, [asset])

  const { style = {} } = styleProps

  const checked = toDelete?.includes(id)

  let { width = 430, height = 290, top, left } = style
  if (checked) {
    width = parseInt(width) - 20 + 'px'
    height = parseInt(height) - 20 + 'px'
  }

  let seekSeconds
  if (asset.data?.segment) {
    const startTime = asset.data?.segment.start_time.replace(';', ':')
    const [hours, minutes, seconds, milliseconds] = startTime?.split(':')
    const seek_seconds =
      parseInt(hours) * 60 * 60 +
      parseInt(minutes) * 60 +
      parseInt(seconds) +
      milliseconds / 100
    seekSeconds = seek_seconds
  }
  const classes = useStyles(style)
  const { imageContainer, hoverVideo, imageWrapper } = classes

  const handleImageDrag = (event, imageURL) => {
    event.dataTransfer.setData(
      'application/x-lexical-drag',
      JSON.stringify({
        data: {
          src: imageURL
        },
        type: 'image'
      })
    )
    event.dataTransfer.setData('dragCustom', JSON.stringify(true))
    const dragIcon = document.getElementById('img_drag' + keyIndex).cloneNode()
    event.dataTransfer.setDragImage(dragIcon, 0, 0)
  }

  if (resultType === 'video') {
    return (
      <>
        {isMultiSelect && (
          <Box
            className="deleteButton"
            sx={{ ...style, cursor: showCheckBox ? 'pointer' : 'not-allowed' }}
            key={key}
            onClick={(e) => {
              if (showCheckBox) {
                setToDelete(!checked, id)
              }
            }}
          >
            {showCheckBox && (
              <Box
                sx={{
                  position: 'absolute',
                  background: '#e8f0fe94',
                  right: '0'
                }}
              >
                <Checkbox
                  sx={{
                    padding: '0px',
                    color: 'black',
                    right: '0',
                    '&.Mui-checked': {
                      color: 'black'
                    }
                  }}
                  checked={checked}
                />
              </Box>
            )}
          </Box>
        )}
        <div className={imageContainer} style={{ ...style }} key={key}>
          <div
            id="video player"
            style={{
              position: 'absolute',
              zIndex: 100,
              color: 'white',
              top: 2,
              left: 0
            }}
          >
            <YouTubeIcon />
          </div>
          {asset.data?.segment ? (
            <HoverVideoPlayer
              className={hoverVideo}
              videoSrc={url}
              playbackRangeStart={seekSeconds + 0.5}
              playbackRangeEnd={seekSeconds + 6}
              loop={true}
              onClick={(e) => {
                onClickFile(e, asset)
              }}
              restartOnPaused
            />
          ) : thumbnailUrl ? (
            <HoverVideoPlayer
              className={hoverVideo}
              videoSrc={url}
              loop={true}
              onClick={(e) => {
                onClickFile(e, asset)
              }}
              pausedOverlay={
                <img src={thumbnailUrl} alt="" style={{ width, height }} />
              }
            />
          ) : (
            <HoverVideoPlayer
              className={hoverVideo}
              playbackRangeStart={1}
              videoSrc={url}
              onClick={(e) => {
                onClickFile(e, asset)
              }}
            />
          )}
        </div>
      </>
    )
  }
  return (
    <>
      {isMultiSelect && (
        <Box
          className="deleteButton"
          sx={{
            ...style,
            cursor: showCheckBox ? 'pointer' : 'not-allowed !important'
          }}
          key={key}
          onClick={(e) => {
            if (showCheckBox) {
              setToDelete(!checked, id)
            }
          }}
        >
          {showCheckBox && (
            <Box
              sx={{ position: 'absolute', background: '#e8f0fe94', right: '0' }}
            >
              <Checkbox
                sx={{
                  padding: '0px',
                  color: 'black',
                  '&.Mui-checked': {
                    color: 'black'
                  }
                }}
                checked={checked}
              />
            </Box>
          )}
        </Box>
      )}
      <div
        id="image-div"
        className={cn(
          '!bg-gray-100 !border-[0.5px] !border-zinc-200 !border-solid box-border rounded-md overflow-hidden flex items-center justify-center',
          imageContainer
        )}
        style={{
          ...style
        }}
        key={key}
      >
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            color: 'white',
            top: 2,
            left: 0
          }}
        >
          {!showCheckBox && <DescriptionIcon />}
        </div>
        <div className={isEditorDrag ? imageWrapper : 'w-full h-full'}>
          {!_.isEmpty(asset.data?.thumbnail_info) ? (
            <ImageResource
              id={'img_drag' + keyIndex}
              thumbnails={asset.data?.thumbnail_info}
              width={width}
              style={{ width, height }}
              onClick={(e) => onClickFile(e, asset)}
              s3Obj={s3Obj}
              draggable={isEditorDrag}
              onDragStart={(event) => {
                handleImageDrag(event, thumbnailUrl || url)
              }}
            />
          ) : (
            <>
              <LazyLoadImage
                onError={() => {
                  if (thumbnailUrl || url) {
                    setError(true)
                  }
                }}
                id={'img_drag' + keyIndex}
                onClick={(e) => {
                  onClickFile(e, asset)
                }}
                height={height}
                width={width}
                PlaceholderSrc={PlaceholderImage}
                effect="blur"
                src={thumbnailUrl || url}
                draggable={isEditorDrag}
                onDragStart={(event) => {
                  handleImageDrag(event, thumbnailUrl || url)
                }}
                className="object-contain w-full h-full object-center"
              />
              {error && (
                <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-gray-100">
                  <p className="text-xs text-zinc-700 line-clamp-3 break-words px-4 text-center">
                    {asset.data.image_name}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export const AssetViewSidePaneMemo = memo(
  (props) => {
    return <AssetViewSidePane {...props} />
  },
  (prevProps, nextProps) => {
    const assetCheck =
      _.isEqual(prevProps.asset, nextProps.asset) &&
      _.isEqual(prevProps.asset, nextProps.asset)
    const sizeCheck =
      _.isEqual(prevProps.imagePaneSize, nextProps.imagePaneSize) &&
      _.isEqual(prevProps.imagePaneSize, nextProps.imagePaneSize)
    const s3Check =
      _.isEqual(prevProps.s3Obj, nextProps.s3Obj) &&
      _.isEqual(prevProps.s3Obj, nextProps.s3Obj)
    const searchKeyCheck =
      _.isEqual(prevProps.searchKey, nextProps.searchKey) &&
      _.isEqual(prevProps.searchKey, nextProps.searchKey)
    return assetCheck && sizeCheck && s3Check && searchKeyCheck
  }
)
