import React, { useEffect } from 'react'

import {
  DropdownMenuCheckboxItemProps,
  DropdownMenuItem
} from '@radix-ui/react-dropdown-menu'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import {
  CheckCircleIcon,
  InProgressSemiCircleIcon,
  StopIcon,
  CicleCross,
  SubmittedRocketIcon,
  TargetIcon,
  CheckIcon
} from '@/components/Icons/Icons'
import { cn } from '@/lib/utils'
import { ModernTooltip } from '@/components/ModernTooltip/ModernTooltip'

type Checked = DropdownMenuCheckboxItemProps['checked']

export function StatusDropdown({
  initialStatus,
  changeStatus,
  groupType = 'opportunity',
  showLabel = false,
  placement = 'right',
  disabled = false
}: {
  initialStatus: string
  groupType: 'opportunity' | 'project'
  changeStatus: (status: string) => void
  showLabel?: boolean
  placement?: 'left' | 'right' | 'bottom' | 'top'
  disabled?: boolean
}) {
  const [status, setStatus] = React.useState<string>(initialStatus)
  const [statusOptions, setStatusOptions] = React.useState<any[]>([])
  useEffect(() => {
    setStatus(initialStatus)
    if (groupType === 'opportunity') {
      setStatusOptions(opportunityStatusOptions)
    } else {
      setStatusOptions(projectStatusOptions)
    }
  }, [initialStatus, groupType])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <button
          className={cn(
            'flex items-center gap-1 rounded-md text-xxs font-medium outline-none twp p-1.5',
            statusOptions.find((option) => option.value === status)?.className,
            !showLabel && 'aspect-square',
            showLabel && 'bg-white p-1 px-2 hover:bg-zinc-100'
          )}
        >
          <ModernTooltip
            align="start"
            content={
              <div className="flex items-center gap-2 text-xs twp bg-white border border-zinc-200">
                <p
                  className={cn(
                    'text-grey-800',
                    statusOptions.find((option) => option.value === status)
                      ?.className
                  )}
                >
                  {
                    statusOptions.find((option) => option.value === status)
                      ?.icon
                  }
                </p>
                {/* <p className="text-grey-800">Status</p> */}
                <p className="text-xs font-medium text-grey-800">
                  {
                    statusOptions.find((option) => option.value === status)
                      ?.label
                  }
                </p>
              </div>
            }
          >
            <div className="flex items-center gap-1">
              {statusOptions.find((option) => option.value === status)?.icon}
              {showLabel && (
                <p className="text-grey-800 font-medium text-xs m-0 ml-0.5">
                  {
                    statusOptions.find((option) => option.value === status)
                      ?.label
                  }
                </p>
              )}
            </div>
          </ModernTooltip>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-40" side={placement}>
        <DropdownMenuLabel className="text-xs font-medium text-zinc-500">
          Change Status
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {statusOptions.map((option) => (
          <DropdownMenuItem
            key={option.value}
            onClick={() => {
              setStatus(option.value)
              changeStatus(option.value)
            }}
            className="flex items-center justify-between px-1.5 py-1 outline-none cursor-pointer hover:bg-gray-50 mb-1"
          >
            <div className={cn('flex items-center gap-2.5 text-sm')}>
              <span className={cn('!bg-white', option.className)}>
                {option.icon}
              </span>
              <span className="text-xs text-grey-800">{option.label}</span>
            </div>
            {status === option.value && <CheckIcon className="w-4 h-4 ml-2" />}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default StatusDropdown

const opportunityStatusOptions = [
  {
    label: 'Lead',
    value: 'lead',
    className: 'bg-violet-50 text-violet-600',
    icon: (
      <TargetIcon style={{ width: '14px', height: '14px' }} strokeWidth="1.7" />
    )
  },
  {
    label: 'InProgress',
    value: 'rfp_in_progress',
    className: 'bg-yellow-50 text-yellow-500',
    icon: (
      <InProgressSemiCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'Submitted',
    value: 'submitted',
    className: 'bg-sky-50 text-sky-600',
    icon: (
      <SubmittedRocketIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'Won',
    value: 'won',
    className: 'bg-green-50 text-green-600',
    icon: (
      <CheckCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'Lost',
    value: 'lost',
    className: 'bg-pink-50 text-pink-600',
    icon: (
      <CicleCross style={{ width: '14px', height: '14px' }} strokeWidth="1.7" />
    )
  },
  {
    label: 'Closed',
    value: 'closed',
    className: 'bg-zinc-100 text-zinc-600',
    icon: (
      <StopIcon style={{ width: '14px', height: '14px' }} strokeWidth="1.7" />
    )
  }
]

const projectStatusOptions = [
  // Won, InProgress, Completed
  {
    label: 'Won',
    value: 'won',
    className: 'bg-green-50 text-green-600',
    icon: (
      <CheckCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'InProgress',
    value: 'in_progress',
    className: 'bg-yellow-50 text-yellow-600',
    icon: (
      <InProgressSemiCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'Completed',
    value: 'completed',
    className: 'bg-blue-50 text-blue-600',
    icon: <CheckIcon className="size-4" />
  }
]
