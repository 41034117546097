import React, { useEffect, useState } from 'react'
import Tab from '../../../components/Tabs'
import { Container, Section } from '../../../components/Container'
import { useStyles } from './styles'
import trackEvent from '../../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../../config/mixpanelEvents'
import WorkspaceList from '../../../containers/WorkspaceList'

const Workspace = () => {
  // const classes = useStyles()
  // const tabs = [
  //   {
  //     label: 'Workspaces',
  //     children:
  //   }
  // ]

  useEffect(() => {
    trackEvent(
      mixpanelEvents.WORKSPACE_DOCUMTENTS_TAB_OPENED,
      'SUCCESS',
      {},
      {}
    )
  }, [])

  return (
    <Container>
      <WorkspaceList />
    </Container>
  )
}

export default Workspace
