import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { IconButton, Box } from '@mui/material'
import { TextLineLimiter } from '../../components'
import { ROUTES } from '../../config/routes'

const convertToTagValueArray = (tagIds = [], tagCenterObject = {}) => {
  const newArray = tagIds.map((id) => {
    const foundData = Object.values(tagCenterObject)
      .flatMap(({ data }) => data)
      .find((item) => item.id === id)
    return foundData
  })
  return newArray
}

const renderURL = (
  value,
  showOpenIcon = false,
  key = '',
  decoration = true
) => {
  const markdownLinkFormatRegex = /\[([^\]]+)\]\((.*?)\)/
  const match = markdownLinkFormatRegex.exec(value)
  let link, name

  if (match) {
    name = match[1]
    link = match[2]
  } else {
    link = value
    name = value
  }
  return showOpenIcon ? (
    <Box
      sx={{ display: 'flex', width: '100%', alignContent: 'center' }}
      key={key}
    >
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          window.open(link, '_blank')
        }}
        style={{ padding: '0px', marginRight: '5px' }}
      >
        <OpenInNewIcon sx={{ fontSize: '15px !important' }} />
      </IconButton>
      <Box sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{name}</Box>
    </Box>
  ) : (
    <a
      href={link}
      key={key}
      target="_blank"
      rel="noreferrer"
      style={{
        color: '#0078D4',
        // textDecoration: decoration ? 'auto' : 'none',
        cursor: decoration ? 'pointer' : 'none'
      }}
    >
      <TextLineLimiter
        content={name}
        limit={1}
        wordBreak={'break-word'}
        showMore={false}
      />
    </a>
  )
}

const generateLink = (tag) => {
  const { tag_key, tag_value, id, is_generated } = tag
  if (!is_generated) return tag_value
  switch (tag_key) {
    case 'generated_companies':
      return `[${tag_value}](${ROUTES?.LIBRARY_CLIENTS_RESUME + '/' + id})`
    case 'generated_people':
      return `[${tag_value}](${ROUTES?.LIBRARY_PEOPLE_RESUME + '/' + id})`
    case 'generated_projects':
      return `[${tag_value}](${ROUTES?.LIBRARY_PROJECTS + '/' + id})`
    default:
      return tag_value
  }
}

export { convertToTagValueArray, renderURL, generateLink }
