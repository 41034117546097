import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    width: '100%',
    height: '100%'
  },
  sectionWrapper: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    maxWidth: '400px',
    textAlign: 'center'
  },
  topMargin: {
    marginTop: '20px',
    fontStyle: 'italic'
  }
}))

export { useStyles }
