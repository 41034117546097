import { useEffect, useState } from 'react'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { IconButton } from '@mui/material'
import copyIcon from '../../assets/images/copy-icon.svg'
import { useStyles } from './styles'
import { Image } from '../../components'
import { getSignedUrl } from '../../utils/AWS'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ImageResult = (props) => {
  const {
    s3 = {},
    imageData = {},
    keyId = '',
    handlePDFViewer,
    handleCopy,
    handleSave,
    savedList = [],
    enableSave = true,
    enableCopy = false
  } = props
  const classes = useStyles()
  const { imageContainer, copyButton, favoriteIcon, imageWrapper } = classes
  const [save, setSave] = useState()
  const [imageURL, setImageURL] = useState('')
  const [imageThumnailURL, setImageThumnailURL] = useState('')

  useEffect(() => {
    async function initalizeData() {
      const finalImageData = imageData.content ?? imageData.data
      const imgURL =
        (await getSignedUrl(finalImageData?.image_url, s3)) + '&cacheblock=true'
      let imgThumnailURL =
        (await getSignedUrl(finalImageData?.image_thumbnail_url, s3)) +
        +'&cacheblock=true'
      imgThumnailURL = imgThumnailURL || imgURL
      setImageURL(imgURL)
      setImageThumnailURL(imgThumnailURL)
    }
    initalizeData()
  }, [imageData, s3])

  useEffect(() => {
    const { content_id = '' } = imageData
    const saveValue = savedList.includes(content_id)
    setSave(!saveValue)
  }, [savedList])

  const handleImageDrag = (event, imageURL) => {
    event.dataTransfer.setData(
      'application/x-lexical-drag',
      JSON.stringify({
        data: {
          src: imageURL
        },
        type: 'image'
      })
    )
    event.dataTransfer.setData('dragCustom', JSON.stringify(true))
    const dragIcon = document.getElementById('img_drag' + keyId).cloneNode()
    event.dataTransfer.setDragImage(dragIcon, 0, 0)
  }

  const handleSaveClick = () => {
    handleSave(imageData, save)
    setSave(!save)
  }

  return (
    <>
      {imageURL && imageThumnailURL && (
        <div className={imageContainer}>
          <div className={imageWrapper}>
            <LazyLoadImage
              effect="blur"
              id={'img_drag' + keyId}
              src={imageThumnailURL}
              draggable
              onDragStart={(event) => {
                handleImageDrag(event, imageURL)
              }}
            />
          </div>
          <div>
            {enableSave && (
              <span>
                {' '}
                {save ? (
                  <IconButton onClick={handleSaveClick}>
                    <FavoriteBorderIcon />{' '}
                  </IconButton>
                ) : (
                  <IconButton onClick={handleSaveClick}>
                    <FavoriteIcon className={favoriteIcon} />
                  </IconButton>
                )}{' '}
              </span>
            )}
            {enableCopy && (
              <IconButton onClick={() => handleCopy(imageURL)}>
                <Image src={copyIcon} className={copyButton} />
              </IconButton>
            )}
          </div>

          {/* {
            enableSave &&
            <span>
              {!save ? (
                <IconButton onClick={handleSaveClick}>
                  <FavoriteBorderIcon />{" "}
                </IconButton>
              ) : (
                <IconButton onClick={handleSaveClick}>
                  <FavoriteIcon />
                </IconButton>
              )}
              {enableCopy &&
                <IconButton onClick={() => handleCopy(imageURL)}>
                  <Image src={copyIcon} className={copyButton} />
                </IconButton>
              }
            </span>
          } */}
        </div>
      )}
    </>
  )
}

export default ImageResult
