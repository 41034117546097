import markdownit from 'markdown-it'

interface MarkdownOptions {
  html?: boolean
  openLinksInNewTab?: boolean
  linkRel?: string
}

export const createMarkdownRenderer = (options: MarkdownOptions = {}) => {
  const {
    html = true,
    openLinksInNewTab = false,
    linkRel = openLinksInNewTab ? 'noopener noreferrer' : ''
  } = options

  const md = markdownit({ html })

  if (openLinksInNewTab || linkRel) {
    // @ts-ignore
    const defaultRender =
      md.renderer.rules.link_open ||
      function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options)
      }

    // @ts-ignore
    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
      if (openLinksInNewTab) tokens[idx].attrSet('target', '_blank')
      if (linkRel) tokens[idx].attrSet('rel', linkRel)
      return defaultRender(tokens, idx, options, env, self)
    }
  }

  return md
}

export const markdownToHtml = (
  markdownText: string = ' ',
  options: MarkdownOptions = {}
): string => {
  const md = createMarkdownRenderer(options)
  return md.render(markdownText)
}

export default markdownToHtml
