import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { ListTable, MenuButton, useConfirmation } from '../../components'
import { useStyles } from './styles'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { workspaceActionsFiles } from '../../store/api'
import { toast } from 'react-toastify'
import { formatDate } from '../../utils/Date'
import HeadlessTable from '@/components/TableV2/HeadlessTable'
import Button2 from '@/components/Button/Button2'
import { MagicWand } from '@/components/Icons/Icons'

const WorkspaceListFiles = (props) => {
  const {
    files = [],
    setFiles = [],
    selectedFile = null,
    setSelectedFile = () => {},
    workspace_id = ''
  } = props
  const classes = useStyles()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [activeTableRow, setActiveTableRow] = useState(null)

  const handleonClickRow = (file) => {
    const { file_id, file_type, file_name } = file
    const obj = {
      id: file_id,
      file_type,
      file_name,
      other_data: {
        asset_type: file_type
      }
    }
    setSelectedFile(obj)
  }

  const headCells = [
    {
      id: 'file_name',
      disablePadding: true,
      label: 'File Name',
      sort: true,
      width: '45%',
      onClick: handleonClickRow
    },
    {
      id: 'file_type',
      disablePadding: true,
      label: 'Type',
      sort: true,
      width: '15%',
      onClick: handleonClickRow
    },
    {
      id: 'added_at',
      disablePadding: true,
      label: 'Date added',
      sort: true,
      width: '15%',
      onClick: handleonClickRow,
      format: formatDate
    },
    {
      id: 'added_by_user_name',
      disablePadding: true,
      label: 'Added by',
      sort: true,
      width: '25%',
      onClick: handleonClickRow
    }
  ]

  const handleDeleteFile = async (data) => {
    const { row } = data
    showConfirmDialog({
      onConfirm: async () => {
        const newFiles = [...files]
        const index = newFiles.findIndex((item) => item.id === row.id)
        newFiles[index].rowLoading = true
        setFiles(newFiles)
        const req = [
          {
            id: row.id,
            workspace_id,
            mode: 'delete'
          }
        ]
        const res = await workspaceActionsFiles(req)
        if (res.status === 200) {
          newFiles.splice(index, 1)
          setFiles(newFiles)
        } else {
          newFiles[index].rowLoading = false
          toast.error('Unable to delete file')
          setFiles(newFiles)
        }
      },
      confirmationMessageTitle: `Are you sure you want to delete the file "${row?.file_name}" ?`
    })
  }

  const menuOptions = [
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: handleDeleteFile
    }
  ]

  const menuOpenCallback = (data) => {
    const { row, index } = data
    setActiveTableRow(index)
  }

  const renderTableActions = (row, index) => {
    const { rowLoading = false } = row
    return (
      <MenuButton
        disabled={rowLoading}
        options={menuOptions}
        callBackData={{ row, index }}
        menuOpenCallback={menuOpenCallback}
        menuCloseCallback={() => setActiveTableRow(null)}
      />
    )
  }

  const columns = [
    {
      id: 'file_name',
      label: 'File Name',
      accessorKey: 'file_name'
    },
    {
      id: 'file_type',
      label: 'Type',
      style: {
        width: '200px'
      },
      accessorKey: 'file_type'
    },
    {
      id: 'added_at',
      label: 'Date added',
      accessorKey: 'added_at',
      style: {
        width: '240px'
      },
      children: (row) => {
        return <p>{formatDate(row.added_at)}</p>
      }
    },
    {
      id: 'added_by_user_name',
      label: 'Added by',
      accessorKey: 'added_by_user_name',
      style: {
        width: '240px'
      }
    },
    {
      id: 'files',
      label: '',
      accessorKey: 'files',
      style: {
        width: '200px '
      },
      children: (row, index) => {
        return (
          <div
            className="flex items-center gap-1 ml-auto"
            onClick={(e) => {
              e.stopPropagation()
              // renderTableActions(row, index)
            }}
          >
            {/* <Button2
              secondary
              noOutline
              style={{
                fontSize: '12px'
              }}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <MagicWand className="size-4" />
            </Button2> */}
            <MenuButton
              // disabled={rowLoading}
              options={menuOptions}
              callBackData={{ row, index }}
              menuOpenCallback={menuOpenCallback}
              menuCloseCallback={() => setActiveTableRow(null)}
            />
          </div>
        )
      }
    }
  ]

  return (
    <>
      <HeadlessTable
        columns={columns}
        data={files}
        hideMultiSelect
        hidePagination
        onRowClick={handleonClickRow}
        // hidePagination
      />

      {ConfirmDialog}
    </>
  )
}

export default WorkspaceListFiles
