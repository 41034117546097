import { ImageAvatarFallback, ImageRender } from '../../components'
import ProjectResult from '../ProjectResult'

const ProjectViewer = (props) => {
  const {
    id,
    data = {},
    onClose = () => {},
    onDeleteCallback = () => {},
    onUpdateCallback = () => {},
    setFile = () => {},
    isSuperUser = false,
    isAdmin = false,
    s3Obj = {}
  } = props

  console.log('data project', data)

  const {
    project_name,
    project_description,
    project_location,
    images,
    other_details
  } = data

  return (
    <div className="grid grid-col-2">
      <div className="px-4 flex flex-col gap-2">
        {project_name && (
          <p
            className="text-xl font-medium m-0"
            dangerouslySetInnerHTML={{
              __html: project_name
            }}
          />
        )}

        {project_description ? (
          <p
            className="m-0 text-sm"
            dangerouslySetInnerHTML={{
              __html: project_description
            }}
          />
        ) : (
          <p className="m-0 text-sm">No description available.</p>
        )}

        {other_details && (
          <p
            className="m-0 text-sm"
            dangerouslySetInnerHTML={{
              __html: other_details
            }}
          />
        )}

        {project_location && (
          <div className="flex items-center text-sm font-medium gap-2">
            Location -
            <p
              className="m-0 text-sm"
              dangerouslySetInnerHTML={{
                __html: project_location
              }}
            />
          </div>
        )}
      </div>

      {images?.length > 0 && (
        <div className="grid grid-col-3 gap-2">
          {images?.map((image, index) => (
            <ImageRender
              s3Obj={s3Obj}
              key={index}
              src={image}
              alt={`Project Image ${index}`}
              style={{
                width: '100%',
                height: '250px',
                border: '1px solid var(--grey-200)',
                borderRadius: '4px',
                // height: '100%',
                objectFit: 'cover'
              }}
            />
          ))}
        </div>
      )}
    </div>
  )

  // return (
  //   <ProjectResult
  //     data={data}
  //     openProject={true}
  //     handleCloseProject={onClose}
  //     s3Obj={s3Obj}
  //     showBackButton={false}
  //   />
  // )
}

export default ProjectViewer
