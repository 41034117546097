import {
  UPDATE_ACTIVE_COLLECTION,
  ATTACH_ALERT_TO_CHAT
} from '../../../actionTypes'

const collections = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_ACTIVE_COLLECTION,
    payload: data
  })
  const { type } = data
  if (type === 'collection') {
    const { id, name } = data
    dispatch({
      type: ATTACH_ALERT_TO_CHAT,
      payload: {
        show: true,
        document_name: name,
        document_type: 'collections',
        document_id: id
      }
    })
  } else {
    dispatch({
      type: ATTACH_ALERT_TO_CHAT,
      payload: null
    })
  }
}

export default collections
