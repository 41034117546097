import React from 'react'

type Props = {
  documentName: string
  type: 'start' | 'end'
}

const DocumentAttachLabel = ({ documentName, type = 'start' }: Props) => {
  const label = 
    type === 'start' ? 'attached to the chat' : 'removed from the chat'

  return (
    <div className="flex justify-center items-center w-full">
      <div
        className="text-center px-2 py-1 bg-grey-200 rounded-md"
        style={{
          margin: '12px 0 4px',
          fontSize: '10px'
        }}
      >
        <span
          style={{
            // textDecoration: 'underline',
            // textUnderlineOffset: '3px',
            // textDecorationColor: 'var(--grey-500)',
            cursor: 'pointer'
          }}
        >
          {documentName}
        </span>{' '}
        {label}
      </div>
    </div>
  )
}

export default DocumentAttachLabel
