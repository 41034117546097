import React, { useEffect, useState } from 'react'
import { Container, Section } from '../../components/Container'
import DashboardSearch from '../../containers/DashboardSearch'
import { useSelector, useDispatch } from 'react-redux'
import { getSearchHistory } from '../../store/Search/Actions'
import { Box } from '@mui/system'
import { useStyles } from './styles'
import { useColor } from '../../ThemeContext'

const Dashboard = ({ imgURL, s3Obj }) => {
  const [imageSrc, setImageSrc] = useState(null)
  const dispatch = useDispatch()
  const searchState = useSelector((state) => state.search)
  const { recentSearch } = searchState
  const { backgroundWallpaper } = useColor()
  const classes = useStyles()
  const { backgroundWrapper } = classes

  const cacheKey =
    backgroundWallpaper !== null ? backgroundWallpaper || imgURL : ''

  useEffect(() => {
    if (!recentSearch) {
      dispatch(getSearchHistory())
    }
  }, [recentSearch])

  useEffect(() => {
    const fetchAndCacheImage = async (url) => {
      if (!url) return
      try {
        const cache = await caches.open('joist-img-cache')
        const cachedResponse = await cache.match(url)

        if (cachedResponse) {
          setImageSrc(URL.createObjectURL(await cachedResponse.blob()))
        } else {
          const response = await fetch(url)
          const responseClone = response.clone()
          setImageSrc(URL.createObjectURL(await response.blob()))
          cache.put(url, responseClone)
        }
      } catch (error) {
        console.error('Failed to fetch and cache image:', error)
      }
    }

    fetchAndCacheImage(cacheKey)
  }, [cacheKey])

  return (
    <Container>
      <Section>
        <Box
          className={backgroundWrapper}
          sx={{ backgroundImage: `url(${imageSrc})` }}
        >
          <DashboardSearch s3Obj={s3Obj} />
        </Box>
      </Section>
    </Container>
  )
}

export default Dashboard
