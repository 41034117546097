import Button2 from '@/components/Button/Button2'
import { SemiCircleIcon, UpArrowIcon } from '@/components/Icons/Icons'
import LexicalEditor from '@/components/Lexical'
import { getActivityLogs } from '@/store/api'
import { formatTimeAgo } from '@/utils/Date'
import { getColorsForInitials } from '@/utils/String'
import _ from 'lodash'
import moment from 'moment'
import React, { act, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CommentUI from '../CommentUI/CommentUI'
import { Skeleton } from '@/components/ui/skeleton'

export const Activity = ({
  id,
  refreshOpportunityState = false,
  setRefreshOpportunityState = () => {}
}) => {
  const [activityLogs, setActivityLogs] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    getActivityLogs(id).then((res) => {
      setActivityLogs(res?.data?.activity ?? [])
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    // console.log('refreshOpportunityState', refreshOpportunityState)
    if (refreshOpportunityState) {
      setLoading(true)
      getActivityLogs(id).then((res) => {
        setActivityLogs(res?.data?.activity ?? [])
        setLoading(false)
      })
    }
    setRefreshOpportunityState(false)
  }, [refreshOpportunityState])

  const messgaeGenerator = (activity) => {
    if (activity.additional_data.modified_field.includes('date')) {
      const oldDate = moment(activity.old_value).format('DD MMMM, YYYY')
      const newDate = moment(activity.new_value).format('DD MMMM, YYYY')

      activity.old_value = oldDate
      activity.new_value = newDate
    }

    if (activity.action_type === 'update') {
      return (
        <p className="text-zinc-600">
          <span className="font-medium text-zinc-800">
            {activity.user_name}{' '}
          </span>
          changed {_.lowerCase(activity.additional_data.modified_field)}{' '}
          {activity.old_value && (
            <>
              from{' '}
              <span className="font-medium text-zinc-800">
                {_.lowerCase(activity.old_value)}{' '}
              </span>
            </>
          )}
          to{' '}
          <span className="font-medium text-zinc-800">
            {_.lowerCase(activity.new_value)}{' '}
          </span>
          <span className="text-xxs text-zinc-500 w-28 whitespace-nowrap">
            - {formatTimeAgo(activity.timestamp)}
          </span>
        </p>
      )
    }
    if (activity.action_type === 'create') {
      return (
        <p className="flex gap-1 text-zinc-600">
          <span className="font-medium text-zinc-800">
            {activity.user_name}
          </span>
          created {_.lowerCase(activity.additional_data.modified_field)} to
          <span className="font-medium text-zinc-800">
            {_.lowerCase(activity.new_value)}
          </span>
          <span className="text-xxs text-zinc-500 w-28 whitespace-nowrap">
            - {formatTimeAgo(activity.timestamp)}
          </span>
        </p>
      )
    }
    if (activity.action_type === 'delete') {
      return (
        <p className="flex gap-1 text-zinc-600">
          <span className="font-medium text-zinc-800">
            {activity.user_name}
          </span>
          deleted {_.lowerCase(activity.additional_data.modified_field)}
          {(activity.additional_data.modified_field === 'rfx' ||
            activity.additional_data.modified_field === 'proposal') && (
            <span className="font-medium text-zinc-800">
              {_.lowerCase(activity.new_value)}
            </span>
          )}
          <span className="text-xxs text-zinc-500 w-28 whitespace-nowrap">
            - {formatTimeAgo(activity.timestamp)}
          </span>
        </p>
      )
    }
    if (activity.action_type === 'attach') {
      return (
        <p className="flex gap-1 text-zinc-600">
          <span className="font-medium text-zinc-800">
            {activity.user_name}
          </span>
          attached a {activity.additional_data.modified_field}
          <span className="font-medium text-zinc-800">
            {_.lowerCase(activity.new_value)}
          </span>
          <span className="text-xxs text-zinc-500 w-28 whitespace-nowrap">
            - {formatTimeAgo(activity.timestamp)}
          </span>
        </p>
      )
    }

    if (activity.action_type === 'remove') {
      return (
        <p className="flex gap-1 text-zinc-600">
          <span className="font-medium text-zinc-800">
            {activity.user_name}
          </span>
          removed{' '}
          <span className="font-medium text-zinc-800">
            {_.lowerCase(activity.old_value)}{' '}
          </span>
          {activity.additional_data.modified_field}
          <span className="font-medium text-zinc-800">
            {_.lowerCase(activity.new_value)}
          </span>
          <span className="text-xxs text-zinc-500 w-28 whitespace-nowrap">
            - {formatTimeAgo(activity.timestamp)}
          </span>
        </p>
      )
    }
    if (activity.action_type === 'add') {
      return (
        <p className="flex gap-1 text-zinc-600">
          <span className="font-medium text-zinc-800">
            {activity.user_name}
          </span>
          added {activity.additional_data.modified_field}
          <span className="font-medium text-zinc-800">
            {_.lowerCase(activity.new_value)}
          </span>
          <span className="text-xxs text-zinc-500 w-28 whitespace-nowrap">
            - {formatTimeAgo(activity.timestamp)}
          </span>
        </p>
      )
    }
  }

  return (
    <div className="pb-4 twp">
      <div className="flex items-center gap-2">
        <p className="text-sm font-medium">Activity</p>
        <div className="h-[1px] w-full bg-zinc-200"></div>
      </div>

      {loading ? (
        <div className="flex items-center mt-2 space-x-4">
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Skeleton className="w-4 h-4 rounded-full" />
              <Skeleton className="h-4 w-[350px]" />
            </div>
            <div className="flex items-center gap-2">
              <Skeleton className="w-4 h-4 rounded-full" />
              <Skeleton className="h-4 w-[280px]" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-3.5 relative">
          <div className="flex flex-col gap-3.5 mt-4 relative px-3">
            {activityLogs.length > 0 && (
              <div className="h-full w-[1px] absolute bg-zinc-200 left-[18px] z-0"></div>
            )}
            {activityLogs.length === 0 && (
              <div className="flex items-center justify-center h-16 text-xs rounded-md bg-zinc-50">
                <p className="text-zinc-600">No activity logs</p>
              </div>
            )}
            {activityLogs.map((item) => (
              <div
                key={item.timestamp}
                className="z-10 flex items-center gap-3 py-1 bg-white"
              >
                <div
                  className={`min-w-4 h-4 rounded-full place-content-center text-xxs text-white flex items-center`}
                  style={{
                    backgroundColor: getColorsForInitials(item.user_name)
                  }}
                >
                  {_.toUpper(item.user_name[0])}
                </div>
                <p className="text-xs text-zinc-800">
                  {messgaeGenerator(item)}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
