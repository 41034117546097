import React, { useState } from 'react'
import {
  CircleLoader,
  CrossIcon,
  PencilIcon,
  PlusIcon,
  ProjectIcon
} from '../../components/Icons/Icons'
import Button2 from '../../components/Button/Button2'
import { Dialog, DialogContent, Fade } from '@mui/material'
import { SelectDropDown } from '../../components'
import { updatePeopleInfo } from '../../store/People/Actions/UpdatePeople/UpdatePeople'
import mixpanelEvents from '../../config/mixpanelEvents'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { changeProjectInfo } from '../../store/api'

const EditProjectModal = ({ open, setOpen, project, onUpdate }) => {
  const [updating, setUpdating] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState(null)
  const [search, setSearch] = useState('')
  const [fetchingPeople, setFetchingPeople] = useState(false)
  const [uploadedImage, setUploadedImage] = useState(null)
  const dispatch = useDispatch()
  console.log('people profile', project)

  const handleClose = () => {
    setSelectedPeople(null)
    setOpen(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const [basicInfo, setBasicInfo] = useState({
    id: project?.id,
    name: project?.title,
    projectType: project?.properties?.project_type?.value ?? 'unknown'
  })

  const handleProfileUpdate = async (info) => {
    // const id = toast.loading('Changing name...')

    if (info.name === '') {
      toast.error('Name cannot be empty')
      return
    }

    const data = {
      project_group_id: info.id,
      project_title: info.name,
      properties: {
        project_type: {
          verified: info.projectType !== 'unknown',
          value: info.projectType
        }
      }
    }

    setUpdating(true)

    const callback = (data) => {
      onUpdate(data)
      setUpdating(false)
    }
    trackEvent(
      mixpanelEvents.PROJECT_INFO_UPDATED,
      'Success',
      {},
      {
        ...data
      }
    )
    console.log('data', data)
    const res = await changeProjectInfo(data)

    if (res.status === 200) {
      callback(info)
    } else {
      toast.error('Project updated failed')
      setUpdating(false)
    }
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div
        className="p-3 border-box text-sm"
        style={{
          width: '450px'
        }}
      >
        <DialogContent
          style={{
            padding: '0',
            minHeight: '400px',
            overflow: 'hidden'
          }}
        >
          <div className="">
            <div
              className="flex justify-center"
              style={{
                height: '100px'
              }}
            >
              {' '}
              <div className="w-24 h-24 bg-grey-100 rounded grid place-content-center">
                <ProjectIcon className="size-6 text-grey-400 mt-1" />
              </div>
            </div>
            <div className="text-left">
              <span className="text-xs text-grey-600 ml-1 ">Name</span>
              <input
                type="text"
                className="input-base capitalize outline-none text-lg w-full border-grey-200 border-1 p-2 rounded text-sm border-box mt-1"
                // autoFocus
                value={basicInfo?.name}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onChange={(e) => {
                  e.stopPropagation()
                  setBasicInfo({
                    ...basicInfo,
                    name: e.target.value
                  })
                }}
                onBlur={(e) => {
                  e.stopPropagation()
                }}
              />
            </div>
            <div className="text-left mt-4">
              <span className="text-xs text-grey-600 ml-1">Project type</span>
              <div className="mt-1">
                <SelectDropDown
                  name="employeeType"
                  label=""
                  value={basicInfo.projectType}
                  options={[
                    { label: 'Internal', value: 'internal' },
                    { label: 'External', value: 'external' },
                    { label: 'Unknown', value: 'unknown' }
                  ]}
                  handleChange={(e) => {
                    setBasicInfo({
                      ...basicInfo,
                      projectType: e
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </div>
      <div className="flex justify-end gap-2 items-center p-2 border-t-1">
        <Button2
          secondary
          noOutline={true}
          onClick={handleClose}
          style={{
            padding: '8px 16px'
          }}
        >
          Cancel
        </Button2>
        <Button2
          onClick={() => {
            handleProfileUpdate(basicInfo)
          }}
          primary
          style={{
            padding: '8px 16px'
          }}
        >
          {updating ? (
            <CircleLoader className="animate-spin text-white size-4" />
          ) : (
            'Update'
          )}
        </Button2>
      </div>
    </Dialog>
  )
}

export default EditProjectModal
