import { checkS3File } from '../AWS'

export const getPDFURL = (s3Obj, doc, type = '') => {
  const {
    compressed_location,
    linearized_compress_location,
    location,
    linearized_location,
    download_location
  } = doc || {}

  const checkAndResolveLocation = async () => {
    try {
      if (type === 'original') {
        if (location && (await checkS3File(location, s3Obj))) {
          return location
        } else if (
          linearized_location &&
          (await checkS3File(linearized_location, s3Obj))
        ) {
          return linearized_location
        }
      } else if (type === 'download') {
        if (
          download_location &&
          (await checkS3File(download_location, s3Obj))
        ) {
          return download_location
        } else if (location && (await checkS3File(location, s3Obj))) {
          return location
        }
      } else {
        if (
          linearized_compress_location &&
          (await checkS3File(linearized_compress_location, s3Obj))
        ) {
          return linearized_compress_location
        } else if (
          compressed_location &&
          (await checkS3File(compressed_location, s3Obj))
        ) {
          return compressed_location
        } else if (
          linearized_location &&
          (await checkS3File(linearized_location, s3Obj))
        ) {
          return linearized_location
        } else if (location && (await checkS3File(location, s3Obj))) {
          return location
        }
      }
      return null
    } catch (error) {
      console.log(error)
      return null
    }
  }

  return checkAndResolveLocation()
}
