import React, { useEffect, useState } from 'react'
import { ImageAvatarFallback, Loader } from '../../components'
import {
  CheckIcon,
  DocumentIcon,
  PencilIcon,
  SemiCircleIcon
} from '../../components/Icons/Icons'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import DisplayTags from '../../sections/ResumeResult/DisplayTags'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Pagination } from '@mui/material'
import Button2 from '../../components/Button/Button2'
import { checkUserRoleViewer } from '../../utils/User'

const PeopleTable = ({
  isSuperUser,
  fetchPage,
  hasNext,
  pageLoading,
  loading,
  displayPeople,
  selectedPeople,
  selectedPeopleObj,
  isProcessing,
  setSelectedPeople,
  setSelectedPeopleObj,
  s3Obj,
  setResumeOpen,
  setShowTagDrawer,
  handleMasterResumeNameUpdate,
  meta,
  unSelectAll
}) => {
  const navigate = useNavigate()
  const [editName, setEditName] = useState(null)
  const [selectedPage, setSelectedPage] = useState(meta?.page ?? 1)
  // get if last row is visible

  const isUserViewer = checkUserRoleViewer()

  useEffect(() => {
    setSelectedPage(meta?.page ?? 1)
  }, [meta])

  const handleSetSelectedPeople = (person) => {
    const { id } = person

    if (selectedPeople.includes(id)) {
      setSelectedPeople(selectedPeople.filter((person) => person !== id))
      setSelectedPeopleObj(
        selectedPeopleObj.filter((person) => person.id !== id)
      )
    } else {
      setSelectedPeopleObj([...selectedPeopleObj, person])
      setSelectedPeople([...selectedPeople, id])
    }
  }

  useEffect(() => {
    const scrollPosition = localStorage.getItem('scrollPosition')
    if (scrollPosition) {
      setTimeout(() => {
        document.getElementById('scrollableDiv').scrollTop =
          parseInt(scrollPosition)
      }, 200)
    }
  }, [])

  const handleOpenPerson = (index) => {
    const person = displayPeople[index]
    // get scrolled position of scrollablediv

    localStorage.setItem(
      'scrollPosition',
      document.getElementById('scrollableDiv').scrollTop
    )

    setResumeOpen(true)

    navigate(
      `${ROUTES.LIBRARY_PEOPLE_RESUME}/${person?.id}/${person?.resumes[0]?.id}`
    )

    trackEvent(
      mixpanelEvents.PEOPLE_MASTER_RESUME_OPENED,
      'SUCCESS',
      {},
      { people_name: displayPeople?.[index]?.name }
    )
  }

  return (
    <div className="border-grey-200 rounded-lg relative">
      <div className="grid grid-col-6 gap-2 p-2 px-4 text-center bg-grey-100 text-xs sticky top-0 z-10 font-medium border-b-1 ">
        <div className=" w-full flex items-center px-1 gap-4">
          <div
            className="size-3 bg-grey-700 rounded text-white
            grid place-content-center cursor-pointer"
            style={{
              opacity: selectedPeople.length > 0 ? 1 : 0
            }}
            onClick={() => {
              if (selectedPeople.length > 0) {
                unSelectAll()
              }
            }}
          >
            -
          </div>
          Name
        </div>
        <div className=" w-full">Email</div>
        <div className=" w-full">Employee type</div>
        <div className="w-full">Tags</div>
        <div className=" w-full">Resumes</div>
      </div>
      {loading ? (
        <div
          className="grid place-content-center "
          style={{ height: 'calc(90vh - 150px)', overflow: 'auto' }}
        >
          <div className="flex items-center gap-2">
            Loading
            <SemiCircleIcon
              strokeWidth="2"
              className="size-4 text-grey-700 animate-spin"
            />
          </div>
        </div>
      ) : displayPeople?.length > 0 ? (
        <>
          <div
            className="text-center"
            id="rows"
            style={{ height: 'calc(90vh - 150px)', overflow: 'auto' }}
          >
            {displayPeople?.map((person, index) => {
              const {
                name,
                profile_picture,
                profile_picture_location,
                resumes = [],
                id,
                properties,
                status,
                version
              } = person

              const { employee_type = {}, email_ids = {} } = properties
              const checked = selectedPeople.includes(id)
              const isMerging = status === 'merging'
              const disabled = isProcessing || isMerging
              return (
                <div
                  className={`grid grid-col-6 gap-2 px-4 text-center text-sm border-b-1 p-2 row text-grey-600 ${
                    checked ? 'bg-grey-100' : ''
                  }`}
                  style={{
                    height: 'fit-content'
                  }}
                  key={index}
                  onClick={() => {
                    if (!disabled) {
                      if (editName?.id) return
                      if (selectedPeople.length > 0) {
                        handleSetSelectedPeople(person)
                      } else {
                        handleOpenPerson(index)
                      }
                    }
                  }}
                >
                  <div className="font-medium w-full flex items-center gap-2 text-capitalize parent-card">
                    <div
                      className="flex items-center h-full cursor-pointer py-1 mr-1"
                      onClick={(e) => {
                        e.stopPropagation()
                        if (!disabled) {
                          handleSetSelectedPeople(person)
                        }
                      }}
                    >
                      <input
                        type="checkbox"
                        className={`cursor-pointer checkbox-ui border-grey-200 rounded w-3 h-3  ${
                          checked ? 'bg-grey-700' : ''
                        }`}
                        checked={checked}
                        readOnly
                      />
                    </div>
                    <ImageAvatarFallback
                      name={name}
                      profilePic={profile_picture ?? profile_picture_location}
                      style={{
                        height: '24px',
                        width: '24px',
                        fontSize: '12px'
                      }}
                      openImage={() => {}}
                      s3Obj={s3Obj}
                    />

                    {editName?.id === id ? (
                      <div
                        className="border-grey-200 rounded flex bg-white overflow-hidden"
                        tabIndex={0}
                        // onBlur={(e) => {
                        //   if (!e.currentTarget.contains(e.relatedTarget)) {
                        //     e.stopPropagation()
                        //     setEditName(null)
                        //   }
                        // }}
                      >
                        <input
                          type="text"
                          className="input-base capitalize outline-none "
                          autoFocus
                          value={editName?.name}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          onChange={(e) => {
                            e.stopPropagation()
                            setEditName({
                              id,
                              name: e.target.value
                            })
                          }}
                          onBlur={(e) => {
                            e.stopPropagation()
                            if (
                              e.target.value.toLowerCase() !==
                              name.toLowerCase()
                            ) {
                              handleMasterResumeNameUpdate(id, e.target.value)
                            }
                            setEditName(null)
                          }}
                        />
                        {/* <Button2
                          primary
                          style={{
                            margin: '1px',
                            borderRadius: '6px',
                            fontSize: '10px',
                            padding: '3px 3px'
                          }}
                          onClick={(e) => {
                            if (
                              editName.name.toLowerCase() !== name.toLowerCase()
                            ) {
                              handleMasterResumeNameUpdate(id, editName.name)
                            }
                            setEditName(null)
                          }}
                        >
                          Save
                        </Button2> */}
                      </div>
                    ) : (
                      <p className="flex items-center gap-2 m-0 text-xs text-grey-800 text-left">
                        {name}
                        {!isUserViewer && (
                          <span
                            style={{
                              opacity: selectedPeople.length === 0 ? 1 : 0
                            }}
                            onClick={(e) => {
                              if (selectedPeople.length === 0) {
                                e.stopPropagation()
                                setEditName({
                                  id,
                                  name: name.toLowerCase()
                                })
                              }
                            }}
                          >
                            <PencilIcon className="size-4 card-options text-grey-400 mt-1" />
                          </span>
                        )}
                      </p>
                    )}
                  </div>

                  <div className="font-medium w-full text-xs flex items-center justify-center">
                    {email_ids?.value ? email_ids?.value?.[0] : '-'}

                    {email_ids?.value?.length > 1 && (
                      <span className="bg-grey-200 rounded-full px-2 text-xxs ml-1 p-05">
                        +{email_ids?.value?.length - 1}
                      </span>
                    )}
                  </div>

                  <div className="font-medium w-full text-xs grid place-content-center ">
                    {employee_type?.value
                      ? employee_type?.value !== 'unknown'
                        ? employee_type?.value
                        : '-'
                      : '-'}
                  </div>
                  <div
                    className="w-full grid place-content-center text-xs"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <DisplayTags
                      tags={person?.tags}
                      setShowTagDrawer={() => setShowTagDrawer(id)}
                    />
                  </div>
                  <div className="font-medium w-full grid place-content-center text-xs">
                    {resumes.length}
                  </div>

                  <div className="w-full flex items-center justify-center text-xs">
                    <DocumentIcon className="size-4" />{' '}
                    {isSuperUser && (
                      <span className="ml-2 text-grey-500 text-xxs">
                        v{version}
                      </span>
                    )}
                  </div>
                </div>
              )
            })}
            {pageLoading && (
              <div className="grid place-content-center">
                <Loader
                  loading={pageLoading}
                  caption={'Loading More People'}
                  flex
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="grid place-content-center " style={{ height: '50vh' }}>
          No Data found
        </div>
      )}

      <div className="sticky bottom-0 right-0 w-full bg-white border-box p-3">
        <div className="flex items-center gap-2">
          <Pagination
            disabled={pageLoading || loading}
            page={selectedPage}
            count={Math.ceil(meta?.totalCount / meta?.pageSize)}
            shape="rounded"
            onChange={(e, page) => {
              setSelectedPage(page)
              fetchPage(page)
            }}
          />

          {meta?.totalCount && (
            <span className="text-xs">
              {`Showing ${(selectedPage - 1) * meta?.pageSize + 1} - ${Math.min(
                selectedPage * meta?.pageSize,
                meta?.totalCount
              )} of ${meta?.totalCount}`}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default PeopleTable
