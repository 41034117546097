import { useEffect, useState, useRef } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate, useParams } from 'react-router-dom'
import _, { isArray, cloneDeep } from 'lodash'
import { Container, Section } from '../../components/Container'
import { TagFilter } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../../config/routes'
import { useStyles } from './styles'
import { fetchProjects, updateProjectTag } from '../../store/Project/Actions'
import { checkUserRoleViewer } from '../../utils/User'
import ProjectResult from '../../sections/ProjectResult'
import { initalizeS3 } from '../../utils/AWS'
import {
  changeProjectInfo,
  getParticularProject,
  multiDocuments,
  pollJobStatus
} from '../../store/api'
import { toast } from 'react-toastify'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import TagCenterUpdate from '../../sections/TagCenterUpdate'
import { Box, Drawer, Zoom } from '@mui/material'
import { updateNewTagValue } from '../../store/TagCenter/Actions'
import FilterListIcon from '@mui/icons-material/FilterList'
import Tooltip from '@mui/material/Tooltip'
import HeadlessTable from '../../components/TableV2/HeadlessTable'
import SearchNew from '../../sections/ListFilter/SearchNew'
import Button2 from '../../components/Button/Button2'
import {
  CheckCircleIcon,
  CheckIcon,
  CrossIcon,
  InProgressSemiCircleIcon,
  MergeIcon,
  PlusIcon,
  PropertiesIcon,
  SemiCircleIcon
} from '../../components/Icons/Icons'
import Label from '@mui/icons-material/Label'
import DisplayTags from '../../sections/ResumeResult/DisplayTags'
import AlphabetSearch from '../PeopleResume/AlphabetSearch'
import BulkUpdateModal from '../../components/BulkUpdateModal/BulkUpdateModal'
import GenericMergeModal from '../../components/GenericMergeModal/GenericMergeModal'
import CreateObjectModal from '../../components/CreateObjectModal/CreateObjectModal'
import StatusDropdown from '../OpportunitiesList/StatusDropdown'

const ProjectList = (props) => {
  const {
    isEditable = true,
    isSuperUser = false,
    showSearch = false,
    projectGroupId = '',
    pageType = 'default'
  } = props
  const { LIBRARY_PROJECTS } = ROUTES
  const isFocused = location.pathname.includes(LIBRARY_PROJECTS)

  const projectList = useSelector((state) => state?.projectsList?.projectList)

  const projectFilters = useSelector((state) => state.projectsList?.filters)

  const hasMoreData = useSelector((state) => state?.projectsList?.hasMoreData)
  const [projectLoading, setProjectLoading] = useState(false)
  const { collectionId, variationId } = useParams()
  const [alphabetKey, setAlphabetKey] = useState(null)
  const [showCreate, setShowCreate] = useState(false)
  const user = useSelector((state) => state.authenticate.user)
  const { domain = {} } = user || {}
  const { user_role = 'user', user_name = '' } = domain
  const [loading, setLoading] = useState(true)
  const [loadingText, setLoadingText] = useState('fetching your projects')
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(null)
  const [meta, setMeta] = useState({})
  const [PDFData, setPDFData] = useState({})
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredRecord, setFilteredRecord] = useState([])
  const navigate = useNavigate()
  const params = useParams()

  const { collectionId: projectID } = params
  const classes = useStyles({ showDrawer })
  const { grid, tableContainer } = classes
  const [currentProjectData, setCurrentProjectData] = useState({})
  const [topVariatonList, setTopVariatonList] = useState([])
  const [topProposalScreenshot, setTopProposalScreenshot] = useState([])
  const [currentProject, setCurrentProject] = useState(null)
  const [screenshotIndex, setScreenshotIndex] = useState(0)
  const [showBulkModal, setShowBulkModal] = useState(false)
  const [s3Obj, sets3Obj] = useState(null)
  const [originProposalNames, setOriginProposalNames] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [showMergeModal, setShowMergeModal] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState([])
  const [notesList, setNotesList] = useState([])
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [tagState, setTagState] = useState({})
  const [selectedTags, setSelectedTags] = useState(null)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [tagsOptions, setTagsOptions] = useState(null)
  const [prevFilters, setPrevFilters] = useState({})
  const [searchOnKey, setSearchOnKey] = useState(false)
  const [singleData, setSingleData] = useState(null)
  const openProject = useRef(false)

  const isUserViewer = checkUserRoleViewer()

  useEffect(() => {
    if (isFocused && showSearch) {
      if (!_.isEmpty(projectFilters)) {
        const {
          searchValue: prevSearchValue,
          selectedTags: prevSelectedTags,
          searchOnKey: prevSearchOnKey
        } = projectFilters
        if (prevSearchValue !== searchValue) {
          setSearchValue(prevSearchValue)
        }
        if (!_.isEqual(prevSelectedTags, selectedTags)) {
          setSelectedTags(prevSelectedTags)
        }
        if (prevSearchOnKey !== searchOnKey) {
          setSearchOnKey(prevSearchOnKey)
        }
      }
    }
  }, [projectFilters, isFocused, showSearch])

  const refresh = () => {
    setIsProcessing(false)
    setSelectedData([])
    setSelectedRowData([])
    setLoading(true)
    setPage(1)
    setSearchValue('')
    setSelectedTags(null)
    setSearchOnKey(false)
    fetchData(1, '', null)
  }

  const getProjectData = async (loader = false) => {
    setProjectLoading({
      show: true
    })

    const res = await getParticularProject(projectID || projectGroupId)
    setProjectLoading({ show: false })
    setCurrentProject(res.data.project)
  }

  useEffect(() => {
    let tagsValues = {}
    const tagMap = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        key_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      if (key_type?.includes('project')) {
        data.forEach((element) => {
          tagMap[element?.id] = {
            key,
            ...element
          }
          if (element.value) {
            if (tagsValues[key]?.values) {
              tagsValues[key].values.push({
                value: element.id,
                label: element.value
              })
            } else {
              if (!tagsValues[key]) tagsValues[key] = {}
              tagsValues[key].values = [
                {
                  value: element.id,
                  label: element.value
                }
              ]
            }
          }
          tagsValues[key] = {
            ...tagsValues[key],
            type,
            ...rest
          }
        })
      }
    })
    const projectStatusFilter = {
      editable: false,
      id: 'string',
      type: 'defaultsingle',
      key: 'project_status',
      key_type: 'project',
      hide_suggestions: true,
      values: [
        { label: 'Completed', value: 'completed' },
        { label: 'In Progress', value: 'in_progress' },
        { label: 'Won', value: 'won' }
      ],
      label: 'Project Status'
    }

    tagsValues = {
      ...tagsValues,
      project_status: projectStatusFilter
    }
    setTagsOptions(tagsValues)
    setTagState(tagMap)
  }, [tagsCenterStateTags])

  const renderTagDrawer = () => {
    return (
      <Drawer
        anchor={'right'}
        open={showTagDrawer}
        onClose={() => setShowTagDrawer(false)}
        disableEnforceFocus={true}
      >
        <Box sx={{ width: '400px', height: '100%' }}>
          {selectedData.length === 1 ? (
            <TagCenterUpdate
              id={selectedData?.[0]}
              filterTags="project"
              document_type={'project'}
              selectCallback={(e) => handleMultiTags(e)}
              onUpdateCallback={onUpdateCallback}
              cancelCallback={() => {
                setShowTagDrawer(false)
                setSelectedData([])
              }}
            />
          ) : (
            <TagCenterUpdate
              isOnlySelect={true}
              filterTags="project"
              selectCallback={(e) => handleMultiTags(e)}
              cancelCallback={() => {
                setShowTagDrawer(false)
                setSelectedPeople([])
              }}
            />
          )}
        </Box>
      </Drawer>
    )
  }

  const handleMultiTags = async (totalTags) => {
    if (selectedData.length === 0) {
      toast.error('Please select atleast one project to tag')
    } else {
      const datetags = {}
      const tags = {}
      const dateArr = {}
      const tagsArr = {}
      for (const key in totalTags) {
        if (
          ['multidate', 'singledate'].includes(totalTags?.[key]?.[0]?.tag_type)
        ) {
          datetags[key] = totalTags[key]
        } else {
          tags[key] = totalTags[key]
        }
      }
      Object.keys(datetags).forEach((key) => {
        datetags[key].forEach((item) => {
          if (dateArr[key]) {
            dateArr[key].push({
              tag_key: item.key,
              tag_value: item.label,
              tag_type: item.tag_type
            })
          } else {
            dateArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.label,
                tag_type: item.tag_type
              }
            ]
          }
        })
      })
      Object.keys(tags).forEach((key) => {
        tags[key].forEach((item) => {
          if (tagsArr[key]) {
            tagsArr[key].push({
              tag_key: item.key,
              tag_value: item.value,
              tag_label: item.label
            })
          } else {
            tagsArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.value,
                tag_label: item.label
              }
            ]
          }
        })
      })
      for (const key in dateArr) {
        if (dateArr[key].length === 0) {
          delete dateArr[key]
        }
      }
      for (const key in tagsArr) {
        if (tagsArr[key].length === 0) {
          delete tagsArr[key]
        }
      }
      if (_.isEmpty(tagsArr) && _.isEmpty(dateArr)) {
        toast.error('Please select atleast one tag')
      } else {
        setShowTagDrawer(false)
        setIsProcessing(true)
        const toastId = toast.info(
          <div style={{ display: 'flex' }}>
            {'Tagging Selected Projects'}&nbsp;
            <CircularProgress size={20} />
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false
          }
        )
        const documents = selectedData.map((item) => {
          return {
            id: item,
            file_type: 'project'
          }
        })
        const req = {
          documents,
          action: 'tags',
          data: { tags: tagsArr, date_tags: dateArr }
        }
        let failed = false

        const res = await multiDocuments(req)
        if (res.status === 200) {
          const job_id = res.data?.job_id
          const { status, payload } = await pollJobStatus(job_id)
          if (status) {
            toast.dismiss(toastId)
            const { new_tags = [] } = payload || {}
            const newTagPayload = {}
            new_tags.forEach((item) => {
              if (!newTagPayload[item.key]) {
                newTagPayload[item.key] = []
              }
              newTagPayload[item.key].push(item)
            })
            Object.keys(newTagPayload || {}).forEach((key) => {
              dispatch(updateNewTagValue({ new_tags: newTagPayload[key], key }))
            })

            const tagsFormated = {}
            const keysToRemove = []
            Object.keys(tagsArr || {}).forEach((key) => {
              keysToRemove.push(key)
              tagsArr[key].forEach((item) => {
                if (tagsFormated[key]) {
                  tagsFormated[key].push({
                    tag_key: key,
                    tag_value: item.tag_label,
                    id: item.tag_value
                  })
                } else {
                  tagsFormated[key] = [
                    {
                      tag_key: key,
                      tag_value: item.tag_label,
                      id: item.tag_value
                    }
                  ]
                }
              })
            })
            Object.keys(datetags || {}).forEach((key) => {
              const values = datetags[key]
              keysToRemove.push(key)
              const { data = [] } = tagsCenterStateTags[key] || {}
              values.forEach((item) => {
                const tag = data.find((element) => {
                  return element.value === item.label
                })
                if (tag) {
                  if (tagsFormated[key]) {
                    tagsFormated[key].push({
                      tag_key: key,
                      tag_value: tag.value,
                      id: tag.id
                    })
                  } else {
                    tagsFormated[key] = [
                      {
                        tag_key: key,
                        tag_value: tag.value,
                        id: tag.id
                      }
                    ]
                  }
                } else {
                  const newTag = new_tags.find((element) => {
                    return element.value === item.label
                  })
                  if (newTag) {
                    if (tagsFormated[key]) {
                      tagsFormated[key].push({
                        tag_key: key,
                        tag_value: newTag.value,
                        id: newTag.id
                      })
                    } else {
                      tagsFormated[key] = [
                        {
                          tag_key: key,
                          tag_value: newTag.value,
                          id: newTag.id
                        }
                      ]
                    }
                  }
                }
              })
            })
            selectedData.forEach((id) => {
              const index = projectList.findIndex((item) => item.id === id)
              if (index === -1) return null
              const oldTags = projectList[index].tags
              const tagsDict = {}
              oldTags.forEach((id) => {
                const item = tagState[id]
                if (item && item.key) {
                  if (tagsDict[item.key]) {
                    tagsDict[item.key].push(id)
                  } else {
                    tagsDict[item.key] = [id]
                  }
                }
              })
              Object.keys(tagsFormated).forEach((key) => {
                tagsDict[key] = tagsFormated[key].map((item) => item.id)
              })
              const newTags = []
              Object.keys(tagsDict).forEach((key) => {
                newTags.push(...tagsDict[key])
              })
              const payload = {
                id,
                tags: newTags
              }
              dispatch(updateProjectTag(payload))
            })
            setIsProcessing(false)
            setSelectedData([])
            setSelectedRowData([])
          } else {
            failed = true
          }
        } else {
          failed = true
        }
        if (failed) {
          toast.dismiss(toastId)
          toast.error('Error in tagging. Try again later')
          setIsProcessing(false)
        }
      }
    }
  }

  const onUpdateCallback = (id, key, tagsFlat = []) => {
    if (key !== 'tags') {
      return
    }

    const index = rows.findIndex((item) => item.id === id)
    if (index === -1) return null
    const tempProjectList = [...rows]
    tempProjectList[index].tags = tagsFlat
    setRows(tempProjectList)
    setShowTagDrawer(false)
    setSelectedData([])
    // dispatch(updatePeopleTag(payload))
  }

  const handleCloseProject = () => {
    openProject.current = false
    navigate(ROUTES.LIBRARY_PROJECTS)
  }

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    handleSearch('')
    initalizeData()
  }, [])

  useEffect(() => {
    if (page === 1) {
      fetchData(page)
    } else {
      setPage(1)
    }
    if (alphabetKey) {
      localStorage.setItem('projectAlphabet', alphabetKey)
    }
  }, [alphabetKey])

  useEffect(() => {
    const savedPage = localStorage.getItem('projectPage', page)
    const savedAlphabet = localStorage.getItem('projectAlphabet')
    const savedSearch = localStorage.getItem('projectSearchQuery')

    if (savedPage) {
      const pageNo = parseInt(savedPage)
      if (isNaN(pageNo)) {
        setPage(1)
      } else {
        setPage(pageNo)
      }
    } else {
      localStorage.setItem('projectPage', 1)
      setPage(1)
    }

    if (savedAlphabet && savedAlphabet !== 'null') {
      setAlphabetKey(savedAlphabet)
    }

    if (savedSearch) {
      setSearchValue(savedSearch)
    }
  }, [])

  useEffect(() => {
    if (projectID) {
      getProjectData(true)
    }
  }, [projectID])

  useEffect(() => {
    if (projectGroupId) {
      getProjectData(true)
    }
  }, [projectGroupId])

  useEffect(() => {
    if (page && !projectID && !projectGroupId) {
      fetchData(page)
      localStorage.setItem('projectPage', page)
    }
  }, [page])

  const navigateVariationChange = (index) => {
    const variationProjectId = topVariatonList?.[index]?.project_id
    if (variationProjectId) {
      navigate(`${LIBRARY_PROJECTS}/${collectionId}/${variationProjectId}`)
    } else {
      navigate(`${LIBRARY_PROJECTS}/${collectionId}`)
    }
  }

  const fetchMoreData = () => {
    const nextPage = page + 1
    setPage(nextPage)
    fetchData(nextPage)
  }

  const navigateOpenProject = (row) => {
    const projectId = row?.id

    navigate(`${LIBRARY_PROJECTS}/${projectId}`)
  }

  const updateProjectInfo = async (row, key, value) => {
    const oldRowInfo = {
      ...row
    }

    const data = {
      project_group_id: row.id,
      project_title: row.title,
      properties: {
        ...row.properties,
        [key]: {
          value
        }
      }
    }

    try {
      const res = await changeProjectInfo(data)
      if (res.status === 200) {
        setRows((prev) => {
          return prev.map((item) => {
            if (item.id === row.id) {
              item = {
                ...item,
                properties: {
                  ...item.properties,
                  [key]: {
                    value
                  }
                }
              }
            }
            return item
          })
        })
      }
    } catch (err) {
      console.log('err', err)
      toast.error('Opportunity info update failed')
      setLoading(true)
      const resetRows = rows.map((item) => {
        if (item.id === row.id) {
          return oldRowInfo
        }
        return item
      })
      setRows(resetRows)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  const columns = [
    {
      id: 'status',
      label: 'Status',
      accessorKey: 'status',
      leftView: true,
      headerStyle: {
        // maxWidth: '200px',
        gap: '10px',
        minWidth: '160px',
        width: '240px'
      },
      style: {
        minWidth: '160px',
        width: '240px'
      },
      children: (row) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <StatusDropdown
              groupType="project"
              showLabel={true}
              initialStatus={row?.properties?.project_status?.value || 'won'}
              changeStatus={(value) => {
                updateProjectInfo(row, 'project_status', value)
              }}
            />
          </span>
        )
      }
    },
    {
      id: 'title',
      label: 'Project Name',
      accessorKey: 'title',
      checkbox: true,
      onClick: navigateOpenProject,
      children: (row) => {
        return (
          <Tooltip title={row.title}>
            <p
              className="m-0 font-medium text-grey-800"
              style={{
                // whiteSpace: 'nowrap',
                maxHeight: '35px',
                textOverflow: 'ellipsis',
                // width: '80%',
                // maxWidth: '300px',
                overflow: 'hidden'
              }}
            >
              {row.title}
            </p>
          </Tooltip>
        )
      }
    },
    {
      id: 'type',
      label: 'Project Type',
      accessorKey: 'type',
      style: {
        maxWidth: '120px'
      },
      children: (row) => {
        return (
          <span className="max-w-20">
            {row?.properties?.project_type?.value
              ? row?.properties?.project_type?.value !== 'unknown'
                ? row?.properties?.project_type?.value
                : '-'
              : '-'}
          </span>
        )
      }
    },
    {
      id: 'tags',
      label: 'Tags',
      style: {
        maxWidth: '150px'
      },
      children: (row) => {
        return (
          <div
            className="flex items-center w-full text-xs"
            onClick={(e) => {
              e.stopPropagation()
              if (!isProcessing) setSelectedData([row.id])
            }}
          >
            <DisplayTags
              tags={row?.tags}
              setShowTagDrawer={() => {
                if (!isProcessing) setShowTagDrawer(row.id)
              }}
            />
          </div>
        )
      }
    },
    // {
    //   id: 'location',
    //   label: 'Location',
    //   accessorKey: 'location',
    //   style: {
    //     maxWidth: '120px'
    //   }
    // },
    {
      id: 'count',
      label: 'No. of Profiles',
      accessorKey: 'count',
      style: {
        maxWidth: '120px'
      },
      children: (row) => {
        return <span>{row?.count}</span>
      }
    }
  ]

  const setInitialData = () => {
    const data = []
    projectList &&
      projectList.forEach((project) => {
        const el = {}
        el.id = project?.id
        el.documentName = project?.data?.project_name
        el.location = project?.data?.project_location
        data.push(el)
      })
  }

  useEffect(() => {
    if (isArray(projectList) && projectList.length > 0) {
      setInitialData()
      setFilteredRecord(projectList)
      const notes = projectList?.map((e) => {
        return { id: e.id, notes: e.notes, tags: e.tags }
      })
      setNotesList(notes)
    }
  }, [projectList])

  useEffect(() => {
    if (!_.isEmpty(PDFData) && PDFData?.showDrawer) {
      setShowDrawer(true)
    }
  }, [PDFData])

  const openPdf = async (proposal_id, page_number, type = 'proposal') => {
    page_number = page_number || 0
    trackEvent(
      mixpanelEvents.PEOPLE_RESUME_PROPOSAL_VIEWED,
      'NAVIGATION',
      {
        proposal_id,
        page_number
      },
      {}
    )
    let path = `/view/pdf/${proposal_id}/${page_number}`
    if (type === 'rfx') {
      path = `/rfx/pdf/${proposal_id}/${page_number}`
    }
    if (path) {
      window.open(path, '_blank')
    }
  }

  const handleNotesUpdate = (notes, id) => {
    const payload = {
      id,
      notes
    }
    const callback = () => {
      const newNotesList = _.cloneDeep(notesList)
      const index = newNotesList.findIndex(
        (item) => item.id === currentProjectData.collection_id
      )
      newNotesList[index].notes = notes
      setNotesList(newNotesList)
    }
    dispatch(updateProjectTag(payload, callback))
  }

  const handleVariationIndexChange = (
    index,
    variationList = topVariatonList
  ) => {
    if (index >= 0) {
      trackEvent(
        mixpanelEvents.PROJECT_VARIATION_OPENED,
        'SUCCESS',
        {},
        { project_name: variationList?.[index]?.data?.project_name }
      )
      setScreenshotIndex(index + 1)
      setCurrentProjectData(variationList?.[index]?.data)
      // setTopVariatonList([]);
    } else {
      setScreenshotIndex(0)
      setCurrentProjectData(currentProject?.data)
      setTopVariatonList(currentProject?.variation_list)
    }
  }

  const handleSearch = (key) => {
    setSearchValue(key)
  }

  const handleSearchQuery = (event, value = null) => {
    if (event?.keyCode === 13) {
      const newFilters = {
        value,
        selectedTags
      }
      if (_.isEqual(newFilters, prevFilters)) {
        setLoading(false)
      } else {
        trackEvent(
          mixpanelEvents.PROJECT_SEARCHED,
          'SUCCESS',
          {},
          { search_value: value }
        )
        setLoading(true)
        setPage(1)
        fetchData(1, value)
        if (value) {
          setSearchOnKey(true)
        } else {
          setSearchOnKey(false)
        }
      }
    }
  }

  const handlePDFViewer = async (index) => {
    const pageNoList = [
      currentProject?.page_number,
      ...currentProject?.variation_list?.map((item) => item?.page_number)
    ]
    const proposalIdList = [
      currentProject?.proposal_id,
      ...currentProject?.variation_list?.map((item) => item?.proposal_id)
    ]
    const newPath = `/view/pdf/${proposalIdList?.[index]}/${pageNoList?.[index]}`
    window.open(newPath, '_blank')
  }

  const handleMerge = () => {
    if (selectedData.length < 2) {
      toast.error('Please select atleast two projects')
      return
    }

    trackEvent(
      mixpanelEvents.PROJECT_BULK_MERGE_CLICKED,
      'SUCCESS',
      {},
      { selected: selectedData.length }
    )

    setShowMergeModal(true)
    // return
  }
  const handleTagQuery = (newSelectedTags = selectedTags) => {
    setLoading(true)
    const newFilters = {
      searchValue,
      selectedTags: newSelectedTags
    }
    if (_.isEqual(newFilters, prevFilters)) {
      setLoading(false)
    } else {
      setPage(1)
      fetchData(1)
    }
  }

  const fetchData = async (
    page,
    searchVal = searchValue,
    selectedTagsObj = selectedTags
  ) => {
    const tag_values = []
    const tag_dates = []
    setLoading(true)
    selectedTagsObj &&
      Object.keys(selectedTagsObj).forEach((key) => {
        const tags = []
        let { condition, values = [] } = selectedTagsObj[key] || {}
        values = values || []
        values.forEach((item) => {
          if (item?.type?.includes('date')) {
            tag_dates.push({ ...item, condition })
          } else {
            tags.push(item.value)
          }
        })
        if (tags.length > 0) {
          tag_values.push({
            key,
            condition,
            values: tags
          })
        }
      })
    const newFilters = {
      searchValue: searchVal,
      selectedTags: selectedTagsObj,
      searchOnKey: !!searchVal
    }
    setPrevFilters(newFilters)

    const finalTags = tag_values.filter(
      (item) => item.key !== 'Project type' && item.key !== 'project_status'
    )

    let propertyFilter = []

    tag_values.map((item) => {
      if (item.key === 'Project type') {
        propertyFilter = [
          ...propertyFilter,
          {
            condition: item.condition,
            type: 'project_type',
            value: item.values[0]
          }
        ]
      }
      if (item.key === 'project_status') {
        propertyFilter = [
          ...propertyFilter,
          {
            condition: item.condition,
            type: 'project_status',
            value: item.values[0]
          }
        ]
      }

      return null
    })

    const req = {
      page_num: page,
      page_size: 20,
      keyword: searchVal,
      tags: finalTags,
      tag_dates,
      alphabetical: alphabetKey,
      property: propertyFilter
    }
    dispatch(
      fetchProjects(req, newFilters, (data) => {
        const newData = _.cloneDeep(data?.project).map((item) => {
          return {
            ...item,
            location: item?.data?.project_location
          }
        })
        setRows(newData)
        setLoading(false)
        setMeta({
          pageSize: data?.page_size,
          totalCount: data?.total_count,
          page: data?.page_num
        })
      })
    )
  }

  useEffect(() => {
    if (projectList) setRows(projectList)
  }, [projectList])

  useEffect(() => {
    if (isFocused && selectedTags && showSearch) {
      handleTagQuery(selectedTags)
    }
  }, [selectedTags, isFocused, showSearch])

  const rowSelection = (data) => {
    const tempSelectedData = cloneDeep(selectedData)
    const tempSelectedRow = cloneDeep(selectedRowData)
    if (tempSelectedData.includes(data.id)) {
      const index = tempSelectedData.indexOf(data?.id)
      tempSelectedData.splice(index, 1)
      tempSelectedRow.splice(tempSelectedData.indexOf(data), 1)
    } else {
      tempSelectedData.push(data.id)
      tempSelectedRow.push(data)
    }
    setSelectedData(tempSelectedData)
    setSelectedRowData(tempSelectedRow)
  }

  const handleTagsValueChange = (key, value, mode) => {
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  if (projectLoading?.show) {
    return (
      <div className="grid h-screen place-content-center">
        <div className="flex items-center gap-2">
          <SemiCircleIcon className="size-5 text-grey-700 animate-spin" />
          {projectLoading?.text ?? 'Fetching project details'}
        </div>
      </div>
    )
  }

  if ((collectionId || projectGroupId) && currentProject) {
    return (
      <ProjectResult
        v2={true}
        refresh={getProjectData}
        singleData={singleData}
        currentProject={currentProject}
        setCurrentProject={setCurrentProject}
        tagState={tagState}
        data={currentProjectData}
        openProject={openProject.current}
        handleCloseProject={handleCloseProject}
        s3Obj={s3Obj}
        openType={'tab'}
        thumbnail_url_list={topProposalScreenshot}
        handleVariationIndexChange={navigateVariationChange}
        screenshotIndex={screenshotIndex}
        originProposalNames={originProposalNames}
        handlePDFViewer={handlePDFViewer}
        openPdf={openPdf}
        handleNotesUpdate={handleNotesUpdate}
        projectId={currentProject?.id}
        projectTitle={currentProject?.title}
        notesList={notesList}
        setNotesList={setNotesList}
        showBackButton={!singleData}
        key={currentProject}
        pageType={pageType}
      />
    )
  }

  return (
    <Container>
      {!collectionId && (
        <Box
          className="flex items-center justify-between gap-2"
          style={{
            padding: '10px 18px'
          }}
        >
          <AlphabetSearch
            source="project"
            alphabetKey={alphabetKey}
            setAlphabetKey={setAlphabetKey}
            meta={meta}
            onClear={() => {
              setAlphabetKey(null)
              localStorage.removeItem('projectAlphabet')
            }}
          />

          <div className="flex items-center gap-1">
            <TagFilter
              source="project"
              showButton
              showTags={false}
              tags={tagsOptions}
              disabled={loading}
              selectedTags={selectedTags}
              onChange={handleTagsValueChange}
              filterIcon={
                _.isEmpty(selectedTags) && (
                  <Button2
                    secondary
                    noOutline
                    style={{
                      padding: '8px 9px'
                    }}
                  >
                    <FilterListIcon
                      className="size-4"
                      sx={{
                        height: '1rem',
                        width: '1rem'
                      }}
                    />
                  </Button2>
                )
              }
              clearFilter={
                !_.isEmpty(selectedTags) && (
                  <Button2 secondary onClick={() => refresh()}>
                    <CrossIcon className="size-4 text-grey-500 " />
                    clear filter
                  </Button2>
                )
              }
            />
            <SearchNew
              value={searchValue}
              style={{
                padding: '6px 10px'
              }}
              onChange={(value) => handleSearch(value)}
              onClear={() => {
                handleSearch('')
                handleSearchQuery({ keyCode: 13 }, '')
                localStorage.removeItem('projectSearchQuery')
              }}
              onEnter={(value) => {
                handleSearch(value)
                handleSearchQuery({ keyCode: 13 }, value)
                localStorage.setItem('projectSearchQuery', value)
              }}
            />
            {!isUserViewer && (
              <Button2
                primary
                style={{
                  padding: '7px 14px',
                  marginLeft: '2px'
                }}
                onClick={() => {
                  setShowCreate(true)
                }}
              >
                <PlusIcon className="size-3" strokeWidth={2.2} />
                New Project
              </Button2>
            )}
          </div>
        </Box>
      )}

      <Section overFlow>
        <div className={tableContainer}>
          <TagFilter
            source="project"
            showButton={false}
            showTags
            tags={tagsOptions}
            disabled={loading || isUserViewer}
            selectedTags={selectedTags}
            onChange={handleTagsValueChange}
          />
          <div
            id="scrollableDivProject"
            style={{ height: 'calc(100% - 10px)', overflow: 'hidden' }}
          >
            <HeadlessTable
              leftAlign={true}
              columns={columns}
              data={rows}
              meta={meta}
              pageSize={10}
              totalCount={projectList?.length}
              loading={loading}
              selectedData={selectedData}
              handleSelectedData={(row) => {
                if (!isProcessing) {
                  rowSelection(row)
                }
              }}
              pageLoading={false}
              onRowClick={navigateOpenProject}
              fetchPage={(page) => {
                setPage(page)
              }}
              unSelectAll={() => {
                setSelectedData([])
                setSelectedRowData([])
              }}
            />

            {!isUserViewer && (
              <Zoom in={selectedRowData.length > 0}>
                <div
                  className="absolute flex items-center justify-center p-2 text-white rounded-full bg-grey-800 w-72"
                  style={{
                    left: '35%',
                    bottom: '150px',
                    padding: '6px',
                    boxShadow: 'rgb(180 180 180) 5px 6px 20px 7px'
                  }}
                >
                  {isProcessing ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <>
                      <Button2
                        secondary
                        noOutline
                        onClick={() => {
                          setShowBulkModal(true)
                          trackEvent(
                            mixpanelEvents.PROJECT_BULK_UPDATE_CLICKED,
                            'SUCCESS',
                            {},
                            {}
                          )
                        }}
                        dark={true}
                        style={{
                          width: '100%',
                          justifyContent: 'center',
                          borderRadius: '100px'
                        }}
                      >
                        <PropertiesIcon className="size-4 " />
                        Update
                      </Button2>
                      <Button2
                        dark={true}
                        secondary
                        noOutline
                        onClick={() => handleMerge()}
                        style={{
                          width: '100%',
                          justifyContent: 'center',
                          borderRadius: '100px'
                        }}
                      >
                        <MergeIcon className="size-4 " />
                        Merge
                      </Button2>
                      <Button2
                        dark={true}
                        secondary
                        noOutline
                        onClick={() => {
                          setShowTagDrawer(true)
                          trackEvent(
                            mixpanelEvents.PROJECT_BULK_TAG_CLICKED,
                            'SUCCESS',
                            {},
                            {}
                          )
                        }}
                        style={{
                          width: '100%',
                          justifyContent: 'center',
                          borderRadius: '100px'
                        }}
                      >
                        <Label className="size-4 " />
                        Tag
                      </Button2>
                    </>
                  )}
                </div>
              </Zoom>
            )}
          </div>
        </div>

        {showBulkModal && (
          <BulkUpdateModal
            s3Obj={s3Obj}
            type={'project'}
            onSuccess={() => {
              refresh()
              setShowBulkModal(false)
              setSelectedData([])
              setSelectedRowData([])
            }}
            onClose={() => setShowBulkModal(false)}
            selectedList={selectedRowData}
            isOpen={showBulkModal}
          />
        )}

        {showMergeModal && (
          <GenericMergeModal
            isOpen={showMergeModal}
            onClose={() => setShowMergeModal(false)}
            selectedData={selectedRowData}
            type="project"
            onMerge={() => {
              refresh()
              setShowMergeModal(false)
              setSelectedData([])
              setSelectedRowData([])
            }}
          />
        )}

        {renderTagDrawer()}
      </Section>

      {showCreate && (
        <CreateObjectModal
          isOpen={showCreate}
          s3Obj={s3Obj}
          type="project"
          onClose={() => setShowCreate(false)}
          onSuccess={(id) => {
            refresh()
            navigate(`${LIBRARY_PROJECTS}/${id}`)
            setShowCreate(false)
          }}
        />
      )}
    </Container>
  )
}

export default ProjectList

const projectStatusOptions = [
  // Won, InProgress, Completed
  {
    label: 'Won',
    value: 'won',
    className: 'bg-green-50 text-green-600',
    icon: (
      <CheckCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'InProgress',
    value: 'in_progress',
    className: 'bg-yellow-50 text-yellow-600',
    icon: (
      <InProgressSemiCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'Completed',
    value: 'completed',
    className: 'bg-blue-50 text-blue-600',
    icon: <CheckIcon className="size-4" />
  }
]
