import React, { useState } from 'react'
import {
  DateRangePicker,
  DateRangeDelimiter,
  LocalizationProvider
} from '@material-ui/pickers'
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../../config/theme'
import { useStyles } from './styles'
import Chevron from '../MultiSelect/Chevron'
import Box from '@mui/material/Box'
import moment from 'moment'
import clsx from 'clsx'
import ClearIcon from '@mui/icons-material/Clear'
const DateRangeComponent = (props) => {
  const {
    id = '',
    range,
    placeholder,
    disabled = false,
    onClose = () => {},
    onOpen = () => {},
    onChange = () => {},
    ...rest
  } = props
  const classes = useStyles()
  const { dateButton, buttonText, disabledButton, dateSelected } = classes
  const [open, setOpen] = useState(false)

  return (
    <div>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <DateRangePicker
            id={id}
            onChange={onChange}
            value={range}
            onOpen={() => {
              setOpen(true)
              onOpen()
            }}
            onClose={() => {
              setOpen(false)
              onClose()
            }}
            disabled={disabled}
            calendars={1}
            renderInput={(startProps, endProps) => {
              const { inputProps = {} } = startProps
              const { onFocus = () => {}, onBlur = () => {} } = inputProps || {}
              const isFilled = range[0] && range[1]
              return (
                <Box
                  className={
                    isFilled
                      ? clsx(dateSelected, dateButton)
                      : disabled
                      ? clsx(dateButton, disabledButton)
                      : dateButton
                  }
                >
                  <Box className={buttonText}>
                    {range[0] || range[1] ? (
                      <>
                        <input
                          ref={startProps.inputRef}
                          {...startProps.inputProps}
                          onChange={() => {}}
                          style={{
                            border: 'none',
                            outline: 'none',
                            background: 'none',
                            cursor: disabled ? 'inherit' : 'pointer',
                            width: '150px',
                            textAlign: 'center'
                          }}
                          value={`${moment(range[0]).format('ll')} - ${moment(
                            range[1]
                          ).format('ll')}`}
                        />
                        <ClearIcon
                          style={{ fontSize: '18px' }}
                          onClick={() => {
                            if (!open) {
                              onChange(null)
                            }
                          }}
                        />
                      </>
                    ) : (
                      <Box
                        sx={{ padding: '0px 10px' }}
                        onClick={(e) => {
                          if (!open) {
                            onFocus()
                          }
                        }}
                      >
                        {placeholder}
                      </Box>
                    )}
                    <Box
                      sx={{ display: 'flex' }}
                      onClick={(e) => {
                        if (!open) {
                          onFocus()
                        }
                      }}
                    >
                      <Chevron open={open} />
                    </Box>
                  </Box>
                </Box>
              )
            }}
            {...rest}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  )
}

export default DateRangeComponent
