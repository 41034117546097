import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  outerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: 'var(--grey-100)',
    borderWidth: '1.5px',
    borderRadius: '5px',
    borderColor: 'var(--grey-300)',
    borderStyle: 'solid',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '90%',
    justifyContent: 'center',
    '& .uppy-Dashboard-inner': {
      height: 'fit-content !important'
    },
    '& .uppy-DashboardContent-panel': {
      position: 'fixed !important',
      maxWidth: '800px',
      margin: 'auto',
      maxHeight: '500px',
      overflow: 'auto',
      border: '1px solid var(--grey-300)'
    },
    '& .uppy-DashboardContent-panelBody': {
      backgroundColor: '#fafafa'
    },
    '& .uppy-DashboardContent-panel::before': {
      content: '""',
      position: 'fixed',
      top: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      left: 0,
      width: '100vw',
      height: '100%',
      zIndex: -1,
      transition: 'background-color 0.5s ease-in-out'
    }
  }
})

export { useStyles }
