import moment from 'moment'

/**
 * Formats a date to show how long ago it was
 * @param {string|Date} date - The date to format (UTC or local)
 * @param {boolean} v2 - Whether to use the v2 formatting style
 * @param {boolean} isUTC - Whether the provided date is in UTC format
 * @returns {string} Formatted time string
 */
function formatTimeAgo(date, v2 = false, isUTC = true) {
  const now = moment()
  // Convert UTC date to local time if needed
  const localTime = isUTC ? moment.utc(date).local() : moment(date)

  if (v2) {
    const diffInDays = now.diff(localTime, 'days')

    if (diffInDays > 0) {
      if (diffInDays === 1) {
        return 'yesterday'
      }
      return `${diffInDays} days ago`
    }

    if (now.diff(localTime, 'minutes') > 0) {
      return localTime.format('h:mm A')
    }

    return 'just now'
  }

  return localTime.fromNow()
}

export default formatTimeAgo

/**
 * Converts a UTC date string to local time
 * @param {string|Date} utcDateString - The UTC date to convert
 * @returns {Date} Local date object
 */
function convertUTCToLocalTime(utcDateString) {
  // Use moment for more reliable UTC to local conversion
  return moment.utc(utcDateString).local().toDate()
}

// Also export the UTC conversion function for direct use
export { convertUTCToLocalTime }
