import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../components/Lexical/ui/TextInput'
import Button2 from '../../components/Button/Button2'
import styled from '@emotion/styled'
import {
  CrossIcon,
  PencilIcon,
  PlusIcon,
  SemiCircleIcon
} from '../../components/Icons/Icons'
import { ImageAvatarFallback, SelectDropDown } from '../../components'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useDispatch } from 'react-redux'
import { mergePeople } from '../../store/People/Actions'
import { toast } from 'react-toastify'
import { mergeProfiles } from '../../store/api'
import { ModernCalendar } from '../ModernCalendar/ModernCalendar'

const GenericMergeModal = ({
  isOpen,
  selectedData,
  onMerge,
  type = 'project' || 'client' || 'opportunity',
  onClose,
  s3Obj
}) => {
  const [loading, setLoading] = useState(false)
  const [finalRecord, setFinalRecord] = useState(null)
  const [selectedList, setSelectedList] = useState(selectedData)
  const [profileImages, setProfileImages] = useState([])
  const [editProfileImage, setEditProfileImage] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState('')
  const [groupIds, setGroupIds] = useState(
    selectedList.map((record) => record.id)
  )

  const dispatch = useDispatch()

  const [uploadedImages, setUploadedImages] = useState([])
  useEffect(() => {
    initMerge()
  }, [selectedData])

  const initMerge = async () => {
    // const type = ''
    const name = selectedList[0]?.name ?? selectedList[0]?.title

    if (type === 'opportunity') {
      const finalPersonTemp = {
        name,
        properties: {
          opportunity_status: {
            value: null
          },
          submission_date: {
            value: undefined
          }
        }
      }

      setFinalRecord({
        ...finalPersonTemp
      })

      return
    }

    if (type === 'project') {
      const finalPersonTemp = {
        name,
        properties: {
          project_type: {
            value: null
          },
          project_status: {
            value: 'won'
          },
          opportunity_status: {
            value: 'won'
          }
        }
      }

      setFinalRecord({
        ...finalPersonTemp
      })

      return
    }

    console.log('type', type)

    const key = type === 'project' ? 'project_type' : 'client_type'

    const finalPersonTemp = {
      name,
      properties: {
        [key]: {
          value: '',
          verified: false
        }
      }
    }

    setFinalRecord({
      ...finalPersonTemp
    })
  }

  const dropdownValues = {
    people: [
      {
        label: 'Employee type',
        key: 'employee_type',
        type: 'dropdown',
        options: [
          { label: 'Internal', value: 'internal' },
          { label: 'External', value: 'external' },
          { label: 'Unknown', value: 'unknown' },
          { label: 'Inactive', value: 'inactive' }
        ]
      }
    ],

    client: [
      {
        label: 'Client type',
        key: 'client_type',
        type: 'dropdown',
        options: [
          { label: 'Client', value: 'client' },
          { label: 'Partner', value: 'partner' },
          { label: 'Competitor', value: 'competitor' }
        ]
      }
    ],

    project: [
      {
        label: 'Project type',
        key: 'project_type',
        type: 'dropdown',
        options: [
          { label: 'Internal', value: 'internal' },
          { label: 'External', value: 'external' },
          { label: 'Unknown', value: 'unknown' }
        ]
      },
      {
        label: 'Project Status',
        type: 'dropdown',
        key: 'project_status',
        options: [
          { label: 'Won', value: 'won' },
          { label: 'In Progress', value: 'in_progress' },
          { label: 'Completed', value: 'completed' }
        ]
      }
    ],

    opportunity: [
      {
        label: 'Opportunity Status',
        type: 'dropdown',
        key: 'opportunity_status',
        options: [
          { label: 'Lead', value: 'lead' },
          { label: 'Won', value: 'won' },
          {
            label: 'Submitted',
            value: 'submitted'
          },
          { label: 'Lost', value: 'lost' },
          { label: 'Closed', value: 'closed' },
          { label: 'In Progress', value: 'rfx_in_progress' }
        ]
      },
      {
        label: 'Submission Date',
        key: 'submission_date',
        placeholder: 'Set Submission Date',
        showLabel: true,
        type: 'date'
      }
    ]
  }

  const additionalFields = {
    opportunity: []
  }

  const handleMergePeople = async () => {
    if (finalRecord?.name.length < 1) {
      toast.error('Please enter a name')
      return
    }

    if (type === 'opportunity') {
      console.log('finalRecord', finalRecord)
      // return
    }
    setLoading(true)

    const body = {
      group_ids: groupIds,
      group_name: finalRecord?.name,
      group_type: type,
      properties: finalRecord?.properties
    }

    console.log('mergePeople', body)

    // const id = toast.loading('Please wait...')
    const event = {
      people: mixpanelEvents.PEOPLE_RESUME_MERGED,
      client: mixpanelEvents.CLIENT_MERGED,
      project: mixpanelEvents.PROJECTS_MERGED
    }

    trackEvent(event[type], 'SUCCESS', {}, {})
    // setIsMerge('loading')

    const callback = () => {
      // setIsMerge(false)
      // setSelectedPeople([])
      // toast.dismiss(id)
      setLoading(false)

      onMerge()
    }

    const fallback = () => {
      // setIsMerge(true)
      // toast.dismiss(id)
      toast.error('Failed to merge people')
      setLoading(false)
    }

    mergeProfiles(body).then(callback).catch(fallback)
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          padding: '14px',
          maxHeight: '450px',
          overflow: 'hidden',
          overflowY: 'auto'
          // height: '110px'
        }
      }}
    >
      <div
        className="relative flex flex-col justify-between h-full"
        style={{
          width: '700px',
          height: '300px',
          overflow: 'auto'
        }}
      >
        <div className="grid grid-col-2">
          <div>
            <p className="px-2 m-0 text-sm font-medium text-center">
              Selected{' '}
              {type === 'people'
                ? 'People'
                : type === 'client'
                ? 'Companies'
                : 'Projects'}
            </p>
            <div className="p-3">
              {selectedList.map((record, index) => {
                return (
                  <div className="flex items-center gap-2 mt-3" key={index}>
                    <div className="flex items-center gap-2">
                      <ImageAvatarFallback
                        profilePic={record?.profile_picture}
                        s3Obj={s3Obj}
                        type="project"
                        style={{
                          width: '35px',
                          height: '35px',
                          objectFit: 'cover',
                          borderRadius: '10px',
                          border: '1px solid var(--grey-200)'
                        }}
                      />
                    </div>
                    <div>
                      <div className="flex items-center gap-1">
                        <p className="m-0 text-xs font-medium">
                          {record?.name ?? record?.title}
                        </p>
                      </div>
                    </div>
                    {selectedList.length > 2 && (
                      <Button2
                        secondary
                        // noOutline
                        onClick={() => {
                          const finalRecords = selectedList.filter(
                            (item) => item.id !== record.id
                          )
                          setSelectedList(finalRecords)

                          setGroupIds(
                            groupIds.filter((item) => item !== record.id)
                          )

                          // initMerge()
                        }}
                        style={{
                          marginLeft: 'auto',
                          padding: '2px 6px',
                          fontSize: '10px'
                        }}
                      >
                        Deselect
                      </Button2>
                    )}
                  </div>
                )
              })}
            </div>
          </div>

          {finalRecord && (
            <div
              style={{
                borderLeft: '1px solid var(--grey-200)'
              }}
            >
              <p className="px-2 m-0 text-sm font-medium text-center">Review</p>

              <div className="p-3">
                <div className="flex flex-col justify-center w-full">
                  <div className="w-full">
                    <span className="ml-1 text-xs text-grey-600">Name</span>
                    <input
                      className="w-full p-1 mb-1 text-sm rounded input-base border-grey-200"
                      value={finalRecord?.name}
                      onChange={(e) => {
                        setFinalRecord({
                          ...finalRecord,
                          name: e.target.value
                        })
                      }}
                    />
                    {/* <p className="m-0">{finalPerson?.name}</p> */}

                    {/* <p className="m-0 ml-2 text-xs">
                      {finalPerson.resumes.length} Resumes
                    </p> */}
                  </div>
                  <div className="flex flex-col gap-2 mt-2 text-left">
                    {dropdownValues[type].map((item, index) => {
                      if (item.type === 'dropdown') {
                        return (
                          <div key={index}>
                            <span className="ml-1 text-xs text-grey-600">
                              {item.label}
                            </span>

                            <div className="mt-1">
                              <SelectDropDown
                                name={item.key}
                                label=""
                                value={_.startCase(
                                  finalRecord?.properties?.[item.key]?.value
                                )}
                                options={item.options}
                                handleChange={(e) => {
                                  setFinalRecord({
                                    ...finalRecord,
                                    properties: {
                                      ...finalRecord.properties,
                                      [item.key]: {
                                        value: e,
                                        verified: true
                                      }
                                    }
                                  })
                                }}
                              />
                            </div>
                          </div>
                        )
                      }

                      if (item.type === 'date') {
                        return (
                          <div key={index}>
                            <span className="ml-1 text-xs text-grey-600">
                              {item.label}
                            </span>

                            <ModernCalendar
                              showLabel={item.showLabel}
                              label={item.placeholder}
                              format="DD, MMM YYYY"
                              value={finalRecord?.properties?.[item.key]?.value}
                              onChange={(e) => {
                                setFinalRecord({
                                  ...finalRecord,
                                  properties: {
                                    ...finalRecord.properties,
                                    [item.key]: {
                                      value: e,
                                      verified: true
                                    }
                                  }
                                })
                              }}
                            />
                          </div>
                        )
                      }
                      return (
                        <div key={index}>
                          <span className="ml-1 text-xs text-grey-600">
                            {item.label}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center w-full gap-2 mt-3 ml-1 bg-white">
        <div className="flex gap-1 ml-auto">
          <Button2 variant="outlined" onClick={onClose} secondary noOutline>
            Cancel
          </Button2>
          <Button2
            variant="outlined"
            onClick={handleMergePeople}
            primary
            // disabled={collectionName === ''}
          >
            {loading ? (
              <SemiCircleIcon className="text-grey-200 animate-spin size-4" />
            ) : (
              'Merge'
            )}
          </Button2>
        </div>
      </div>{' '}
    </Dialog>
  )
}

export default GenericMergeModal
