'use client'

import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import {
  TooltipProvider,
  Tooltip as TooltipRoot,
  TooltipTrigger,
  TooltipContent
} from '@/components/ui/tooltip'

type TooltipProps = Omit<
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>,
  'content'
> & {
  content: React.ReactNode
  providerProps?: React.ComponentPropsWithoutRef<typeof TooltipProvider>
  rootProps?: React.ComponentPropsWithoutRef<typeof TooltipRoot>
  triggerProps?: React.ComponentPropsWithoutRef<typeof TooltipTrigger>
}

/**
 * Custom Tooltip component
 *
 * @description
 * easy to use custom tooltip component with simpler configuration
 *
 * @props
 * - ...props: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
 * - content: React.ReactNode
 * - providerProps: React.ComponentPropsWithoutRef<typeof TooltipProvider>
 * - rootProps: React.ComponentPropsWithoutRef<typeof TooltipRoot>
 * - triggerProps: React.ComponentPropsWithoutRef<typeof TooltipTrigger>
 *
 * @example
 * <Tooltip content="Add to library">
 *  <Button>Hover</Button>
 * </Tooltip>
 */

const Tooltip = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipProps
>(
  (
    { children, content, providerProps, rootProps, triggerProps, ...props },
    ref
  ) => {
    return (
      <TooltipProvider delayDuration={300} {...providerProps}>
        <TooltipRoot {...rootProps}>
          <TooltipTrigger asChild {...triggerProps}>
            {children}
          </TooltipTrigger>
          <TooltipContent ref={ref} {...props}>
            {content}
          </TooltipContent>
        </TooltipRoot>
      </TooltipProvider>
    )
  }
)
Tooltip.displayName = 'Tooltip'

export { Tooltip }
