import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import _ from 'lodash'
import { checkExpiry, getSignedUrl } from '../../utils/AWS'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Loader, ImageGallery, useConfirmation } from '../../components'
import { PDFDocument } from 'pdf-lib'
import MarkdownIt from 'markdown-it'

import Box from '@mui/material/Box'
import { useColor } from '../../ThemeContext'

import {
  changeProjectInfo,
  contentSearch,
  deleteProjectFromGroup,
  postCollectionData
} from '../../store/api'
import CloseIcon from '@mui/icons-material/Close'

import Drawer from '@mui/material/Drawer'
import TagCenterUpdate from '../TagCenterUpdate'
import { useDispatch, useSelector } from 'react-redux'
import { updateProjectTag } from '../../store/Project/Actions'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import { checkUserRoleViewer } from '../../utils/User'
import Notebook from '../Notebook/Notebook'
import Button2 from '../../components/Button/Button2'
import {
  RedirectIcon,
  PencilIcon,
  ProjectIcon,
  CheckCircleIcon,
  InProgressSemiCircleIcon,
  CheckIcon
} from '../../components/Icons/Icons'
import DisplayTags from '../ResumeResult/DisplayTags'
import { toast } from 'react-toastify'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import EditProjectModal from './EditProjectModal'
import AttachFilesUI from '../../components/AttachFilesUI/AttachFilesUI'
import ProjectResumes from '../ProjectResumes/ProjectResumes'
import StatusDropdown from '@/containers/OpportunitiesList/StatusDropdown'
import { ModernTooltip } from '@/components/ModernTooltip/ModernTooltip'

const ScrollablePictures = (props) => {
  const { images = [], s3Obj, viewImage = () => {}, type = '' } = props
  const [imagesUrl, setImagesUrl] = useState([])
  const [loading, setLoading] = useState(true)

  const s3Urls = async (images, s3Obj) => {
    const newImagesUrl = []
    setLoading(true)

    try {
      await Promise.all(
        images.map(async (imageUrl) => {
          const signedSrc = await checkExpiry(
            imageUrl?.src ? imageUrl?.src : imageUrl,
            s3Obj
          )
          newImagesUrl.push(signedSrc)
        })
      )
      setImagesUrl(newImagesUrl)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    s3Urls(images, s3Obj)
  }, [images, s3Obj])

  return (
    <Box id="Hori-Scroll-Box" style={{ height: '250px', maxWidth: '100%' }}>
      {loading ? (
        <Loader loading={loading} caption={''} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '250px',
            overflowX: 'auto',
            maxWidth: '100%',
            overflowY: 'hidden'
          }}
        >
          {imagesUrl.map((image, index) => (
            <div
              key={index}
              style={{
                // position: 'relative',
                marginRight: '10px',
                margin: '8px',
                borderRadius: '10px',
                height: '250px',
                maxWidth: '100%', // Ensure the inner div doesn't exceed the parent's width
                flexShrink: 0
              }}
              onClick={() => viewImage([{ src: image }])}
            >
              <img
                src={image}
                alt={`Image ${index}`}
                style={{
                  width: '100%',
                  maxHeight: '220px',
                  borderRadius: '10px'
                }}
              />
            </div>
          ))}
        </div>
      )}
    </Box>
  )
}

const Text = (props) => {
  const { text, style } = props
  return <span style={style} dangerouslySetInnerHTML={{ __html: text }} />
}

const ProjectResult = (props) => {
  const {
    singleData,
    v2 = false,
    data = {},
    openProject,
    openPdf,
    currentProject,
    handleCloseProject = () => {},
    s3Obj,
    openType = 'tab',
    thumbnail_url_list = [],
    handleVariationIndexChange = () => {},
    screenshotIndex = 0,
    originProposalNames = [],
    handlePDFViewer = () => {},
    handleNotesUpdate = () => {},
    notesList = [],
    tagState = {},
    projectId = '',
    projectTitle = '',
    refresh,
    showBackButton = true,
    setCurrentProject = () => {},
    pageType = 'default'
  } = props

  const [selectedImage, setSelectedImage] = useState([])
  const md = new MarkdownIt() // this is used to convert textToHtml
  const [timeline, setTimeline] = useState('')
  const [signedThumbnailList, setSignedThumbnailList] = useState([])
  const { selectedColor } = useColor()
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [editProfile, setEditProfile] = useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const isUserViewer = checkUserRoleViewer()
  const [editName, setEditName] = useState(null)
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [selectedProject, setSelectedProject] = useState(null)

  const gets3Urls = async (images, s3Obj) => {
    const newImagesUrl = []
    try {
      await Promise.all(
        images.map(async (imageUrl) => {
          const signedSrc = await checkExpiry(
            imageUrl?.src ? imageUrl?.src : imageUrl,
            s3Obj
          )
          newImagesUrl.push(signedSrc)
        })
      )
      const thumbnailList = []
      newImagesUrl.forEach((image, index) => {
        thumbnailList.push({
          screenShot: image,
          proposal_name: originProposalNames[index],
          proposal_id: currentProject?.proposal_id,
          page_number: currentProject?.page_number,
          clientId: currentProject?.client_group?.id
        })
      })
      setSignedThumbnailList(thumbnailList)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (thumbnail_url_list.length > 0) {
      gets3Urls(thumbnail_url_list, s3Obj)
    } else {
      setLoading(false)
    }
  }, [thumbnail_url_list, s3Obj])

  useEffect(() => {
    const { uploaded_files = [] } = currentProject || {}
    setFiles(uploaded_files)
  }, [currentProject])

  const preprocessMarkdown = (markdownText) => {
    let format = markdownText.replace(/<br>/g, '  \n')
    format = format.replace(/\*/g, '\n+')
    format = format.replace(/<mark>/g, '')
    format = format.replace(/<\/mark>/g, '')
    return format
  }

  const convertToHTML = (markdownText) => {
    markdownText = markdownText || ' '
    const preprocessedText = preprocessMarkdown(markdownText)
    return md.render(preprocessedText)
  }

  const [editedNote, setEditedNote] = useState('')
  const [tags, setTags] = useState([])
  const [prevNote, setPrevNote] = useState('')

  const [activeTab, setActiveTab] = useState(0)
  const [notesData, setNotesData] = useState(currentProject?.notes ?? [])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredProjects, setFilteredProjects] = useState(currentProject.data)
  const [anchorEl, setAnchorEl] = useState(null)
  const [basicInfo, setBasicInfo] = useState({
    name: currentProject.title,
    logo: '',
    projectType: currentProject?.properties?.project_type?.value ?? 'unknown',
    properties: currentProject?.properties,
    tags: currentProject?.tags
  })
  const [contentSearching, setContentSearching] = useState(false)
  const [files, setFiles] = useState([])

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredProjects(currentProject.data)
      return
    }
    const timeout = setTimeout(() => {
      setContentSearching(true)
      trackEvent(
        mixpanelEvents.PROJECT_DOCUMENT_CONTENT_SEARCHED,
        'SUCCESS',
        {},
        {
          project_id: currentProject?.id,
          search_query: searchQuery
        }
      )

      contentSearch({
        group_id: currentProject?.id,
        search_keyword: searchQuery,
        search_type: 'project'
      })
        .then((res) => {
          const foundList = res.data.data
          setContentSearching(false)
          const filtered = singleData.data.filter((project) => {
            return foundList.includes(project.id)
          })

          setFilteredProjects(filtered)
        })
        .catch((err) => {
          console.log(err)
          setContentSearching(false)
        })
    }, 1500)

    return () => clearTimeout(timeout)
  }, [searchQuery])

  useEffect(() => {
    const item = notesList.filter((note) => note.id === projectId)[0]
    const notes = item?.notes
    const tags = item?.tags
    setEditedNote(notes)
    setPrevNote(notes)
    setTags(tags)
  }, [notesList])

  useEffect(() => {
    if (activeTab === 1) {
      trackEvent(
        mixpanelEvents.PROJECT_NOTES_TAB_OPENED,
        'SUCCESS',
        {},
        {
          project_id: currentProject?.id
        }
      )
    }

    if (activeTab === 2) {
      trackEvent(
        mixpanelEvents.PROJECT_FILE_TAB_OPENED,
        'SUCCESS',
        {},
        {
          project_id: currentProject?.id
        }
      )
    }
  }, [activeTab])

  const renderObjectProperties = (object) => {
    return Object.keys(object).map((key) => (
      <div key={key}>
        {object?.[key] && (
          <>
            <Text
              text={_.startCase(key) + ' - '}
              style={{ fontWeight: 'bold' }}
            />
            {typeof object[key] === 'object' ? (
              renderObjectProperties(object[key])
            ) : (
              <Text text={object[key] + '<br/>'} />
            )}
          </>
        )}
      </div>
    ))
  }

  const renderKeyTeamMembers = (teamMembers) => {
    if (typeof teamMembers === 'string') {
      return (
        <div>
          <Text text={teamMembers + '<br/>'} />
        </div>
      )
    }
    return teamMembers?.map((member, index) => (
      <div key={index}>
        <Text
          text={
            (member?.name ? member?.name : '') +
            (member?.role ? ', ' + member?.role : '') +
            (member?.responsibilities ? ', ' + member?.responsibilities : '') +
            '<br/>'
          }
        />
      </div>
    ))
  }

  const handleDownloadCustomPdf = async (
    proposalId,
    pageNumber,
    pdfName,
    thumbnail_url,
    sourceType = 'proposal'
  ) => {
    const toastId = toast.loading('Downloading Resume PDF...')
    try {
      let fileUrl = `https://${process.env.REACT_APP_PDF_BUCKET}.s3.amazonaws.com/raw-pdfs/${domain_id}/${proposalId}.pdf`
      if (sourceType === 'rfx') {
        fileUrl = `https://${process.env.REACT_APP_RFX_BUCKET}.s3.amazonaws.com/${process.env.REACT_APP_RFX_PREFIX}/${domain_id}/${proposalId}.pdf`
      }
      const finalUrl = await getSignedUrl(fileUrl, s3Obj, `${proposalId}.pdf`)
      const arrayBuffer = await fetch(finalUrl).then((res) => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(arrayBuffer)
      const newPdf = await PDFDocument.create()

      const pagesToBeCopied = [pageNumber]
      const copiedPages = await newPdf.copyPages(pdfDoc, pagesToBeCopied)

      for (const copiedPage of copiedPages) {
        newPdf.addPage(copiedPage)
      }
      const pdfBytes = await newPdf.save()
      const blob = new Blob([pdfBytes], { type: 'application/pdf' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = pdfName
      link.click()

      trackEvent(mixpanelEvents.PROJECT_RESUME_DOWNLOAD, 'SUCCESS', {
        proposal_id: proposalId
      })
    } catch (error) {
      if (thumbnail_url) {
        await initalizeDownload(thumbnail_url, pdfName + '.png', s3Obj)
      }
    }
    toast.dismiss(toastId)
  }

  const viewImage = async (payload) => {
    const url = _.cloneDeep(payload)
    if (_.isArray(url)) {
      await Promise.all(
        url.map(async (img, index) => {
          const signedSrc = await checkExpiry(img.src, s3Obj)
          url[index].src = signedSrc
        })
      )
      setSelectedImage(url)
    } else {
      const signedSrc = await checkExpiry(url, s3Obj)
      setSelectedImage([{ src: signedSrc }])
    }

    trackEvent(
      mixpanelEvents.PROJECT_SCREENSHOT_VIEWED,
      'SUCCESS',
      {},
      { proposal_id: currentProject?.proposal_id }
    )
  }

  const renderTagDrawer = () => {
    const id = projectId
    const document_type = 'project'
    return (
      id && (
        <Drawer
          anchor={'right'}
          open={showTagDrawer}
          onClose={() => setShowTagDrawer(false)}
        >
          <Box sx={{ padding: '20px', width: '400px', height: '100%' }}>
            <TagCenterUpdate
              id={id}
              filterTags="project"
              document_type={document_type}
              onUpdateCallback={onUpdateCallback}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          </Box>
        </Drawer>
      )
    )
  }

  const onUpdateCallback = (id, mode, tagsFormated) => {
    if (mode === 'tags') {
      const newTags = []
      tagsFormated.forEach((tag) => {
        newTags.push(tag.id)
      })
      const payload = {
        id,
        tags: newTags
      }
      const callback = () => {
        setTags(newTags)
        setBasicInfo({
          ...basicInfo,
          tags: newTags
        })
      }
      dispatch(updateProjectTag(payload, callback))
    }
  }

  const handleMasterMappingDelete = (
    // selectedProject,
    resumeIndex,
    operation = null
    // resumeId = null
  ) => {
    const { resumes = [] } = selectedProject
    const selectedResumeData = resumes[resumeIndex]

    const { id = '' } = selectedResumeData || {}
    showConfirmDialog({
      onConfirm: () => {
        // setIsSaving(true)
        const toastId = toast.loading(
          operation === 'remove' ? 'Moving Resume...' : 'Deleting Resume...'
        )
        const newResumes = resumes.filter(
          (resume, index) => index !== resumeIndex
        )
        const req = {
          collection_id: currentProject.id,
          project_id: selectedProject.project_id,
          op_type: operation
        }
        const callback = () => {
          toast.dismiss(toastId)
          // setIsSaving(false)
          // if (selectedProject?.resumes?.length === 1) {
          //   setSelectedPerson(null)
          //   handleBacktoSearch()
          // } else {
          //   fetchResumes(true)
          // }

          refresh()
        }
        if (operation === 'remove') {
          trackEvent(
            mixpanelEvents.PROJECT_RESUME_REMOVED,
            'IN-PROCESS',
            {},
            { project_name: selectedProject?.name }
          )
        } else {
          trackEvent(
            mixpanelEvents.PROJECT_RESUME_DELETED,
            'IN-PROCESS',
            {},
            { people_name: selectedProject?.name }
          )
        }

        deleteProjectFromGroup(req).then(callback)
      },
      confirmationMessageTitle: (
        <>
          Are you sure you want to {operation === 'remove' ? 'move' : operation}{' '}
          this resume ?
        </>
      )
    })
  }

  const updateProjectInfo = async (key, value) => {
    const oldRowInfo = {
      ...basicInfo
    }

    const data = {
      project_group_id: currentProject?.id,
      project_title: currentProject?.title,
      properties: {
        ...basicInfo?.properties,
        [key]: {
          value,
          is_verified: true
        }
      }
    }

    try {
      const res = await changeProjectInfo(data)
      if (res.status === 200) {
        // setRefreshState(true)
        setBasicInfo({
          ...basicInfo,
          properties: {
            ...data.properties
          }
        })
      }
    } catch (err) {
      console.log('err', err)
      toast.error('Project info update failed')
      setLoading(true)
      setBasicInfo(oldRowInfo)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  return openType === 'search_results' ? (
    <>
      <Dialog
        open={openProject}
        onClose={handleCloseProject}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </DialogTitle>
        <DialogContent>
          {images?.length > 0 && (
            <ScrollablePictures
              images={images}
              s3Obj={s3Obj}
              type={'view'}
              viewImage={viewImage}
            />
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '30% 70%',
              gap: '0px',
              gridAutoFlow: 'row'
            }}
          >
            <div style={{ gridColumn: '1', margin: '5px' }}>
              {project_location && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Location : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={project_location + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {total_project_cost && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Budget : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={total_project_cost + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {square_footage && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Area : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={square_footage + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {timeline !== '' && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Duration : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={timeline + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {project_team_members.length > 0 && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text
                    text={'Key Team Members -'}
                    style={{ fontWeight: 'bold' }}
                  />
                  {renderKeyTeamMembers(project_team_members)}
                </Box>
              )}
            </div>
            <div style={{ gridColumn: '2', margin: '5px', overflow: 'auto' }}>
              {project_description && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text
                    text={'About the project -  <br/>'}
                    style={{ fontWeight: 'bold' }}
                  />
                  <Text text={convertToHTML(project_description)} />
                </Box>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          <IconButton onClick={handleCloseProject}>
            <CloseIcon sx={{}} />
          </IconButton>
        </DialogActions>
      </Dialog>
      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}
    </>
  ) : loading ? (
    <Loader loading={loading} caption={''} flex />
  ) : (
    <>
      <div className="w-full h-full">
        <div className="h-full">
          <div
            style={{
              width: '70%',
              margin: 'auto',
              padding: '20px',
              paddingBottom: '0px'
            }}
          >
            {pageType === 'default' && (
              <div className="flex items-center gap-2 mb-3 text-xxs text-grey-500">
                <span className="cursor-pointer" onClick={handleCloseProject}>
                  Project
                </span>{' '}
                <span> {'>'} </span>{' '}
                <span className="text-grey-700 text-capitalize w-44 text-truncate">
                  {basicInfo.name}
                </span>
              </div>
            )}
            <div className="flex items-center gap-2">
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  padding: '6px',
                  borderRadius: '10px',
                  border: '1px solid var(--grey-200)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'var(--grey-100)'
                }}
              >
                <ProjectIcon className="mt-1 size-4 text-grey-400" />
              </div>
              <div className="flex flex-col w-full">
                <div className="flex items-center w-full gap-2">
                  <div className="w-full">
                    <div className="flex items-center">
                      <p
                        className="gap-1 m-0 text-lg font-medium leading-5 text-capitalize"
                        style={{
                          maxHeight: '40px',
                          width: '80%',
                          overflow: 'hidden'
                        }}
                      >
                        {basicInfo.name}
                      </p>
                      {!isUserViewer && (
                        <Button2
                          secondary
                          style={{
                            marginLeft: 'auto',
                            gap: '6px'
                          }}
                          onClick={(e) => {
                            setEditProfile(true)
                          }}
                        >
                          <PencilIcon className="size-3 text-grey-700" />
                          Edit Project
                        </Button2>
                      )}
                    </div>

                    {basicInfo && (
                      <div className="flex items-center gap-1 twp">
                        <StatusDropdown
                          showLabel={true}
                          groupType="project"
                          initialStatus={
                            basicInfo?.properties?.project_status?.value ??
                            'won'
                          }
                          changeStatus={(status) => {
                            updateProjectInfo('project_status', status)
                          }}
                        />

                        <div className="rounded-full size-1 bg-zinc-200"> </div>

                        {currentProject?.company &&
                          currentProject?.company?.length > 0 && (
                            <>
                              {currentProject?.company?.map((company) => (
                                <>
                                  <ModernTooltip
                                    content={
                                      <div className="px-1 font-medium bg-white border border-zinc-200">
                                        <div className="flex items-center gap-1 text-xs text-zinc-700">
                                          {/* <ProjectIcon className="size-3 text-zinc-700" />{' '} */}
                                          Company details
                                        </div>

                                        <div
                                          className="flex items-center gap-1 py-1 text-sm font-medium cursor-pointer text-zinc-800"
                                          onClick={() => {
                                            window.open(
                                              `/library/companies/${company?.company_id}`,
                                              '_blank'
                                            )
                                          }}
                                        >
                                          {company?.company_name}{' '}
                                          <RedirectIcon className="size-3 text-zinc-700" />
                                        </div>
                                      </div>
                                    }
                                  >
                                    <div
                                      className="flex items-center gap-1 p-1 px-2 text-xs font-medium rounded-md cursor-pointer text-zinc-800 hover:bg-zinc-100"
                                      onClick={() => {
                                        window.open(
                                          `/library/companies/${company?.company_id}`,
                                          '_blank'
                                        )
                                      }}
                                    >
                                      <ProjectIcon className="size-3.5 text-zinc-500" />
                                      {company?.company_name}
                                    </div>
                                  </ModernTooltip>

                                  <div className="rounded-full size-1 bg-zinc-200">
                                    {' '}
                                  </div>
                                </>
                              ))}
                            </>
                          )}
                        <DisplayTags
                          tags={basicInfo?.tags ?? []}
                          setShowTagDrawer={() => {
                            setShowTagDrawer(true)
                          }}
                        />

                        {basicInfo.projectType !== 'unknown' && (
                          <>
                            <div className="rounded-full size-1 bg-zinc-200">
                              {' '}
                            </div>

                            <div className="px-2 mb-1 rounded-full bg-grey-100 text-xxs w-fit">
                              {basicInfo.projectType}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-3">
              <p
                className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
                onClick={() => setActiveTab(0)}
                style={{
                  borderBottom: activeTab === 0 && '2px solid var(--grey-700)'
                }}
              >
                Profiles
              </p>
              <p
                className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
                onClick={() => setActiveTab(1)}
                style={{
                  borderBottom: activeTab === 1 && '2px solid var(--grey-700)'
                }}
              >
                Notes
              </p>
              <p
                className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
                onClick={() => setActiveTab(2)}
                style={{
                  borderBottom: activeTab === 2 && '2px solid var(--grey-700)'
                }}
              >
                Files
              </p>
            </div>
          </div>

          <div
            className="p-4 bg-grey-100"
            style={{
              height: 'calc(100% - 200px)'
            }}
          >
            <div
              className="rounded-lg"
              style={{
                width: '70%',
                margin: 'auto',
                height: 'calc(100% - 20px)',
                overflowY: 'auto',
                backgroundColor: 'white',
                padding: '0 20px'
              }}
            >
              {activeTab === 0 && (
                <ProjectResumes
                  currentProject={currentProject}
                  handleDownloadCustomPdf={handleDownloadCustomPdf}
                  handleMasterMappingDelete={handleMasterMappingDelete}
                  viewImage={viewImage}
                  openPdf={openPdf}
                  s3Obj={s3Obj}
                  isUserViewer={isUserViewer}
                  setFiles={setFiles}
                  files={files}
                  projectId={projectId}
                  projectTitle={projectTitle}
                  setCurrentProject={setCurrentProject}
                />
              )}

              {activeTab === 1 && (
                <Notebook
                  notesData={notesData}
                  source={{
                    id: projectId,
                    type: 'project',
                    name: currentProject.title
                  }}
                />
              )}
              {activeTab === 2 && (
                <AttachFilesUI
                  files={files}
                  setFiles={setFiles}
                  profileId={projectId}
                  source="project"
                  isUserViewer={isUserViewer}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}

      {editProfile && (
        <EditProjectModal
          open={editProfile}
          setOpen={() => setEditProfile(false)}
          project={currentProject}
          onUpdate={() => {
            setEditProfile(false)
            // reloc
          }}
        />
      )}

      {ConfirmDialog}
      {renderTagDrawer()}
    </>
  )
}

export default ProjectResult

const projectStatusOptions = [
  // Won, InProgress, Completed
  {
    label: 'Won',
    value: 'won',
    className: 'bg-green-50 text-green-600',
    icon: (
      <CheckCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'InProgress',
    value: 'in_progress',
    className: 'bg-yellow-50 text-yellow-600',
    icon: (
      <InProgressSemiCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'Completed',
    value: 'completed',
    className: 'bg-blue-50 text-blue-600',
    icon: <CheckIcon className="size-4" />
  }
]
