import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogClose
} from '@/components/ui/dialog'
import StatusDropdown from '@/containers/OpportunitiesList/StatusDropdown'
import { ModernCalendar } from '../ModernCalendar/ModernCalendar'
import {
  CheckCircleIcon,
  CicleCross,
  CrossIcon,
  InProgressSemiCircleIcon,
  PlusIcon,
  SemiCircleIcon,
  StopIcon,
  SubmittedRocketIcon,
  TargetIcon
} from '../Icons/Icons'
import AttachFilesDropdown from '../AttachFilesDropdown/AttachFilesDropdown'
import { createProfile } from '@/store/api'
import { useNavigate } from 'react-router-dom'
import trackEvent from '@/utils/TrackEvent/TrackEvent'
import mixpanelEvents from '@/config/mixpanelEvents'

type ObjectBody = {
  group_name: string
  group_type: string
  properties?: any
  attached_files?: any
}

type OpportunityBody = ObjectBody & {
  group_type: 'opportunity'
  properties: {
    opportunity_status: { value: string }
    submission_date: { value: Date | undefined }
  }
  attached_files: any
}

type ProjectBody = ObjectBody & {
  group_type: 'project'
  properties: {
    project_status: { value: string }
    project_location: { value: string }
  }
  attached_files: any
}

type ClientBody = ObjectBody & {
  group_type: 'client'
  properties: {
    client_type: { value: string }
  }
  attached_files: any
}

type PeopleBody = ObjectBody & {
  group_type: 'people'
  properties: {
    people_type: { value: string }
  }
  attached_files: any
}

type StepperModalProps = {
  type?: 'opportunity' | 'project' | 'client' | 'people'
}

const StepperModal = ({
  type = 'opportunity'
}: {
  type?: 'opportunity' | 'project' | 'client' | 'people'
}) => {
  const [step, setStep] = useState(1)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isFileLoading, setIsFileLoading] = useState(false)
  const [extractedObject, setExtractedObject] = useState<ObjectBody>({
    group_name: '',
    group_type: type,
    properties: {},
    attached_files: []
  })

  const navigate = useNavigate()
  const form = config[type].form
  useEffect(() => {
    if (type === 'opportunity') {
      const opportunity: OpportunityBody = {
        group_name: '',
        group_type: 'opportunity',
        properties: {
          opportunity_status: { value: 'lead' },
          submission_date: { value: undefined }
        },
        attached_files: []
      }
      setExtractedObject(opportunity)
    }

    if (type === 'project') {
      const project: ProjectBody = {
        group_name: '',
        group_type: 'project',
        properties: {
          project_status: { value: 'won' },
          project_location: { value: '' }
        },
        attached_files: []
      }

      setExtractedObject(project)
    }
  }, [type])

  const createOpportunity = () => {
    trackEvent(mixpanelEvents?.CREATE_NEW_OPPORTUNITY, 'SUCCESS', {}, {})

    setLoading(true)
    createProfile(extractedObject).then((res) => {
      setLoading(false)
      setOpen(false)
      if (type === 'opportunity') {
        navigate(`/library/opportunities/${res?.data?.id}`)
      }

      if (type === 'project') {
        navigate(`/library/projects/${res?.data?.id}`)
      }
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className="flex items-center gap-1 px-4 py-2 font-medium text-white rounded-lg outline-none text-xxs bg-zinc-800"
        // onClick={() => {
        //   setOpen(true)
        // }}
      >
        <PlusIcon className="size-3" /> New Opportunity
      </DialogTrigger>
      <DialogContent
        onInteractOutside={(event) => {
          event.preventDefault()
          if (!loading && !isFileLoading) setOpen(false)
        }}
        onEscapeKeyDown={(e) => {
          e.preventDefault()
          if (!loading && !isFileLoading) setOpen(false)
        }}
        className="max-w-lg gap-1 px-3 pb-2.5"
        hideCloseButton
      >
        <div>
          <p className="pl-2 -mt-2 text-xs font-medium">New opportunity</p>
          <DialogClose asChild className={'absolute top-2 right-2'}>
            <button
              className="IconButton disabled:pointer-events-none"
              disabled={loading || isFileLoading}
              aria-label="Close"
            >
              <CrossIcon className="size-4" />
            </button>
          </DialogClose>
        </div>
        <div className="w-full mx-auto">
          <div className="flex flex-col gap-3 py-2">
            {form.map((field) => {
              if (field.type === 'text') {
                return (
                  <input
                    type="text"
                    key={field.key}
                    autoFocus
                    placeholder={field.label}
                    value={extractedObject?.group_name}
                    onChange={(e) => {
                      setExtractedObject({
                        ...extractedObject,
                        group_name: e.target.value
                      })
                    }}
                    className="pl-2 text-sm outline-none placeholder:text-zinc-400 "
                  />
                )
              }
              return null
            })}

            <div className="flex items-center gap-1">
              <StatusDropdown
                initialStatus={
                  extractedObject?.properties?.opportunity_status?.value
                }
                groupType="opportunity"
                placement="bottom"
                showLabel={true}
                changeStatus={(status) => {
                  setExtractedObject({
                    ...extractedObject,
                    properties: {
                      ...extractedObject?.properties,
                      opportunity_status: { value: status }
                    }
                  })
                }}
              />

              <div className="rounded-full size-1 bg-zinc-300"></div>

              <ModernCalendar
                value={extractedObject?.properties?.submission_date?.value}
                format="DD MMM, YYYY"
                onChange={(date: any) => {
                  setExtractedObject({
                    ...extractedObject,
                    properties: {
                      ...extractedObject?.properties,
                      submission_date: { value: date }
                    }
                  })
                }}
                showLabel
                label="Set submission date"
              />

              <div className="rounded-full size-1 bg-zinc-300"></div>

              <AttachFilesDropdown
                placement="bottom"
                filterType="rfx"
                onSelect={(file) => {
                  setExtractedObject({
                    ...extractedObject,
                    attached_files: [file]
                  })
                }}
                value={extractedObject?.attached_files?.[0]}
                placeholder="Attach RFP/Q"
                setIsFileLoading={setIsFileLoading}
              />
            </div>
            <div className="flex items-center gap-1"></div>
          </div>
          <div className="flex items-center justify-end gap-2 pt-2 border-t border-zinc-200">
            <button
              className="flex items-center gap-1 px-4 text-xs font-medium text-white rounded-lg bg-zinc-800 p-1-5 disabled:opacity-50"
              onClick={() => {
                createOpportunity()
              }}
              disabled={
                !extractedObject?.group_name || loading || isFileLoading
              }
            >
              {loading ? (
                <SemiCircleIcon className="size-4 animate-spin-fast" />
              ) : (
                'Create'
              )}
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default StepperModal

const opportunityStatusOptions = [
  {
    label: 'Lead',
    value: 'lead',
    className: 'bg-violet-50 text-violet-600',
    icon: (
      <TargetIcon style={{ width: '14px', height: '14px' }} strokeWidth="1.7" />
    )
  },
  {
    label: 'InProgress',
    value: 'rfp_in_progress',
    className: 'bg-yellow-50 text-yellow-500',
    icon: (
      <InProgressSemiCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'Submitted',
    value: 'submitted',
    className: 'bg-sky-50 text-sky-600',
    icon: (
      <SubmittedRocketIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'Won',
    value: 'won',
    className: 'bg-green-50 text-green-600',
    icon: (
      <CheckCircleIcon
        style={{ width: '14px', height: '14px' }}
        strokeWidth="1.7"
      />
    )
  },
  {
    label: 'Lost',
    value: 'lost',
    className: 'bg-pink-50 text-pink-600',
    icon: (
      <CicleCross style={{ width: '14px', height: '14px' }} strokeWidth="1.7" />
    )
  },
  {
    label: 'Closed',
    value: 'closed',
    className: 'bg-zinc-100 text-zinc-600',
    icon: (
      <StopIcon style={{ width: '14px', height: '14px' }} strokeWidth="1.7" />
    )
  }
]

const config: {
  [key in 'opportunity' | 'project' | 'client' | 'people']: {
    form: any[]
  }
} = {
  opportunity: {
    form: [
      {
        type: 'text',
        label: 'Opportunity Title',
        key: 'group_name',
        mandatory: true
      },
      {
        type: 'dropdown',
        label: 'Opportunity Status',
        key: 'opportunity_status',
        // mandatory: true,
        options: opportunityStatusOptions
      },
      {
        type: 'date',
        label: 'Submission Date',
        key: 'submission_date'
        // mandatory: true
      },
      {
        type: 'file',
        label: 'Attach RFP/Q',
        key: 'attached_files'
        // mandatory: true
      }
    ]
  },
  project: {
    form: [
      {
        type: 'text',
        label: 'Project Title',
        key: 'group_name',
        mandatory: true
      },
      {
        type: 'text',
        label: 'Project Location',
        key: 'project_location'
      }
    ]
  },
  client: {
    form: [{}]
  },
  people: {
    form: [{}]
  }
}
