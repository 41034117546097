import React from 'react'

import { cn } from '@/lib/utils'

import { cva, VariantProps } from 'class-variance-authority'
import { LoaderCircleIcon } from '../Icons/Icons'
import { Slot } from '@radix-ui/react-slot'

const loaderVariants = cva('animate-spin text-foreground', {
  variants: {
    size: {
      sm: 'w-4 h-4',
      md: 'w-6 h-6',
      lg: 'w-8 h-8'
    }
  },
  defaultVariants: {
    size: 'md'
  }
})

export interface LoaderProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof loaderVariants> {
  fill?: boolean
}

const Loader = (props: LoaderProps) => {
  const { className, size, fill = false, ...rest } = props

  const Comp = fill ? 'div' : Slot

  return (
    <Comp className={cn(fill && 'w-full h-full grid place-items-center')}>
      <LoaderCircleIcon className={cn(loaderVariants({ size }))} {...rest} />
    </Comp>
  )
}

Loader.displayName = 'Loader'

export { Loader }
