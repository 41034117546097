import { Box, Fade, Popover, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { useSelector } from 'react-redux'
import Label from '@mui/icons-material/Label'
import moment from 'moment'

const DisplayTags = (props) => {
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)

  const { tags = [], setShowTagDrawer = () => {} } = props
  const [tagsObj, setTagsObj] = useState({})
  const [tagsOptions, setTagsOptions] = useState({})
  const [tagState, setTagState] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const classes = useStyles()

  useEffect(() => {
    const tagsValues = {}
    const tagMap = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        key_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      data.forEach((element) => {
        tagMap[element?.id] = {
          key,
          ...element
        }
        if (element.value) {
          if (tagsValues[key]?.values) {
            tagsValues[key].values.push({
              value: element.id,
              label: element.value
            })
          } else {
            if (!tagsValues[key]) tagsValues[key] = {}
            tagsValues[key].values = [
              {
                value: element.id,
                label: element.value
              }
            ]
          }
        }
        tagsValues[key] = {
          ...tagsValues[key],
          type,
          ...rest
        }
      })
    })

    setTagsOptions(tagsValues)
    setTagState(tagMap)
  }, [tagsCenterStateTags])

  const handlePopoverOpen = (event) => {
    if (Object.keys(tagsObj).length === 0) return

    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    const tagMap = {}
    tags.forEach((tag) => {
      const valueObj = tagState[tag]
      const { key, value, type = '' } = valueObj || {}
      const val = type?.includes('date') ? moment(value).format('ll') : value
      if (tagMap[key]) {
        tagMap[key] = [...tagMap[key], val]
      } else {
        tagMap[key] = [val]
      }
    })

    if (JSON.stringify(tagMap) !== JSON.stringify(tagsObj)) {
      setTagsObj(tagMap)
    }
  }, [tags, tagState, tagsObj])

  const totalTagsCount = Object.keys(tagsObj).reduce((acc, key) => {
    return acc + tagsObj[key].length
  }, 0)

  return (
    <div
      className="flex items-center"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '2px'
        }}
        className="parent-tag-containter"
        onClick={() => {
          setShowTagDrawer(true)
        }}
      >
        {Object.keys(tagsObj).map((key, index) => {
          const tagData = tagsObj[key]

          if (index > 1) return null

          return tagData.map((tag, index) => {
            return (
              <Box
                key={index}
                sx={{}}
                className={classes.tagWrapper + ' value-tag'}
              >
                <Box className={classes.tagKey}>{tag}</Box>
              </Box>
            )
          })
        })}
      </Box>

      <Popover
        elevation={3}
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          marginTop: '4px'
        }}
        open={open}
        anchorEl={anchorEl}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="">
          {Object.keys(tagsObj).map((key, index) => {
            return (
              <div key={index} className="w-52 border-b-1 p-2">
                <p className="m-0 text-xxs font-medium text-grey-600 text-capitalize">
                  {key}
                </p>
                <div className="flex items-center flex-wrap gap-1 mt-1">
                  {tagsObj[key].map((tag, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{}}
                        className={classes.tagWrapper + ' value-tag'}
                      >
                        {/* <div
                style={{
                  height: '8px',
                  width: '8px',
                  borderRadius: '100%',
                  // backgroundColor: getColorsForInitials(index)
                }}
              ></div> */}
                        <Box className={classes.tagKey}>{tag}</Box>
                        {/* <Box className={classes.tagValue}>{tagData?.join(', ')}</Box> */}
                      </Box>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </Popover>
      <div
        style={{
          fontSize: '10px',
          padding: '2px 6px',
          borderRadius: '30px',
          border: '1px solid #c084fc',
          background: '#f3e8ff',
          color: '#581c87',
          cursor: 'pointer',
          marginLeft: '2px',
          display: totalTagsCount > 2 ? 'flex' : 'none'
        }}
        className="more-tag"
      >
        +{totalTagsCount - 2}
      </div>
      {Object.keys(tagsObj).length === 0 && (
        <div
          onClick={() => {
            setShowTagDrawer(true)
          }}
          style={{
            fontSize: '10px',
            padding: '2px 10px',
            borderRadius: '30px',
            backgroundColor: 'var(--grey-100)',
            color: 'var(--grey-800)',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '2px'
          }}
        >
          <Label
            sx={{
              height: '12px',
              width: '12px'
            }}
          />{' '}
          Add tag
        </div>
      )}
    </div>
  )
}

export default DisplayTags

const getColorsForInitials = (i) => {
  const colors = ['#8338ec', '#007f5f', '#00296b']

  const charCode = name.charCodeAt(0)
  return colors[i]
}
