export const getInitialsFromUserName = (userName, chat = 1) => {
  const names = userName?.split(' ')
  const initials = names?.[0]?.substring(0, 1).toUpperCase()
  // if (names?.length > 1) {
  //   initials += names[names.length - 1].substring(0, 1).toUpperCase()
  // }
  return initials
}

export const getColorsForInitials = (name, v2 = false) => {
  const colors = [
    '#FFC0CB',
    '#FFA07A',
    '#FFD700',
    '#FF4500',
    '#FF6347',
    '#FF69B4',
    '#FF1493',
    '#FF00FF',
    '#FF00FF',
    '#FF69B4',
    '#FF6347',
    '#FF4500',
    '#FFD700',
    '#FFA07A',
    '#FFC0CB'
  ]

  const colorMap = [
    {
      bg: '#ede9fe',
      text: '#7c3aed'
    },
    {
      bg: '#d1fae5',
      text: '#047857'
    },
    {
      bg: '#fee2e2',
      text: '#e53e3e'
    },
    {
      bg: '#fce7f3',
      text: '#ec4899'
    },
    {
      bg: '#dbeafe',
      text: '#3b82f6'
    },

    {
      bg: '#dcfce7',
      text: '#047857'
    },
    {
      bg: '#fefce8',
      text: '#f59e0b'
    },
    {
      bg: '#ede9fe',
      text: '#7c3aed'
    }
  ]

  if (v2) {
    const charCode = name ? name.charCodeAt(0) : 0
    return colorMap[charCode % colorMap.length]
  }

  const charCode = name ? name.charCodeAt(0) : 0
  return colors[charCode % colors.length]
}

export const getColorForIndex = (index) => {
  const colors = [
    'bg-sky-500',
    'bg-purple-500',
    'bg-pink-500',
    'bg-red-500',
    'bg-yellow-500',
    'bg-green-500',
    'bg-teal-500',
    'bg-cyan-500'
  ]

  const hexColors = [
    '#0ea5e9',
    '#d946ef',
    '#ec4899',
    '#e53e3e',
    '#f59e0b',
    '#047857',
    '#047857'
  ]

  return hexColors[index % hexColors.length]
}

export const getColorsForIndex = (index) => {
  const colors = [
    'text-purple-500 ',
    'text-red-500 ',
    'text-green-500 ',
    'text-yellow-500 ',
    'text-blue-500 ',
    'text-orange-500 '
  ]
  return colors[index % colors.length]
}

export const checkArrOfObjHasAtleastOneValue = (arr) => {
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i]
      for (const key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          obj[key] !== undefined &&
          obj[key] !== null &&
          obj[key] !== '' &&
          obj[key] !== [] &&
          obj[key] !== {}
        ) {
          return true
        }
      }
    }
  }
  return false
}
