import React, { useState, useEffect } from 'react'
import JustifiedLayout from '@codekraft-studio/react-justified-layout'
import { Box } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Loader from '../../components/Loader'
import { cn } from '@/lib/utils'

const bricks = (array, signedImages, onClickImage) => {
  return array.map((item, index) => {
    const { style = {} } = item
    const { width = 215, height = 145 } = style

    return (
      <div
        id="image-div"
        key={index}
        className={cn(
          'twp bg-gray-100 box-border border border-gray-200 rounded-md overflow-hidden',
          '[&_img]:cursor-pointer [&_img]:object-contain [&_img]:object-center [&_img]:w-full [&_img]:h-full'
        )}
        style={{
          ...style
        }}
      >
        <LazyLoadImage
          height={height}
          width={width}
          effect="blur"
          onClick={() => onClickImage(index)}
          src={signedImages[index]?.signedSrc}
        />
      </div>
    )
  })
}

const SimilarityViewer = (props) => {
  const { signedImages = [], aspectRatio, handleImageClick = () => {} } = props
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (signedImages) {
      setLoading(false)
    }
  }, [signedImages])

  const onClickImage = (index) => {
    const image = signedImages[index]
    handleImageClick(image, signedImages)
  }

  return loading ? (
    <Box
      id="image_loading"
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader loading={loading} caption={''} />
    </Box>
  ) : (
    <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
      {signedImages?.length > 0 && aspectRatio.length > 0 && (
        <JustifiedLayout
          items={aspectRatio}
          options={{
            containerPadding: 20,
            boxSpacing: 10,
            targetRowHeight: 150,
            targetRowHeightTolerance: 0.1
          }}
        >
          {(items) => bricks(items, signedImages, onClickImage)}
        </JustifiedLayout>
      )}
    </Box>
  )
}

export default SimilarityViewer
