import { Box, Dialog, Fade, Menu } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LexicalEditor from '../../components/Lexical'
import { $generateHtmlFromNodes } from '@lexical/html'
import Button2 from '../../components/Button/Button2'
import {
  NoteAddIcon,
  PlusIcon,
  SemiCircleIcon,
  ThreeDotsIcon,
  TrashIcon
} from '../../components/Icons/Icons'
import {
  createLibraryDocument,
  deleteLibraryDocument,
  updateLibraryDocument
} from '../../store/Library/Actions'
import { useDispatch } from 'react-redux'
import { autocompleteMention, getLibraryDoc } from '../../store/api'
import { getColorsForInitials } from '../../utils/String'
import { formatTimeAgo } from '../../utils/Date'
import { updateNotes } from '../../store/Library/Actions/UpdateLibraryDocument/UpdateLibraryDocument'
import { toast } from 'react-toastify'
import { checkUserRoleViewer } from '../../utils/User'

const Notebook = ({ notesData, source, refresh = () => {} }) => {
  const [currentEditorState, setCurrentEditorState] = useState({})
  const [editedHtmlString, setEditedHtmlString] = useState(`<h1>Testigng</h1>`)
  const [unSavedChanges, setUnSavedChanges] = useState(false)
  const dispatch = useDispatch()
  const [notes, setNotes] = useState(notesData)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [mentionedDocs, setMentionedDocs] = useState([])
  const [selectedNote, setSelectedNote] = useState(null)
  const [noteLoading, setNoteLoading] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [disableSave, setDisableSave] = useState(true)
  const [showCreateNote, setShowCreateNote] = useState(false)
  const [saving, setSaving] = useState(false)

  const isUserViewer = checkUserRoleViewer()

  useEffect(() => {}, [disableSave])

  useEffect(() => {
    if (notesData?.length > 0 && !selectedNote) {
      handleChangeNote(notesData[0])
    }
  }, [notesData])

  const handleCreate = (name) => {
    // return

    dispatch(
      createLibraryDocument(
        {
          document_content: {},
          document_html: '',
          document_name: name,
          library_type: 'note',
          mentioned_documents: [
            {
              ...source,
              is_mentioned: false
            }
          ]
        },
        (response) => {
          setNotes((prev) => {
            return [response.document, ...prev]
          })
          setNoteLoading(true)
          setSelectedNote(response.document)
          setShowCreateNote(false)
          handleChangeNote({
            ...response.document
          })
        }
      )
    )
  }

  const handleSave = () => {
    setSaving(true)

    // return

    dispatch(
      updateNotes(
        {
          id: selectedNote.id,
          document_content: currentEditorState,
          document_html: editedHtmlString,
          document_name: selectedNote.document_name,
          mentioned_documents: selectedNote.mentioned_documents
        },
        (response) => {
          //
          toast.success('Note Saved')

          setDisableSave(true)

          setTimeout(() => {
            setDisableSave(false)
          }, 1000)
          // console.log('note response', response)
          setSaving(false)
          setNotes((prev) => {
            return prev.map((note) => {
              if (note.id === selectedNote.id) {
                return {
                  ...note,
                  // content: editor.toHTML(),
                  document_name: selectedNote.document_name,
                  mentioned_documents: selectedNote.mentioned_documents,
                  document_content: currentEditorState
                }
              }
              return note
            })
          })
          refresh()
          setUnSavedChanges(false)
        }
      )
    )
  }

  const handleEditorChange = (currentEditorState, editor) => {
    editor.update(() => {
      // list MentionNodes
      let mentionedDocsTemp = []
      currentEditorState?._nodeMap?.forEach((node) => {
        setDisableSave(false)
        if (node?.__type === 'mention' && node?.__id) {
          const mention = {
            id: node.__id,
            type: node.__mentionType,
            name: node.__text,
            is_mentioned: true
          }

          mentionedDocsTemp.push(mention)
        }
      })

      mentionedDocsTemp = [...mentionedDocs, ...mentionedDocsTemp]

      const uniqueMentions = mentionedDocsTemp.filter(
        (thing, index, self) =>
          index ===
          self.findIndex((t) => t.id === thing.id && t.type === thing.type)
      )

      // console.log('uniqueMentions', uniqueMentions)
      setCurrentEditorState(currentEditorState)

      const htmlString = $generateHtmlFromNodes(editor, null)
      setEditedHtmlString(htmlString)

      setSelectedNote((prev) => {
        return {
          ...prev,
          document_html: htmlString,
          // document_content: currentEditorState,
          mentioned_documents: uniqueMentions
        }
      })
    })
  }

  const handleDeleteConfirmation = () => {
    setAnchorEl(null)
    setShowDeleteConfirmation(true)
  }

  const handleDelete = () => {
    dispatch(
      deleteLibraryDocument({ id: selectedNote.id }, () => {
        refresh()
        setShowDeleteConfirmation(false)
        const newList = notes.filter((note) => note.id !== selectedNote.id)
        setNotes(newList)
        if (newList.length > 0) {
          setNoteLoading(true)

          setSelectedNote(newList[0])
        } else {
          setNoteLoading(true)

          setSelectedNote(null)
        }
      })
    )
  }

  const handleChangeNote = async (note) => {
    setNoteLoading(true)
    setSaving(false)
    setDisableSave(true)
    const newController = new AbortController()
    const res = await getLibraryDoc(note.id, {
      signal: newController.signal
    })

    if (res.status === 200) {
      setSelectedNote(res.data)

      setMentionedDocs(() => {
        const finalDocs = res.data.mentioned_documents.filter(
          (doc) => !doc.is_mentioned && doc.id !== source.id
        )

        finalDocs.push({
          id: source.id,
          type: source.type,
          name: source.name,
          is_mentioned: false
        })

        return finalDocs
      })
      setNoteLoading(false)
    }
  }

  if (!notes || notes?.length === 0) {
    return (
      <div className="grid h-full text-center place-content-center">
        <p className="m-0 text-lg font-medium text-center text-grey-700">
          No notes
        </p>

        <p className="m-0 text-sm text-center text-grey-500">
          Create a note to get started
        </p>

        <Button2
          primary
          onClick={() => setShowCreateNote(true)}
          style={{
            width: 'fit-content',
            margin: 'auto',
            padding: '6px 15px',
            marginTop: '10px'
          }}
          disabled={isUserViewer}
        >
          <NoteAddIcon className="size-4" />
          Create note
        </Button2>

        <CreateNotebookDocument
          onCreate={handleCreate}
          show={showCreateNote}
          onClose={() => {
            setShowCreateNote(false)
          }}
        />
      </div>
    )
  }

  return (
    <div className="flex h-full">
      <div
        className="flex flex-col gap-1"
        style={{
          width: '30%',
          borderRight: '1px solid var(--grey-200)'
        }}
      >
        <div className="flex items-center justify-between p-2 border-b-1">
          <p className="m-0 text-sm font-medium">All Notes</p>
          {!isUserViewer && (
            <Button2
              secondary
              noOutline
              onClick={() => {
                setShowCreateNote(true)
              }}
              style={{
                padding: '4px'
              }}
            >
              <PlusIcon className="size-4" />
            </Button2>
          )}
        </div>
        <div
          style={{
            padding: '6px'
          }}
        >
          {notes.map((note) => {
            return (
              <NoteTab
                key={note.id}
                note={note}
                selected={selectedNote}
                setSelected={(note) => {
                  handleChangeNote(note)
                }}
              />
            )
          })}
        </div>
      </div>
      {noteLoading ? (
        <div
          className="grid h-full place-content-center"
          style={{
            width: '70%',
            padding: '0 15px',
            boxsizing: 'border-box'
          }}
        >
          <SemiCircleIcon className="size-5 animate-spin" />
        </div>
      ) : (
        selectedNote && (
          <Box
            id="notes-editor"
            style={{
              height: 'calc(100% - 50px)',
              width: '70%',
              padding: '0 15px',
              boxsizing: 'border-box'
            }}
          >
            <div className="flex items-center justify-between">
              <input
                type="text"
                className="text-lg font-medium input-base"
                style={{
                  padding: '10px 4px'
                }}
                placeholder="Enter note title"
                onChange={(e) => {
                  setSelectedNote({
                    ...selectedNote,
                    document_name: e.target.value
                  })
                }}
                value={selectedNote.document_name}
              />
              <div className="flex gap-2">
                <Button2
                  secondary
                  onClick={handleSave}
                  disabled={disableSave}
                  style={{
                    padding: '4px 8px',
                    fontSize: '12px'
                  }}
                >
                  {saving ? (
                    <SemiCircleIcon className="size-4 animate-spin" />
                  ) : disableSave ? (
                    'Saved'
                  ) : (
                    'Save'
                  )}
                </Button2>
                <Button2
                  secondary
                  noOutline
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                  }}
                  style={{
                    padding: '4px'
                  }}
                >
                  <ThreeDotsIcon className="size-4" />
                </Button2>

                <Menu
                  elevation={0}
                  TransitionComponent={Fade}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  sx={{
                    '& .MuiMenu-list': {
                      padding: '4px 6px'
                    },
                    '& .MuiMenu-paper': {
                      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
                    }
                  }}
                  // className="p-1 px-2"
                  style={{
                    padding: '4px'
                  }}
                >
                  <Button2
                    noOutline
                    secondary
                    style={{
                      width: '100%',
                      padding: '4px 8px',
                      color: '#ff4747'
                    }}
                    onClick={handleDeleteConfirmation}
                  >
                    <TrashIcon className="size-4" /> Delete note
                  </Button2>
                </Menu>
              </div>
            </div>
            <LexicalEditor
              miniEditor={true}
              initialEditorContent={
                !_.isEmpty(selectedNote?.document_content)
                  ? selectedNote?.document_content
                  : {}
              }
              unSavedChanges={unSavedChanges}
              // setUnSavedChanges={}
              handleEditorChange={handleEditorChange}
              key={selectedNote}
            />
            <div className="flex items-center gap-1 mt-3 text-xxs text-grey-600">
              <div
                className="flex items-center justify-center mr-1 text-white rounded-full size-4 text-xxs"
                style={{
                  backgroundColor: getColorsForInitials(
                    selectedNote?.contributors?.[0].name
                  )
                }}
              >
                {selectedNote?.contributors?.[0]?.name?.[0]}
              </div>{' '}
              {selectedNote?.contributors?.[0].name} created this note{' '}
              <span className="rounded-full size-1 bg-grey-300"></span>
              {formatTimeAgo(selectedNote?.updated_at)}
            </div>
          </Box>
        )
      )}

      <CreateNotebookDocument
        onCreate={handleCreate}
        show={showCreateNote}
        onClose={() => {
          setShowCreateNote(false)
        }}
      />
      <DeleteNotebookDocument
        show={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleDelete}
        confirmationMessageTitle="Are you sure you want to delete this note?"
        confirmationMessage="This action cannot be undone."
        key={showDeleteConfirmation}
      />
    </div>
  )
}

export default Notebook

const CreateNotebookDocument = ({ onCreate, show, onClose }) => {
  const [noteName, setNoteName] = useState('New Note')
  const [loading, setLoading] = useState(false)

  // on enter call onCreate

  return (
    <Dialog open={show} onClose={onClose}>
      <div className="p-3 px-4 w-72">
        {/* <p className="m-0 text-sm">Create Note</p> */}
        <div className="flex flex-col justify-end gap-3">
          <div>
            <label className="text-xxs text-grey-600">Name</label>
            <input
              type="text"
              className="w-full p-2 text-sm rounded-lg input-base border-1 border-grey-200"
              style={{
                boxSizing: 'border-box'
              }}
              autoFocus
              onKeyUp={(e) => {
                if (e.target.value.length === 0) {
                  return
                }
                if (e.key === 'Enter') {
                  onCreate(noteName)
                  setLoading(true)
                }
              }}
              placeholder="Enter note name"
              onChange={(e) => {
                setNoteName(e.target.value)
              }}
              value={noteName}
            />
          </div>
          <div className="flex justify-end gap-1">
            <Button2
              secondary
              noOutline
              disabled={loading}
              onClick={onClose}
              style={{
                marginLeft: 'auto'
              }}
            >
              Cancel
            </Button2>
            <Button2
              primary
              disabled={noteName.length === 0 || loading}
              onClick={() => {
                onCreate(noteName)
                setLoading(true)
              }}
            >
              {loading ? (
                <SemiCircleIcon className="size-3 animate-spin" />
              ) : (
                'Create'
              )}
            </Button2>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const DeleteNotebookDocument = ({
  show,
  onClose,
  onConfirm,
  confirmationMessageTitle,
  confirmationMessage
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  return (
    <Dialog open={show} onClose={onClose}>
      <div className="p-3 px-4 w-84">
        <p className="m-0 text-xs font-medium">{confirmationMessageTitle}</p>
        <p className="m-0 mt-1 text-xxs text-grey-600">{confirmationMessage}</p>
        <div className="flex justify-end gap-1 mt-3">
          <Button2 secondary noOutline onClick={onClose}>
            Cancel
          </Button2>
          <Button2
            secondary
            noOutline
            onClick={() => {
              setLoading(true)
              onConfirm()
            }}
            style={{
              backgroundColor: '#ef4444',
              color: 'white'
            }}
            disabled={loading}
          >
            {loading ? (
              <SemiCircleIcon className="size-4 animate-spin" />
            ) : (
              'Delete'
            )}
          </Button2>
        </div>
      </div>
    </Dialog>
  )
}

const NoteTab = ({ note, selected, setSelected }) => {
  return (
    <div
      key={note.id}
      className="flex items-start justify-between p-2 px-4 rounded-lg cursor-pointer hover-bg-grey-100"
      style={{
        backgroundColor: selected?.id === note.id ? 'var(--grey-100)' : ''
      }}
      onClick={() => {
        setSelected(note)
      }}
    >
      <p className="w-32 m-0 font-medium text-xxs">{note.document_name}</p>

      {/* add time with time and yesterday and date */}
      <p
        className="m-0 text-xxs text-grey-600"
        style={{
          fontSize: '9px'
        }}
      >
        {formatTimeAgo(note.updated_at, true)}
      </p>
    </div>
  )
}
