import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { ListTable, MenuButton, useConfirmation } from '../../components'
import { useStyles } from './styles'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { workspaceActionsDrafts } from '../../store/api'
import { toast } from 'react-toastify'
import { formatDate } from '../../utils/Date'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { ROUTES } from '../../config/routes'
import { useLocation, useNavigate } from 'react-router-dom'
import HeadlessTable from '@/components/TableV2/HeadlessTable'
import { ThreeDotsIcon } from '@/components/Icons/Icons'

const WorkspaceListDrafts = (props) => {
  const {
    drafts = [],
    setDrafts = [],
    workspace_id = '',
    workspaceName = ''
  } = props
  const classes = useStyles()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [activeTableRow, setActiveTableRow] = useState(null)
  const { WORKSPACE } = ROUTES
  const navigate = useNavigate()

  const handleonClickRow = (file) => {
    const { id } = file

    navigate(`${WORKSPACE}/${workspace_id}/${id}`, {
      state: {
        workspaceName,
        workspaceId: workspace_id,
        draftName: file.document_name
      }
    })
  }
  const handleOpenDraft = (data) => {
    const { row } = data
    const { id } = row
    window.open(`${WORKSPACE}/${workspace_id}/${id}`, '_blank')
  }

  const headCells = [
    {
      id: 'document_name',
      disablePadding: true,
      label: 'Draft Name',
      sort: true,
      width: '30%',
      onClick: handleonClickRow
    },
    {
      id: 'created_by_user_name',
      disablePadding: true,
      label: 'Created by',
      sort: true,
      width: '30%',
      onClick: handleonClickRow
    },
    {
      id: 'created_at',
      disablePadding: true,
      label: 'Created at',
      sort: true,
      width: '15%',
      onClick: handleonClickRow,
      format: formatDate
    },
    {
      id: 'updated_at',
      disablePadding: true,
      label: 'Updated at',
      sort: true,
      width: '15%',
      onClick: handleonClickRow,
      format: formatDate
    }
  ]

  const handleDeleteFile = async (data) => {
    const { row } = data
    showConfirmDialog({
      onConfirm: async () => {
        const index = drafts.findIndex((draft) => draft.id === row.id)
        const newDrafts = [...drafts]
        newDrafts[index].rowLoading = true
        setDrafts(newDrafts)
        const req = {
          id: row.id,
          workspace_id,
          mode: 'delete'
        }

        const res = await workspaceActionsDrafts(req)
        if (res.status === 200) {
          newDrafts.splice(index, 1)
          setDrafts(newDrafts)
        } else {
          newDrafts[index].rowLoading = false
          toast.error('Unable to delete draft')
          setDrafts(newDrafts)
        }
      },
      confirmationMessageTitle: `Are you sure you want to delete the draft "${row?.document_name}" ?`
    })
  }

  const menuOptions = [
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: handleDeleteFile
    },
    {
      label: 'Open in new tab',
      icon: <OpenInNewIcon />,
      onClick: handleOpenDraft
    }
  ]

  const menuOpenCallback = (data) => {
    const { row, index } = data
    setActiveTableRow(index)
  }

  const renderTableActions = (row, index) => {
    const { rowLoading = false } = row
    return (
      <MenuButton
        disabled={rowLoading}
        options={menuOptions}
        callBackData={{ row, index }}
        menuOpenCallback={menuOpenCallback}
        menuCloseCallback={() => setActiveTableRow(null)}
      />
    )
  }

  const columns = [
    {
      id: 'document_name',
      label: 'Draft Name',
      accessorKey: 'document_name'
      // sort: true,

      // onClick: handleonClickRow
    },
    {
      id: 'created_by_user_name',
      label: 'Created by',
      accessorKey: 'created_by_user_name',
      // sort: true,
      style: {
        width: '240px'
      }
      // onClick: handleonClickRow
    },
    {
      id: 'updated_at',
      label: 'Updated at',
      accessorKey: 'updated_at',
      // sort: true,
      style: {
        width: '240px'
      },
      children: (row) => {
        return <p>{formatDate(row.updated_at)}</p>
      }
    },
    {
      id: 'created_at',
      label: 'Created at',
      accessorKey: 'created_at',
      // sort: true,
      style: {
        width: '240px'
      },
      children: (row) => {
        return <p>{formatDate(row.created_at)}</p>
      }
      // onClick: handleonClickRow
    },
    {
      id: 'files',
      label: '',
      accessorKey: 'files',
      // sort: true,
      style: {
        width: '130px'
      },
      children: (row, index) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation()
              renderTableActions(row, index)
            }}
          >
            <MenuButton
              // disabled={rowLoading}
              options={menuOptions}
              callBackData={{ row, index }}
              menuOpenCallback={menuOpenCallback}
              menuCloseCallback={() => setActiveTableRow(null)}
            />
          </div>
        )
      }
    }
  ]

  return (
    <>
      <HeadlessTable
        columns={columns}
        data={drafts}
        hideMultiSelect
        hidePagination
        onRowClick={handleonClickRow}
        // hidePagination
      />

      {ConfirmDialog}
    </>
  )
}

export default WorkspaceListDrafts
