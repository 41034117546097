import { useEffect, useState } from 'react'
import { Box, Fade } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { useStyles, MenuPaperProps } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import { signOut, checkUserRoleSuperUser } from '../../utils/User'
import packageInfo from '../../../package.json'
import Tooltip from '@mui/material/Tooltip'
import ListItemButton from '@mui/material/ListItemButton'

export const AvatarIcon = (props) => {
  const classes = useStyles()
  const { avatar } = classes
  const [userInitial, setUserInitial] = useState(false)
  const user = useSelector((state) => state.authenticate.user)
  const userName = user?.domain?.user_name ? user?.domain?.user_name : ' '

  useEffect(() => {
    getInitials()
  }, [userName])

  const getInitials = () => {
    const names = userName.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase()
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    setUserInitial(initials)
  }

  return (
    <Avatar className={avatar} {...props}>
      {userInitial}
    </Avatar>
  )
}

const UserAvatar = ({ sideBarOpen }) => {
  const classes = useStyles()
  const {
    avatar,
    avatarButton,
    centerMenuItems,
    userEmailBox,
    userDetailsContainer,
    userNameBox,
    userDomainBox,
    buttonWrapper
  } = classes
  const [anchorEl, setAnchorEl] = useState(null)
  const user = useSelector((state) => state.authenticate.user)
  const { domain = {} } = user || {}
  const { domain_id, domain_name, email, user_role = 'user' } = domain
  const userName = user?.domain?.user_name ? user?.domain?.user_name : ' '
  const { SETTINGS } = ROUTES
  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const isSuperUser = checkUserRoleSuperUser()

  const handleSettingClick = () => {
    navigate(SETTINGS)
  }

  return (
    <>
      <ListItemButton
        className={buttonWrapper}
        disableRipple
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Tooltip
          title={'Profile'}
          placement="right"
          arrow
          style={{ paddingLeft: '3px' }}
        >
          {sideBarOpen ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: 10,
                paddingLeft: 14,
                gap: 10,
                borderTop: '1px solid var(--bg-dark-primary-2)',
                width: '100%'
              }}
            >
              <div
                style={{
                  height: '30px',
                  width: '30px',
                  minWidth: '30px',
                  backgroundColor: 'var(--grey-300)',
                  color: 'var(--dark-text)',
                  borderRadius: '100%',
                  display: 'grid',
                  placeContent: 'center'
                }}
              >
                {userName[0]}
              </div>
              <div
                style={{
                  color: 'var(--grey-100)',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                  maxHeight: '200px',
                  overflow: 'auto'
                  // width: '100%'
                }}
              >
                <div
                  style={{
                    fontSize: '14px'
                  }}
                >
                  {userName}
                </div>
                <div
                  style={{
                    fontSize: '12px'
                  }}
                >
                  {email}
                </div>
              </div>
            </div>
          ) : (
            <IconButton id="menu-icon" className={avatarButton} disableRipple>
              <div
                style={{
                  height: '30px',
                  width: '30px',
                  minWidth: '30px',
                  backgroundColor: 'var(--grey-300)',
                  color: 'var(--dark-text)',
                  borderRadius: '100%',
                  display: 'grid',
                  placeContent: 'center',
                  fontSize: '14px'
                }}
              >
                {userName[0]}
              </div>
            </IconButton>
          )}
        </Tooltip>
      </ListItemButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        TransitionComponent={Fade}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        PaperProps={MenuPaperProps}
      >
        <Box className={userDetailsContainer}>
          <AvatarIcon />
          <Box className={userNameBox}>{userName}</Box>
          <Box className={userEmailBox}>{email}</Box>
          <Box className={userEmailBox}>{domain_name}</Box>
          {isSuperUser && (
            <Box
              className={userDomainBox}
              sx={{ cursor: 'pointer' }}
              onClick={() => navigator.clipboard.writeText(domain_id)}
            >
              ({domain_id})
            </Box>
          )}
          {['local', 'staging'].includes(process.env.REACT_APP_ENV) && (
            <Box className={userEmailBox}>Version: {packageInfo.version}</Box>
          )}
        </Box>
        <Divider />
        <MenuItem
          id="icon-setting"
          className={centerMenuItems}
          onClick={() => handleSettingClick()}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          id="logout"
          className={centerMenuItems}
          onClick={() => signOut(dispatch)}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserAvatar
