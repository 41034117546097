import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import _, { set } from 'lodash'
import {
  getChatSessionHistory,
  getSingleChatHistory,
  updateSessionChatFeedback,
  updateChatSessionHistory,
  deleteChatSessionHistory
} from '../../../store/Chat/Actions'
import { chatShare } from '../../../store/api'
import { useStyles } from './styles'
import {
  Grid,
  TextField,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material'
import ChatBot from '../ChatBot'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import CloseIcon from '@mui/icons-material/Close'
import Select from 'react-select'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { ReactComponent as Robot } from '../../../assets/images/robot.svg'
import Avatar from '@mui/material/Avatar'
import { toast } from 'react-toastify'
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined'
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined'
import { initalizeS3 } from '../../../utils/AWS'
import {
  ImageRender,
  UserAvatar,
  MenuButton,
  Loader,
  Button,
  Container,
  Section,
  SectionFixed,
  useConfirmation
} from '../../../components'
import Button2 from '../../Button/Button2'
import { textToHtml } from '../../../utils/CopyHTML'
import trackEvent from '../../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../../config/mixpanelEvents'
import {
  ChatIcon,
  PenLineIcon,
  Share2Icon,
  SparkIcon2,
  TrashIcon
} from '../../Icons/Icons'
import SearchNew from '../../../sections/ListFilter/SearchNew'

const TextGenearate = (props) => {
  const { text, onFinish = () => {}, delay = 40 } = props
  const [charIndex, setCharIndex] = useState(1)

  useEffect(() => {
    if (text) {
      let current
      clearTimeout(current)
      if (charIndex < text.length) {
        current = setTimeout(() => setCharIndex(charIndex + 1), delay)
      } else {
        onFinish()
      }
      return () => clearTimeout(current)
    }
  }, [charIndex, text])

  return text ? text.substr(0, charIndex) : ''
}

const SessionChat = (props) => {
  const {
    fullScreen = false,
    selectedSession = '',
    setSelectedSession = () => {},
    selectedSessionPath = '',
    setSelectedSessionPath = () => {},
    setSessionChat = () => {},
    sessionChat = null,
    setView = () => {},
    view = 'session',
    animationId = '',
    animationText = null,
    removeAnimation = () => {},
    handleTitleGeneration = () => {},
    handleChatAddition = () => {},
    ...rest
  } = props

  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const userData = useSelector((state) => state.authenticate?.user)
  const domainData = userData?.domain
  const { domain_users = [] } = domainData || {}
  const chatState = useSelector((state) => state.chat)
  const { sessionChatHistory = {} } = chatState
  const {
    sessions = {},
    hasMoreData = false,
    sessionsCount
  } = sessionChatHistory
  const classes = useStyles()
  const { sessionId: sessionIdParams = '' } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const stateData = location.state ?? {}
  const { searchKey = '' } = stateData

  const [isEdit, setEdit] = useState({})
  const [loadingSessionHistory, setLoadingSessionHistory] = useState(true)
  const pageSize = 50
  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const {
    loaderContainer,
    outerContainer,
    selectedHistoryOption,
    newChatButton,
    historySessionTitle,
    historyChatTitle,
    addHighlight,
    titleActions,
    newChat,
    disabledButton,
    tooltip
  } = classes
  const [loadingMoreSessions, setLoadingMoreSessions] = useState(false)
  const titleRef = useRef()

  const [showShareModal, setShowShareModal] = useState(false)
  const [shareObj, setShareObj] = useState({})
  const [shareLoading, setShareLoading] = useState(false)

  const [s3Obj, sets3Obj] = useState({})

  const [currentActiveMenu, setCurrentActiveMenu] = useState(null)

  useEffect(() => {
    // trackEvent(mixpanelEvents.CHAT_TAB_CLICKED, 'SUCCESS', {}, {})
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    if (_.isEmpty(sessions)) {
      fetchSessionChat(pageSize)
    } else if (!_.isEmpty(sessions)) {
      setLoadingSessionHistory(false)
    }
  }, [])

  const fetchMoreData = () => {
    if (!loadingMoreSessions) {
      setLoadingMoreSessions(true)
      const nextPage = page + 1
      setPage(nextPage)
      const req = {
        keyword: searchValue,
        page_num: nextPage,
        page_size: pageSize
      }
      const callback = () => {
        setLoadingMoreSessions(false)
      }
      dispatch(getChatSessionHistory(req, callback))
    }
  }

  const fetchSessionChat = () => {
    setLoadingSessionHistory(true)
    const req = {
      keyword: searchValue,
      page_num: page,
      page_size: pageSize
    }

    const callback = () => {
      setLoadingSessionHistory(false)
    }
    dispatch(getChatSessionHistory(req, callback))
  }

  const handleNewChat = (data) => {
    trackEvent(mixpanelEvents.CHAT_NEW_SESSION, 'SUCCESS', {}, {})
    setSelectedSession('new')
    setSessionChat({})
  }

  useEffect(() => {
    if (selectedSession !== 'new' && !_.isEmpty(sessions)) {
      const { sessionIndex, classifier, sessionId } = selectedSessionPath
      const sessionData = sessions?.[classifier]?.[sessionIndex]?.[sessionId]
      if (sessionData) {
        setSessionChat(_.cloneDeep(sessionData))
      } else {
        if (!selectedSession && sessionIdParams && sessionIdParams !== 'new') {
          const obj = findSessionById(sessionIdParams, sessions)
          if (obj) {
            const { key, index } = obj
            const sessionVal = sessions[key][index][sessionIdParams]
            setSessionChat(_.cloneDeep(sessionVal))
            setSelectedSessionPath({
              sessionIndex: index,
              classifier: key,
              sessionId: sessionIdParams
            })
            setSelectedSession(sessionIdParams)
          } else {
            const callback = () => {
              setSelectedSession('new')
              setSessionChat({})
            }
            dispatch(getSingleChatHistory(sessionIdParams, callback))
          }
        } else {
          setSelectedSession('new')
          setSessionChat({})
        }
      }
    }
  }, [sessions])

  function findSessionById(idToFind, sessions) {
    for (const key in sessions) {
      if (Array.isArray(sessions[key])) {
        const index = sessions[key].findIndex((item) => item[idToFind])
        if (index !== -1) {
          return { key, index }
        }
      }
    }
    return null
  }

  const handleMenuShare = (data) => {
    const { sessionData } = data
    setShowShareModal(sessionData)
    setShareObj({
      ...shareObj,
      session: sessionData,
      shareAll: true
    })
  }

  const handleMenuRename = (data) => {
    const { currentSessionId } = data
    setEdit((prev) => {
      return {
        ...prev,
        [currentSessionId]: true
      }
    })
  }
  const handleMenuDeleteSession = (data) => {
    const { sessionsIndex, title, currentSessionId, sessionData } = data
    handleDeleteSession(sessionsIndex, title, currentSessionId, sessionData)
  }

  const handleMenuTitleGeneration = (data) => {
    const { sessionData } = data
    handleTitleGeneration(sessionData?.id, '', true)
  }

  const menuOptions = [
    {
      label: 'Generate Title',
      icon: <SparkIcon2 className="mr-2 size-4" />,
      onClick: handleMenuTitleGeneration
    },
    {
      label: 'Share',
      icon: <Share2Icon className="mr-2 size-4" />,
      onClick: handleMenuShare
    },
    {
      label: 'Rename',
      icon: <PenLineIcon className="mr-2 size-4" />,
      onClick: handleMenuRename
    },
    {
      label: 'Delete',
      icon: <TrashIcon className="mr-2 size-4" />,
      onClick: handleMenuDeleteSession
    }
  ]

  const handleSessionSelection = (
    currentSessionId,
    data,
    sessionIndex,
    title
  ) => {
    const clone = _.cloneDeep(data)
    trackEvent(mixpanelEvents.CHAT_SESSION_OPENED, 'SUCCESS', {}, {})
    setSessionChat(clone)
    setSelectedSessionPath({
      sessionIndex,
      classifier: title,
      sessionId: currentSessionId
    })
    setSelectedSession(currentSessionId)
  }

  const handleEditSession = (index, sessionsIndex, id, oldValue) => {
    const inputValue = titleRef.current.value
    dispatch(
      updateChatSessionHistory(
        { id, title: inputValue, sessionsIndex, index },
        { id, title: oldValue, sessionsIndex, index }
      )
    )
    setEdit((prev) => {
      return {
        ...prev,
        [id]: false
      }
    })
  }

  const handleDeleteSession = (index, sessionsIndex, id, oldValue) => {
    showConfirmDialog({
      onConfirm: async () => {
        dispatch(
          deleteChatSessionHistory(
            { id, sessionsIndex, index },
            { id, data: oldValue, sessionsIndex, index }
          )
        )
      },
      confirmationMessageTitle: `Are you sure you want to delete this session ?`
    })
  }

  const updateFeedbackCallback = (data) => {
    dispatch(updateSessionChatFeedback(data))
  }

  const searchOnEnter = (event, val) => {
    if (event?.keyCode === 13) {
      setLoadingSessionHistory(true)
      const req = {
        keyword: val,
        page_num: 1,
        page_size: pageSize
      }
      setPage(1)
      const callback = () => {
        setLoadingSessionHistory(false)
      }
      dispatch(getChatSessionHistory(req, callback))
    }
  }

  const handleShareSession = async () => {
    setShareLoading(true)
    const {
      session = {},
      selectedMessages = [],
      users = [],
      shareAll = true
    } = shareObj
    if (_.isEmpty(users)) {
      toast.error('Please select users to share')
      setShareLoading(false)
      return
    }
    if (!shareAll && selectedMessages.length === 0) {
      toast.error('Please select messages to share')
      setShareLoading(false)
      return
    }
    const shareMessages = shareAll ? [] : selectedMessages
    const { id: session_id } = session
    const usersArray = users.map((data) => data.id)
    const payload = {
      session_id,
      user_ids: usersArray,
      selected_chat_ids: shareMessages
    }
    const res = await chatShare(payload)
    if (res.status === 200) {
      trackEvent(mixpanelEvents.CHAT_SESSION_SHARED, 'SUCCESS', {}, {})
      toast.success('Chat shared successfully')
    } else {
      trackEvent(mixpanelEvents.CHAT_SESSION_SHARED, 'FAILURE', {}, {})
      toast.error('Something went wrong. Please try again later')
    }
    setShareLoading(false)
    closeShareModal()
  }

  const closeShareModal = () => {
    setShowShareModal(false)
    setShareObj({})
  }

  const handleShareUserSelect = (e) => {
    setShareObj({
      ...shareObj,
      users: e
    })
  }

  const domainUsersOptions = domain_users.map((data) => {
    return {
      label: data?.user_name,
      value: data?.id,
      ...data
    }
  })

  const handleSharedMessageSelect = (id, checked) => {
    if (checked) {
      setShareObj((prevShareObj) => {
        const updatedSelectedMessages = [
          ...(prevShareObj.selectedMessages || []),
          id
        ]
        return { ...prevShareObj, selectedMessages: updatedSelectedMessages }
      })
    } else {
      setShareObj((prevShareObj) => {
        const updatedSelectedMessages = (
          prevShareObj.selectedMessages || []
        ).filter((data) => data !== id)
        return { ...prevShareObj, selectedMessages: updatedSelectedMessages }
      })
    }
  }

  const toggleShareAll = () => {
    setShareObj({
      ...shareObj,
      shareAll: !shareAll
    })
  }

  const handleRenameKeyDown = (
    event,
    sessionsIndex,
    title,
    currentSessionId,
    sessionTitle
  ) => {
    if (event.key === 'Enter') {
      handleEditSession(sessionsIndex, title, currentSessionId, sessionTitle)
    } else if (event.key === 'Escape') {
      setEdit((prev) => {
        return {
          ...prev,
          [currentSessionId]: false
        }
      })
    }
  }

  const { session: shareSession = {}, shareAll } = shareObj
  const { chat = {} } = shareSession || {}
  const { chats = [] } = chat || []

  const isSessionEmpty = Object.values(sessions).every(
    (arr) => Array.isArray(arr) && arr.length === 0
  )

  const renderSessionList = () => {
    return (
      <Section overFlow>
        <div className={classes?.scrollableDiv}>
          {loadingSessionHistory ? (
            <Container>
              <Section>
                <Box className={loaderContainer}>
                  <Loader loading={loadingSessionHistory} caption={''} />
                </Box>
              </Section>
            </Container>
          ) : isSessionEmpty ? (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              No sessions found
            </Box>
          ) : (
            <>
              {Object.entries(sessions).map((data, titleIndex) => {
                const title = data[0]
                const sessionsData = data[1]
                return sessionsData?.length > 0 ? (
                  <>
                    <div
                      id="session-title"
                      className="mt-4 mb-1 text-xs text-grey-400"
                    >
                      {_.startCase(title)}
                    </div>
                    {sessionsData.map((sessions, sessionsIndex) => {
                      return Object.entries(sessions).map(
                        (session, sessionIndex) => {
                          const sessionTitle = session[1]?.title ?? ''
                          const currentSessionId = session[0]
                          const selectedEdit = isEdit?.[currentSessionId]
                          const { type } = session[1]
                          const isMenuOpen =
                            currentActiveMenu === currentSessionId
                          return (
                            <>
                              <div
                                className={
                                  selectedSession !== currentSessionId
                                    ? isMenuOpen
                                      ? clsx(
                                          historyChatTitle,
                                          addHighlight,
                                          classes.menuOpen
                                        )
                                      : clsx(historyChatTitle, addHighlight)
                                    : clsx(
                                        historyChatTitle,
                                        selectedHistoryOption
                                      )
                                }
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                                onClick={() => {
                                  handleSessionSelection(
                                    currentSessionId,
                                    session[1],
                                    sessionsIndex,
                                    title
                                  )
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: '5px',
                                    fontSize: '22px',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {type === 'workspace' ? (
                                    <RateReviewOutlinedIcon
                                      style={{
                                        fontSize: 'inherit'
                                      }}
                                    />
                                  ) : type === 'shared' ? (
                                    <ThreePOutlinedIcon
                                      style={{
                                        fontSize: 'inherit'
                                      }}
                                    />
                                  ) : (
                                    <ChatIcon
                                      // id="sidebar-chaticon"
                                      style={{
                                        width: '18px',
                                        height: '18px'
                                      }}
                                    />
                                  )}
                                </div>
                                {selectedEdit ? (
                                  <TextField
                                    fullWidth
                                    id="outlined-required"
                                    label=""
                                    onKeyDown={(e) =>
                                      handleRenameKeyDown(
                                        e,
                                        sessionsIndex,
                                        title,
                                        currentSessionId,
                                        sessionTitle
                                      )
                                    }
                                    defaultValue={sessionTitle}
                                    inputRef={(input) => {
                                      titleRef.current = input
                                      if (input) {
                                        input.focus()
                                      }
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    inputProps={{
                                      padding: '5px 10px'
                                    }}
                                    onFocus={(e) =>
                                      e.currentTarget.setSelectionRange(
                                        e.currentTarget.value.length,
                                        e.currentTarget.value.length
                                      )
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      flex: '1',
                                      overflow: 'hidden',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical'
                                    }}
                                  >
                                    {animationId === currentSessionId ? (
                                      <TextGenearate
                                        text={animationText}
                                        onFinish={removeAnimation}
                                      />
                                    ) : (
                                      sessionTitle
                                    )}
                                  </div>
                                )}
                                <>
                                  {selectedEdit ? (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex'
                                        }}
                                      >
                                        <IconButton
                                          disableRipple
                                          style={{
                                            padding: '0px'
                                          }}
                                          className={titleActions}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            handleEditSession(
                                              sessionsIndex,
                                              title,
                                              currentSessionId,
                                              sessionTitle
                                            )
                                          }}
                                        >
                                          <DoneIcon />
                                        </IconButton>
                                        <IconButton
                                          disableRipple
                                          style={{
                                            padding: '0px'
                                          }}
                                          className={titleActions}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            setEdit((prev) => {
                                              return {
                                                ...prev,
                                                [currentSessionId]: false
                                              }
                                            })
                                          }}
                                        >
                                          <ClearIcon />
                                        </IconButton>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex'
                                        }}
                                      >
                                        <MenuButton
                                          options={menuOptions}
                                          menuOpenCallback={() => {
                                            setCurrentActiveMenu(
                                              currentSessionId
                                            )
                                          }}
                                          menuProps={{
                                            sx: {
                                              zIndex: 1302
                                            }
                                          }}
                                          menuCloseCallback={() => {
                                            setCurrentActiveMenu(null)
                                          }}
                                          callBackData={{
                                            sessionsIndex,
                                            title,
                                            currentSessionId,
                                            sessionData: session[1]
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                </>
                              </div>
                            </>
                          )
                        }
                      )
                    })}
                  </>
                ) : null
              })}
            </>
          )}
          {loadingMoreSessions && (
            <Loader loading={loadingMoreSessions} caption={''} />
          )}
          {hasMoreData && !loadingMoreSessions && (
            <Box>
              <Button2 onClick={fetchMoreData} style={{ width: '100%' }}>
                Load More
              </Button2>
            </Box>
          )}
        </div>
      </Section>
    )
  }

  return (
    <Container style={{ overflow: 'auto' }}>
      <Section className={outerContainer} overFlow>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            position: 'relative'
          }}
        >
          <Grid container>
            {fullScreen && (
              <Grid
                item
                xs={12}
                sm={3}
                lg={3}
                xl={3}
                sx={{
                  borderRight: '1px solid rgb(229, 229, 229)',
                  height: '100%'
                }}
              >
                <Container>
                  <SectionFixed>
                    <div className="p-3 pb-0">
                      <SearchNew
                        value={searchValue}
                        onChange={(val) => setSearchValue(val)}
                        onEnter={(val) => {
                          console.log('search')
                          searchOnEnter(
                            {
                              keyCode: 13
                            },
                            val
                          )
                        }}
                        onClear={() => {
                          setSearchValue('')
                          searchOnEnter(
                            {
                              keyCode: 13
                            },
                            ''
                          )
                        }}
                        id="searchInputs"
                      />
                    </div>
                  </SectionFixed>
                  {renderSessionList()}
                </Container>
              </Grid>
            )}
            <Grid
              item
              xs={fullScreen ? 12 : 12}
              sm={fullScreen ? 9 : 12}
              lg={fullScreen ? 9 : 12}
              xl={fullScreen ? 9 : 12}
              sx={{ alignContent: 'center', height: '100%' }}
            >
              <div className="h-full">
                <ChatBot
                  initalPrompt={!loadingSessionHistory && searchKey}
                  historyProps={sessionChat}
                  chatType="session"
                  trackerId={selectedSession}
                  showEmptyChatElements
                  showHistoryByDefault={true}
                  handleChatAddition={handleChatAddition}
                  textBoxPlaceholder={'Ask AI anything...'}
                  imageTextBoxPlaceHolder={'Describe the image'}
                  updateFeedbackCallback={updateFeedbackCallback}
                  showFilter={true}
                  showTools={true}
                  s3Obj={s3Obj}
                  fullScreen={fullScreen}
                  {...rest}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={showShareModal}
          onClose={() => closeShareModal(false)}
          fullWidth
          maxWidth="sm"
          sx={{
            zIndex: 1302
          }}
        >
          <DialogTitle
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Share Chat Session
            <IconButton
              disableRipple
              edge="end"
              color="inherit"
              onClick={() => closeShareModal()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{}}>
            <Box sx={{ marginBottom: '20px' }}>
              Selected messages will be shared in a new chat session with the
              chosen users. Messages sent after sharing will not be reflected in
              the shared session.
            </Box>
            <Select
              options={domainUsersOptions}
              onChange={(e) => {
                handleShareUserSelect(e)
              }}
              placeholder="Select Users"
              isMulti
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'black !important',
                  marginBottom: '20px',
                  boxShadow: 'none !important'
                }),
                menuList: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '14px ',
                  paddingBottom: '0px !important',
                  height: '100%',
                  overflow: 'auto'
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  borderBottom: '1px solid #D9D9D9 !important',
                  padding: '5px 10px !important',
                  wordBreak: 'break-word',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#000000 !important'
                })
              }}
            />

            <Box sx={{ display: 'flex' }}>
              <Box sx={{ cursor: 'pointer' }} onClick={() => toggleShareAll()}>
                {shareAll ? (
                  <CheckBoxOutlinedIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )}
              </Box>
              <Box
                onClick={() => toggleShareAll()}
                sx={{ marginLeft: '3px', cursor: 'pointer' }}
              >
                Share the entire session
              </Box>
            </Box>
            {!shareAll && chats?.length > 0 && (
              <Box
                sx={{
                  maxHeight: '400px',
                  overflow: 'auto',
                  border: '1px solid black',
                  borderRadius: '4px',
                  marginBottom: '20px'
                }}
              >
                <List id="historyList" sx={{ padding: '0px' }}>
                  {chats?.map((message, index) => {
                    const { id, author, data = {}, type } = message || {}
                    const {
                      partialText,
                      text,
                      error,
                      imageUrls = []
                    } = data || {}
                    const errortext =
                      type === 'image'
                        ? 'Error during Image Generation\nSomething went wrong! Please try again.'
                        : 'Error\nSomething went wrong! Please try again.'
                    const textToRender = error
                      ? textToHtml(errortext)
                      : partialText
                      ? 'Generation Stopped by User'
                      : text
                    const checked = shareObj?.selectedMessages?.includes(id)
                    return (
                      <ListItem
                        sx={{
                          background: checked
                            ? author === 'Me'
                              ? '#DBEAFF !important'
                              : '#b1cdf4 !important'
                            : 'inherit'
                        }}
                        key={index}
                        className={
                          classes.message +
                          ' ' +
                          classes[`message${message.author}`]
                        }
                      >
                        <Box className={classes.messageWrapper}>
                          <Box
                            sx={{
                              height: '44px',
                              padding: '0px',
                              color: 'black',
                              margin: '0px 5px',
                              visibility:
                                author === 'Me' ? 'visible' : 'hidden',
                              cursor: 'pointer'
                            }}
                            onClick={() =>
                              handleSharedMessageSelect(id, !checked)
                            }
                          >
                            {checked ? (
                              <CheckBoxOutlinedIcon />
                            ) : (
                              <CheckBoxOutlineBlankOutlinedIcon />
                            )}
                          </Box>
                          <div>
                            <div className={classes.avatarWrapper}>
                              <Box className={classes.avatarCheckBox}>
                                {author === 'Them' ? (
                                  <Avatar className={classes.avatar}>
                                    <SvgIcon>
                                      <Robot />
                                    </SvgIcon>
                                  </Avatar>
                                ) : (
                                  <UserAvatar className={classes.avatar} />
                                )}
                              </Box>

                              {type === 'image' && !_.isEmpty(imageUrls) ? (
                                <div className={classes.imageContainer}>
                                  {imageUrls.map((url, idx) => {
                                    return (
                                      <Box
                                        key={idx}
                                        className={classes.imageWrapper}
                                      >
                                        <ImageRender
                                          style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'contain',
                                            overflow: 'hidden'
                                          }}
                                          id={id + author + idx}
                                          effect="blur"
                                          src={url}
                                          s3Obj={s3Obj}
                                          showLoader={false}
                                        />
                                      </Box>
                                    )
                                  })}
                                </div>
                              ) : (
                                <div className={classes.messageText}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: textToRender
                                    }}
                                    className={
                                      message?.data?.error
                                        ? classes.errorContainer
                                        : ''
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Box>
                      </ListItem>
                    )
                  })}
                </List>
              </Box>
            )}
            <Button
              disabled={shareLoading}
              variant="contained"
              color="primary"
              onClick={handleShareSession}
              style={{ float: 'right' }}
            >
              {shareLoading ? 'Sharing...' : 'Share'}
            </Button>
          </DialogContent>
        </Dialog>
      </Section>
      {ConfirmDialog}
    </Container>
  )
}

export default SessionChat
