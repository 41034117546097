import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const drawerWidth = 240

const openedMixin = (theme) => {
  const { navigationColor } = useColor()
  return {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: navigationColor,
    overflowX: 'hidden'
  }
}

const closedMixin = (theme) => {
  const { navigationColor } = useColor()
  return {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    backgroundColor: navigationColor,

    width: '50px'
  }
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar
}))

const DrawerFooter = styled('div')(({ theme }) => ({
  marginTop: 'auto'
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  color: 'var(--dark-text)',
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const useStyles = makeStyles({
  box: {
    display: 'flex'
  },
  disableDrawer: {
    pointerEvents: 'none'
  },
  button: (props) => ({
    minHeight: 48,
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgb(196 196 196 / 30%) !important'
    },

    display: 'flex',
    justifyContent: props.open ? 'start' : 'center',
    paddingLeft: props.open ? '15px !important' : '16px'
  }),
  headerLogo: (props) => ({
    minHeight: 48,
    width: '100%',
    display: 'flex',
    justifyContent: props.open ? 'start' : 'center',
    paddingLeft: props.open ? '10px !important' : '0px'
  }),
  listIcon: (props) => ({
    minWidth: '0px !important'
  }),
  listText: (props) => ({
    opacity: props.open ? 1 : 0,

    color: 'var(--dark-text)',
    flex: 'none',
    marginLeft: 30
  }),
  listItem: {
    display: 'block'
  },
  sideBarWrapper: {
    '& #sidebarRight': {
      visibility: 'hidden'
    },
    '&:hover #sidebarRight': {
      visibility: 'visible'
    }
  },

  iconAiFab: {
    paddingRight: '10.5px',
    '&:hover .chatIcon': {
      scale: 1.1,
      transition: 'scale 0.2s ease-in-out'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      inset: '0',
      borderRadius: '7px 0 0 7px',

      padding: '1.5px 0 1.5px 1.5px',
      background: 'linear-gradient(100deg, #471CA8, #FF3465)',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude'
    }
  },

  icon: {
    height: 'inherit !important',
    textAlign: 'center',
    color: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bottomIcon: {
    width: '30px !important',
    paddingLeft: '7px !important'
  },
  toolTipBg: {
    background: 'white'
  }
  /* topMenuList: { paddingLeft: "10px !important" }, */
})

export {
  drawerWidth,
  useStyles,
  Drawer,
  DrawerHeader,
  DrawerFooter,
  closedMixin,
  openedMixin
}
