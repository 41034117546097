import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  Suspense,
  lazy
} from 'react'
import {
  fetchRFXPainPoints,
  getRFXPainPoints,
  getStoryboardGenerationHistory,
  getWorkspaceDraft,
  workspaceActionsDrafts
} from '../../store/api'
import {
  Container,
  Loader,
  Section,
  useConfirmation,
  Tabs
} from '../../components'
import { Resizable } from 'react-resizable'
import { useResizeDetector } from 'react-resize-detector'
import { useStyles } from './styles'
import { initalizeS3, getSignedUrl, initalizeAWSSigner } from '../../utils/AWS'
import { checkAccessByRole } from '../../utils/User'
import { Box } from '@mui/material'
import LexicalEditor from '../../components/Lexical'
import _, { get } from 'lodash'
import DashboardResult from '../DashboardResult'
import ChatBot from '../ChatBot'
import InfoTab from './InfoTab'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import {
  $createSectionElementNode,
  $getSectionElementById
} from '../../components/Lexical/nodes/SectionNode'
import {
  $getRoot,
  $createTextNode,
  $getSelection,
  $createParagraphNode
} from 'lexical'
import { $insertHTMLString } from '../../components/Lexical/insertHTML'
import { $generateHtmlFromNodes } from '@lexical/html'
import { v4 as uuid } from 'uuid'
import { Auth } from 'aws-amplify'
import SettingUpWorkspace from './SettingUpWorkspace'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import {
  BlendIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  DownloadIcon,
  DownloadIcon2,
  PencilIcon,
  SearchIcon,
  SemiCircleIcon,
  TemplateIcon,
  WorkspaceIcons
} from '@/components/Icons/Icons'
import { ROUTES } from '@/config/routes'
import { useLocation, useNavigate } from 'react-router-dom'
import { cn } from '@/lib/utils'
import Button2 from '@/components/Button/Button2'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Tooltip } from '@/components/ui/tooltip-simple'
const Storyboard = lazy(() => import('./Storyboard'))

const CustomResizeHandle = React.forwardRef((props, ref2) => {
  const { handleAxis, ...restProps } = props
  return (
    <div ref={ref2} {...restProps}>
      <span
        className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
      ></span>
    </div>
  )
})

const WorkspaceDraft = (porps) => {
  const { workspaceId, draftId } = porps
  const { width, height, ref } = useResizeDetector()
  const classes = useStyles()
  const [draft, setDraft] = useState(null)
  const [draftName, setDraftName] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(null)
  const [editorWidth, setEditorWidth] = useState(0)
  const [sidePaneWidth, setSidePaneWidth] = useState(0)
  const [editorContent, setEditorContent] = useState({})
  const [s3Obj, sets3Obj] = useState({})
  const [activeSideTab, setActiveSideTab] = useState(0)
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [signerObj, setSignerObj] = useState(null)
  const [loadingStates, setLoadingStates] = useState({})
  const [activeEditor, setActiveEditor] = useState(null)
  const [currentEditorState, setCurrentEditorState] = useState(null)
  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )
  const [storyboards, setStoryboards] = useState(null)
  const [selectedSection, setSelectedSection] = useState(null)
  const latestRequest = useRef(null)
  const [latestRequestTracker, setLatestRequestTracker] = useState(null)

  const { type = 'draft', updated_at } = draft?.draft || {}
  const isTypeDraft = type === 'draft'

  const editable = Object.values(loadingStates).every(
    (state) => state === false
  )

  const [settingUpSpace, setSettingUpSpace] = useState({
    init: true
  })

  const [painPoints, setPainPoints] = useState(null)
  const [painPointInit, setPainPointInit] = useState(false)
  const { WORKSPACE } = ROUTES
  const navigate = useNavigate()
  const location = useLocation()
  const {
    workspaceName: workspaceNameFromLocation,
    workspaceId: workspaceIdFromLocation,
    draftName: draftNameFromLocation
  } = location.state || {}
  const showWorkspaceAutomation =
    checkAccessByRole('workspace_automation_admin', 'admin') ||
    checkAccessByRole('workspace_automation_user', 'user')

  console.log('drafts data', draft)

  const handleSave = async (
    value,
    key,
    force = false,
    onEndCallback = () => {}
  ) => {
    setDraft({
      ...draft,
      draft: {
        ...draft.draft,
        [key]: value
      }
    })

    const req = {
      id: draftId,
      workspace_id: workspaceId,
      mode: 'update',
      [key]: value,
      force
    }

    const res = await workspaceActionsDrafts(req)
    if (res.status === 200) {
      const { data } = res
      const { updated_at } = data
      setDraft({
        ...draft,
        draft: {
          ...draft.draft,
          [key]: value,
          updated_at,
          updated_by_user_name: currentUserName
        }
      })
    } else {
      toast.error('Error in saving draft name')
    }
    onEndCallback()
  }

  useEffect(() => {
    if (storyboards?.length > 0) {
      getHistory()
    }
  }, [storyboards])

  useEffect(() => {
    setEditable(editable)
  }, [editable])

  const setEditable = (val = false) => {
    if (activeEditor) {
      activeEditor.update(async () => {
        activeEditor.setEditable(val)
      })
    }
  }

  const generateContentFromStory = async (req) => {
    const { storyboard_id, storyboard_config } = req || {}
    const { section_title = '' } = storyboard_config || {}

    setSettingUpSpace({
      ...settingUpSpace,
      init: false
    })

    if (loadingStates[storyboard_id]) {
      toast.error(
        'Content generation is in progress. Please wait for the current process to complete.'
      )
      return
    }

    setLoadingStates((prev) => ({
      ...prev,
      [storyboard_id]: true
    }))

    if (!activeEditor) {
      toast.error('Unable to find editor object')
      setLoadingStates((prev) => ({
        ...prev,
        [storyboard_id]: false
      }))
      return
    }

    // clear or create section

    activeEditor.update(async () => {
      let section = $getSectionElementById(storyboard_id)
      if (!section) {
        const root = $getRoot()
        const currentContent = root.getTextContent()
        if (currentContent === '') {
          root.clear()
        }
        section = $createSectionElementNode(storyboard_id)
        root.append(section)
      } else {
        section.clear()
      }

      const para = $createParagraphNode()
      para.append(
        $createTextNode(
          `Generating content for ${section_title || storyboard_id} ...`
        )
      )

      section.append(para)
    })

    let isMessageEnd = false
    let completeText = ''
    let remainingText = ''

    const url = process.env.REACT_APP_CHAT_URL || ''
    const apiUrl = new URL(url)
    const currentSession = await Auth.currentSession()
    const token = currentSession?.getAccessToken()?.getJwtToken()
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(req),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname,
        userAuth: 'Bearer ' + token
      }
    })
    const response = await fetch(`${url}`, { ...signedUrl })
    if (response.status === 200 && response.body) {
      const reader = response.body.getReader()
      while (true) {
        const { done, value } = await reader.read()
        if (done) {
          break
        }
        const text = new TextDecoder().decode(value)
        if (text.includes('__END_OF_CHAT__')) {
          isMessageEnd = true

          remainingText = text.split('__END_OF_CHAT__')[0]
        }
        if (!isMessageEnd) {
          completeText = completeText + text
          updateSectionContent(completeText, storyboard_id)
        } else {
          if (remainingText) {
            completeText = completeText + remainingText
            updateSectionContent(completeText, storyboard_id)
          }
        }
      }
      if (!completeText) {
        activeEditor.update(async () => {
          updateSectionContent('Content Generation Failed', storyboard_id)
        })
      }
    } else {
      activeEditor.update(async () => {
        updateSectionContent('Content Generation Failed', storyboard_id)
      })
    }

    setLoadingStates((prev) => ({
      ...prev,
      [storyboard_id]: false
    }))
  }

  const deleteSectionFromEditor = async (sectionId) => {
    activeEditor.update(async () => {
      const section = $getSectionElementById(sectionId)
      if (section) {
        section.remove()
      }
    })
  }

  const updateSectionContent = (content, sectionId) => {
    if (activeEditor) {
      activeEditor.update(async () => {
        const sectionById = $getSectionElementById(sectionId)
        if (sectionById) {
          sectionById.clear()
          const para = $createParagraphNode()
          sectionById.append(para)
          para.select()
          const selection = $getSelection()
          $insertHTMLString(content, selection, activeEditor)
        }
      })
    }
  }

  const positionChangeCallback = (sections) => {
    activeEditor.update(async () => {
      const sectionArray = []
      sections.forEach((section) => {
        const { id } = section
        const sectionNode = $getSectionElementById(id)
        if (sectionNode) {
          sectionArray.push(sectionNode)
          sectionNode.remove()
        }
      })
      const root = $getRoot()
      sectionArray.forEach((section) => {
        root.append(section)
      })
    })
  }

  const insertIntoEditor = (content, sectionId) => {
    activeEditor.update(async () => {
      let section = $getSectionElementById(sectionId)
      if (!section) {
        const root = $getRoot()
        const currentContent = root.getTextContent()
        if (currentContent === '') {
          root.clear()
        }
        section = $createSectionElementNode(sectionId)
        root.append(section)
      } else {
        section.clear()
      }
    })
    updateSectionContent(content, sectionId)
  }

  const commonTabs = [
    {
      label: 'Search',
      icon: <SearchIcon className="text-purple-600 size-4" />,
      tooltipDescription:
        'Search for a proposals, content, or any other information',
      children: <DashboardResult parent="editor" compactTabs={true} />
    }
  ]

  const tabs =
    isTypeDraft || !showWorkspaceAutomation
      ? [
          {
            label: 'Search',
            icon: <SearchIcon className="text-purple-600 size-4" />,
            tooltipDescription:
              'Search for a proposals, content, or any other information',
            children: <DashboardResult parent="editor" compactTabs={true} />
          }
        ]
      : [
          {
            label: 'Storyboard',
            icon: <TemplateIcon className="text-green-600 size-4" />,
            tooltipDescription:
              'Create a storyboard to generate content for your workspace',
            children: (
              <Suspense fallback={<Loader loading={true} />}>
                <Storyboard
                  draftId={draftId}
                  workspaceId={workspaceId}
                  generateContentFromStory={generateContentFromStory}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                  storyboards={storyboards}
                  loadingStates={loadingStates}
                  setStoryboards={setStoryboards}
                  deleteSectionFromEditor={deleteSectionFromEditor}
                  positionChangeCallback={positionChangeCallback}
                  insertIntoEditor={insertIntoEditor}
                  showConfirmDialog={showConfirmDialog}
                  settingUpWorkspace={(logsInfo) => {
                    setSettingUpSpace({
                      ...settingUpSpace,
                      ...logsInfo
                    })
                  }}
                />
              </Suspense>
            )
          },
          ...commonTabs,
          {
            label: 'Pain Points',
            icon: <BlendIcon className="text-pink-600 size-4" />,
            tooltipDescription: 'Pain points extracted from the RFP/Q',
            children: painPoints ? (
              <div className="p-4">
                <div
                  style={{
                    fontSize: '20px',
                    fontFamily: 'PoppinsMedium',
                    padding: '18px',
                    paddingTop: '0',
                    paddingLeft: '0'
                  }}
                  className={`flex justify-between items-center`}
                >
                  <div>
                    Pain Points
                    <div
                      style={{
                        fontSize: '11px'
                      }}
                    >
                      {Object.keys(painPoints).length} detected
                    </div>
                  </div>
                </div>
                {Object.keys(painPoints).map((key, index) => {
                  return (
                    <div key={index} className="text-xs">
                      <div className="flex flex-row items-center gap-2">
                        <div className="rounded-lg size-2 border-05 border-grey-500 bg-grey-800"></div>

                        <label className="text-sm font-medium" htmlFor={key}>
                          {key}
                        </label>
                      </div>

                      <div className="p-1 px-2 ml-3 text-grey-600">
                        {painPoints[key]}
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div>
                <div
                  style={{
                    fontSize: '20px',
                    fontFamily: 'PoppinsMedium',
                    padding: '18px',
                    paddingTop: '0',
                    paddingLeft: '0'
                  }}
                  className={`flex justify-between items-center`}
                >
                  <div className="p-4">
                    Pain Points
                    <div
                      style={{
                        fontSize: '11px'
                      }}
                    >
                      Extracting Pain points
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        ]

  useEffect(() => {
    const fetchDraft = async () => {
      const res = await getWorkspaceDraft(workspaceId, draftId)
      if (res.status === 200) {
        const data = res.data
        const { draft, storyboards = [] } = data

        const { document_content = {} } = draft
        if (draft?.metadata_?.rfx_id) {
          pollPainPoints(draft?.metadata_?.rfx_id)
        }

        if (document_content) {
          const regexp = /(?<=\"src\"\:\")(.*?)(?=\")/g
          let str = JSON.stringify(document_content)
          const array = [...str.matchAll(regexp)]
          await Promise.all(
            array.map(async (url) => {
              if (['amazonaws'].includes(url[0])) {
                const oldUrl = url[0]
                const newUrl = await getSignedUrl(url[0], s3Obj)
                str = str?.replace(oldUrl, newUrl)
              }
            })
          )
          let newContent = JSON.parse(str)
          newContent =
            _.isEmpty(newContent) || _.isEmpty(newContent?.root?.children)
              ? {}
              : newContent
          setEditorContent(newContent)
        }

        if (draft?.type === 'draft' || !showWorkspaceAutomation) {
          setSettingUpSpace({
            ...settingUpSpace,
            init: false
          })
        }

        if (draft?.type === 'storyboards' && draft.status === 'completed') {
          setSettingUpSpace({
            ...settingUpSpace,
            init: false
          })
        }

        setDraft(data)
        setDraftName(data.draft.document_name)
        setStoryboards(storyboards)

        setLoading(false)
      } else {
        setLoadingError('Unable to fetch the workspace draft')
      }
    }
    if (workspaceId && draftId) fetchDraft()
  }, [workspaceId, draftId])

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      console.log('s3 obj', s3)
      sets3Obj(s3)
      const signer = await initalizeAWSSigner()
      setSignerObj(signer)
    }
    initalizeData()
  }, [])

  const pollPainPoints = async (id) => {
    const interval = setInterval(async () => {
      const res = await fetchRFXPainPoints(id)
      if (res.status === 200) {
        const { data } = res

        const painPoints = data.data

        if (Object.keys(painPoints).length > 0) {
          setPainPoints(data.data)
          clearInterval(interval)
        } else {
          // if (!painPointInit) {
          //   setPainPointInit(true)
          //   getRFXPainPoints(draft?.metadata_?.rfx_id)
          // }
        }
      }
    }, 5000)
  }

  const handleOnResize = (event, obj) => {
    if (event && obj) {
      const newEWidth = obj.size.width
      const newSWidth = width - obj.size.width - 1
      if ((newSWidth / width) * 100 > 15 && (newEWidth / width) * 100 > 50) {
        setEditorWidth(newEWidth)
        setSidePaneWidth(newSWidth)
      }
    } else {
      if (editorWidth && sidePaneWidth) {
        const sWidth = sidePaneWidth
        const eWidth = width - sWidth - 1
        setEditorWidth(eWidth)
        setSidePaneWidth(sWidth)
      } else {
        const eWidth = Math.round((60 / 100) * width)
        const sWidth = width - eWidth - 1
        setEditorWidth(eWidth)
        setSidePaneWidth(sWidth)
      }
    }
  }

  const handleOpenSection = (sectionId) => {
    setActiveSideTab(0)
    const story = storyboards.find((story) => story.id === sectionId)
    if (story) setSelectedSection(story)
  }

  useEffect(() => {
    handleOnResize()
  }, [width])

  const handleEditorChange = (currentEditorState, editor) => {
    setActiveEditor(editor)
    setCurrentEditorState(currentEditorState)
    const reqId = uuid()
    debouncedSave(editor, currentEditorState, reqId)
  }

  const debouncedSave = useCallback(
    _.debounce((editor, editorState, reqId) => {
      latestRequest.current = reqId
      setLatestRequestTracker('syncing')
      saveToServer(editor, editorState, reqId)
    }, 3000),
    [draft]
  )

  const saveToServer = async (editor, editorState, reqId) => {
    editor.update(() => {
      if (_.isEmpty(editorState)) {
        if (latestRequest?.current === reqId) {
          latestRequest.current = null
          setLatestRequestTracker(null)
        }
        return
      }
      const htmlString = $generateHtmlFromNodes(editor, null)
      handleContentSave(htmlString, editorState, reqId)
    })
  }

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue =
      "Are you sure you want to leave? Your changes aren't synced with the server yet."
  }

  const handleManualSave = () => {
    const reqId = uuid()
    latestRequest.current = reqId
    setLatestRequestTracker('syncing')
    saveToServer(activeEditor, currentEditorState, reqId)
  }

  useEffect(() => {
    if (latestRequestTracker) {
      window.addEventListener('beforeunload', alertUser)
      return () => window.removeEventListener('beforeunload', alertUser)
    } else {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [latestRequestTracker])

  const handleContentSave = async (htmlString, editorState, reqId) => {
    const req = {
      id: draftId,
      workspace_id: workspaceId,
      mode: 'update',
      document_content: editorState,
      document_content_html: htmlString
    }
    const res = await workspaceActionsDrafts(req)
    if (res.status === 200) {
      const { data } = res
      const { updated_at } = data
      setDraft({
        ...draft,
        draft: {
          ...draft.draft,
          updated_at,
          updated_by_user_name: currentUserName
        }
      })
      if (latestRequest?.current === reqId) {
        latestRequest.current = null
        setLatestRequestTracker(null)
      }
    } else {
      latestRequest.current = null
      setLatestRequestTracker('error')
    }
  }

  const getHistory = async () => {
    try {
      const res = await getStoryboardGenerationHistory(storyboards[0].id)

      if (res.status === 200) {
        if (res.data.length > 0) {
          setSettingUpSpace({
            ...settingUpSpace,
            init: false
          })
        }
      }
    } catch (error) {
      toast.error('Failed to fetch generation history')
      setLoading(false)
    }
  }

  const getFontSizeClass = (text) => {
    console.log('text', text.length, text)
    const length = text?.length || 0
    if (length > 30) return 'text-sm' // 16px
    if (length > 20) return 'text-lg' // 18px
    return 'text-xl' // 20px
  }

  return (
    <Box className={classes.draftWrapper} id="editorContainerId" ref={ref}>
      {!settingUpSpace.init && (
        <div className="relative flex items-center justify-between p-2 border-b twp border-zinc-100">
          <div className="flex items-center gap-2 px-1">
            <div
              className="p-2 border rounded-lg cursor-pointer border-zinc-100 hover:bg-zinc-100"
              onClick={() => {
                navigate(`${WORKSPACE}/${workspaceId}`, {
                  state: {
                    workspaceName: workspaceNameFromLocation
                  }
                })
              }}
            >
              <ChevronRightIcon className="rotate-180 size-4" />
            </div>
            <div className="flex items-center gap-1">
              {tabs.map((tab, i) => {
                // if (i > 0) return null

                return (
                  <Tooltip
                    align="start"
                    content={
                      <div className="w-40 z-[9999]">
                        <p className="text-sm font-medium">{tab.label}</p>
                        <p className="mt-1 text-xs text-zinc-500">
                          {tab.tooltipDescription}
                        </p>
                      </div>
                    }
                    key={tab.label}
                  >
                    <div
                      className={cn(
                        'twp flex items-center gap-1 p-1.5 px-2 rounded-lg cursor-pointer hover:bg-zinc-100 transition-all duration-300 ease-in-out',
                        activeSideTab === i && 'text-zinc-800 bg-zinc-100'
                      )}
                      key={tab.label}
                      onClick={() => {
                        // trackEvent(tab.mixpanelEvent, 'SUCCESS', {}, {})

                        if (activeSideTab === i) {
                          setActiveSideTab(null)
                        } else {
                          setActiveSideTab(i)
                        }
                      }}
                    >
                      {tab.icon}
                      {activeSideTab === i && (
                        <p className="text-xs font-medium transition-all duration-300 ease-in-out text-zinc-700">
                          {tab.label}
                        </p>
                      )}
                      {/* <p className="w-0 text-xs font-medium transition-all duration-300 ease-in-out opacity-0 group-hover:w-20 text-zinc-700 group-hover:opacity-100 whitespace-nowrap">
                    {tab.label}
                  </p> */}
                    </div>
                  </Tooltip>
                )
              })}
            </div>
          </div>

          <div className="flex items-center gap-1 p-3.5 px-6 capitalize text-zinc-800 absolute left-1/2 -translate-x-1/2">
            <span
              className={cn(
                'min-w-0 font-medium outline-none transition-all duration-300 ease-in-out',
                getFontSizeClass(draftName)
              )}
              role="textbox"
              contentEditable
              onBlur={(e) => {
                setDraftName(e.target.innerText)
              }}
            >
              {draftName}
            </span>
          </div>

          <div className="flex items-center gap-1">
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center gap-2 p-2 px-4 text-xs font-medium text-white rounded-lg bg-zinc-800 hover:bg-zinc-700">
                <DownloadIcon2 className="mr-0.5 size-4" strokeWidth={1.5} />{' '}
                Export
                <ChevronDownIcon className="size-3" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" side="bottom">
                <DropdownMenuItem
                  className="flex items-center gap-2 text-sm"
                  onClick={() => {
                    document.getElementById('download-word').click()
                  }}
                >
                  Export as
                  <div className="text-white bg-blue-600 rounded text-[9px] px-2 font-medium w-12 text-center">
                    WORD
                  </div>
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="flex items-center gap-2 text-sm"
                  onClick={() => {
                    document.getElementById('download-html').click()
                  }}
                >
                  Export as
                  <div className="text-white bg-orange-500 rounded text-[9px] px-2 font-medium w-12 text-center">
                    HTML
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      )}

      {loading ? (
        <div className="flex flex-col items-center justify-center h-full">
          <SemiCircleIcon className="size-4 animate-spin-fast" />
          <p className="text-xs font-medium">Setting up the draft</p>
        </div>
      ) : (
        <Container style={{ height: 'calc(100% - 54px)' }}>
          <Section overFlow>
            {settingUpSpace.init && (
              <SettingUpWorkspace
                StoryBoard={
                  <Suspense fallback={<Loader loading={true} />}>
                    <Storyboard
                      // darkTheme={true}
                      draftId={draftId}
                      workspaceId={workspaceId}
                      generateContentFromStory={generateContentFromStory}
                      selectedSection={selectedSection}
                      setSelectedSection={setSelectedSection}
                      storyboards={storyboards}
                      loadingStates={loadingStates}
                      setStoryboards={setStoryboards}
                      deleteSectionFromEditor={deleteSectionFromEditor}
                      positionChangeCallback={positionChangeCallback}
                      insertIntoEditor={insertIntoEditor}
                      showConfirmDialog={showConfirmDialog}
                      settingUpWorkspace={(logsInfo) => {
                        setSettingUpSpace({
                          ...settingUpSpace,
                          ...logsInfo
                        })
                      }}
                    />
                  </Suspense>
                }
                settingUpSpace={settingUpSpace}
                draft={draft}
                painPoints={painPoints}
              />
            )}
            <Box
              className={classes.flexContainer}
              style={{
                display: settingUpSpace.init ? 'none' : 'flex'
              }}
            >
              <div
                className={cn(
                  'w-0 transition-all duration-300 ease-in-out',
                  activeSideTab !== null && 'w-[30%]'
                )}
              >
                {activeSideTab !== null && tabs[activeSideTab]?.children}
              </div>

              <Box
                className={cn(
                  'h-full w-full transition-all duration-300 ease-in-out',
                  activeSideTab !== null && 'w-[70%]'
                )}
              >
                <Resizable
                  width={editorWidth}
                  axis="x"
                  onResize={handleOnResize}
                  resizeHandles={['w']}
                  handle={<CustomResizeHandle style={{}} />}
                >
                  <Box
                    sx={{
                      width: '100%',
                      margin: '0 auto',
                      height: '100%',
                      borderRight: '1px solid #EEEEEE'
                    }}
                    id={isTypeDraft ? 'draft-editor' : 'storyboard-editor'}
                  >
                    <LexicalEditor
                      initialEditorContent={editorContent || {}}
                      handleEditorChange={handleEditorChange}
                      editorWidth={editorWidth}
                      documentTitle={draft?.draft?.document_name}
                      enableAI
                      enableNarratives
                      enableActions
                      enableSectionFloatbar={
                        !isTypeDraft && showWorkspaceAutomation
                      }
                      enableSyncStatus
                      syncStatus={latestRequestTracker}
                      retrySync={() => handleManualSave()}
                      handleOpenSection={handleOpenSection}
                    />
                  </Box>
                </Resizable>
              </Box>
            </Box>
          </Section>
          {ConfirmDialog}
        </Container>
      )}
    </Box>
  )
}

export default WorkspaceDraft
