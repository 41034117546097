import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Dialog, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { menuData, menuDefaultValue } from './menu'
import Switch from '@mui/material/Switch'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Container, Section, SectionFixed } from '../../components/Container'
import _ from 'lodash'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import MenuButton from '../../components/MenuButton'
import DoneIcon from '@mui/icons-material/Done'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { updateStyleSettings } from '../../store/Analytics/Actions'
import Loader from '../../components/Loader'
import { useLocation } from 'react-router-dom'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { checkUserRoleViewer } from '../../utils/User'
import { TextLineLimiter } from '../../components'
import {
  ChevronDownIcon,
  CrossIcon,
  MagicWand,
  PlusIcon
} from '../../components/Icons/Icons'
import Button2 from '../../components/Button/Button2'
import GenerateTrackers from './GenerateTrackers'
import Alert from '@mui/material/Alert'

function objectsDiffer(obj1, obj2) {
  if (!obj1 && !obj2) {
    return
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return true
  }

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (!obj2.hasOwnProperty(key)) {
        return true
      }

      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        if (objectsDiffer(obj1[key], obj2[key])) {
          return true
        }
      } else if (obj1[key] !== obj2[key]) {
        return true
      }
    }
  }

  return false
}

const StyleSettings = () => {
  const menuKeys = Object.keys(menuData)
  const [selectedKey, setSelectedKey] = useState(menuKeys[0])
  const [valueData, setValueData] = useState({})
  const [dbValueData, setDbValueData] = useState({})
  const [loading, setLoading] = useState(true)
  const [showGenerateTrackers, setShowGenerateTrackers] = useState(false)
  const styleSettings = useSelector(
    (state) => state?.analyticList?.styleSettings
  )
  const dispatch = useDispatch()
  const location = useLocation()
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const isUserViewer = checkUserRoleViewer()
  const [selectedKeyWords, setSelectedKeyWords] = useState([])
  const [message, setMessage] = useState(null)
  const [addNewKeyword, setAddNewKeyword] = useState(false)
  const [suggestionKeywords, setSuggestionKeywords] = useState([])
  const keywords = [
    'Full-Service Leadership',
    'Integrated Expertise',
    'Comprehensive Project Management',
    'Innovative Problem-Solving',
    'Client-Centric Excellence',
    'Tailored & Resilient Solutions',
    'Reliable Execution',
    'Proven Track Record',
    'Collaborative Partnerships',
    'Agile Project Delivery',
    'Value-Driven Solutions',
    'On-Time Excellence',
    'Sustainability Commitment',
    'End-to-End Project Support',
    'Regulatory and Industry Expertise',
    'Future-Ready Strategies',
    'Proactive Risk Management',
    'Trusted Integrity',
    'Long-Term Value Creation',
    'Holistic Planning Approach',
    'Cross-Sector Versatility',
    'Environmental Leadership',
    'Enhanced Safety Standards',
    'Nationwide Recognition',
    'Community Transformation',
    'Federal and Defense Proficiency',
    'Transparent Practices'
  ]

  useEffect(() => {
    if (_.isEmpty(valueData)) {
      if (!_.isEmpty(styleSettings)) {
        setValueData(styleSettings)
        setSelectedKeyWords(styleSettings?.brand_message?.topics ?? [])
        // console.log('message', styleSettings)
        setMessage(styleSettings?.brand_message?.message ?? null)
        setSuggestionKeywords(
          styleSettings?.brand_message?.suggestions ?? keywords
        )
        setDbValueData(styleSettings)
        setLoading(false)
      } else if (styleSettings !== null && _.isEmpty(styleSettings)) {
        setValueData(menuDefaultValue)
        setDbValueData(menuDefaultValue)
        // console.log('updated settings')
        if (!isUserViewer) {
          dispatch(
            updateStyleSettings({ settings: _.cloneDeep(menuDefaultValue) })
          )
        }
        setLoading(false)
      }
    }
  }, [styleSettings])

  useEffect(() => {
    if (
      !_.isEmpty(valueDataRef.current) &&
      !_.isEmpty(dbValueDataRef.current)
    ) {
      const diff = objectsDiffer(valueData, dbValueData)
      const timer = setTimeout(() => {
        if (valueData && dbValueData && diff) {
          if (!isUserViewer) {
            dispatch(updateStyleSettings({ settings: valueData }))
          }
          setDbValueData(valueData)
        }
      }, 3000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [valueData])

  const valueDataRef = useRef(valueData)
  const dbValueDataRef = useRef(dbValueData)

  useEffect(() => {
    valueDataRef.current = valueData
    dbValueDataRef.current = dbValueData
  }, [valueData, dbValueData])

  const handleUnmount = () => {
    if (
      !_.isEmpty(valueDataRef.current) &&
      !_.isEmpty(dbValueDataRef.current)
    ) {
      const diff = objectsDiffer(valueDataRef.current, dbValueDataRef.current)
      if (valueDataRef.current && diff && !isUserViewer) {
        dispatch(updateStyleSettings({ settings: valueDataRef.current }))
      }
    }
  }

  useEffect(() => {
    const alert = (event) => {
      // event.preventDefault()
      handleUnmount()
    }
    window.addEventListener('beforeunload', alert)
    return () => {
      window.removeEventListener('beforeunload', alert)
      handleUnmount()
    }
  }, [location])

  const handleSaveTracker = (trackers, message) => {
    let newData = _.cloneDeep(valueData)

    newData = {
      ...newData,
      brand_message: {
        topics: trackers,
        message,
        suggestions: suggestionKeywords
      }
    }

    setValueData(newData)
  }

  return loading ? (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className="search-loader-container"
    >
      <Loader loading={loading} caption={'fetching your style settings'} />
    </Grid>
  ) : (
    <Grid container>
      <Grid item xs={0} md={1} lg={2} xl={2}></Grid>
      <Grid item xs={12} md={10} lg={8} xl={8} sx={{ height: '100%' }}>
        {isUserViewer && (
          <Alert severity="info">
            You cannot edit these settings. Please contact your admin for
            assistance.
          </Alert>
        )}
        <Accordion
          // key={index}
          defaultExpanded
          style={{ padding: '5px', margin: '0 auto' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ maxHeight: '50px' }}
          >
            <Box style={{ fontSize: '24px', fontWeight: '600' }}>
              Brand Message
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: '0', display: 'block' }}>
            <div>
              <div className="flex items-start justify-between">
                <p
                  className="m-0 mb-2 text-xs text-grey-600"
                  style={{
                    width: '70%'
                  }}
                >
                  A strong brand message clearly communicates a company's
                  values, purpose, and unique value proposition, helping to
                  build trust and differentiate it in a competitive market.
                </p>
                <Button2
                  primary
                  onClick={() => {
                    trackEvent(
                      mixpanelEvents.GENERATE_TRACKER_CLICKED,
                      'CLICK',
                      {},
                      {}
                    )
                    setShowGenerateTrackers(true)
                  }}
                  disabled={isUserViewer}
                >
                  <MagicWand className="size-4" />
                  Generate Trackers
                </Button2>
              </div>

              {message && (
                <div
                  className="p-2 px-4 rounded bg-grey-50"
                  style={{
                    border: '  0.75px solid var(--grey-200)'
                  }}
                >
                  <p
                    onClick={() => {
                      setShowMessage(!showMessage)
                    }}
                    className="flex items-center m-0 text-sm cursor-pointer"
                  >
                    Message
                    <ChevronDownIcon
                      className={`size-3 ml-1 ${
                        showMessage ? '' : 'rotate-270'
                      }`}
                    />
                  </p>
                  {showMessage && (
                    <div className="mt-1 mb-2 text-xs">{message}</div>
                  )}
                </div>
              )}

              {showGenerateTrackers && (
                <GenerateTrackers
                  isOpen={showGenerateTrackers}
                  onClose={() => {
                    setShowGenerateTrackers(false)
                  }}
                  onSave={(trackers, message) => {
                    const newTrackers = [...selectedKeyWords]

                    trackEvent(
                      mixpanelEvents.ADD_GENERATED_TRACKER,
                      'CLICK',
                      {},
                      {}
                    )
                    trackers.forEach((tracker) => {
                      if (!newTrackers.includes(tracker)) {
                        newTrackers.push(tracker)
                      }
                    })

                    setMessage(message)
                    setSelectedKeyWords(newTrackers)
                    handleSaveTracker(newTrackers, message)
                    setShowGenerateTrackers(false)
                  }}
                />
              )}
              <div className="p-2 px-4 rounded">
                <label className="mt-4 text-sm font-medium">
                  Brand Trackers
                </label>
                <div className="flex flex-wrap items-center gap-2 mt-1">
                  {selectedKeyWords.map((keyword, index) => {
                    return (
                      <div
                        key={index}
                        className="flex items-center p-1 px-3 text-xs rounded-full cursor-pointer bg-grey-300"
                        style={{
                          border: '0.75px solid var(--grey-200)'
                        }}
                        onClick={() => {
                          const finalTrackers = selectedKeyWords.filter(
                            (key) => key !== keyword
                          )
                          setSelectedKeyWords(
                            finalTrackers.length > 0 ? finalTrackers : []
                          )

                          handleSaveTracker(finalTrackers, message)
                        }}
                      >
                        {keyword}
                        {!isUserViewer && <CrossIcon className="ml-1 size-3" />}
                      </div>
                    )
                  })}
                  {addNewKeyword ? (
                    <div
                      className="flex p-1 text-center rounded-lg bg-grey-50 text-xxs"
                      style={{
                        border: '0.75px solid var(--grey-200)'
                      }}
                      // onClick={() => {
                      //   setAddNewKeyword(!addNewKeyword)
                      // }}
                    >
                      <input
                        type="text"
                        tabIndex={0}
                        autoFocus
                        id="keywordInput"
                        className="ml-2 bg-transparent outline-none input-base"
                      />
                      <Button2
                        secondary
                        noOutline
                        onClick={() => {
                          setAddNewKeyword(!addNewKeyword)
                        }}
                      >
                        <CrossIcon className="size-3" />
                      </Button2>
                      <Button2
                        primary
                        style={{
                          padding: '0.5rem 10px'
                        }}
                        onClick={() => {
                          if (
                            document.getElementById('keywordInput').value
                              .length < 4
                          ) {
                            return
                          }

                          setSelectedKeyWords([
                            ...selectedKeyWords,
                            document.getElementById('keywordInput').value
                          ])

                          handleSaveTracker(
                            [
                              ...selectedKeyWords,
                              document.getElementById('keywordInput').value
                            ],
                            message
                          )

                          document.getElementById('keywordInput').value = ''
                        }}
                      >
                        <PlusIcon className="size-3" />
                      </Button2>
                    </div>
                  ) : (
                    <>
                      {isUserViewer ? null : (
                        <div
                          className="p-1 px-4 text-center rounded-full cursor-pointer bg-grey-50 text-xxs"
                          style={{
                            border: '0.75px solid var(--grey-200)'
                          }}
                          onClick={() => {
                            trackEvent(
                              mixpanelEvents.ADD_NEW_TRACKER,
                              'CLICK',
                              {},
                              {}
                            )
                            setAddNewKeyword(!addNewKeyword)
                            setShowSuggestions(true)
                          }}
                        >
                          + add
                        </div>
                      )}
                    </>
                  )}
                </div>
                {!isUserViewer && (
                  <p
                    className="flex items-center m-0 mt-3 mb-2 cursor-pointer text-xxs text-grey-600"
                    onClick={() => {
                      setShowSuggestions(!showSuggestions)
                    }}
                  >
                    Suggestions{' '}
                    <ChevronDownIcon
                      className={`size-3 ml-1 ${
                        showSuggestions ? '' : 'rotate-270'
                      }`}
                    />
                  </p>
                )}
                {showSuggestions && !isUserViewer && (
                  <div className="flex flex-wrap items-center gap-2">
                    {suggestionKeywords.map((keyword, index) => {
                      if (selectedKeyWords.includes(keyword)) {
                        return null
                      }

                      return (
                        <div
                          key={index}
                          className="p-1 px-3 text-xs rounded-full cursor-pointer bg-grey-50"
                          style={{
                            border: '0.75px solid var(--grey-200)'
                          }}
                          onClick={() => {
                            setSelectedKeyWords([...selectedKeyWords, keyword])

                            trackEvent(
                              mixpanelEvents.ADD_EXISTING_TRACKER,
                              'CLICK',
                              {
                                keyword
                              },
                              {}
                            )

                            handleSaveTracker(
                              [...selectedKeyWords, keyword],
                              message
                            )
                          }}
                        >
                          {keyword}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {menuKeys.map((key, index) => (
          <Accordion
            key={index}
            defaultExpanded
            style={{ padding: '5px', margin: '0 auto' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ maxHeight: '50px' }}
            >
              <Box style={{ fontSize: '24px', fontWeight: '600' }}>
                {_.startCase(key)}
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: '0', display: 'block' }}>
              <Box>
                <RenderSetting
                  indexKey={key}
                  data={menuData[key]}
                  valueData={valueData}
                  setValueData={(value) => {
                    setValueData(value)
                  }}
                  editingDisabled={isUserViewer}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      <Grid item xs={0} md={1} lg={2} xl={2}></Grid>
    </Grid>
  )
}

const RenderSetting = ({
  data,
  indexKey = '',
  valueData = {},
  setValueData = () => {},
  editingDisabled = false
}) => {
  const { title = '', description = '', options = [] } = data
  return (
    data && (
      <Container>
        <SectionFixed>
          <Box sx={{}}>
            {/* <Box sx={{
                        fontSize: "24px",
                        fontWeight: "600",
                    }}>
                        {title}
                    </Box> */}
            {description !== '' && (
              <Box
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  fontStyle: 'italic',
                  marginTop: '15px'
                }}
              >
                {description}
              </Box>
            )}
          </Box>
        </SectionFixed>
        <Section overFlow>
          <RenderOptions
            options={options}
            indexKey={indexKey}
            valueData={valueData}
            setValueData={setValueData}
            editingDisabled={editingDisabled}
          />
        </Section>
      </Container>
    )
  )
}

const RenderOptions = ({
  options,
  indexKey = '',
  valueData = {},
  setValueData = () => {},
  editingDisabled = false
}) => {
  const [addInputText, setAddInputText] = useState('')
  const [showTableInput, setShowTableInput] = useState(false)
  const [tableInput, setTableInput] = useState({})
  const [editValueIndex, setEditValueIndex] = useState(null)
  const [editValueInput, setEditValueInput] = useState({})
  const handleChange = (value, key) => {
    const newData = _.cloneDeep(valueData)
    const existingData = _.get(newData, key)
    const updatedData = _.assign({}, existingData, value)
    _.set(newData, key, updatedData)
    setValueData(newData)
  }
  const handleAddInputAdd = (value, key, label, type, category) => {
    if (addInputText) {
      handleChange(
        {
          value: [...value, addInputText],
          label,
          type,
          category
        },
        key
      )
      setAddInputText('')
    }
  }
  const handleAddInputRemove = (value, key, index, label, type, category) => {
    const newValue = value.filter((item, i) => i !== index)
    handleChange(
      {
        value: [...newValue],
        label,
        type,
        category
      },
      key
    )
  }

  const handleTableInputReset = () => {
    setTableInput({
      orgText: '',
      corrText: ''
    })
    setShowTableInput(false)
  }

  const handleTableInputAdd = (value, key, label, type, rule, category) => {
    if (category === 'wordy_phrase') {
      trackEvent(
        mixpanelEvents.ANALYTICS_STYLE_GUIDE_WORDY_PHRASES_ADDED,
        'SUCCESS',
        {},
        {}
      )
    } else {
      trackEvent(
        mixpanelEvents.ANALYTICS_STYLE_GUIDE_WORDS_TO_AVOID_ADDED,
        'SUCCESS',
        {},
        {}
      )
    }
    handleChange(
      {
        value: [
          ...value,
          {
            originalText: tableInput.orgText,
            correctedText: tableInput.corrText,
            rule
          }
        ],
        label,
        type,
        category
      },
      key
    )
    handleTableInputReset()
  }

  const handleTableRemove = (index, value, key, label, type, category) => {
    const newValue = value.filter((item, i) => i !== index)
    handleChange(
      {
        value: [...newValue],
        label,
        type,
        category
      },
      key
    )
  }

  const getDefinedValue = (value, type, defaultValue = '') => {
    if (type === 'switch') {
      return value || false
    }
    if (type === 'checkbox') {
      return value || false
    }
    if (type === 'select') {
      return value || defaultValue
    }
    if (type === 'date') {
      return value || new Date()
    }
    if (type === 'text') {
      return value || ''
    }
    if (type === 'table') {
      return value || []
    }
    if (type === 'addinput') {
      return value || []
    }
    return value || ''
  }

  const handleCSVUpload = (event, value, key, label, type, rule, category) => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = (e) => {
      const content = e.target.result
      const lines = content.split('\n')
      const headers = lines[0].split(',')
      const data = []
      for (let i = 1; i < lines.length; i++) {
        const line = lines[i].split(',')
        data.push({
          originalText: line[0].trim(),
          correctedText: line[1].trim(),
          rule
        })
      }
      handleChange(
        {
          value: [...data, ...value],
          label,
          type,
          category
        },
        key
      )
    }
    reader.readAsText(file)
  }

  const handleCSVDownload = (value, rule) => {
    if (value.length === 0) {
      toast.error('No CSV data available to download.')
      return
    }
    const csvContent = 'data:text/csv;charset=utf-8,' + convertToCsv(value)
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${rule}_${new Date().getTime()}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const convertToCsv = (data) => {
    const headers = Object.keys(data[0])
    const rows = data.map((row) =>
      headers.map((header) => row[header] || '').join(',')
    )
    return [headers.join(','), ...rows].join('\n')
  }

  const handleUploadButtonClick = (
    value,
    secondaryKey,
    label,
    type,
    rule,
    category
  ) => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.csv'
    fileInput.addEventListener('change', (event) =>
      handleCSVUpload(event, value, secondaryKey, label, type, rule, category)
    )
    fileInput.click()
  }

  const handleOptionClick = (
    option,
    index,
    value,
    secondaryKey,
    label,
    type,
    category
  ) => {
    switch (option) {
      case 'Remove':
        if (category === 'wordy_phrase') {
          trackEvent(
            mixpanelEvents.ANALYTICS_STYLE_GUIDE_WORDY_PHRASES_DELETED,
            'SUCCESS',
            {},
            { details: value[index] }
          )
        } else {
          trackEvent(
            mixpanelEvents.ANALYTICS_STYLE_GUIDE_WORDS_TO_AVOID_DELETED,
            'SUCCESS',
            {},
            { details: value[index] }
          )
        }
        handleTableRemove(index, value, secondaryKey, label, type, category)
        break
      case 'Edit':
        handleTableEdit(index, value)
        break
    }
  }

  const handleTableEdit = (index, value) => {
    setEditValueIndex(index)
    setEditValueInput({ ...value[index] })
  }

  const handleCloseTableEdit = () => {
    setEditValueIndex(null)
    setEditValueInput({})
  }

  const handleTableInputEdit = (value, key, label, type, category) => {
    const newValue = _.cloneDeep(value)
    newValue[editValueIndex] = editValueInput
    handleChange(
      {
        value: newValue,
        label,
        type,
        category
      },
      key
    )
    handleCloseTableEdit()
  }

  return (
    options && (
      <Container
        sx={{
          padding: '10px',
          height: 'calc(100% - 20px)',
          display: 'flex',
          flexFlow: 'column'
        }}
      >
        {options.map((option, index) => {
          const {
            label = '',
            description = '',
            defaultSelectValue = '',
            type = '',
            options = [],
            example = '',
            startProps = '',
            endProps = '',
            category = ''
          } = option
          const secondaryKey = `${indexKey}[${index}]`
          const value = getDefinedValue(
            _.get(valueData, secondaryKey + '.value'),
            type,
            defaultSelectValue
          )
          if (type === 'switch') {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  marginTop: '0px',
                  marginBottom: '15px'
                }}
              >
                <Switch
                  checked={value}
                  disabled={editingDisabled}
                  onChange={(e) =>
                    handleChange(
                      {
                        value: e.target.checked,
                        type,
                        label,
                        category
                      },
                      secondaryKey
                    )
                  }
                />
                <Box
                  sx={{
                    fontSize: '16px',
                    fontWeight: '700',
                    marginTop: '9px'
                  }}
                >
                  {startProps && (
                    <RenderOptions
                      options={startProps}
                      indexKey={secondaryKey}
                      valueData={valueData}
                      setValueData={setValueData}
                      editingDisabled={editingDisabled}
                    />
                  )}
                  {label && label}
                  {endProps && (
                    <RenderOptions
                      options={endProps}
                      indexKey={secondaryKey}
                      valueData={valueData}
                      setValueData={setValueData}
                      editingDisabled={editingDisabled}
                    />
                  )}
                  {description && (
                    <Box
                      sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        maxWidth: '500px',
                        marginTop: '10px'
                      }}
                    >
                      {description}
                    </Box>
                  )}
                  {example && (
                    <Box
                      sx={{
                        marginTop: '10px',
                        fontSize: '13px',
                        fontWeight: '400',
                        maxWidth: '500px',
                        fontStyle: 'italic'
                      }}
                    >
                      <Box
                        component={'span'}
                        sx={{
                          fontWeight: '700'
                        }}
                      >
                        Example:&nbsp;
                      </Box>
                      {example}
                    </Box>
                  )}
                  {options?.length > 0 && (
                    <Box>
                      <RenderOptions
                        options={options}
                        indexKey={secondaryKey}
                        valueData={valueData}
                        setValueData={setValueData}
                        editingDisabled={editingDisabled}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )
          } else if (type === 'checkbox') {
            return (
              <Box key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: '15px',
                    fontWeight: '400',
                    fontSize: '13px',
                    alignItems: 'center'
                  }}
                >
                  <Checkbox
                    sx={{ padding: '0px' }}
                    checked={value}
                    disabled={editingDisabled}
                    onChange={(e) =>
                      handleChange(
                        {
                          value: e.target.checked,
                          type,
                          label,
                          category
                        },
                        secondaryKey
                      )
                    }
                  />
                  {startProps && (
                    <RenderOptions
                      options={startProps}
                      indexKey={secondaryKey}
                      valueData={valueData}
                      setValueData={setValueData}
                      editingDisabled={editingDisabled}
                    />
                  )}
                  {label && label}
                  {endProps && (
                    <RenderOptions
                      options={endProps}
                      indexKey={secondaryKey}
                      valueData={valueData}
                      setValueData={setValueData}
                      editingDisabled={editingDisabled}
                    />
                  )}
                </Box>
                {description && (
                  <Box
                    sx={{
                      fontSize: '12px',
                      fontWeight: '400',
                      maxWidth: '500px',
                      marginTop: '10px'
                    }}
                  >
                    {description}
                  </Box>
                )}
                {example && (
                  <Box
                    sx={{
                      marginTop: '10px',
                      fontSize: '13px',
                      fontWeight: '400',
                      maxWidth: '500px',
                      fontStyle: 'italic'
                    }}
                  >
                    <Box
                      component={'span'}
                      sx={{
                        fontWeight: '700'
                      }}
                    >
                      Example:&nbsp;
                    </Box>
                    {example}
                  </Box>
                )}
                {options?.length > 0 && (
                  <Box>
                    <RenderOptions
                      options={options}
                      indexKey={secondaryKey}
                      valueData={valueData}
                      setValueData={setValueData}
                      editingDisabled={editingDisabled}
                    />
                  </Box>
                )}
              </Box>
            )
          } else if (type === 'title') {
            return (
              <Box key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: '15px'
                  }}
                >
                  {startProps && (
                    <RenderOptions
                      options={startProps}
                      indexKey={secondaryKey}
                      valueData={valueData}
                      setValueData={setValueData}
                      editingDisabled={editingDisabled}
                    />
                  )}
                  {label && label}
                  {endProps && (
                    <RenderOptions
                      options={endProps}
                      indexKey={secondaryKey}
                      valueData={valueData}
                      setValueData={setValueData}
                      editingDisabled={editingDisabled}
                    />
                  )}
                </Box>
                {description && (
                  <Box
                    sx={{
                      fontSize: '12px',
                      fontWeight: '400',
                      maxWidth: '500px',
                      marginTop: '10px'
                    }}
                  >
                    {description}
                  </Box>
                )}
                {example && (
                  <Box
                    sx={{
                      marginTop: '10px',
                      fontSize: '13px',
                      fontWeight: '400',
                      maxWidth: '500px',
                      fontStyle: 'italic'
                    }}
                  >
                    <Box
                      component={'span'}
                      sx={{
                        fontWeight: '700'
                      }}
                    >
                      Example:&nbsp;
                    </Box>
                    {example}
                  </Box>
                )}
                {options?.length > 0 && (
                  <Box>
                    <RenderOptions
                      options={options}
                      indexKey={secondaryKey}
                      valueData={valueData}
                      setValueData={setValueData}
                      editingDisabled={editingDisabled}
                    />
                  </Box>
                )}
              </Box>
            )
          } else if (type === 'select') {
            const { selectOptions } = option
            return (
              <Box key={index}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  {(startProps || label || endProps) && (
                    <Box
                      sx={{
                        display: 'flex',
                        marginTop: '15px'
                      }}
                    >
                      {startProps && (
                        <RenderOptions
                          options={startProps}
                          indexKey={secondaryKey}
                          valueData={valueData}
                          setValueData={setValueData}
                          editingDisabled={editingDisabled}
                        />
                      )}
                      {label && label}
                      {endProps && (
                        <RenderOptions
                          options={endProps}
                          indexKey={secondaryKey}
                          valueData={valueData}
                          setValueData={setValueData}
                          editingDisabled={editingDisabled}
                        />
                      )}
                    </Box>
                  )}
                  <Select
                    inputProps={{
                      style: {
                        padding: '5px'
                      }
                    }}
                    value={value}
                    disabled={editingDisabled}
                    onChange={(e) =>
                      handleChange(
                        {
                          value: e.target.value,
                          type,
                          label,
                          category
                        },
                        secondaryKey
                      )
                    }
                  >
                    {selectOptions.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {description && (
                    <Box
                      sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        maxWidth: '500px',
                        marginTop: '10px'
                      }}
                    >
                      {description}
                    </Box>
                  )}
                  {example && (
                    <Box
                      sx={{
                        marginTop: '10px',
                        fontSize: '13px',
                        fontWeight: '400',
                        maxWidth: '500px',
                        fontStyle: 'italic'
                      }}
                    >
                      <Box
                        component={'span'}
                        sx={{
                          fontWeight: '700'
                        }}
                      >
                        Example:&nbsp;
                      </Box>
                      {example}
                    </Box>
                  )}
                  {options?.length > 0 && (
                    <Box>
                      <RenderOptions
                        options={options}
                        indexKey={secondaryKey}
                        valueData={valueData}
                        setValueData={setValueData}
                        editingDisabled={editingDisabled}
                      />
                    </Box>
                  )}
                </FormControl>
              </Box>
            )
          } else if (type === 'addinput') {
            const { inputPlaceholder, buttonLabel } = option
            return (
              <Box key={index}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <Box
                    sx={{
                      display: 'flex',
                      marginTop: '15px'
                    }}
                  >
                    {startProps && (
                      <RenderOptions
                        options={startProps}
                        indexKey={secondaryKey}
                        valueData={valueData}
                        setValueData={setValueData}
                        editingDisabled={editingDisabled}
                      />
                    )}
                    {label && label}
                    {endProps && (
                      <RenderOptions
                        options={endProps}
                        indexKey={secondaryKey}
                        valueData={valueData}
                        setValueData={setValueData}
                        editingDisabled={editingDisabled}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <TextField
                      variant="outlined"
                      helperText={null}
                      disabled={editingDisabled}
                      inputProps={{
                        style: {
                          padding: '5px',
                          minWidth: '250px'
                        }
                      }}
                      placeholder={inputPlaceholder}
                      value={addInputText}
                      onChange={(e) => setAddInputText(e.target.value)}
                    />
                    <Button
                      sx={{ marginLeft: '10px', padding: '3px' }}
                      variant="contained"
                      onClick={() =>
                        handleAddInputAdd(
                          value,
                          secondaryKey,
                          label,
                          type,
                          category
                        )
                      }
                      disabled={editingDisabled}
                    >
                      {buttonLabel}
                    </Button>
                  </Box>
                  {_.isArray(value) && (
                    <Box
                      sx={{
                        fontSize: '13px',
                        fontWeight: '500',
                        maxWidth: '500px',
                        fontStyle: 'italic',
                        marginTop: '10px'
                      }}
                    >
                      {value.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px 5px',
                            borderBottom: '1px solid #949494',
                            borderTop: index === 0 ? '1px solid #949494' : ''
                          }}
                        >
                          <Box>&bull; {item}</Box>
                          <Box>
                            <IconButton
                              sx={{ padding: 0 }}
                              disableRipple
                              disabled={editingDisabled}
                              onClick={() =>
                                handleAddInputRemove(
                                  value,
                                  secondaryKey,
                                  index,
                                  label,
                                  type,
                                  category
                                )
                              }
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  )}
                  {description && (
                    <Box
                      sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        maxWidth: '500px',
                        marginTop: '10px'
                      }}
                    >
                      {description}
                    </Box>
                  )}
                  {example && (
                    <Box
                      sx={{
                        marginTop: '10px',
                        fontSize: '13px',
                        fontWeight: '400',
                        maxWidth: '500px',
                        fontStyle: 'italic'
                      }}
                    >
                      <Box
                        component={'span'}
                        sx={{
                          fontWeight: '700'
                        }}
                      >
                        Example:&nbsp;
                      </Box>
                      {example}
                    </Box>
                  )}
                  {options?.length > 0 && (
                    <Box>
                      <RenderOptions
                        options={options}
                        indexKey={secondaryKey}
                        valueData={valueData}
                        setValueData={setValueData}
                        editingDisabled={editingDisabled}
                      />
                    </Box>
                  )}
                </FormControl>
              </Box>
            )
          } else if (type === 'tableinput') {
            const { rule } = option
            return (
              <Fragment key={index}>
                <SectionFixed>
                  <Box
                    sx={{
                      display: 'flex',
                      marginTop: '15px'
                    }}
                  >
                    {startProps && (
                      <RenderOptions
                        options={startProps}
                        indexKey={secondaryKey}
                        valueData={valueData}
                        setValueData={setValueData}
                        editingDisabled={editingDisabled}
                      />
                    )}
                    {label && label}
                    {endProps && (
                      <RenderOptions
                        options={endProps}
                        indexKey={secondaryKey}
                        valueData={valueData}
                        setValueData={setValueData}
                        editingDisabled={editingDisabled}
                      />
                    )}
                  </Box>
                  {description && (
                    <Box
                      sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        maxWidth: '500px',
                        marginTop: '10px'
                      }}
                    >
                      {description}
                    </Box>
                  )}
                  {example && (
                    <Box
                      sx={{
                        marginTop: '10px',
                        fontSize: '13px',
                        fontWeight: '400',
                        maxWidth: '500px',
                        fontStyle: 'italic'
                      }}
                    >
                      <Box
                        component={'span'}
                        sx={{
                          fontWeight: '700'
                        }}
                      >
                        Example:&nbsp;
                      </Box>
                      {example}
                    </Box>
                  )}
                  <Box
                    sx={{
                      textAlign: 'end',
                      marginBottom: '15px'
                    }}
                  >
                    <Button
                      sx={{ marginLeft: '10px', padding: '5px' }}
                      variant="contained"
                      disabled={editingDisabled}
                      onClick={() =>
                        handleUploadButtonClick(
                          value,
                          secondaryKey,
                          label,
                          type,
                          rule,
                          category
                        )
                      }
                    >
                      Import CSV
                    </Button>
                    <Button
                      sx={{ marginLeft: '10px', padding: '5px' }}
                      variant="contained"
                      onClick={() => handleCSVDownload(value, rule)}
                    >
                      Export CSV
                    </Button>
                  </Box>
                </SectionFixed>
                <Section overFlow>
                  <Box sx={{ height: 'calc(100% - 20px)', padding: '10px' }}>
                    <Table stickyHeader sx={{ minWidth: 650 }}>
                      <TableHead
                        sx={{
                          th: {
                            fontWeight: 'bold',
                            width: '30%'
                          }
                        }}
                      >
                        <TableRow>
                          <TableCell>Original Text</TableCell>
                          <TableCell>Suggested Text</TableCell>
                          <TableCell>Rule</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_.isArray(value) &&
                          value.map((row, index) => {
                            return editValueIndex !== null &&
                              editValueIndex === index ? (
                              <>
                                <TableCell>
                                  <TextField
                                    inputProps={{
                                      style: {
                                        padding: '7px'
                                      }
                                    }}
                                    variant="outlined"
                                    value={editValueInput?.originalText}
                                    onChange={(e) =>
                                      setEditValueInput({
                                        ...editValueInput,
                                        originalText: e.target.value
                                      })
                                    }
                                    disabled={editingDisabled}
                                    helperText={null}
                                    placeholder="Original Text"
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    variant="outlined"
                                    helperText={null}
                                    value={editValueInput?.correctedText}
                                    onChange={(e) =>
                                      setEditValueInput({
                                        ...editValueInput,
                                        correctedText: e.target.value
                                      })
                                    }
                                    disabled={editingDisabled}
                                    placeholder="Suggested Text"
                                    inputProps={{
                                      style: {
                                        padding: '7px'
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Box
                                    component={'span'}
                                    sx={{
                                      borderRadius: '4px',
                                      padding: '10px 5px',
                                      backgroundColor: '#DBEAFF'
                                    }}
                                  >
                                    {rule}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{ width: '20px', padding: '16px 0px' }}
                                >
                                  <IconButton
                                    sx={{ padding: '0px' }}
                                    disabled={editingDisabled}
                                    onClick={() =>
                                      handleTableInputEdit(
                                        value,
                                        secondaryKey,
                                        label,
                                        type,
                                        category
                                      )
                                    }
                                  >
                                    <DoneIcon />
                                  </IconButton>
                                  <IconButton
                                    sx={{ padding: '0px', marginLeft: '5px' }}
                                    onClick={() => handleCloseTableEdit()}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </TableCell>
                              </>
                            ) : (
                              <TableRow
                                key={index}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell>{row.originalText}</TableCell>
                                <TableCell>{row.correctedText}</TableCell>
                                <TableCell>
                                  <Box
                                    component={'span'}
                                    sx={{
                                      borderRadius: '4px',
                                      padding: '10px 5px',
                                      backgroundColor: '#DBEAFF'
                                    }}
                                  >
                                    {row.rule}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{ width: '20px', padding: '16px 0px' }}
                                >
                                  <IconButton
                                    sx={{ padding: '0px' }}
                                    disabled={editingDisabled}
                                    onClick={() =>
                                      handleOptionClick(
                                        'Edit',
                                        index,
                                        value,
                                        secondaryKey,
                                        label,
                                        type,
                                        category
                                      )
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    sx={{ padding: '0px', marginLeft: '10px' }}
                                    disabled={editingDisabled}
                                    onClick={() =>
                                      handleOptionClick(
                                        'Remove',
                                        index,
                                        value,
                                        secondaryKey,
                                        label,
                                        type,
                                        category
                                      )
                                    }
                                  >
                                    <DeleteForeverIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        <TableRow>
                          {showTableInput ? (
                            <>
                              <TableCell>
                                <TextField
                                  inputProps={{
                                    style: {
                                      padding: '7px'
                                    }
                                  }}
                                  variant="outlined"
                                  value={tableInput?.orgText}
                                  onChange={(e) =>
                                    setTableInput({
                                      ...tableInput,
                                      orgText: e.target.value
                                    })
                                  }
                                  helperText={null}
                                  placeholder="Original Text"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  variant="outlined"
                                  helperText={null}
                                  value={tableInput?.corrText}
                                  disabled={editingDisabled}
                                  onChange={(e) =>
                                    setTableInput({
                                      ...tableInput,
                                      corrText: e.target.value
                                    })
                                  }
                                  placeholder="Suggested Text"
                                  inputProps={{
                                    style: {
                                      padding: '7px'
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Box
                                  component={'span'}
                                  sx={{
                                    borderRadius: '4px',
                                    padding: '10px 5px',
                                    backgroundColor: '#DBEAFF'
                                  }}
                                >
                                  {rule}
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{ width: '20px', padding: '16px 0px' }}
                              >
                                <IconButton
                                  sx={{ padding: '0px' }}
                                  disabled={editingDisabled}
                                  onClick={() =>
                                    handleTableInputAdd(
                                      value,
                                      secondaryKey,
                                      label,
                                      type,
                                      rule,
                                      category
                                    )
                                  }
                                >
                                  <DoneIcon />
                                </IconButton>
                                <IconButton
                                  sx={{ padding: '0px', marginLeft: '5px' }}
                                  disabled={editingDisabled}
                                  onClick={() => handleTableInputReset()}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                            </>
                          ) : (
                            <TableCell colSpan={4}>
                              <IconButton
                                sx={{ padding: '0px' }}
                                disabled={editingDisabled}
                                onClick={() => setShowTableInput(true)}
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                    {options?.length > 0 && (
                      <Box>
                        <RenderOptions
                          options={options}
                          indexKey={secondaryKey}
                          valueData={valueData}
                          setValueData={setValueData}
                          editingDisabled={editingDisabled}
                        />
                      </Box>
                    )}
                  </Box>
                </Section>
              </Fragment>
            )
          }
          return <></>
        })}
      </Container>
    )
  )
}

export default StyleSettings
