import React, { useEffect, useState } from 'react'
import { getColorsForInitials } from '../../utils/String'
import { Fade, Popover, Tooltip, tooltipClasses } from '@mui/material'
import Button2 from '../../components/Button/Button2'
import SearchNew from '../ListFilter/SearchNew'
import { renderURL } from '../../utils/TagCenter'
import moment from 'moment'
import { styled } from '@mui/styles'
import { checkUserRoleViewer } from '../../utils/User'
import CircularProgress from '@mui/material/CircularProgress'

const TagPills = ({
  tag,
  options,
  onChange,
  isMulti,
  isURL,
  isDate,
  showAdd = false,
  onCreate,
  showAllTags
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedOption, setSelectedOptions] = useState(tag)
  const [label, setLabel] = useState(tag?.label)
  const open = Boolean(anchorEl)
  const [tooltipAnchor, setTooltipAnchor] = useState(null)
  const tooltipOpen = Boolean(tooltipAnchor)
  const [openCalender, setOpenCalender] = useState(false)
  const [createTag, setCreateTag] = useState(false)
  const isUserViewer = checkUserRoleViewer()
  const handleClick = (event) => {
    event.stopPropagation()
    if (isUserViewer) {
      return
    }

    if (isDate) {
      setOpenCalender(true)
      return
    }

    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    setSelectedOptions(tag)
    setLabel(tag?.label)
  }, [tag])

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className="">
      {isMulti ? (
        selectedOption?.length > 0 ? (
          <div
            className="flex items-center gap-1"
            onClick={(e) => {
              handleClick(e)
            }}
          >
            {showAllTags ? (
              <div className="flex flex-wrap items-center gap-2">
                {selectedOption.map((option) => {
                  return (
                    <div
                      key={option}
                      className="grid p-1 px-2 overflow-hidden font-medium text-center rounded-full cursor-pointer place-content-center w-fit"
                      style={{
                        // border: '1px solid',
                        borderColor: getColorsForInitials(
                          selectedOption[0].label,
                          true
                        ).text,
                        backgroundColor: isURL
                          ? '#dbeafe'
                          : getColorsForInitials(selectedOption[0].label, true)
                              .bg,
                        color: getColorsForInitials(
                          selectedOption[0].label,
                          true
                        ).text,
                        fontSize: '10px',
                        padding: '2.5px 8px'
                        // minWidth: '19px'
                      }}
                      onClick={(e) => handleClick(e)}
                    >
                      <span
                        className="overflow-hidden text-truncate"
                        style={
                          {
                            // maxWidth: '90px'
                          }
                        }
                      >
                        {isURL ? (
                          <span
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          >
                            {renderURL(option.label, false, '', true)}
                          </span>
                        ) : (
                          option.label
                        )}
                      </span>
                    </div>
                  )
                })}
              </div>
            ) : (
              <HtmlTooltip
                disableFocusListener={!open}
                // disableHoverListener={open}
                title={
                  <div className="flex flex-wrap gap-2 p-2">
                    {selectedOption.map((option) => {
                      return (
                        <div
                          key={option}
                          className="grid p-1 px-2 overflow-hidden font-medium text-center rounded-full place-content-center w-fit"
                          style={{
                            // border: '1px solid',
                            borderColor: getColorsForInitials(
                              selectedOption[0].label,
                              true
                            ).text,
                            backgroundColor: isURL
                              ? '#dbeafe'
                              : getColorsForInitials(
                                  selectedOption[0].label,
                                  true
                                ).bg,
                            color: getColorsForInitials(
                              selectedOption[0].label,
                              true
                            ).text,
                            fontSize: '10px',
                            padding: '2.5px 8px'
                            // minWidth: '19px'
                          }}
                          onClick={(e) => handleClick(e)}
                        >
                          <span
                            className="overflow-hidden text-truncate"
                            style={
                              {
                                // maxWidth: '90px'
                              }
                            }
                          >
                            {isURL ? (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                              >
                                {renderURL(option.label, false, '', true)}
                              </span>
                            ) : (
                              option.label
                            )}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                }
              >
                <div
                  className="grid p-1 px-2 overflow-hidden font-medium text-center rounded-full place-content-center w-fit"
                  style={{
                    // border: '1px solid',
                    borderColor: getColorsForInitials(
                      selectedOption[0].label,
                      true
                    ).text,
                    backgroundColor: isURL
                      ? '#dbeafe'
                      : getColorsForInitials(selectedOption[0].label, true).bg,
                    color: getColorsForInitials(selectedOption[0].label, true)
                      .text,
                    fontSize: '10px',
                    padding: '4px 8px'
                    // minWidth: '19px'
                  }}
                  onClick={(e) => handleClick(e)}
                >
                  <span
                    className="flex overflow-hidden text-truncate"
                    style={{
                      maxWidth: '90px'
                    }}
                  >
                    {isURL ? (
                      <span
                        className=""
                        style={{
                          width: selectedOption.length > 1 ? '78%' : '100%'
                        }}
                      >
                        {renderURL(selectedOption[0].label, false, '', false)}
                      </span>
                    ) : (
                      <span>{selectedOption[0].label}</span>
                    )}
                    {selectedOption.length > 1 && (
                      <span
                        className="grid m-0 ml-1 rounded-full text-xxs size-4 place-content-center"
                        style={{
                          // border: '1px solid',
                          borderColor: getColorsForInitials(
                            selectedOption[0].label,
                            true
                          ).text,
                          backgroundColor: isURL
                            ? '#3b82f6'
                            : getColorsForInitials(
                                selectedOption[0].label,
                                true
                              ).text,
                          color: 'white',
                          fontSize: '10px'
                          // padding: '3px'
                          // minWidth: '19px'
                        }}
                      >
                        +{selectedOption.length - 1}
                      </span>
                    )}
                  </span>
                </div>
              </HtmlTooltip>
            )}
          </div>
        ) : (
          <div
            className="grid p-1 px-2 overflow-hidden font-medium text-center rounded-full place-content-center w-fit"
            style={{
              // border: '1px solid',
              borderColor: getColorsForInitials(label, true).text,
              backgroundColor: getColorsForInitials(label, true).bg,
              color: getColorsForInitials(label, true).text,
              fontSize: '10px',
              padding: '2.5px 8px'
              // minWidth: '19px'
            }}
            onClick={(e) => handleClick(e)}
          >
            <span
              className="overflow-hidden cursor-pointer text-truncate"
              style={{
                maxWidth: '90px'
              }}
            >
              {showAdd ? label || '+ Add' : label}
            </span>
          </div>
        )
      ) : (
        <div
          className="grid p-1 px-2 overflow-hidden font-medium text-center rounded-full place-content-center w-fit"
          style={{
            // border: '1px solid',
            borderColor: getColorsForInitials(label, true).text,
            backgroundColor: isURL
              ? '#dbeafe'
              : getColorsForInitials(label, true).bg,
            color: isURL ? '#3b82f6' : getColorsForInitials(label, true).text,
            fontSize: '10px',
            padding: '2.5px 8px'
            // minWidth: '19px'
          }}
          onClick={(e) => handleClick(e)}
        >
          <span
            className="overflow-hidden cursor-pointer text-truncate"
            style={{
              maxWidth: '90px'
            }}
          >
            {isDate && label
              ? moment(label).format('ll')
              : showAdd
              ? isURL
                ? label
                  ? renderURL(label, false, '', true)
                  : '+ Add'
                : label || '+ Add'
              : isURL
              ? renderURL(label, false, '', true)
              : label}
          </span>
        </div>
      )}

      {open && (
        <TagOptions
          isMulti={isMulti}
          isURL={isURL}
          options={options}
          anchorEl={anchorEl}
          handleClose={handleClose}
          open={open}
          onCreate={onCreate}
          onChange={(value) => {
            console.log('value', value, selectedOption)
            if (JSON.stringify(value) !== JSON.stringify(selectedOption)) {
              onChange(value)
              setSelectedOptions(value)
              setLabel(value?.label)
            }
            handleClose()
          }}
          selectedOption={selectedOption}
        />
      )}
    </div>
  )
}

export default TagPills

const TagOptions = ({
  options,
  anchorEl,
  handleClose,
  open,
  onChange,
  isMulti,
  isURL,
  selectedOption,
  onCreate
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [defaultOptions, setDefaultOptions] = useState(selectedOption ?? [])
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (searchValue) {
      const tempOptions = [...options]
      const filteredOptions2 = tempOptions.filter((option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
      )
      setFilteredOptions(filteredOptions2)
    } else {
      setFilteredOptions(options)
    }
  }, [options, searchValue])

  useEffect(() => {
    if (isMulti) {
      setSelectedOptions(defaultOptions?.map((option) => option.value))
    } else {
      setSelectedOptions([defaultOptions?.value])
    }
  }, [defaultOptions])

  return (
    <Popover
      id="tag-pills"
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        onChange(defaultOptions)
        handleClose()
      }}
      elevation={1}
      TransitionComponent={Fade}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      sx={{
        '&.MuiPopover-root': {
          zIndex: 1350
        },
        '&.MuiMenu-paper': {
          zIndex: 9999,
          boxShadow:
            '3px 2px 16px 2px rgb(117 117 117 / 20%), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
          marginTop: '10px'
        }
      }}
    >
      <div className="px-2 overflow-hidden">
        <SearchNew
          type="text"
          key="SearchInput"
          value={searchValue}
          autoFocus
          onChange={(value) => {
            setSearchValue(value)
          }}
          onClear={() => {
            setSearchValue('')
            setFilteredOptions(options)
          }}
          style={{
            background: 'white',
            marginTop: '5px'
          }}
          inputStyle={{
            background: 'white'
          }}
        />
        <div
          className="flex flex-col gap-1 mt-3 overflow-auto hideScrollbar "
          style={{
            height: '300px'
          }}
        >
          {filteredOptions.map((option) => {
            return (
              <div
                key={option.value}
                className={`hover-bg-grey-50 rounded px-2 cursor-pointer flex items-center gap-2 ${
                  option.synced ? '' : 'cursor-not-allowed bg-grey-300'
                }`}
                style={{
                  padding: '6px 10px'
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (option.synced) {
                    console.log('options change', option, selectedOptions)
                    if (isMulti) {
                      if (selectedOptions.includes(option.value)) {
                        setDefaultOptions(
                          defaultOptions.filter((o) => o.value !== option.value)
                        )
                      } else {
                        setDefaultOptions([...defaultOptions, option])
                      }
                    } else {
                      if (option.value === selectedOptions[0]) {
                        onChange(null)
                      } else {
                        onChange(option)
                      }
                    }
                  }
                }}
              >
                {!option.synced ? (
                  <CircularProgress size={10} />
                ) : (
                  <div
                    style={{
                      height: '10px',
                      width: '10px'
                    }}
                    className={`rounded-sm ${
                      selectedOptions.includes(option.value)
                        ? 'bg-grey-800'
                        : 'bg-grey-200'
                    }`}
                  ></div>
                )}
                <div
                  className="grid p-1 px-2 font-medium rounded-full place-content-center w-fit"
                  style={{
                    // border: '1px solid',
                    borderColor: getColorsForInitials(option.label, true).text,
                    backgroundColor: isURL
                      ? '#dbeafe'
                      : getColorsForInitials(option.label, true).bg,
                    color: getColorsForInitials(option.label, true).text,
                    fontSize: '10px'
                    // minWidth: '19px'
                  }}
                >
                  {isURL ? renderURL(option.label) : option.label}
                </div>
              </div>
            )
          })}

          {filteredOptions.length === 0 && searchValue.length === 0 && (
            <div className="flex flex-col gap-1 text-center text-grey-700">
              <p className="m-0 text-sm">No Tags found. </p>
              <p className="m-0 text-xs">Type Something to create one</p>
            </div>
          )}

          {filteredOptions.length === 0 && searchValue.length > 0 && (
            <Button2
              secondary
              noOutline
              onClick={(e) => {
                e.stopPropagation()
                onCreate(searchValue)
                // handleClose()
              }}
            >
              + Create tag '{searchValue}'
            </Button2>
          )}
        </div>
      </div>
    </Popover>
  )
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    TransitionComponent={Fade}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0',
    maxWidth: '200px',
    backgroundColor: 'white',
    color: 'white',
    textAlign: 'left',
    width: '100%',
    height: '100%',
    border: '1px solid #dadde9',
    boxShadow:
      '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    // border: '1px solid #dadde9',
    gap: '3px',
    display: 'flex',
    flexDirection: 'column'
  }
}))
