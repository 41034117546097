const mixpanelEvents = {
  DASHBOARD_SEARCH_TEXT: 'Dashboard Search Text',
  DASHBOARD_SEARCH_ASSETS: 'Dashboard Search Assets',
  DASHBOARD_SEARCH: 'Dashboard Search',
  DASHBOARD_SEARCH_TYPE_CHAT: 'Dashboard Search Type Chat',
  DASHBOARD_SEARCH_TYPE_TEXT: 'Dashboard Search Type Text',
  DASHBOARD_SEARCH_TYPE_ASSETS: 'Dashboard Search Type Assets',

  DASHBOARD_CHAT: 'Dashboard Chat',
  DASHBOARD_RESULT_COPIED: 'Dashboard Result Copied',
  DASHBOARD_RESULT_PROPOSAL_OPENED: 'Dashboard Result Proposal Opened',
  DASHBOARD_SCREENSHOT_OPENED: 'Dashboard Screenshot Opened',
  DASHBOARD_PROJECT_OPENED: 'Dashboard Project Opened',
  DASHBOARD_PEOPLE_RESUME_OPENED: 'Dashboard People Resume Opened',
  DASHBOARD_FILTERS_APPLIED: 'Dashboard Filters Applied',
  DASHBOARD_RESULT_PROPOSAL_AI_GENERATE_USED:
    'Dashboard Result Proposal AI Generate Used',
  DASHBOARD_RESULT_PROPOSAL_TEXT_COPIED:
    'Dashboard Result Proposal Text Copied',
  DASHBOARD_RESULT_PROPOSAL_CREATE_KNOWLEDGE:
    'Dashboard Result Proposal Create Knowledge',
  DASHBOARD_RESULT_PROJECT_OPENED: 'Dashboard Result Project Opened',
  DASHBOARD_RESULT_SOURCE_DOCUMENT_FILTER:
    'Dashboard Result Source Document Filter',
  DASHBOARD_SEARCH_GENERATIVE_EXPERIENCE_USED:
    'Dashboard Search Generative Experience Used',

  ALL_FILES_VIEWED: 'All Files Viewed',
  PROPOSALS_VIEWED: 'Proposals Viewed from files',
  IMAGES_VIEWED: 'Images Viewed from files',
  VIDEOS_VIEWED: 'Videos Viewed from files',
  DOCUMENTS_VIEWED: 'Documents Viewed from files',
  MY_UPLOADS_VIEWED: 'My Uploads Viewed from files',
  FILES_UPLOAD_CLICKED: 'Files Upload Clicked from files',
  VIEW_MODE_TOGGLED: 'View Mode Toggled from files',
  FILES_ADD_COLUMN: 'Files Add Column from files',

  KNOWLEDGE_TAB_CLICKED: 'Knowledge Tab Clicked',
  KNOWLEDGE_CONTENT_OPENED: 'Knowledge Content Opened',
  KNOWLEDGE_CONTENT_CREATED: 'Knowledge Content Created',
  KNOWLEDGE_CONTENT_UPDATED: 'Knowledge Content Updated',
  KNOWLEDGE_CONTENT_COPIED: 'Knowledge Content Copied',
  KNOWLEDGE_CONTENT_AI_GENERATE_USED: 'Knowledge Content AI Generate Used',
  KNOWLEDGE_CONTENT_DELETED: 'Knowledge Content Deleted',

  ASSETS_TAB_CLICKED: 'Assets Tab Clicked',
  ASSET_OPENED: 'Asset Opened',
  ASSET_DOWNLOAD: 'Asset Download',
  ASSET_UPLOAD: 'Asset Upload',
  ASSET_ADDED_TAG: 'Asset Added Tag', // Not added this event
  ASSET_DELETED_TAG: 'Asset Deleted Tag', // Not added this event
  ASSET_EDITED_TAG: 'Asset Edited Tag', // Not added this event

  PROPOSALS_TAB_CLICKED: 'Proposals Tab Clicked',
  PROPOSAL_OPENED: 'Proposal Opened',
  PROPOSAL_DOWNLOAD: 'Proposal Download',
  PROPOSAL_UPLOAD: 'Proposal Upload',
  PROPOSAL_DELETED: 'Proposal Deleted',
  PROPOSAL_FILTER_APPLIED: 'Proposal Filter Applied',
  PROPOSAL_CHAT: 'Proposal Chat',

  PEOPLE_BULK_UPDATE: 'People Bulk Update',
  PEOPLE_TAB_CLICKED: 'People Tab Clicked',
  PEOPLE_RESUME_PROPOSAL_VIEWED: 'People Resume Proposal Viewed',
  PEOPLE_MASTER_RESUME_OPENED: 'People Master Resume Opened',
  PEOPLE_RESUME_OPENED: 'People Resume Opened',
  PEOPLE_RESUME_EDITED: 'People Resume Edited',
  PEOPLE_RESUME_DOWNLOAD: 'People Resume Download',
  PEOPLE_RESUME_SCREENSHOT_VIEWED: 'People Resume Screenshot Viewed',
  PEOPLE_RESUME_NOTES_EDITED: 'People Resume Notes Edited',
  PEOPLE_RESUME_PROFILE_UPLOADED: 'People Resume Profile Uploaded',
  PEOPLE_RESUME_TAGS_VIEWED: 'People Resume Tags Viewed',
  PEOPLE_RESUME_DELETED: 'People Resume Deleted',
  PEOPLE_RESUME_REMOVED: 'People Resume Removed',
  PEOPLE_RESUME_MERGED: 'People Resume Merged',
  PEOPLE_RESUME_SEARCHED: 'People Resume Searched',
  PEOPLE_MASTER_RESUME_NAME_UPDATED: 'People Master Resume Name Updated',
  PEOPLE_PROFILE_UPDATED: 'People Profile Updated',
  PEOPLE_FILES_OPENED: 'People Files Opened',
  PEOPLE_FILES_UPLOADED: 'People Files Uploaded',
  PEOPLE_FILES_DELETED: 'People Files Deleted',
  PEOPLE_FILE_TAB_OPENED: 'People File Tab Opened',
  PEOPLE_NOTES_TAB_OPENED: 'People Notes Tab Opened',
  PEOPLE_DOCUMENT_CONTENT_SEARCHED: 'People Document Content Searched',
  PEOPLE_BULK_PROPERTIES_UPDATED: 'People Bulk Properties Updated',
  PEOPLE_BULK_DELETE: 'People Bulk Delete',
  PEOPLE_BULK_UPDATE_CLICKED: 'People Bulk Update Clicked',
  PEOPLE_BULK_MERGE_CLICKED: 'People Bulk Merge Clicked',
  PEOPLE_BULK_TAG_CLICKED: 'People Bulk Tag Clicked',
  PEOPLE_RESUME_PINNED: 'People Resume Pinned',

  PROJECT_BULK_UPDATE: 'Project Bulk Update',
  PROJECTS_TAB_CLICKED: 'Projects Tab Clicked',
  PROJECT_OPENED: 'Project Opened',
  PROJECT_VARIATION_OPENED: 'Project Variation Opened',
  PROJECT_SEARCHED: 'Project Searched',
  PROJECTS_MERGED: 'Projects Merged',
  PROJECT_SCREENSHOT_VIEWED: 'Project Screenshot Viewed',

  PROJECT_RESUME_REMOVED: 'Project Resume Removed',
  PROJECT_RESUME_DELETED: 'Project Resume Deleted',
  PROJECT_RESUME_DOWNLOAD: 'Project Resume Download',
  PROJECT_INFO_UPDATED: 'Project Info Updated',
  PROJECT_FILES_OPENED: 'Project Files Opened',
  PROJECT_FILES_UPLOADED: 'Project Files Uploaded',
  PROJECT_FILES_DELETED: 'Project Files Deleted',
  PROJECT_FILE_TAB_OPENED: 'Project File Tab Opened',
  PROJECT_NOTES_TAB_OPENED: 'Project Notes Tab Opened',
  PROJECT_DOCUMENT_CONTENT_SEARCHED: 'Project Document Content Searched',
  PROJECT_BULK_PROPERTIES_UPDATED: 'Project Bulk Properties Updated',
  PROJECT_BULK_DELETE: 'Project Bulk Delete',
  PROJECT_BULK_UPDATE_CLICKED: 'Project Bulk Update Clicked',
  PROJECT_BULK_MERGE_CLICKED: 'Project Bulk Merge Clicked',
  PROJECT_BULK_TAG_CLICKED: 'Project Bulk Tag Clicked',
  PROJECT_PINNED: 'Project Pinned',

  WORKSPACE_DOCUMTENTS_TAB_OPENED: 'Workspace Documents Tab Opened',
  WORKSPACE_DOCUMENT_OPENED: 'Workspace Document Opened',
  WORKSPACE_DOCUMENT_DELETED: 'Workspace Document Deleted',
  WORKSPACE_DOCUMENT_CREATED: 'Workspace Document Created',
  WORKSPACE_AI_GENERATE_USED: 'Workspace AI Generate Used',
  WORKSPACE_CHAT: 'Workspace Chat',
  WORKSPACE_SEARCH: 'Workspace Search',
  WORKSPACE_RFX_SELECTED: 'Workspace Rfx Selected',
  WORKSPACE_REFERENCE_PROPOSAL_SELECTED:
    'Workspace Reference Proposal Selected',
  WORKSPACE_TAB_OPENED: 'Workspace Tab Opened',
  WORKSPACE_OPENED: 'Workspace Opened',
  WORKSPACE_DELETED: 'Workspace Deleted',
  WORKSPACE_CREATED: 'Workspace Created',
  WORKSPACE_FILES_OPENED: 'Workspace Files Opened',
  WORKSPACE_FILES_UPLOAD: 'Workspace Files Upload',
  WORKSPACE_PAIN_POINTS_OPENED: 'Workspace Pain Points Opened',
  WORKSPACE_STORYBOARD_OPENED: 'Workspace Storyboard Opened',
  WORKSPACE_SECTION_OPENED: 'Workspace Section Opened',
  WORKSPACE_STORYBOARD_DELETED: 'Workspace Storyboard Deleted',
  WORKSPACE_STORYBOARD_DATA_EDIT: 'Workspace Storyboard Data Edit',
  WORKSPACE_STORYBOARD_ADD_SUB_SECTION: 'Workspace Storyboard Add Sub Section',
  WORKSPACE_STORYBOARD_DELETE_SUB_SECTION:
    'Workspace Storyboard Delete Sub Section',
  WORKSPACE_STORYBOARD_EDIT_SUB_SECTION:
    'Workspace Storyboard Edit Sub Section',
  WORKSPACE_STORYBOARD_ADD_PEOPLE: 'Workspace Storyboard Add People',
  WORKSPACE_STORYBOARD_DELETE_PEOPLE: 'Workspace Storyboard Delete People',
  WORKSPACE_STORYBOARD_ADD_PROJECT: 'Workspace Storyboard Add Project',
  WORKSPACE_STORYBOARD_DELETE_PROJECT: 'Workspace Storyboard Delete Project',
  WORKSPACE_STORYBOARD_GENERATED: 'Workspace Storyboard Generated',
  WORKSPACE_STORYBOARD_CREATED: 'Workspace Storyboard Created',

  ANALYTICS_TAB_CLICKED: 'Analytics Tab Clicked',
  ANALYTICS_FILE_OPENED: 'Analytics File Opened',
  ANALYTICS_FILE_DELETED: 'Analytics File Deleted',
  ANALYTICS_FILE_UPLOAD: 'Analytics File Upload',
  ANALYTICS_SUGGESTION_TAB_OPENED: 'Analytics Suggestion Tab Opened',
  ANALYTICS_INSIGHTS_TAB_OPENED: 'Analytics Insights Tab Opened',
  ANALYTICS_MARKED_AS_RESOLVED: 'Analytics Marked As Resolved',
  ANALYTICS_MARKED_AS_IGNORED: 'Analytics Marked As Ignored',
  ANALYTICS_STYLE_GUIDE_TAB_OPENED: 'Analytics Style Guide Tab Opened',
  ANALYTICS_STYLE_GUIDE_WORDY_PHRASES_ADDED:
    'Analytics Style Guide Wordy Phrases Added',
  ANALYTICS_STYLE_GUIDE_WORDY_PHRASES_DELETED:
    'Analytics Style Guide Wordy Phrases Deleted',
  ANALYTICS_STYLE_GUIDE_WORDS_TO_AVOID_ADDED:
    'Analytics Style Guide Words To Avoid Added',
  ANALYTICS_STYLE_GUIDE_WORDS_TO_AVOID_DELETED:
    'Analytics Style Guide Words To Avoid Deleted',
  ANALYTICS_CHAT: 'Analytics Chat',
  ANALYTICS_INSIGHTS_CHART_RESET: 'Analytics Insights Chart Reset',
  ANALYTICS_INSIGHTS_CHART_DOWNLOAD: 'Analytics Insights Chart Download',
  ANALYTICS_SUGGESTION_ENTITIES_OPENED: 'Analytics Suggestion Entities Opened',
  ANALYTICS_WIN_STRATEGY_ENTITIES_OPENED:
    'Analytics Win Strategy Entities Opened',
  ANALYTICS_COMPLIANCE_DOWNLOADED: 'Analytics Compliance Downloaded',
  RFX_OPENED_FROM_ANALYTICS_COMPLIANCE: 'Rfx Opened From Analytics Compliance',

  RFX_TAB_CLICKED: 'Rfx Tab Clicked',
  RFX_FILE_OPENED: 'Rfx File Opened',
  RFX_FILE_DELETED: 'Rfx File Deleted',
  RFX_FILE_UPLOAD: 'Rfx File Upload',
  RFX_CONTENT_COPIED: 'Rfx Content Copied',
  RFX_CONTENT_EDITED: 'Rfx Content Edited',
  RFX_CONTENT_REFRESHED: 'Rfx Content Refreshed',
  RFX_FILE_DOWNLOAD: 'Rfx File Download',
  RFX_CHAT: 'Rfx Chat',
  RFX_FILE_UPLOAD_FROM_COMPLIANCE: 'Rfx File Upload From Compliance',

  CHAT_TAB_CLICKED: 'Chat Tab Clicked',
  CHAT_ICON_CLICKED_SIDEBAR: 'Chat Icon Clicked Sidebar',
  CHAT_SESSION_OPENED: 'Chat Session Opened',
  CHAT_SESSION_DELETED: 'Chat Session Deleted',
  CHAT_SESSION_SHARED: 'Chat Session Shared',
  CHAT_SESSION_RENAMED: 'Chat Session Renamed',
  CHAT_SESSION_GEN_TITLE: 'Chat Session Gen Title',
  CHAT_SESSION_REGEN_TITLE: 'Chat Session Regen Title',
  CHAT_NEW_SESSION: 'New Chat Session',
  CHAT_IMAGE_GENERATED: 'Chat Image Generated',
  CHAT_IMAGE_SAVE_INTO_ASSETS: 'Chat Image Save Into Assets',
  CHAT_FEEDBACK_SUBMITTED: 'Chat Feedback Submitted',
  CHAT_POSITIVE_FEEDBACK_OPENED: 'Chat Positive Feedback Opened',
  CHAT_NEGATIVE_FEEDBACK_OPENED: 'Chat Negative Feedback Opened',
  CHAT_CONTENT_COPIED: 'Chat Content Copied',
  CHAT_SESSION_SEARCH: 'Chat Session Search',
  CHAT_EXISTING_SESSION: 'Chat Existing Session',
  CHAT_SESSION_FILTER_APPLIED: 'Chat Session Filter Applied',
  CHAT_VOICE_APPLIED: 'Chat Voice Applied',
  CHAT_SOCKET_CONNECTED: 'Chat Socket Connected',
  CHAT_SOCKET_DISCONNECTED: 'Chat Socket Disconnected',
  CHAT_SOCKET_ERRORED: 'Chat Socket Errored',
  CHAT_PROMPT_LIBRARY_OPENED: 'Chat Prompt Library Opened',
  CHAT_PROMPT_COPIED: 'Chat Prompt Copied',
  CHAT_PROMPT_USED: 'Chat Prompt Used',
  CHAT_MESSAGE_SENT: 'Chat Message Sent',
  CHAT_ICON_CLICKED: 'Chat Icon Clicked',
  CHAT_HISTORY_OPENED: 'Chat History Opened',
  CHAT_WINDOW_MAXIMIZED: 'Chat Window Maximized',
  CHAT_WINDOW_MINIMIZED: 'Chat Window Minimized',
  CHAT_WINDOW_MOVED: 'Chat Window Moved',

  CHAT_WINDOW_RESIZED: 'Chat Window Resized',
  CHAT_WINDOW_CLOSED: 'Chat Window Closed',
  CHAT_SETTINGS_OPENED: 'Chat Settings Opened',
  CHAT_ATTACH_FILE_CLICKED: 'Chat Attach File Clicked',
  CHAT_FILE_ATTACHED: 'File Attached to Chat',
  CHAT_FILTER_CLICKED: 'Chat Filter Clicked',
  CHAT_FILTER_APPLIED: 'Chat Filter Applied',
  CHAT_ADD_CONTENT_TO_CHAT: 'Chat Add Content to Chat',

  SETTINGS_TAB_CLICKED: 'Settings Tab Clicked',
  SETTINGS_PROFILE_DETAILS_UPDATED: 'Settings Profile Details Updated',
  SETTINGS_DOMAIN_DETAILS_UPDATED: 'Settings Domain Details Updated',
  SETTINGS_USER_INVITED: 'Settings User Invited',
  SETTINGS_CHAT_INSTRUCTIONS_UPDATED: 'Settings Chat Instructions Updated',

  AI_GENERATE_USED: 'AI Generate Used',

  DOCUMENT_UPLOAD: 'Document Upload',
  DOCUMENT_CHAT: 'Document Chat',

  CLIENTS_TAB_CLICKED: 'Clients Tab Clicked',
  CLIENT_BULK_UPDATE: 'Client Bulk Update',
  CLIENT_INFO_UPDATED: 'Client Info Updated',
  CLIENT_MERGED: 'Client Merged',
  TAGS_ADDED_TO_UPLOAD: 'Added tags while uploading',
  CLIENT_FILES_OPENED: 'Client Files Opened',
  CLIENT_FILES_UPLOADED: 'Client Files Uploaded',
  CLIENT_FILES_DELETED: 'Client Files Deleted',
  CLIENT_FILE_TAB_OPENED: 'Client File Tab Opened',
  CLIENT_NOTES_TAB_OPENED: 'Client Notes Tab Opened',
  CLIENT_DOCUMENT_CONTENT_SEARCHED: 'Client Document Content Searched',
  CLIENT_BULK_PROPERTIES_UPDATED: 'Client Bulk Properties Updated',
  CLIENT_BULK_DELETE: 'Client Bulk Delete',
  CLIENT_BULK_UPDATE_CLICKED: 'Client Bulk Update Clicked',
  CLIENT_BULK_MERGE_CLICKED: 'Client Bulk Merge Clicked',
  CLIENT_BULK_TAG_CLICKED: 'Client Bulk Tag Clicked',

  COMMAND_BAR_LAUNCHED: 'Command Bar Launched',
  COMMAND_BAR_COMMAND_SEARCH: 'Search from Command Bar',
  COMMANDBAR_NAVIGATION: 'Command Bar Navigation',
  SIDEBAR_TOGGLE: 'Sidebar Toggle',
  ALPHABET_SEARCH: 'Alphabet Search',

  ADD_NEW_TRACKER: ' Add New Tracker',
  ADD_EXISTING_TRACKER: ' Add Existing Tracker',
  GENERATE_TRACKER_CLICKED: 'Generate Tracker Clicked',
  GENERATE_TRACKER_WITH_BRAND_MESSAGE: 'Generate Tracker With Brand Message',
  ADD_GENERATED_TRACKER: 'Add Generated Tracker',

  OPPORTUNITIES_FILES_UPLOADED: 'Opportunities Files Uploaded',
  OPPORTUNITIES_TAB_CLICKED: 'Opportunities Tab Clicked',
  CREATE_NEW_OPPORTUNITY: 'Create New Opportunity',
  OPPORTUNITY_BULK_PROPERTIES_UPDATED: 'Opportunity Bulk Properties Updated',
  OPPORTUNITY_BULK_DELETE: 'Opportunity Bulk Delete',
  OPPORTUNITY_BULK_UPDATE_CLICKED: 'Opportunity Bulk Update Clicked',
  OPPORTUNITY_BULK_MERGE_CLICKED: 'Opportunity Bulk Merge Clicked',
  OPPORTUNITY_BULK_TAG_CLICKED: 'Opportunity Bulk Tag Clicked',
  OPPORTUNITY_BULK_UPDATE: 'Opportunity Bulk Update',
  OPPORTUNITY_FILES_OPENED: 'Opportunity Files Opened',
  OPPORTUNITY_FILES_UPLOAD: 'Opportunity Files Upload',
  OPPORTUNITY_FILES_DELETED: 'Opportunity Files Deleted',
  OPPORTUNITY_FILE_TAB_OPENED: 'Opportunity File Tab Opened',
  OPPORTUNITY_NOTES_TAB_OPENED: 'Opportunity Notes Tab Opened',
  OPPORTUNITY_ADD_CALENDAR_EVENT: 'Opportunity Add Calendar Event',
  OPPORTUNITY_ADD_COMMENT: 'Opportunity Add Comment'
}

export default mixpanelEvents
