import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate, useParams } from 'react-router-dom'
import _, { isArray } from 'lodash'
import { Container, Section, SectionFixed } from '../../components/Container'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../../config/routes'
import { useStyles } from './styles'
import { checkUserRoleViewer } from '../../utils/User'
import ClientResult from '../../sections/ClientResult'
import { initalizeS3 } from '../../utils/AWS'
import {
  getIndividualClient,
  multiDocuments,
  pollJobStatus
} from '../../store/api'
import { toast } from 'react-toastify'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { fetchClientsList, updateClientTag } from '../../store/Clients/Actions'
import HeadlessTable from '../../components/TableV2/HeadlessTable'
import SearchNew from '../../sections/ListFilter/SearchNew'
import { ImageAvatarFallback, TagFilter } from '../../components'
import DisplayTags from '../../sections/ResumeResult/DisplayTags'
import { Box, Drawer, Zoom } from '@mui/material'
import TagCenterUpdate from '../../sections/TagCenterUpdate'
import Button2 from '../../components/Button/Button2'
import {
  CrossIcon,
  MergeIcon,
  PlusIcon,
  PropertiesIcon
} from '../../components/Icons/Icons'
import Label from '@mui/icons-material/Label'
import { updateNewTagValue } from '../../store/TagCenter/Actions'
import AlphabetSearch from '../PeopleResume/AlphabetSearch'
import BulkUpdateModal from '../../components/BulkUpdateModal/BulkUpdateModal'
import GenericMergeModal from '../../components/GenericMergeModal/GenericMergeModal'
import FilterListIcon from '@mui/icons-material/FilterList'

import Slide from '@mui/material/Slide'
import CreateObjectModal from '../../components/CreateObjectModal/CreateObjectModal'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ClientList = (props) => {
  const { showSearch = false, isEditable = true, isSuperUser = false } = props
  const { LIBRARY_CLIENTS_RESUME } = ROUTES
  const isFocused = location.pathname.includes(LIBRARY_CLIENTS_RESUME)

  const clientsList = useSelector((state) => state?.clients?.clientsList)
  const hasMoreData = useSelector((state) => state?.clients?.hasMoreData)
  const [alphabetKey, setAlphabetKey] = useState(null)
  const { clientId } = useParams()
  const user = useSelector((state) => state.authenticate.user)
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [meta, setMeta] = useState({})
  const [page, setPage] = useState(null)
  const [PDFData, setPDFData] = useState({})
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [showBulkModal, setShowBulkModal] = useState(false)
  const [showMergeModal, setShowMergeModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredRecord, setFilteredRecord] = useState([])
  const navigate = useNavigate()
  const classes = useStyles({ showDrawer })
  const { grid, tableContainer } = classes
  const [currentClientData, setCurrentClientData] = useState({})
  const [topVariatonList, setTopVariatonList] = useState([])
  const [topProposalScreenshot, setTopProposalScreenshot] = useState([])
  const [topProposalScreenshotOrigin, setTopProposalScreenshotOrigin] =
    useState([])
  const [openClient, setOpenClient] = useState(false)
  const [currentClient, setCurrentClient] = useState(null)
  const [screenshotIndex, setScreenshotIndex] = useState(0)
  const [s3Obj, sets3Obj] = useState(null)
  const [originProposalNames, setOriginProposalNames] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [selectedRowData, setSelectedRowData] = useState([])
  const [singleData, setSingleData] = useState(null)
  const [tagState, setTagState] = useState({})
  const [selectedTags, setSelectedTags] = useState(null)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [tagsOptions, setTagsOptions] = useState(null)
  const [showClientGroupModal, setShowClientGroupModal] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const isUserViewer = checkUserRoleViewer()

  const handleCloseClient = () => {
    setOpenClient(false)
    setLoading(true)
    navigate(ROUTES.LIBRARY_CLIENTS_RESUME)
  }

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    handleSearch('')
    initalizeData()
  }, [])

  useEffect(() => {
    const savedPage = localStorage.getItem('clientsPage')
    const savedAlphabet = localStorage.getItem('clientAlphabet')
    const savedSearchQuery = localStorage.getItem('clientSearchQuery')

    if (savedPage) {
      const pageNo = parseInt(savedPage)
      if (isNaN(pageNo)) {
        setPage(1)
      } else {
        setPage(pageNo)
      }
    } else {
      setPage(1)
      localStorage.setItem('clientsPage', '1')
    }

    if (savedAlphabet && savedAlphabet !== 'null') {
      setAlphabetKey(savedAlphabet)
    }

    if (savedSearchQuery) {
      setSearchValue(savedSearchQuery)
    }
  }, [])

  useEffect(() => {
    if (page && !clientId) {
      fetchMoreData(page)
      localStorage.setItem('clientsPage', page)
    }
  }, [page])

  useEffect(() => {
    if (page === 1) {
      fetchMoreData(page)
    } else {
      setPage(1)
    }
    if (alphabetKey) {
      localStorage.setItem('clientAlphabet', alphabetKey)
    }
  }, [alphabetKey])

  useEffect(() => {
    if (isFocused) {
      if (!clientsList && showSearch) {
        setLoading(true)
        // fetchMoreData(1)
      }
    }
  }, [clientsList, isFocused, hasMoreData, clientId, singleData])

  useEffect(() => {
    if (clientId) {
      fetchSingleData()
      setOpenClient(true)
    }
  }, [clientId])

  useEffect(() => {
    if (selectedTags) {
      fetchMoreData(page)
    }
  }, [selectedTags])

  useEffect(() => {
    const tagsValues = {}
    const tagMap = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        key_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      if (key_type?.includes('clientgroup')) {
        data.forEach((element) => {
          tagMap[element?.id] = {
            key,
            ...element
          }
          if (element.value) {
            if (tagsValues[key]?.values) {
              tagsValues[key].values.push({
                value: element.id,
                label: element.value
              })
            } else {
              if (!tagsValues[key]) tagsValues[key] = {}
              tagsValues[key].values = [
                {
                  value: element.id,
                  label: element.value
                }
              ]
            }
          }
          tagsValues[key] = {
            ...tagsValues[key],
            type,
            ...rest
          }
        })
      }
    })
    setTagsOptions(tagsValues)
    setTagState(tagMap)
  }, [tagsCenterStateTags])

  const fetchSingleData = async () => {
    setLoading(true)
    const res = await getIndividualClient({ client_group_id: clientId })
    if (res.status === 200) {
      let { clients, projects } = res.data || {}

      const { domain_id, proposal_id } = clients
      const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/1.png`

      clients = {
        ...clients,
        thumbnail_url,
        projects
      }
      setLoading(false)
      setCurrentClient(clients)
      setSingleData(clients)
      // if (clientsList?.length > 0) {

      // } else {
      //   toast.error('Unable to fetch clients')
      // }
    } else {
      toast.error('Unable to fetch clients')
    }
  }

  const fetchMoreData = (page, tags = selectedTags) => {
    // setPage(page)
    setLoading(true)
    const tag_values = []
    const tag_dates = []
    const selectedTagsObj = tags
    selectedTagsObj &&
      Object.keys(selectedTagsObj).forEach((key) => {
        const tags = []
        let { condition, values = [] } = selectedTagsObj[key] || {}
        values = values || []
        values.forEach((item) => {
          if (item?.type?.includes('date')) {
            tag_dates.push({ ...item, condition })
          } else {
            tags.push(item.value)
          }
        })
        if (tags.length > 0) {
          tag_values.push({
            key,
            condition,
            values: tags
          })
        }
      })

    const finalTags = tag_values.filter((item) => item.key !== 'Client type')

    let propertyFilter = null

    tag_values.map((item) => {
      if (item.key === 'Client type') {
        propertyFilter = [
          {
            condition: item.condition,
            type: 'client_type',
            value: item.values[0]
          }
        ]
      }

      return null
    })

    dispatch(
      fetchClientsList(
        {
          page_num: page,
          page_size: 20,
          keyword: searchValue,
          tags: finalTags,
          property: propertyFilter,
          alphabetical: alphabetKey
        },
        'fetch',
        (data) => {
          setRows(data?.clients)
          setLoading(false)
          setMeta({
            pageSize: data?.page_size,
            totalCount: data?.total_count,
            page: data?.page_num
          })
        }
      )
    )
  }

  useEffect(() => {
    if (clientsList) setRows(clientsList)
  }, [clientsList])

  const navigateOpenClient = (i, row) => {
    const clientId = row?.id
    navigate('/library/companies/' + clientId)
  }

  const columns = [
    {
      id: 'title',
      label: 'Company Name',
      accessorKey: 'title',
      children: (row) => {
        return (
          <span className="flex items-center gap-2 text-xs text-grey-800">
            <ImageAvatarFallback
              name={row?.title}
              profilePic={row?.enrichment_data?.logo}
              style={{
                fontSize: '8px',
                background: 'var(--grey-700)',
                height: '26px',
                width: '26px',
                minWidth: '26px'
              }}
              openImage={() => {}}
              s3Obj={s3Obj}
            />
            {row?.title}
          </span>
        )
      }
    },
    {
      id: 'type',
      label: 'Company Type',
      accessorKey: 'type',
      children: (row) => {
        return <span>{row?.properties?.client_type?.value ?? '-'}</span>
      }
    },
    {
      id: 'tags',
      label: 'Tags',
      children: (row) => {
        return (
          <div
            className="flex items-center w-full text-xs"
            onClick={(e) => {
              e.stopPropagation()
              if (!isProcessing) setSelectedData([row.id])
            }}
          >
            <DisplayTags
              tags={row?.tags ?? []}
              setShowTagDrawer={() => {
                if (!isProcessing) setShowTagDrawer(row.id)
              }}
            />
          </div>
        )
      }
    },
    {
      id: 'domain',
      label: 'Domain',
      accessorKey: 'domain',
      children: (row) => {
        return <span>{row?.enrichment_data?.domain ?? '-'}</span>
      }
    },
    {
      id: 'projectName',
      label: 'No. of Projects',
      accessorKey: 'count',
      children: (row) => {
        return <span>{row?.count ?? '-'}</span>
      }
    }
  ]

  const setInitialData = () => {
    const data = []
    clientsList &&
      clientsList.forEach((client) => {
        const el = {}
        el.id = client?.id
        el.documentName = client?.title
        el.projectName = client?.data?.project_name
        el.location = client?.data?.client_location
        el.logo = client?.enrichment_data?.logo
        el.domain = client?.enrichment_data?.domain
        data.push(el)
      })

    // setRows(data)
  }

  useEffect(() => {
    if (isArray(clientsList) && clientsList.length > 0) {
      setInitialData()
      setLoading(false)
      setFilteredRecord(clientsList)
    }
  }, [clientsList])

  useEffect(() => {
    if (!_.isEmpty(PDFData) && PDFData?.showDrawer) {
      setShowDrawer(true)
    }
  }, [PDFData])

  const handleTagsValueChange = (key, value, mode) => {
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  const refresh = () => {
    setIsProcessing(false)
    setSelectedData([])
    setSelectedRowData([])
    setLoading(true)
    setPage(1)
    setSearchValue('')
    setSelectedTags(null)
    fetchMoreData(1, null)
  }

  const handleMultiTags = async (totalTags) => {
    if (selectedData.length === 0) {
      toast.error('Please select atleast one company to tag')
    } else {
      const datetags = {}
      const tags = {}
      const dateArr = {}
      const tagsArr = {}
      for (const key in totalTags) {
        if (
          ['multidate', 'singledate'].includes(totalTags?.[key]?.[0]?.tag_type)
        ) {
          datetags[key] = totalTags[key]
        } else {
          tags[key] = totalTags[key]
        }
      }
      Object.keys(datetags).forEach((key) => {
        datetags[key].forEach((item) => {
          if (dateArr[key]) {
            dateArr[key].push({
              tag_key: item.key,
              tag_value: item.label,
              tag_type: item.tag_type
            })
          } else {
            dateArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.label,
                tag_type: item.tag_type
              }
            ]
          }
        })
      })
      Object.keys(tags).forEach((key) => {
        tags[key].forEach((item) => {
          if (tagsArr[key]) {
            tagsArr[key].push({
              tag_key: item.key,
              tag_value: item.value,
              tag_label: item.label
            })
          } else {
            tagsArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.value,
                tag_label: item.label
              }
            ]
          }
        })
      })
      for (const key in dateArr) {
        if (dateArr[key].length === 0) {
          delete dateArr[key]
        }
      }
      for (const key in tagsArr) {
        if (tagsArr[key].length === 0) {
          delete tagsArr[key]
        }
      }
      if (_.isEmpty(tagsArr) && _.isEmpty(dateArr)) {
        toast.error('Please select atleast one tag')
      } else {
        setShowTagDrawer(false)
        setIsProcessing(true)
        const toastId = toast.info(
          <div style={{ display: 'flex' }}>
            {'Tagging Selected Projects'}&nbsp;
            <CircularProgress size={20} />
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false
          }
        )
        const documents = selectedData.map((item) => {
          return {
            id: item,
            file_type: 'project'
          }
        })
        const req = {
          documents,
          action: 'tags',
          data: { tags: tagsArr, date_tags: dateArr }
        }
        let failed = false
        const res = await multiDocuments(req)
        if (res.status === 200) {
          const job_id = res.data?.job_id
          const { status, payload } = await pollJobStatus(job_id)
          if (status) {
            toast.dismiss(toastId)
            const { new_tags = [] } = payload || {}
            const newTagPayload = {}
            new_tags.forEach((item) => {
              if (!newTagPayload[item.key]) {
                newTagPayload[item.key] = []
              }
              newTagPayload[item.key].push(item)
            })
            Object.keys(newTagPayload || {}).forEach((key) => {
              dispatch(updateNewTagValue({ new_tags: newTagPayload[key], key }))
            })
            // fetchMoreData(page)
            const tagsFormated = {}
            const keysToRemove = []
            Object.keys(tagsArr || {}).forEach((key) => {
              keysToRemove.push(key)
              tagsArr[key].forEach((item) => {
                if (tagsFormated[key]) {
                  tagsFormated[key].push({
                    tag_key: key,
                    tag_value: item.tag_label,
                    id: item.tag_value
                  })
                } else {
                  tagsFormated[key] = [
                    {
                      tag_key: key,
                      tag_value: item.tag_label,
                      id: item.tag_value
                    }
                  ]
                }
              })
            })
            Object.keys(datetags || {}).forEach((key) => {
              const values = datetags[key]
              keysToRemove.push(key)
              const { data = [] } = tagsCenterStateTags[key] || {}
              values.forEach((item) => {
                const tag = data.find((element) => {
                  return element.value === item.label
                })
                if (tag) {
                  if (tagsFormated[key]) {
                    tagsFormated[key].push({
                      tag_key: key,
                      tag_value: tag.value,
                      id: tag.id
                    })
                  } else {
                    tagsFormated[key] = [
                      {
                        tag_key: key,
                        tag_value: tag.value,
                        id: tag.id
                      }
                    ]
                  }
                } else {
                  const newTag = new_tags.find((element) => {
                    return element.value === item.label
                  })
                  if (newTag) {
                    if (tagsFormated[key]) {
                      tagsFormated[key].push({
                        tag_key: key,
                        tag_value: newTag.value,
                        id: newTag.id
                      })
                    } else {
                      tagsFormated[key] = [
                        {
                          tag_key: key,
                          tag_value: newTag.value,
                          id: newTag.id
                        }
                      ]
                    }
                  }
                }
              })
            })
            selectedData.forEach((id) => {
              const index = clientsList.findIndex((item) => item.id === id)
              if (index === -1) return null
              const oldTags = clientsList[index].tags
              const tagsDict = {}
              oldTags.forEach((id) => {
                const item = tagState[id]
                if (item && item.key) {
                  if (tagsDict[item.key]) {
                    tagsDict[item.key].push(id)
                  } else {
                    tagsDict[item.key] = [id]
                  }
                }
              })
              Object.keys(tagsFormated).forEach((key) => {
                tagsDict[key] = tagsFormated[key].map((item) => item.id)
              })
              const newTags = []
              Object.keys(tagsDict).forEach((key) => {
                newTags.push(...tagsDict[key])
              })
              const payload = {
                id,
                tags: newTags
              }
              dispatch(updateClientTag(payload))
            })
            setIsProcessing(false)
            setSelectedData([])
            setSelectedRowData([])
          } else {
            failed = true
          }
        } else {
          failed = true
        }
        if (failed) {
          toast.dismiss(toastId)
          toast.error('Error in tagging. Try again later')
          setIsProcessing(false)
        }
      }
    }
  }

  const onUpdateCallback = (id, key, tagsFlat = []) => {
    if (key !== 'tags') {
      return
    }

    const index = rows.findIndex((item) => item.id === id)
    if (index === -1) return null
    const tempProjectList = [...rows]
    tempProjectList[index].tags = tagsFlat
    setRows(tempProjectList)
    setShowTagDrawer(false)
    setSelectedData([])
  }

  const handleSearch = (key) => {
    setSearchValue(key)
  }

  const handleSearchQuery = (event, value = null) => {
    if (event?.keyCode === 13) {
      trackEvent('CLIENT_SEARCHED', 'SUCCESS', {}, { search_value: value })
      setLoading(true)
      setPage(1)
      dispatch(
        fetchClientsList(
          {
            page_num: 1,
            page_size: 20,
            keyword: value,
            alphabetical: alphabetKey
          },
          'fetch',
          (data) => {
            setRows(data?.clients)
            setLoading(false)
            setMeta({
              pageSize: data?.page_size,
              totalCount: data?.total_count,
              page: data?.page_num
            })
          }
        )
      )
    }
  }

  const handleMerge = () => {
    if (selectedData.length < 2) {
      toast.error('Please select atleast two companies')
      return
    }
    trackEvent(mixpanelEvents.CLIENT_BULK_MERGE_CLICKED, 'SUCCESS', {}, {})
    setShowMergeModal(true)
    // return
  }

  const renderTagDrawer = () => {
    return (
      <Drawer
        anchor={'right'}
        open={showTagDrawer}
        onClose={() => setShowTagDrawer(false)}
        disableEnforceFocus={true}
      >
        <Box sx={{ width: '400px', height: '100%' }}>
          {selectedData.length === 1 ? (
            <TagCenterUpdate
              id={selectedData?.[0]}
              filterTags="clientgroup"
              document_type={'clientgroup'}
              selectCallback={(e) => handleMultiTags(e)}
              onUpdateCallback={onUpdateCallback}
              cancelCallback={() => {
                setShowTagDrawer(false)
                setSelectedData([])
              }}
            />
          ) : (
            <TagCenterUpdate
              isOnlySelect={true}
              filterTags="clientgroup"
              selectCallback={(e) => handleMultiTags(e)}
              cancelCallback={() => {
                setShowTagDrawer(false)
                setSelectedData([])
              }}
            />
          )}
        </Box>
      </Drawer>
    )
  }

  if (loading) {
    return <Loader loading={loading} caption={''} flex />
  }

  if (clientId && currentClient) {
    return (
      <ClientResult
        currentClient={currentClient}
        data={currentClientData}
        openProject={openClient}
        handleCloseProject={handleCloseClient}
        s3Obj={s3Obj}
        openType={'tab'}
        thumbnail_url_list={topProposalScreenshot}
        refresh={fetchSingleData}
        screenshotIndex={screenshotIndex}
        originProposalNames={originProposalNames}
        // handlePDFViewer={handlePDFViewer}
        topProposalScreenshotOrigin={topProposalScreenshotOrigin}
        topVariatonList={topVariatonList}
        setTopVariatonList={setTopVariatonList}
        singleData={singleData}
        setSingleData={setSingleData}
      />
    )
  }

  return (
    <Container>
      {showSearch && !singleData && (
        <SectionFixed>
          {showSearch && (
            <div
              className="flex items-center justify-between gap-2"
              style={{
                padding: '10px 18px'
              }}
            >
              <AlphabetSearch
                source="client"
                alphabetKey={alphabetKey}
                setAlphabetKey={setAlphabetKey}
                meta={meta}
                onClear={() => {
                  setAlphabetKey(null)
                  localStorage.removeItem('clientAlphabet')
                }}
              />

              <div className="flex items-center gap-1">
                <TagFilter
                  showButton
                  source="client"
                  showTags={false}
                  tags={tagsOptions}
                  disabled={loading}
                  selectedTags={selectedTags}
                  onChange={handleTagsValueChange}
                  filterIcon={
                    _.isEmpty(selectedTags) && (
                      <Button2
                        secondary
                        noOutline
                        style={{
                          padding: '8px 9px'
                        }}
                      >
                        <FilterListIcon
                          className="size-4"
                          sx={{
                            height: '1rem',
                            width: '1rem'
                          }}
                        />
                      </Button2>
                    )
                  }
                  clearFilter={
                    !_.isEmpty(selectedTags) && (
                      <Button2 secondary onClick={() => refresh()}>
                        <CrossIcon className="size-4 text-grey-500 " />
                        clear filter
                      </Button2>
                    )
                  }
                />
                <SearchNew
                  value={searchValue}
                  style={{
                    padding: '6px 10px'
                  }}
                  onChange={(value) => handleSearch(value)}
                  onClear={() => {
                    handleSearch('')
                    handleSearchQuery({ keyCode: 13 }, '')
                    localStorage.removeItem('clientSearchQuery')
                  }}
                  onEnter={(value) => {
                    handleSearch(value)
                    handleSearchQuery({ keyCode: 13 }, value)
                    localStorage.setItem('clientSearchQuery', value)
                  }}
                />
                {!isUserViewer && (
                  <Button2
                    primary
                    style={{
                      padding: '7px 14px',
                      marginLeft: '2px'
                    }}
                    onClick={() => {
                      // getProposalNames()
                      setShowClientGroupModal(true)
                    }}
                  >
                    <PlusIcon className="size-3" strokeWidth={2.2} />
                    New Company
                  </Button2>
                )}
              </div>
            </div>
          )}
        </SectionFixed>
      )}
      <Section overFlow>
        <div className={tableContainer}>
          {openClient ? (
            <></>
          ) : (
            <>
              <TagFilter
                source="client"
                showButton={false}
                showTags
                tags={tagsOptions}
                // disabled={loading || isUserViewer}
                selectedTags={selectedTags}
                onChange={handleTagsValueChange}
              />
              <HeadlessTable
                leftAlign={true}
                columns={columns}
                meta={meta}
                data={rows}
                loading={loading}
                fetchPage={(page) => {
                  setPage(page)
                }}
                onRowClick={(row) => {
                  navigateOpenClient(null, row)
                }}
                selectedData={selectedData}
                handleSelectedData={(row) => {
                  if (!isProcessing) {
                    if (selectedData.includes(row.id)) {
                      setSelectedData(
                        selectedData.filter((item) => item !== row.id)
                      )

                      setSelectedRowData(
                        selectedRowData.filter((item) => item.id !== row.id)
                      )
                    } else {
                      setSelectedData([...selectedData, row.id])

                      setSelectedRowData([...selectedRowData, row])
                    }
                  }
                }}
                unSelectAll={() => {
                  setSelectedData([])
                  setSelectedRowData([])
                }}
              />

              {!isUserViewer && (
                <Zoom in={selectedRowData.length > 0}>
                  <div
                    className="absolute flex items-center justify-center p-2 text-white rounded-full bg-grey-800 w-72"
                    style={{
                      left: '35%',
                      bottom: '150px',
                      padding: '6px',
                      boxShadow: 'rgb(180 180 180) 5px 6px 20px 7px'
                    }}
                  >
                    {isProcessing ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <>
                        <Button2
                          secondary
                          noOutline
                          onClick={() => {
                            setShowBulkModal(true)
                            trackEvent(
                              mixpanelEvents.CLIENT_BULK_UPDATE_CLICKED,
                              'SUCCESS',
                              {},
                              {}
                            )
                          }}
                          dark={true}
                          style={{
                            width: '100%',
                            justifyContent: 'center',
                            borderRadius: '100px'
                          }}
                        >
                          <PropertiesIcon className="size-4 " />
                          Update
                        </Button2>
                        <Button2
                          dark={true}
                          secondary
                          noOutline
                          onClick={() => handleMerge()}
                          style={{
                            width: '100%',
                            justifyContent: 'center',
                            borderRadius: '100px'
                          }}
                        >
                          <MergeIcon className="size-4 " />
                          Merge
                        </Button2>
                        <Button2
                          dark={true}
                          secondary
                          noOutline
                          onClick={() => {
                            setShowTagDrawer(true)
                            trackEvent(
                              mixpanelEvents.CLIENT_BULK_TAG_CLICKED,
                              'SUCCESS',
                              {},
                              {}
                            )
                          }}
                          style={{
                            width: '100%',
                            justifyContent: 'center',
                            borderRadius: '100px'
                          }}
                        >
                          <Label className="size-4 " />
                          Tag
                        </Button2>
                      </>
                    )}
                  </div>
                </Zoom>
              )}
            </>
          )}
        </div>
      </Section>

      {showBulkModal && (
        <BulkUpdateModal
          s3Obj={s3Obj}
          type={'client'}
          onSuccess={() => {
            fetchMoreData(page)
            setShowBulkModal(false)
            setSelectedData([])
            setSelectedRowData([])
          }}
          onClose={() => setShowBulkModal(false)}
          selectedList={selectedRowData}
          isOpen={showBulkModal}
        />
      )}

      {showMergeModal && (
        <GenericMergeModal
          isOpen={showMergeModal}
          onClose={() => setShowMergeModal(false)}
          selectedData={selectedRowData}
          type="client"
          onMerge={() => {
            fetchMoreData(page)
            setShowMergeModal(false)
            setSelectedData([])
            setSelectedRowData([])
          }}
        />
      )}

      {showClientGroupModal && (
        <CreateObjectModal
          isOpen={showClientGroupModal}
          onClose={() => setShowClientGroupModal(false)}
          type="client"
          s3Obj={s3Obj}
          onSuccess={() => {
            setShowClientGroupModal(false)
            fetchMoreData(page)
          }}
        />
      )}
      {renderTagDrawer()}
    </Container>
  )
}

export default ClientList
