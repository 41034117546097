import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor()
  return {
    containerStyle: {
      height: '100%',
      width: '100%'
    },
    searchStyle: {
      height: '100%',
      width: '100%',
      borderLeft: '1px solid #EEEEEE',
      padding: '15px'
    },
    customResizeHandler: {
      height: '100%',
      position: 'absolute',
      top: 0,
      right: '0px',
      cursor: 'ew-resize',
      boxSizing: 'border-box',
      padding: '0 5px 5px 0',
      zIndex: 2
    },
    tableHeader: {
      padding: '4px 8px',
      textAlign: 'left',
      fontWeight: 700,
      fontSize: '14px',
      color: '#797979',
      backgroundColor: 'white',
      zIndex: 2,
      borderTop: '.5px solid var(--grey-200)',
      borderBottom: '.5px solid var(--grey-200)',
      position: 'relative',
      wordBreak: 'break-word'
    },
    tableRow: {
      borderBottom: '.5px solid var(--grey-200)',
      padding: '4px 8px',
      textAlign: 'left'
    },
    tableRowLoader: {
      borderBottom: '.5px solid var(--grey-200)',
      padding: '6.5px 8px',
      textAlign: 'left'
    },
    tableHeaderLoader: {
      borderBottom: '1px solid var(--grey-100)',
      padding: '14px',
      textAlign: 'left'
    },
    tableWrapper: {
      width: '100%',
      minWidth: '100%',
      borderCollapse: 'collapse',
      borderTop: '1px solid var(--grey-100)',
      overflow: 'auto',
      tableLayout: 'fixed',
      '& th': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: 'white'
      },
      '& tr': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#E5E5E5'
        }
      }
    },
    tableRowActive: {
      backgroundColor: '#DBEAFF !important'
    },
    icon: {
      fontSize: '15px',
      color: '#797979'
    },
    tableRowText: {
      fontSize: '12px',
      wordBreak: 'break-word',
      color: '#797979',
      fontWeight: '400',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      '& .MuiTypography-root': {
        fontSize: '14px'
      }
    },
    tableRowTextFileName: {
      width: '100%',
      fontSize: '12px',
      wordBreak: 'break-word',
      color: 'var(--grey-800)',
      fontWeight: '500',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      '& .MuiInputBase-root': {
        width: '100%'
      },
      '& input': {
        padding: '4.94px',
        fontSize: '14px',
        wordBreak: 'break-word',
        color: '#797979',
        fontWeight: '500',
        backgroundColor: 'white'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#E5E5E5'
        },
        '&:hover fieldset': {
          borderColor: '#E5E5E5'
        }
      }
    },
    nameRowWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    tableOptionsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px 40px 10px 40px',
      color: selectedColor,
      gap: 10
    },
    buttonTextWrapper: {
      fontSize: '12px',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      textTransform: 'none'
    },
    button: {
      backgroundColor: selectedColor
    },
    hideMenuListText: {
      fontSize: '12px',
      fontWeight: '500',
      color: '#000000',
      width: '100px',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      overflowWrap: 'break-word'
    },
    hideMenuDivider: {
      margin: '0px !important'
    },
    blackDivider: {
      backgroundColor: '#000000'
    },
    hideMenu: {
      '& .MuiSvgIcon-root': {
        fontSize: '18px'
      },
      '& .MuiButtonBase-root': {
        padding: '3px 5px'
      },
      '& .MuiMenu-paper': {
        border: '1px solid #E5E5E5',
        marginTop: '10px'
      },
      '& .MuiList-root': {
        padding: '0px'
      }
    },
    columnMenu: {
      '& .MuiButtonBase-root': {
        padding: '2px 5px !important'
      }
    },
    sortMenu: {
      '& .MuiButtonBase-root': {
        padding: '4px 5px'
      },
      '& .MuiMenu-paper': {
        border: '1px solid #E5E5E5',
        marginTop: '10px'
      },
      '& .MuiList-root': {
        padding: '0px'
      }
    },
    tableHeaderText: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      wordBreak: 'keep-all',
      fontSize: '12px',
      color: 'var(--grey-700)'
    },
    sortLabel: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '20px',
      width: '10px',
      '& button': {
        padding: '0px !important'
      }
    },
    sortIcon: {
      width: '8px !important',
      height: '8px !important',
      color: '#949494',
      cursor: 'pointer'
    },
    sortIconActive: {
      color: '#000000',
      width: '8px !important',
      height: '8px !important',
      cursor: 'pointer'
    },
    emptyDataWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      fontSize: '14px',
      color: '#797979',
      cursor: 'auto'
    },
    searchWrapper: {
      padding: '20px 40px 10px 40px',
      width: 'calc(100% - 80px)',
      fontSize: '16px',
      margin: '0px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '50px',
      '& .MuiGrid-item': {
        padding: '0px'
      }
    },
    searchButtonWrapper: {
      display: 'flex',
      gap: '10px',
      whiteSpace: 'nowrap',
      alignItems: 'center'
    },
    searchBarWrapper: {
      width: '-webkit-fill-available'
    },
    tagsWrapper: {
      padding: '10px 40px 20px 40px'
    },
    tags: {
      '& button': {
        minWidth: '100px',
        maxWidth: '200px'
      },
      '& #multiCheckboxWrapper': {
        width: 'auto !important',
        '& button': {
          fontSize: '12px',
          padding: '1px 4px'
        }
      },
      '& #multiCheckboxOption': {
        width: '100% !important',
        fontWeight: '500',
        fontSize: '12px',
        color: '#000000',
        '& .MuiSvgIcon-root': {
          fontSize: '20px'
        },
        '& .MuiCheckbox-root': {
          padding: '5px'
        }
      },
      '& .Mui-checked': {
        color: '#0645AD !important'
      },
      '& #multiCheckboxMenuWrapper': {
        border: '1px solid #E5E5E5 !important',
        borderRadius: '4px !important',
        marginTop: '5px'
      },
      '& #multiCheckboxMenu': {
        width: '250px !important',
        '& input': {
          fontSize: '12px !important'
        }
      }
    },
    tagsSelected: {
      '& button': {
        color: selectedColor,
        border: '1px solid ' + selectedColor
      }
    },
    clearFilterWrapper: {
      color: '#797979',
      fontSize: '14px',
      fontWeight: '400',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: '95px'
    },
    imageContainer: {
      alignItems: 'center',
      display: 'flex',
      overflow: 'auto',
      height: '250px'
    },
    imageWrapper: {
      width: '240px',
      height: '238px !important',
      objectFit: 'contain',
      border: '1px solid #E5E5E5',
      cursor: 'pointer',
      margin: '5px'
    },
    hoverVideo: {
      height: '100%',
      width: '100%',
      '& video': {
        display: 'block !important',
        width: '100% !important',
        objectFit: 'contain !important',
        height: '100% !important'
      }
    },
    assetWrapper: {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      overflow: 'hidden',
      backgroundColor: '#DBEAFF',
      '& img': {
        objectFit: 'contain'
      },
      '&:hover #assetOverlayHidden': {
        opacity: '1 !important'
      },
      '&:hover': {
        '& img': {
          opacity: 0.7
        },
        '& video': {
          opacity: 0.7
        }
      }
    },
    assetOverlayHidden: {
      opacity: '0'
    },
    assetOverlay: {
      position: 'absolute',
      zIndex: '2',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      '& .MuiSvgIcon-root': {
        display: 'flex',
        color: 'black',
        backgroundColor: '#ffffffb3',
        height: '25px !important',
        width: '25px !important'
      }
    },
    assetMainText: {
      fontSize: '14px',
      fontColor: '#000000'
    },
    assetName: {
      fontSize: '12px',
      fontColor: '#797979'
    },
    assetSubText: {
      fontSize: '10px',
      fontColor: '#797979'
    },
    titleText: {
      fontSize: '12px',
      padding: '5px 30px'
    },
    searchTag: {
      border: '1px solid #E5E5E5',
      borderRadius: '4px',
      padding: '0px 10px',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      gap: '3px',
      maxWidth: '200px',
      color: '#797979',
      '& .MuiSvgIcon-root': {
        fontSize: '18px',
        color: '#797979'
      }
    },
    rowTagsWrapper: {
      fontSize: '12px',
      wordBreak: 'break-word',
      color: '#797979',
      fontWeight: '400',
      '& #multiCheckboxWrapper': {
        width: 'auto !important',
        '& button': {
          fontSize: '12px',
          padding: '1px 4px'
        }
      },
      '& #multiCheckboxMenu': {
        width: '250px !important'
      },
      '& #multiCheckboxOption': {
        width: '100% !important',
        fontWeight: '500',
        fontSize: '12px',
        color: '#000000',
        '& .MuiSvgIcon-root': {
          fontSize: '20px'
        },
        '& .MuiCheckbox-root': {
          padding: '5px'
        }
      },
      '& .Mui-checked': {
        color: '#0645AD !important'
      },
      '& #multiCheckboxMenuWrapper': {
        border: '1px solid #E5E5E5 !important',
        borderRadius: '4px !important'
      },
      '& button': {
        padding: '0px',
        fontSize: '12px'
      }
    },
    collectionWrapper: {
      padding: '10px 40px 20px 40px',
      color: '#797979',
      maxWidth: '500px'
    },
    collectionName: {
      fontSize: '14px'
    },
    collectionDesc: {
      fontSize: '12px'
    },
    gridTitleText: {
      fontSize: '14px',
      padding: '5px 30px'
    },
    fileViwerWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%'
    },
    fileChangeIcons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        fontSize: '55px',
        color: '#fff'
      },
      '& button': {
        width: '100%',
        borderRadius: '0px',
        padding: '0px'
      },
      '& .Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: '#00000063'
        }
      }
    },
    menuButton: {
      position: 'sticky !important',
      right: 0,
      background: '#fff',
      borderLeft: '1px solid #E5E5E5',
      zIndex: 3
    },
    dialogSubText: {
      fontSize: '14px',
      fontFamily: 'PoppinsRegular',
      color: '#000000',
      marginTop: '10px'
    },
    breadcrumb: {
      fontSize: '14px',
      color: '#797979',
      cursor: 'pointer',
      '&:hover': {
        color: selectedColor
      }
    },
    breadcrumbActive: {
      fontSize: '14px',
      color: selectedColor,
      cursor: 'pointer'
    },
    buttonText: {
      [theme.breakpoints.down('lg')]: {
        display: 'none'
      }
    },
    multiControl: {
      position: 'absolute',
      bottom: '15px',
      display: 'flex',
      width: '100%',
      maxWidth: '700px',
      color: '#fff',
      justifyContent: 'center',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2
    },
    multiControlBox: {
      display: 'flex',
      borderRadius: '4px',
      background: '#000',
      padding: '10px 15px',
      width: '100%',
      maxWidth: '700px',
      gap: '10px',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    multiControlOptions: {
      alignItems: 'center',
      display: 'flex',
      gap: '10px',
      color: '#fff',
      '& button': {
        color: '#fff'
      }
    }
  }
})

export { useStyles }
