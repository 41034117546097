import { useState, useEffect } from 'react'
// import { useStyles } from "./styles";
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import {
  setActiveTag,
  updateLibraryDocument,
  removeCollection,
  changeCollectionName,
  fetchCollections
} from '../../../store/Library/Actions'
import { scrollIntoViewIfNeeded } from '../../../utils/ScrollIntoView'
import Favorite from '@mui/icons-material/Favorite'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import { UPDATE_LIBRARY_COLLECTIONS } from '../../../store/actionTypes'
import { SelectRadio, TextInput, useConfirmation } from '../../../components'
import Loader from '../../../components/Loader'
import { createCollection } from '../../../store/api'
import {
  checkUserRoleAdmin,
  checkDomainAccess,
  checkUserRoleViewer
} from '../../../utils/User'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import DoneIcon from '@mui/icons-material/Done'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as AI_Flash } from '../../../assets/images/AI_Flash.svg'
import SvgIcon from '@mui/material/SvgIcon'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Typography } from '@mui/material'
import TextLineLimiter from '../../../components/TextLineLimiter'
import { useColor } from '../../../ThemeContext'
import { toast } from 'react-toastify'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { useStyles } from './styles'
import Button2 from '../../../components/Button/Button2'
import {
  BoxIcon,
  LibraryIcon,
  NotesIcon,
  PencilIcon,
  PinIcon,
  PlusIcon,
  TrashIcon,
  UnpinIcon
} from '../../../components/Icons/Icons'
import CollectionModal from '../../../containers/LibraryList/CollectionModal'
import { updateUserConfig } from '../../../store/Authentication/Actions'

const SortMenu = (props) => {
  const {
    filterBy = [],
    setFilterBy = () => {},
    disabled = false,
    filterOpen = false,
    setFilterOpen = () => {}
  } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!disabled) {
      setFilterOpen(!filterOpen)
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setFilterOpen(false)
  }

  const filterOptions = [
    {
      key: 'name',
      order: 'asc',
      label: 'Name (A-Z)'
    },
    {
      key: 'name',
      order: 'desc',
      label: 'Name (Z-A)'
    },
    {
      key: 'library_id',
      order: 'desc',
      label: 'Collections Size (Largest)'
    },
    {
      key: 'library_id',
      order: 'asc',
      label: 'Collections Size (Smallest)'
    },
    {
      key: 'created_at',
      order: 'desc',
      label: 'Created Date (Newest)'
    },
    {
      key: 'created_at',
      order: 'asc',
      label: 'Created Date (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'asc',
      label: 'Last Modified (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'desc',
      label: 'Last Modified (Newest)'
    }
  ]

  const handleFilter = (e, filter) => {
    e.stopPropagation()
    e.preventDefault()
    setFilterBy(filter)
    handleClose()
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <IconButton
        className={classes.iconButton}
        onClick={handleClick}
        disabled={disabled}
      >
        <SwapVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
      >
        {filterOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={
              filterBy.key === option.key && filterBy.order === option.order
            }
            onClick={(e) =>
              handleFilter(e, { key: option.key, order: option.order })
            }
          >
            <Box className={classes.hideMenuListText}>{option.label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

const LibraryCollectionList = (props) => {
  const { active, setActive } = props
  const libraryState = useSelector((state) => state.library)
  const {
    libraryCollections: libraryCollectionsState = {},
    activeTag = '',
    suggestedCount = 0
  } = libraryState

  const [libraryCollections, setLibraryCollections] = useState({})
  const { public_collections = [], private_collections = [] } =
    libraryCollections || {}
  const libraryTagsCount =
    public_collections.length + private_collections.length
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}
  // const classes = useStyles();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showCreateCollection, setShowCreateCollection] = useState(false)
  const [collectionName, setCollectionName] = useState('')
  const isAdmin = checkUserRoleAdmin()
  const hasSuggestions = checkDomainAccess('library_suggestions')
  const showSuggestions = isAdmin && hasSuggestions
  const [hovered, setHovered] = useState('')
  const [isEditIndex, setIsEditIndex] = useState({})
  const [isEditName, setIsEditName] = useState({})
  const [filterBy, setFilterBy] = useState({
    key: 'name',
    order: 'asc'
  })
  const [filterOpen, setFilterOpen] = useState(false)
  const isUserViewer = checkUserRoleViewer()
  const [showCollectionModal, setShowCollectionModal] = useState(false)
  const [deleteCollectionModal, setDeleteCollectionModal] = useState(false)
  const [collectionInfo, setCollectionInfo] = useState(null)
  const getDataFromLocal = () => {
    let data = localStorage.getItem('libraryCollectionsSort')
    data = data ? JSON.parse(data) : null
    return data
  }

  const [faviouriteCollections, setFaviouriteCollections] = useState([])

  const user_config_state = useSelector(
    (state) => state.authenticate.user?.domain?.user_config || {}
  )

  const saveDataToLocal = (data) => {
    localStorage.setItem('libraryCollectionsSort', JSON.stringify(data))
  }

  useEffect(() => {
    const data = getDataFromLocal()
    if (data) {
      setFilterBy(data)
    }

    if (user_config_state?.favourite_collections) {
      setFaviouriteCollections(user_config_state?.favourite_collections)
    }
  }, [])

  useEffect(() => {
    if (libraryCollectionsState) {
      const collections = sortCollections(
        _.cloneDeep(libraryCollectionsState),
        filterBy
      )
      setLibraryCollections(collections)
      saveDataToLocal(filterBy)
    }
  }, [libraryCollectionsState, filterBy])

  const sortCollections = (collections, filterBy) => {
    const { public_collections = [], private_collections = [] } =
      collections || {}
    const sortData = (data) => {
      return data.sort((a, b) => {
        const key = filterBy.key
        let order = filterBy.order
        let comparisonA = a[key]
        let comparisonB = b[key]
        if (key === 'created_at' || key === 'updated_at') {
          comparisonA = new Date(a[key])
          comparisonB = new Date(b[key])
          if (comparisonA.getTime() === comparisonB.getTime()) {
            comparisonA = a.name.toLowerCase()
            comparisonB = b.name.toLowerCase()
            order = 'asc'
          }
        } else if (key === 'name') {
          comparisonA = a[key].toLowerCase()
          comparisonB = b[key].toLowerCase()
        } else if (key === 'library_id') {
          comparisonA = a[key]?.length || 0
          comparisonB = b[key]?.length || 0
          if (comparisonA === comparisonB) {
            comparisonA = a.name.toLowerCase()
            comparisonB = b.name.toLowerCase()
            order = 'asc'
          }
        }
        if (comparisonA < comparisonB) {
          return order === 'desc' ? 1 : -1
        }
        if (comparisonA > comparisonB) {
          return order === 'desc' ? -1 : 1
        }
        return 0
      })
    }
    return {
      public_collections: sortData(public_collections),
      private_collections
    }
  }

  const handleTagClick = (tag) => {
    if (filterOpen) {
      return
    }
    const data = {
      domainId: domain_id
    }
    if (tag) {
      data.collection = tag?.id
      data.collectionType = tag?.visibility
      data.library_type = tag?.library_type
    }
    dispatch(setActiveTag(tag))
  }

  const handleNewCollection = async (name, visibility) => {
    setLoading(visibility)

    try {
      if (name) {
        const res = await createCollection({ name, visibility })
        if (res.status === 200) {
          dispatch({
            type: UPDATE_LIBRARY_COLLECTIONS,
            payload: res.data
          })

          setShowCollectionModal(false)
          setCollectionName('')
        } else {
          toast.error(res.data.message || 'Error')
        }
      } else {
        toast.error('Collection Name and Type are required')
      }
    } catch (e) {
      setLoading(false)
    }
    setLoading(false)
  }

  const handleDrop = (event, tag) => {
    let el = event.dataTransfer.getData('data')
    if (el) {
      el = JSON.parse(el)
      const { collections } = el
      const { id } = tag
      const objKeys = [...Object.keys(collections)]
      if (!objKeys.includes(id)) {
        const temp = {
          id: el.id,
          collections: [...objKeys, id]
        }
        dispatch(updateLibraryDocument(temp))
      }
    }
    setActive('')
  }

  const handleDragOver = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handeShowClose = () => {
    setCollectionName('')
    setLoading(false)
    setShowCreateCollection(false)
  }

  useEffect(() => {
    handleTagClick({
      name: 'Knowledge',
      visibility: '',
      library_type: 'knowledge'
    })
  }, [])

  const { ConfirmDialog, showConfirmDialog } = useConfirmation()

  const handleDeleteClick = (selectedTag) => {
    showConfirmDialog({
      onConfirm: async () => {
        dispatch(
          removeCollection(
            selectedTag,
            handleTagClick({
              name: 'Knowledge',
              visibility: '',
              library_type: 'knowledge'
            })
          )
        )
      },
      deleteType: true,
      confirmButtonText: 'Delete',
      confirmationMessageTitle: `Are you sure you want to delete the "${selectedTag?.name}" ?`
    })
  }

  useEffect(() => {
    const { name, visibility } = activeTag || {}
    const index = _.findIndex(
      visibility === 'public' ? public_collections : private_collections,
      { name }
    )
    if (index > -1) {
      scrollIntoViewIfNeeded('collections_' + visibility + index, 'collections')
    }
  }, [activeTag])

  const handleMouseEnter = (id) => {
    setHovered(id)
  }

  const handleMouseLeave = () => {
    setHovered('')
  }

  const handleChangeCollectionName = (tag) => {
    const { id, visibility } = tag
    if (!isEditName?.[id]) {
      toast.error('Collection name is required')
      return
    }
    const callback = () => {
      setIsEditIndex({
        ...isEditIndex,
        [id]: false
      })
      setIsEditName({
        ...isEditName,
        [id]: ''
      })
    }
    const req = {
      name: isEditName?.[id],
      id,
      visibility
    }
    dispatch(changeCollectionName(req, callback))
  }

  const updateCollectionDetails = (collection) => {
    const req = {
      name: collection.name,
      id: collection.id,
      visibility: collection.visibility
    }

    dispatch(
      changeCollectionName(req, (data) => {
        setShowCollectionModal(false)

        dispatch(
          setActiveTag({
            name: collection.name,
            id: collection.id,
            visibility: collection.visibility
          })
        )

        dispatch(fetchCollections())
      })
    )
  }

  const handlePinnedCollections = (tag) => {
    const userconfig = _.cloneDeep(user_config_state)
    if (userconfig?.favourite_collections) {
      let tempCollections = []
      if (faviouriteCollections.includes(tag.id)) {
        tempCollections = faviouriteCollections.filter(
          (item) => item !== tag.id
        )
        setFaviouriteCollections(tempCollections)
      } else {
        tempCollections = [...faviouriteCollections, tag.id]
        setFaviouriteCollections(tempCollections)
      }

      const req = {
        user_config: {
          ...userconfig,
          favourite_collections: tempCollections
        }
      }
      const callback = () => {
        toast.success('View saved successfully')
      }
      console.log('pinned Settings', req)
      dispatch(updateUserConfig(req, callback))
    } else {
      setFaviouriteCollections([tag.id])
      const req = {
        user_config: {
          ...userconfig,
          favourite_collections: [tag.id]
        }
      }
      const callback = () => {
        toast.success('View saved successfully')
      }
      console.log('pinned Settings', req)

      dispatch(updateUserConfig(req, callback))
    }
  }

  const { selectedColor } = useColor()

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box
        id="collections"
        sx={{
          padding: '10px',
          // height: 'calc(100% - 30px)',
          // width: 'calc(100% - 30px)',
          // overflow: 'auto',
          fontSize: '16px',
          color: '#71717a',
          '& .Mui-selected': {
            backgroundColor: '#f4f4f5',
            color: '#18181b'
          },
          '& .Mui-selected:hover': {
            backgroundColor: '#f4f4f5',
            color: '#18181b'
          },
          '& input': {
            padding: '2px 7px'
          },
          '& .MuiButtonBase-root': {
            padding: '3px 10px',
            borderRadius: '5px'
          }
        }}
      >
        <div className="flex flex-col text-grey-500">
          <Button2
            secondary
            noOutline
            style={{
              width: '100%',
              paddingLeft: '8px',
              backgroundColor:
                activeTag?.name === 'Knowledge' &&
                activeTag?.library_type === 'knowledge'
                  ? 'var(--grey-100)'
                  : ''
            }}
            onClick={() =>
              handleTagClick({
                name: 'Knowledge',
                visibility: '',
                library_type: 'knowledge'
              })
            }
          >
            <LibraryIcon className="size-4 text-grey-600" />
            Knowledge
          </Button2>
          <Button2
            secondary
            noOutline
            style={{
              width: '100%',
              paddingLeft: '8px',
              backgroundColor:
                activeTag?.name === 'Notes' && activeTag.library_type === 'note'
                  ? 'var(--grey-100)'
                  : ''
            }}
            onClick={() =>
              handleTagClick({
                name: 'Notes',
                visibility: '',
                library_type: 'note'
              })
            }
          >
            <NotesIcon className="size-4 text-grey-600" />
            Notes
          </Button2>
          {public_collections.map((tag, key) => {
            if (faviouriteCollections.includes(tag.id)) {
              return (
                <CollectionCard
                  key={key}
                  tag={tag}
                  activeTag={activeTag}
                  isActive={
                    activeTag?.name === tag.name &&
                    activeTag?.visibility === 'public'
                  }
                  pinned={true}
                  handlePinnedCollections={handlePinnedCollections}
                  handleTagClick={handleTagClick}
                  handleDeleteClick={handleDeleteClick}
                  setCollectionInfo={setCollectionInfo}
                  setShowCollectionModal={setShowCollectionModal}
                />
              )
            }
            return null
          })}

          {private_collections.map((tag, key) => {
            if (faviouriteCollections.includes(tag.id)) {
              return (
                <CollectionCard
                  key={key}
                  tag={tag}
                  pinned={true}
                  activeTag={activeTag}
                  isActive={
                    activeTag?.name === tag.name &&
                    activeTag?.visibility === 'private'
                  }
                  handlePinnedCollections={handlePinnedCollections}
                  handleTagClick={handleTagClick}
                  handleDeleteClick={handleDeleteClick}
                  setCollectionInfo={setCollectionInfo}
                  setShowCollectionModal={setShowCollectionModal}
                />
              )
            }
            return null
          })}

          {public_collections.length > 0 && (
            <div
              style={{
                marginTop: '14px'
              }}
            >
              <p
                className="text-xxs m-0 px-3"
                style={{
                  paddingLeft: '11px'
                }}
              >
                Public
              </p>
              {public_collections.map((tag, key) => {
                const { name, library_id = [], status, id } = tag
                const count = parseInt(
                  library_id?.length ? library_id?.length : 0
                )
                const keyid = 'public' + key
                const disableItem = !!status
                const isEditing = isEditIndex?.[id]

                return (
                  <CollectionCard
                    key={key}
                    tag={tag}
                    activeTag={activeTag}
                    isActive={
                      activeTag?.name === tag.name &&
                      activeTag?.visibility === 'public'
                    }
                    handlePinnedCollections={handlePinnedCollections}
                    handleTagClick={handleTagClick}
                    handleDeleteClick={handleDeleteClick}
                    setCollectionInfo={setCollectionInfo}
                    setShowCollectionModal={setShowCollectionModal}
                  />
                )
              })}
            </div>
          )}

          {private_collections.length > 0 && (
            <div
              style={{
                marginTop: '14px'
              }}
            >
              <div className="flex gap-1 justify-between items-center">
                <p
                  className="text-xxs m-0 px-3"
                  style={{
                    paddingLeft: '11px'
                  }}
                >
                  Private
                </p>
                {/* <Button2 secondary noOutline></Button2> */}
              </div>
              {private_collections.map((tag, key) => {
                const { name, library_id = [], status, id } = tag
                const count = parseInt(
                  library_id?.length ? library_id?.length : 0
                )
                const keyid = 'public' + key
                const disableItem = !!status
                const isEditing = isEditIndex?.[id]

                return (
                  <CollectionCard
                    key={key}
                    tag={tag}
                    activeTag={activeTag}
                    isActive={
                      activeTag?.name === tag.name &&
                      activeTag?.visibility === 'private'
                    }
                    handlePinnedCollections={handlePinnedCollections}
                    handleTagClick={handleTagClick}
                    handleDeleteClick={handleDeleteClick}
                    setCollectionInfo={setCollectionInfo}
                    setShowCollectionModal={setShowCollectionModal}
                  />
                )
              })}
            </div>
          )}

          <div
            style={{
              marginTop: '14px'
            }}
          >
            {public_collections.length === 0 &&
            private_collections.length === 0 ? (
              <div className="bg-grey-100 p-4 rounded-lg m-1">
                <p className="m-0 text-sm font-medium leading-5 text-grey-800">
                  Organize your content
                </p>
                <p className="m-0 text-xxs mt-1 text-grey-600">
                  Create a collection to group related content together.
                </p>
                <Button2
                  primary
                  style={{
                    // background: 'var(--grey-50)',
                    marginTop: '14px',
                    // width: '100%',
                    textAlign: 'center',
                    border: 'none'
                  }}
                  onClick={() => {
                    setCollectionInfo(null)
                    setShowCollectionModal(true)
                  }}
                >
                  Get Started
                </Button2>
              </div>
            ) : (
              <Button2
                secondary
                noOutline
                style={{
                  width: '100%',
                  padding: '5px 10px',
                  color: 'var(--grey-700)'
                }}
                onClick={() => {
                  if (isUserViewer) {
                    setCollectionInfo({ visibility: 'private' })
                  } else {
                    setCollectionInfo(null)
                  }
                  setShowCollectionModal(true)
                }}
              >
                <PlusIcon className="size-3 text-grey-600" />
                New Collection
              </Button2>
            )}
          </div>
        </div>
      </Box>

      {ConfirmDialog}

      {showCollectionModal && (
        <CollectionModal
          isOpen={showCollectionModal}
          update={updateCollectionDetails}
          create={handleNewCollection}
          key={collectionInfo?.id}
          // handleCreateClose={handleCreateClose}
          collectionInfo={collectionInfo}
          onClose={() => setShowCollectionModal(false)}
          // type={collectionInfo ? 'edit' : 'create'}
          type="create"
          isUserViewer={isUserViewer}
          // buttonFunction={handleCreateCollection}
        />
      )}
    </Box>
  )
}

export default LibraryCollectionList

const CollectionCard = ({
  tag,
  key,
  handleTagClick,
  activeTag,
  handleDeleteClick,
  pinned,
  setCollectionInfo,
  setShowCollectionModal,
  handlePinnedCollections,
  isActive
}) => {
  return (
    <div
      key={key}
      className="flex justify-between items-center parent-card button-base gap-1 bg-grey-50 rounded-lg text-grey-800 px-3 text-xs p-1-5 font-medium hover-bg-grey-100"
      onClick={() => handleTagClick(tag)}
      style={{
        textTransform: 'capitalize',
        padding: '5px 10px',
        color: 'var(--grey-700)',
        background: isActive ? 'var(--grey-100)' : '',
        paddingLeft: pinned ? '8px' : '10px'
        // fontSize: '13px'
      }}
    >
      <span className="flex items-center gap-1">
        <BoxIcon
          className={pinned ? 'size-4 text-grey-500' : 'size-3 text-grey-500'}
          style={{
            minWidth: '0.75rem',
            minHeight: '0.75rem'
          }}
        />
        {tag.name}
      </span>

      <div className="flex gap-1 items-center card-options">
        <span
          className="cursor-pointer flex items-center"
          onClick={(e) => {
            // e.stopPropagation()
            handlePinnedCollections(tag)
          }}
        >
          {pinned ? (
            <UnpinIcon className="size-4 text-grey-700 rotate-30" />
          ) : (
            <PinIcon className="size-4 text-grey-700 rotate-30" />
          )}
        </span>
        {!pinned && (
          <>
            <span
              className="cursor-pointer flex items-center"
              onClick={(e) => {
                // e.stopPropagation()
                setCollectionInfo(tag)
                handleDeleteClick({
                  ...tag
                })
              }}
            >
              <TrashIcon className="size-4 text-grey-700" />
            </span>

            <span
              className="cursor-pointer flex items-center"
              onClick={(e) => {
                // e.stopPropagation()
                setShowCollectionModal(true)
                setCollectionInfo({
                  ...tag
                })
              }}
            >
              <PencilIcon className="size-4 text-grey-700" />
            </span>
          </>
        )}
      </div>
    </div>
  )
}
