import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDocumentTagValues, updateResource } from '../../store/api'
import {
  Button,
  Loader,
  MultiSelectCheckBox,
  DateTagSelector,
  Container,
  Section,
  SectionFixed
} from '../../components'
import { Box, IconButton, CircularProgress } from '@mui/material'
import { useStyles } from './styles'
import { toast } from 'react-toastify'
import { AddNewKey, addNewValue } from '../../sections/TagCenterSettings'
import AddIcon from '@mui/icons-material/Add'
import { useColor } from '../../ThemeContext'
import { updateNewTagValue } from '../../store/TagCenter/Actions'
import _ from 'lodash'
import ClearIcon from '@mui/icons-material/Clear'
import { renderURL } from '../../utils/TagCenter'
import { checkUserRoleViewer, hasAccess } from '../../utils/User'
import InsightsIcon from '@mui/icons-material/Insights'
import { ROUTES } from '../../config/routes'
import TagPills from '../TagPills/TagPills'
import Button2 from '../../components/Button/Button2'
import { PlusIcon } from '../../components/Icons/Icons'

const TagCenterUpdate = (props) => {
  const {
    id = '',
    document_type = '',
    filterTags = 'document',
    onUpdateCallback = () => {},
    isOnlySelect = false,
    cancelCallback = null,
    selectCallback = () => {}
  } = props
  const tagsCenterStateTagsAll = useSelector((state) => state?.tagCenter?.tags)
  const [tagCenterData, setTagCenterData] = useState(null)
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const [tagsCenterStateTags, setTagsCenterStateTags] = useState(null)
  const keys = Object.keys(tagsCenterStateTags || {})
  const [loadingTags, setLoadingTags] = useState(false)
  const [openAddNewKey, setOpenAddNewKey] = useState(false)
  const [tagInputLoading, setTagInputLoading] = useState({})
  const dispatch = useDispatch()
  const selectWrapperRef = useRef()
  const selectRef = useRef()
  const canCreateTags = hasAccess('tags', 'create')

  const isUserViewer = checkUserRoleViewer()

  const handleOpenAddNewKey = () => {
    setOpenAddNewKey(true)
  }

  const handleCloseAddNewKey = () => {
    setOpenAddNewKey(false)
  }

  useEffect(() => {
    const filteredData = Object.keys(tagsCenterStateTagsAll || {}).reduce(
      (acc, key) => {
        const { key_type } = tagsCenterStateTagsAll[key] || {}
        if (key_type && key_type?.includes(filterTags)) {
          acc[key] = tagsCenterStateTagsAll[key]
        }
        return acc
      },
      {}
    )

    setTagsCenterStateTags({ ...filteredData })
  }, [tagsCenterStateTagsAll])

  useEffect(() => {
    async function fetchData() {
      const res = await getDocumentTagValues(id, document_type)
      if (res.status === 200) {
        const tag = res.data
        const tagsValues = {}
        Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
          const { data = [], type } = tagsCenterStateTags[key] || {}
          data.forEach((element) => {
            if (tag.includes(element.id) && element.value) {
              if (tagsValues[key]) {
                tagsValues[key].push({
                  value: element.id,
                  label: element.value,
                  tag_type: type,
                  key
                })
              } else {
                tagsValues[key] = [
                  {
                    key,
                    value: element.id,
                    label: element.value,
                    tag_type: type
                  }
                ]
              }
            }
          })
        })
        setTagCenterData(tagsValues)
        setLoading(false)
      }
    }
    if (isOnlySelect) {
      setLoading(false)
    } else {
      if (
        id &&
        document_type &&
        tagsCenterStateTags !== null &&
        tagCenterData === null
      ) {
        fetchData()
      }
    }
  }, [id, document_type, isOnlySelect, tagsCenterStateTags])

  const handleOnChange = (value, key) => {
    if (_.isEmpty(value)) {
      setTagCenterData((prev) => {
        const newTagCenterData = { ...prev }
        delete newTagCenterData[key]
        return newTagCenterData
      })
    } else {
      setTagCenterData((prev) => ({
        ...prev,
        [key]: _.isArray(value) ? value : [value]
      }))
    }
  }

  const handleOnAddNew = async (value, key, isMulti) => {
    if (selectRef?.current) {
      selectRef.current.selectOption(value)
    }
    if (isMulti) {
      if (selectWrapperRef?.current) {
        selectWrapperRef.current.clearInputValue()
      }
    }
    setTimeout(() => {
      setTagInputLoading({ ...tagInputLoading, [key]: false })
    }, 300)
  }

  const handleDateChange = async (value, current_key, tag_id, tag_type) => {
    value = value.map((item) => {
      if (item instanceof Date) {
        return item.toISOString()
      }
      return item
    })
    const formatValue = []
    value.forEach((item) => {
      formatValue.push({
        value: tag_id,
        label: item,
        tag_type,
        key: current_key
      })
    })

    if (_.isEmpty(value)) {
      setTagCenterData((prev) => {
        const newTagCenterData = { ...prev }
        delete newTagCenterData[current_key]
        return newTagCenterData
      })
    } else {
      setTagCenterData((prev) => ({
        ...prev,
        [current_key]: formatValue
      }))
    }
  }

  const handleSave = async () => {
    onUpdateCallback(id, 'loading', true)
    setLoadingTags(true)
    const datetags = {}
    const tags = {}
    const dateArr = []
    const tagsFlat = []
    for (const key in tagCenterData) {
      if (
        ['multidate', 'singledate'].includes(
          tagCenterData?.[key]?.[0]?.tag_type
        )
      ) {
        datetags[key] = tagCenterData[key]
      } else {
        tags[key] = tagCenterData[key]
      }
    }
    Object.keys(datetags).forEach((key) => {
      datetags[key].forEach((item) => {
        dateArr.push({
          tag_key: item.key,
          tag_value: item.label,
          tag_type: item.tag_type
        })
      })
    })
    Object.keys(tags).forEach((key) => {
      tags[key].forEach((item) => {
        tagsFlat.push(item.value)
      })
    })

    const req = {
      document_id: id,
      type: document_type,
      tags: tagsFlat,
      tags_date: dateArr,
      tag_update_all: true
    }
    const res = await updateResource(req)
    if (res.status === 200) {
      const { new_tags, all_tag_ids } = res.data
      const newTagsObj = {}
      new_tags.forEach((item) => {
        if (newTagsObj[item.key]) {
          newTagsObj[item.key].push(item)
        } else {
          newTagsObj[item.key] = [item]
        }
      })

      Object.keys(newTagsObj).forEach((key) => {
        dispatch(updateNewTagValue({ new_tags: newTagsObj[key], key }))
      })

      onUpdateCallback(id, 'tags', all_tag_ids)
    } else {
      toast.error('Something went wrong when updating tags')
    }
    setLoadingTags(false)
    onUpdateCallback(id, 'loading', false)
  }

  return loading ? (
    <Loader loading={true} caption={''} flex />
  ) : (
    <Container className="relative w-full">
      <div className={`${!cancelCallback ? 'pb-4' : 'p-4'}`}>
        <div className="flex items-center justify-between mb-4">
          <div className="text-lg font-medium">Update Tags</div>

          <div className="flex items-center gap-2">
            <AddNewKey
              isOpen={openAddNewKey}
              onClose={handleCloseAddNewKey}
              source={filterTags}
            />
            {canCreateTags && cancelCallback && (
              <Button2
                secondary
                onClick={() => handleOpenAddNewKey()}
                noOutline
                style={{
                  background: 'var(--grey-100)',
                  padding: '4px 16px',
                  fontSize: '12px',
                  color: 'var(--grey-900)'
                }}
              >
                <PlusIcon className="size-3" strokeWidth={2} />
                New Tag
              </Button2>
            )}

            <Button2
              primary
              style={{
                padding: '4px 16px',
                fontSize: '12px'
              }}
              loading={loadingTags}
              onClick={() => {
                if (!isOnlySelect) {
                  handleSave()
                } else {
                  selectCallback(tagCenterData)
                }
              }}
              disabled={loadingTags}
            >
              Save
            </Button2>
          </div>
        </div>
        <div
          className="flex flex-col gap-5"
          style={{
            height: cancelCallback ? 'calc(100vh - 130px)' : 'auto',
            overflowY: cancelCallback ? 'auto' : ''
          }}
        >
          {keys.map((key, index) => {
            const {
              data = [],
              type,
              id,
              value_type,
              is_generated = false
            } = tagsCenterStateTags[key] || {}
            const isMulti = type.includes('multi')
            const loading = tagInputLoading[key] || false
            const defaultValue = tagCenterData
              ? isMulti
                ? tagCenterData[key]
                : tagCenterData[key] && tagCenterData[key][0]
              : []
            const isDisabled = loadingTags || false || isUserViewer
            const isDocumentType = value_type?.includes('document_type')
            const isGenerated = is_generated
            const isURL = type.includes('url')
            if (
              ['multiselect', 'singleselect', 'multiurl', 'singleurl'].includes(
                type
              )
            ) {
              const options = loading
                ? []
                : data
                    .filter((item) => {
                      return item.value
                    })
                    .map((item) => {
                      return {
                        value: item.id,
                        label: item.value,
                        synced: item?.synced === undefined ? true : item.synced
                      }
                    })
              return (
                <Box key={index} className="">
                  <div>
                    <p className="m-0 mb-2 text-xxs">
                      {isGenerated
                        ? _.startCase(key.replace('generated_', ''))
                        : key}
                    </p>
                    <TagPills
                      tag={defaultValue}
                      options={options}
                      onChange={(value) => {
                        handleOnChange(value, key)
                      }}
                      isMulti={isMulti}
                      isURL={isURL}
                      isDate={false}
                      onCreate={(value) => {
                        const callback = (newOption) => {
                          if (key.includes('generated_')) {
                            let route = ''
                            if (key === 'generated_companies') {
                              route = ROUTES?.LIBRARY_CLIENTS_RESUME
                            } else if (key === 'generated_people') {
                              route = ROUTES?.LIBRARY_PEOPLE_RESUME
                            } else if (key === 'generated_projects') {
                              route = ROUTES?.LIBRARY_PROJECTS
                            }
                            newOption.label = `[${newOption.label}](${
                              route + '/' + newOption.value
                            })`
                          }
                          handleOnAddNew(newOption, key, isMulti)
                        }
                        addNewValue(
                          value,
                          key,
                          type,
                          tagsCenterStateTags,
                          dispatch,
                          callback
                        )
                      }}
                      showAdd={true}
                      showAllTags={true}
                    />
                  </div>
                  {/* <MultiSelectCheckBox
                  formatOptionLabel={(option) => {
                    const { __isNew__ = false } = option || {}
                    return !__isNew__ && isURL
                      ? renderURL(option.label, true)
                      : option.label
                  }}
                  selectRef={selectRef}
                  ref={selectWrapperRef}
                  isSortOptions
                  isMulti={isMulti}
                  isDisabled={isDisabled || loading}
                  getDropdownButtonLabel={({
                    placeholderButtonLabel,
                    value
                  }) => {
                    const isString = typeof placeholderButtonLabel === 'string'
                    if (!value) {
                      return (
                        <span
                          style={{
                            color: '#797979',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {placeholderButtonLabel}
                        </span>
                      )
                    }
                    const valueLables = _.isArray(value)
                      ? value?.map((item) => {
                          return item?.label
                        })
                      : [value?.label]
                    if (Array.isArray(valueLables)) {
                      if (value.length === 0) {
                        return (
                          <span
                            style={{
                              color: '#797979',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {placeholderButtonLabel}
                          </span>
                        )
                      }
                      return (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {isString ? (
                            <span style={{ color: '#797979' }}>
                              {placeholderButtonLabel}
                              <span>:&nbsp;</span>
                            </span>
                          ) : (
                            <Box
                              style={{
                                color: '#797979',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {placeholderButtonLabel}
                              <Box>:&nbsp;</Box>
                            </Box>
                          )}
                          <span>
                            {isURL
                              ? valueLables.map((item, index) => {
                                  return renderURL(item, true, index)
                                })
                              : valueLables.join(', ')}
                          </span>
                        </span>
                      )
                    }
                    return isURL ? (
                      <Box
                        sx={{
                          whiteSpace: 'break-spaces',
                          wordBreak: 'break-all',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {renderURL(value.label, true)}
                      </Box>
                    ) : (
                      value.label
                    )
                  }}
                  placeholderButtonLabel={
                    isGenerated ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '3px'
                        }}
                      >
                        <InsightsIcon sx={{ fontSize: '16px' }} />
                        {_.startCase(key.replace('generated_', ''))}
                      </Box>
                    ) : (
                      key
                    )
                  }
                  placeholder={
                    'Select Value for ' + isGenerated
                      ? _.startCase(key.replace('generated_', ''))
                      : _.startCase(key)
                  }
                  options={options}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  onChange={(value) => {
                    handleOnChange(value, key)
                  }}
                  value={defaultValue}
                  isLoading={loading}
                  isCreatable={!isDocumentType && canCreateTags}
                  isClearable={isOnlySelect ? true : !isDocumentType}
                  onCreateOption={(value) => {
                    setTagInputLoading({ ...tagInputLoading, [key]: true })
                    const callback = (newOption) => {
                      if (key.includes('generated_')) {
                        let route = ''
                        if (key === 'generated_companies') {
                          route = ROUTES?.LIBRARY_CLIENTS_RESUME
                        } else if (key === 'generated_people') {
                          route = ROUTES?.LIBRARY_PEOPLE_RESUME
                        } else if (key === 'generated_projects') {
                          route = ROUTES?.LIBRARY_PROJECTS
                        }
                        newOption.label = `[${newOption.label}](${
                          route + '/' + newOption.value
                        })`
                      }
                      handleOnAddNew(newOption, key, isMulti)
                    }
                    addNewValue(
                      value,
                      key,
                      type,
                      tagsCenterStateTags,
                      dispatch,
                      callback
                    )
                  }}
                  components={{
                    IndicatorsContainer: (props) => {
                      const { clearValue, hasValue, isDisabled, selectProps } =
                        props
                      const { isClearable } = selectProps || {}
                      return (
                        hasValue &&
                        isClearable && (
                          <Box
                            sx={{
                              '& .MuiSvgIcon-root': {
                                color: '#0645AD',
                                fontSize: '18px'
                              }
                            }}
                          >
                            <IconButton
                              disableRipple
                              onClick={clearValue}
                              disabled={isDisabled}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>
                        )
                      )
                    }
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: '0',
                      margin: '5px'
                    }),
                    input: (base) => ({
                      ...base,
                      fontSize: '12px',
                      padding: '0px'
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      padding: '0px 8px'
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontSize: '12px'
                    }),
                    noOptionsMessage: (base) => ({
                      ...base,
                      fontSize: '12px',
                      color: '#0645AD'
                    }),
                    singleValue: (base) => ({
                      ...base,
                      fontSize: '12px'
                    }),
                    multiValue: (base) => ({
                      ...base,
                      fontSize: '12px'
                    }),
                    loadingMessage: (base) => ({
                      ...base,
                      fontSize: '12px'
                    })
                  }}
                /> */}
                </Box>
              )
            } else {
              return null
            }
          })}

          {keys.map((key, index) => {
            const {
              data = [],
              type,
              id,
              value_type,
              is_generated = false
            } = tagsCenterStateTags[key] || {}
            const isMulti = type.includes('multi')
            const loading = tagInputLoading[key] || false
            const defaultValue = tagCenterData
              ? isMulti
                ? tagCenterData[key]
                : tagCenterData[key] && tagCenterData[key][0]
              : []
            const isDisabled = loadingTags || false || isUserViewer
            const isDocumentType = value_type?.includes('document_type')
            const isGenerated = is_generated
            const isURL = type.includes('url')
            if (
              ![
                'multiselect',
                'singleselect',
                'multiurl',
                'singleurl'
              ].includes(type)
            ) {
              return (
                <Box key={index}>
                  <DateTagSelector
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    showPlaceholderOnFilled
                    onChange={(value) => {
                      handleDateChange(value, key, id, type)
                    }}
                    value={isMulti ? defaultValue : [defaultValue]}
                    placeholder={key}
                  />
                </Box>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>
      {cancelCallback && (
        <div
          className={`px-4 p-2 flex`}
          style={{
            borderTop: '1px solid var(--grey-200)'
          }}
        >
          <div className="flex gap-2">
            {cancelCallback && (
              <Button2
                secondary
                variant="outlined"
                onClick={() => {
                  cancelCallback()
                }}
              >
                Close
              </Button2>
            )}
          </div>
        </div>
      )}
    </Container>
  )
}

export default TagCenterUpdate
