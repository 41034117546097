import { cn } from '@/lib/utils'
import React, { useEffect, useState } from 'react'
import {
  CheckIcon,
  CloudUpload,
  CrossIcon,
  PaperClipIcon,
  SemiCircleIcon,
  UploadIcon
} from '../Icons/Icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../ui/dropdown-menu'
import { ModernTooltip } from '../ModernTooltip/ModernTooltip'
import Button2 from '../Button/Button2'
import SearchNew from '@/sections/ListFilter/SearchNew'
import { getAllRfxNames, searchProposals } from '@/store/api'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { Auth } from 'aws-amplify'
import { initalizeS3 } from '@/utils/AWS'
import trackEvent from '@/utils/TrackEvent/TrackEvent'
import mixpanelEvents from '@/config/mixpanelEvents'
import { useSelector } from 'react-redux'
import { Tooltip } from '@/components/ui/tooltip-simple'

const assertServerError = (res: any, type: any) => {
  trackEvent(
    mixpanelEvents.OPPORTUNITIES_FILES_UPLOADED,
    !res.error ? 'SUCCESS' : 'ERROR',
    {},
    {}
  )
}

const getDocumentType = (file: any) => {
  const { meta, extension } = file
  const { document_type } = meta
  let type = 'document'
  if (['pdf', 'pptx', 'docx', 'doc', 'ppt'].includes(extension)) {
    if (document_type?.label?.toLowerCase() === 'proposal') {
      type = 'proposal'
    } else {
      type = 'document'
    }
  } else {
    type = 'asset'
  }
  return type
}

interface FileOptions {
  label: string
  value: string
  type: string
}

const AttachFilesDropdown = ({
  placement = 'bottom',
  onSelect,
  value,
  placeholder,
  setIsFileLoading = () => {},
  filterType = null
}: {
  placement?: 'bottom' | 'top' | 'left' | 'right'
  onSelect: (file: any) => void
  value: any
  placeholder: string
  setIsFileLoading?: (loading: boolean) => void
  filterType?: 'rfx' | null
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const [search, setSearch] = useState('')

  const [fileOptions, setFileOptions] = useState<FileOptions[]>([])
  const [filteredFileOptions, setFilteredFileOptions] = useState<FileOptions[]>(
    []
  )

  const [selectedFile, setselectedFile] = useState<any>(null)

  const [s3Obj, sets3Obj] = useState<any>(null)
  const [uppy, setUppy] = useState<any>(null)

  const [isFileUploading, setIsFileUploading] = useState(false)
  const tagsCenterStateTags = useSelector(
    (state: any) => state?.tagCenter?.tags
  )
  const [documentTypes, setDocumentTypes] = useState(null)

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
    async function initalize() {
      const session = (await Auth.currentSession()) as any
      const newUppy = new Uppy({
        autoProceed: true,
        id: 'uppy1',
        restrictions: {
          allowedFileTypes: [
            'application/pdf',
            'video/mp4',
            'video/webm',
            'video/mpeg',
            'video/ogg',
            'video/avi',
            'image/jpeg',
            'image/png',
            'image/jpg',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ]
        },
        debug: true,
        allowMultipleUploadBatches: true,
        allowMultipleUploads: true,
        locale: {
          strings: {
            pickFiles: 'Select files',
            pleaseWait: 'Please wait...'
          }
        }
      }).use(AwsS3, {
        companionUrl: process.env.REACT_APP_API_GATEWAY_URL || '',
        id: 'AwsS3',
        limit: 4,
        shouldUseMultipart: true,
        companionHeaders: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
        },
        async createMultipartUpload(file: any) {
          const metadata = {} as any
          Object.keys(file.meta).forEach((key) => {
            if (file.meta[key] != null) {
              metadata[key] = file.meta[key].toString()
            }
          })
          const filename = `${file.name}`
          const uploadtype = 'rfx'
          const collection_id = file?.meta?.collection_id
          const compliance_id = ''
          const document_tag = file?.meta?.document_tag
          const session = (await Auth.currentSession()) as any
          const response = await fetch(
            `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart`,
            {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
              },
              body: JSON.stringify({
                filename,
                type: file.type,
                extension: file.extension,
                uploadtype,
                name: metadata.name,
                created_at: file?.data?.lastModified
                  ? new Date(file?.data?.lastModified)
                  : new Date(),
                collection_id,
                ...(compliance_id ? { compliance_id } : {}),
                ...(metadata?.analytics_id
                  ? { analytics_id: metadata?.analytics_id }
                  : {}),
                document_tag
              })
            }
          )
          const data = await response.json()
          file.serverFileId = data.file_id
          file.s3Multipart = {
            key: data.key,
            uploadId: data.uploadId
          }
          onSelect({
            file_name: file.name,
            file_id: data.file_id,
            file_type: 'rfx'
          })
          return {
            uploadId: data.uploadId,
            key: data.key,
            parts: []
          }
        },
        async listParts(file, { key, uploadId }) {
          const filename = encodeURIComponent(key)
          const uploadtype = 'rfx'
          const session = (await Auth.currentSession()) as any
          const response = await fetch(
            `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart/${uploadId}/list?key=${filename}&uploadtype=${uploadtype}`,
            {
              method: 'get',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
              }
            }
          )
          const data = await response.json()
          return data
        },
        async signPart(file, opts) {
          const { uploadId, key, partNumber, body, signal } = opts
          if (!uploadId || !key) {
            // console.log(file, opts)
            throw new Error(
              "Oops! We couldn't retrieve the file. Please try uploading it again."
            )
          }
          const filename = encodeURIComponent(key)
          const uploadtype = 'rfx'
          const session = (await Auth.currentSession()) as any
          const response = await fetch(
            `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart/${uploadId}/batch?key=${filename}&partNumbers=${partNumber}&uploadtype=${uploadtype}`,
            {
              method: 'get',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
              }
            }
          )
          const data = await response.json()
          return {
            url: data.presignedUrls?.[partNumber],
            headers: {
              'Content-Type': 'application/json'
            }
          }
        },
        async abortMultipartUpload(file, { key, uploadId }): Promise<void> {
          if (uploadId) {
            const filename = encodeURIComponent(key)
            const uploadIdEnc = encodeURIComponent(uploadId)
            let uploadtype = 'rfx'
            uploadtype = encodeURIComponent(uploadtype)
            const session = (await Auth.currentSession()) as any
            const response = await fetch(
              `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart/${uploadIdEnc}?uploadtype=${uploadtype}&key=${filename}`,
              {
                method: 'delete',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
                }
              }
            )
          }
        },
        async completeMultipartUpload(file: any, { key, uploadId, parts }) {
          const metadata = {} as any
          const documentType = getDocumentType(file)
          Object.keys(file.meta).forEach((key) => {
            if (file.meta[key] != null) {
              if (key.includes('_')) {
                metadata[key] = file?.meta[key].toString()
              }
            }
          })
          const filename = encodeURIComponent(key)
          const uploadIdEnc = encodeURIComponent(uploadId)
          const uploadtype = 'rfx'
          parts = parts.map((part, index) => ({
            ETag: part?.ETag,
            PartNumber: part?.PartNumber
          }))
          const session = (await Auth.currentSession()) as any
          const response = await fetch(
            `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart/${uploadIdEnc}/complete?uploadtype=${uploadtype}&key=${filename}`,
            {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
              },
              body: JSON.stringify({
                parts,
                metadata,
                document_type: documentType,
                ...(file?.meta?.compliance_id
                  ? { compliance_id: file?.meta?.compliance_id }
                  : {})
              })
            }
          )
          setIsFileUploading(false)
          setIsFileLoading(false)
          const trackEventRes = assertServerError(
            {
              error: false
            },
            uploadtype
          )
          const data = await response.json()
          return data
        }
      })
      setUppy(newUppy)
    }
    initalize()
  }, [])

  const getDocTypes = () => {
    const values = Object.entries(tagsCenterStateTags || {}).reduce(
      (acc: any, [key, item]: [any, any]) => {
        if (item?.value_type?.includes('document_type')) {
          acc.push({ key, ...item })
        }
        return acc
      },
      []
    )
    const options = values[0]?.data
      ? values[0]?.data
          .filter((item: any) => {
            return item?.value
          })
          .map((item: any) => {
            return {
              value: item.id,
              label: item.value,
              value_type: item.value_type
            }
          })
      : []

    setDocumentTypes(options)

    return options
  }

  const addFiles = (files: any) => {
    if (files.length > 0) {
      let document_tag = getDocTypes()
      document_tag = document_tag.filter((item: any) => {
        return item.value_type === 'document_type_RFQ/P'
      })[0]
      const descriptors = Array.from(files).map((file: any) => {
        return {
          source: 'resource',
          name: file.name,
          extension: file.name.split('.').pop(),
          type: file.type,
          data: file,
          meta: {
            document_tag: {
              label: document_tag?.label,
              value: document_tag?.value,
              value_type: document_tag?.value_type
            }
          }
        }
      })
      try {
        setIsFileUploading(true)
        setIsFileLoading(true)
        uppy.addFiles(descriptors)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const handleFileUpload = async (e: any) => {
    const files = e.target.files

    if (files.length > 0) {
      addFiles(files)
    }
  }

  useEffect(() => {
    setselectedFile(value)
  }, [value])

  useEffect(() => {
    const getRfxNames = async () => {
      const res = await getAllRfxNames()
      if (res.data) {
        setFileOptions(
          res.data.rfx_list.map((item: any) => {
            return {
              label: item.document_name,
              value: item.id,
              type: 'rfx'
            }
          })
        )
        setFilteredFileOptions(
          res.data.rfx_list.map((item: any) => {
            return {
              label: item.document_name,
              value: item.id,
              type: 'rfx'
            }
          })
        )
        setFilteredFileOptions(
          res.data.rfx_list.map((item: any) => {
            return {
              label: item.document_name,
              value: item.id,
              type: 'rfx'
            }
          })
        )
      }
    }
    getRfxNames()
  }, [])

  return (
    <DropdownMenu>
      <div className="flex items-center rounded-md group hover:bg-zinc-100 pr-1.5">
        <DropdownMenuTrigger asChild>
          <button
            className={cn(
              'flex items-center gap-1 rounded-md text-xs font-medium outline-none twp p-1 px-1.5 hover:bg-zinc-100 text-zinc-700'
            )}
          >
            {!selectedFile ? (
              <>
                <PaperClipIcon className="size-3" /> {placeholder}
              </>
            ) : (
              <div className="flex items-center gap-1">
                <div className="flex items-center gap-1 group">
                  <PaperClipIcon className="size-3" />
                  <p
                    className={cn(
                      'text-xs font-medium',
                      isFileUploading ? 'text-orange-400' : 'text-black'
                    )}
                  >
                    {selectedFile.file_name}
                  </p>
                  {isFileUploading && (
                    <div
                      className="flex items-center gap-2 p-2 font-medium text-orange-400 rounded-lg text-xxs"
                      style={{
                        backgroundColor: '#ffedd5',
                        color: '#ea580c'
                      }}
                    >
                      <SemiCircleIcon className="size-3 animate-spin" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </button>
        </DropdownMenuTrigger>
        {selectedFile && (
          <button
            className="opacity-0 group-hover:opacity-100"
            onClick={() => {
              onSelect(null)
            }}
          >
            <CrossIcon className="size-3" />
          </button>
        )}
      </div>

      <DropdownMenuContent className="w-56 " side={placement}>
        <div className="flex items-center gap-0.5">
          <SearchNew
            onDebounce={() => {}}
            onChange={(value) => {
              setSearch(value)
              if (value === '') {
                setFilteredFileOptions(fileOptions)
              } else {
                setFilteredFileOptions(
                  fileOptions.filter((item) =>
                    item.label.toLowerCase().includes(value.toLowerCase())
                  )
                )
              }
            }}
            value={search}
            applyDebounce={false}
            loading={false}
            placeholder="Search files..."
            style={{
              fontSize: '12px',
              borderRadius: '6px',
              padding: '4px 10px'
            }}
          />
          <label
            htmlFor="attachFiles"
            className="grid rounded-md cursor-pointer w-7 h-7 bg-zinc-800 aspect-square place-content-center"
          >
            <ModernTooltip content={<p className="text-xs">Upload file</p>}>
              <div className="flex items-center justify-center gap-1 text-xs font-medium text-center text-white rounded">
                <input
                  id="attachFiles"
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    const files = e.target.files
                    if (files) {
                      setUploadedFiles([...uploadedFiles, ...files])
                      handleFileUpload(e)
                    }
                  }}
                />

                <UploadIcon className="size-3.5" />
              </div>
            </ModernTooltip>
          </label>
        </div>

        <DropdownMenuSeparator />
        <div className="overflow-auto max-h-44">
          {filteredFileOptions?.map((option: any) => (
            <DropdownMenuItem
              key={option.value}
              onClick={() => {
                onSelect({
                  file_name: option.label,
                  file_id: option.value,
                  file_type: option.type
                })
              }}
              className="flex items-center justify-between px-1.5 py-1 outline-none cursor-pointer hover:!bg-zinc-100 mb-1 w-full group text-zinc-800 font-medium"
            >
              <Tooltip
                content={
                  <div className="">
                    <div className="flex items-center rounded-md">
                      <div className="px-3 font-medium capitalize transition-all duration-100 ease-in-out border rounded-md opacity-0 text-xxs text-zinc-100 bg-zinc-800 place-content-center group-hover:opacity-100 border-zinc-200">
                        {option.type}
                      </div>
                      <p className="px-2 text-xs">{option.label}</p>
                    </div>
                  </div>
                }
              >
                <div
                  className={cn(
                    'flex items-center gap-2.5 text-sm justify-between w-full'
                  )}
                >
                  <span className="w-[95%] truncate text-xxs text-zinc-800">
                    {option.label}
                  </span>
                </div>
              </Tooltip>
            </DropdownMenuItem>
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default AttachFilesDropdown
