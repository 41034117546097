export const formatChatContext = (inputText: string) => {
  return `<br/><br/><blockquote class="chat-input-context">${inputText}</blockquote><br/>`
}

export const triggerChatSend = () => {
  const button = document.getElementById('chat-send-button')
  if (button) {
    button.click()
  }
}
