import React, { useState, useEffect } from 'react'
import { checkExpiry } from '../../utils/AWS'
import { ImageGallery, useConfirmation } from '../../components'

import Box from '@mui/material/Box'

import {
  changeProjectInfo,
  contentSearch,
  getParticularProject
} from '../../store/api'

import Drawer from '@mui/material/Drawer'
import TagCenterUpdate from '../TagCenterUpdate'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { updateProjectTag } from '../../store/Project/Actions'
import { checkUserRoleViewer } from '../../utils/User'
import Notebook from '../Notebook/Notebook'
import Button2 from '../../components/Button/Button2'
import {
  RedirectIcon,
  PencilIcon,
  ProjectIcon,
  SemiCircleIcon
} from '../../components/Icons/Icons'
import DisplayTags from '../ResumeResult/DisplayTags'
import { toast } from 'react-toastify'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import EditProjectModal from './EditProjectModal'
import AttachFilesUI from '../../components/AttachFilesUI/AttachFilesUI'
import { useNavigate, useParams } from 'react-router-dom'
import StatusDropdown from '@/containers/OpportunitiesList/StatusDropdown'
import { ModernCalendar } from '@/components/ModernCalendar/ModernCalendar'
import { ModernTooltip } from '@/components/ModernTooltip/ModernTooltip'
import Overview from './Overview'

const Text = (props) => {
  const { text, style } = props
  return <span style={style} dangerouslySetInnerHTML={{ __html: text }} />
}

const Opportunity = (props) => {
  const {
    singleData,
    s3Obj,
    thumbnail_url_list = [],
    originProposalNames = [],
    notesList = [],
    refresh,
    opportunityGroupId = '',
    pageType = 'default'
  } = props

  const [currentProject, setCurrentProject] = useState(null)
  const [selectedImage, setSelectedImage] = useState([])
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [editProfile, setEditProfile] = useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const isUserViewer = checkUserRoleViewer()
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [activeTab, setActiveTab] = useState(0)
  const [notesData, setNotesData] = useState(currentProject?.notes ?? [])
  const [searchQuery, setSearchQuery] = useState('')
  const [commentsData, setCommentsData] = useState([])
  const [filteredProjects, setFilteredProjects] = useState(currentProject?.data)
  const [basicInfo, setBasicInfo] = useState(null)
  const [fromRfx, setFromRfx] = useState(false)
  const [contentSearching, setContentSearching] = useState(false)
  const [files, setFiles] = useState([])
  const [projectLoading, setProjectLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [refreshState, setRefreshState] = useState(false)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const params = useParams()
  const { collectionId } = params || {}
  useEffect(() => {
    if (thumbnail_url_list.length > 0) {
      gets3Urls(thumbnail_url_list, s3Obj)
    } else {
      setLoading(false)
    }
  }, [thumbnail_url_list, s3Obj])

  useEffect(() => {
    const { uploaded_files = [] } = currentProject || {}
    setFiles(uploaded_files)
  }, [currentProject])

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredProjects(currentProject?.data)
      return
    }
    const timeout = setTimeout(() => {
      setContentSearching(true)
      trackEvent(
        mixpanelEvents.PROJECT_DOCUMENT_CONTENT_SEARCHED,
        'SUCCESS',
        {},
        {
          project_id: currentProject?.id,
          search_query: searchQuery
        }
      )

      contentSearch({
        group_id: currentProject?.id,
        search_keyword: searchQuery,
        search_type: 'project'
      })
        .then((res) => {
          // console.log()
          const foundList = res.data.data
          setContentSearching(false)
          const filtered = singleData.data.filter((project) => {
            return foundList.includes(project.id)
          })

          setFilteredProjects(filtered)
        })
        .catch((err) => {
          console.log(err)
          setContentSearching(false)
        })
    }, 1500)

    return () => clearTimeout(timeout)
  }, [searchQuery])

  useEffect(() => {
    if (activeTab === 1) {
      trackEvent(
        mixpanelEvents.OPPORTUNITY_NOTES_TAB_OPENED,
        'SUCCESS',
        {},
        {
          project_id: currentProject?.id
        }
      )
    }

    if (activeTab === 2) {
      trackEvent(
        mixpanelEvents.OPPORTUNITY_FILE_TAB_OPENED,
        'SUCCESS',
        {},
        {
          project_id: currentProject?.id
        }
      )
    }
  }, [activeTab])

  useEffect(() => {
    if (collectionId && collectionId !== '') getProjectData(collectionId)
  }, [collectionId])

  useEffect(() => {
    if (opportunityGroupId && opportunityGroupId !== '')
      getProjectData(opportunityGroupId)
  }, [opportunityGroupId])

  useEffect(() => {
    if (currentProject) {
      setBasicInfo({
        name: currentProject?.title,
        logo: '',
        properties: currentProject?.properties,
        project_type:
          currentProject?.properties?.project_type?.value ?? 'unknown',
        tags: currentProject?.tags,
        id: currentProject?.id
      })
    }
  }, [currentProject])

  const getProjectData = async (id, skipLoading = false) => {
    if (!skipLoading) {
      setProjectLoading(true)
    }

    const res = await getParticularProject(id, true)
    setProjectLoading(false)
    setCurrentProject(res.data?.project)
    // console.log('res currentProject', res.data.project, currentProject)
    const fromRfxTemp = res.data?.project?.rfx_analysis?.length > 0 || false
    setFromRfx(fromRfxTemp)
    setBasicInfo({
      ...basicInfo,
      ...res.data?.project
    })
    setNotesData(res.data.project?.notes)
    setCommentsData(res.data.project?.comments)
  }

  const gets3Urls = async (images, s3Obj) => {
    const newImagesUrl = []
    try {
      await Promise.all(
        images.map(async (imageUrl) => {
          const signedSrc = await checkExpiry(
            imageUrl?.src ? imageUrl?.src : imageUrl,
            s3Obj
          )
          newImagesUrl.push(signedSrc)
        })
      )
      const thumbnailList = []
      newImagesUrl.forEach((image, index) => {
        thumbnailList.push({
          screenShot: image,
          proposal_name: originProposalNames[index],
          proposal_id: currentProject?.proposal_id,
          page_number: currentProject?.page_number,
          clientId: currentProject?.client_group?.id
        })
      })
      setSignedThumbnailList(thumbnailList)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const renderTagDrawer = () => {
    const id = currentProject?.id
    const document_type = 'project'
    return (
      id && (
        <Drawer
          anchor={'right'}
          open={showTagDrawer}
          onClose={() => setShowTagDrawer(false)}
        >
          <Box sx={{ padding: '20px', width: '400px', height: '100%' }}>
            <TagCenterUpdate
              id={id}
              filterTags="project"
              document_type={document_type}
              onUpdateCallback={onUpdateCallback}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          </Box>
        </Drawer>
      )
    )
  }

  const onUpdateCallback = (id, mode, tagsFormated) => {
    if (mode === 'tags') {
      const newTags = []
      tagsFormated.forEach((tag) => {
        newTags.push(tag.id)
      })
      const payload = {
        id,
        tags: newTags
      }
      const callback = () => {
        // setTags(newTags)
        setRefreshState(true)
        setBasicInfo({
          ...basicInfo,
          tags: newTags
        })
      }
      dispatch(updateProjectTag(payload, callback))
    }
  }

  const updateOpportunityInfo = async (key, value) => {
    const oldRowInfo = {
      ...basicInfo
    }

    const data = {
      project_group_id: currentProject?.id,
      project_title: currentProject?.title,
      properties: {
        ...basicInfo?.properties,
        [key]: {
          value,
          is_verified: true
        }
      }
    }

    try {
      const res = await changeProjectInfo(data)
      if (res.status === 200) {
        setRefreshState(true)
        setBasicInfo({
          ...basicInfo,
          properties: {
            ...basicInfo.properties,
            [key]: {
              value,
              is_verified: true
            }
          },
          [key]: value
        })
      }
    } catch (err) {
      // console.log('err', err)
      toast.error('Opportunity info update failed')
      setLoading(true)
      setBasicInfo(oldRowInfo)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  const attachRFP = async (file) => {
    let data = {
      project_group_id: currentProject?.id,
      project_title: currentProject?.title,
      properties: {
        ...basicInfo?.properties
      },
      attached_files: [file]
    }

    // console.log('file rfp', file, data)

    if (!basicInfo.properties.submission_date) {
      data = {
        ...data,
        properties: {
          ...data.properties,
          submission_date: {
            value: null
          }
        }
      }
    }

    try {
      const res = await changeProjectInfo(data)
      if (res.status === 200) {
        // setRefreshState(true)
        getProjectData(collectionId || opportunityGroupId)
      }
    } catch (err) {
      // console.log('err', err)
      toast.error('RFP attachment failed')
    }
  }

  if (projectLoading) {
    return (
      <div className="grid h-full place-content-center">
        <SemiCircleIcon className="size-5 text-grey-400 animate-spin-fast" />
      </div>
    )
  }

  return (
    <>
      <div className="w-full h-full">
        <div className="h-full">
          <div
            style={{
              width: '70%',
              margin: 'auto',
              padding: '20px',
              paddingBottom: '0px'
            }}
          >
            {pageType === 'default' && (
              <div className="flex items-center gap-2 mb-3 text-xxs text-grey-500">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    navigate('/library/opportunities')
                  }}
                >
                  Opportunity
                </span>{' '}
                <span> {'>'} </span>{' '}
                <span className="text-grey-700 text-capitalize w-44 text-truncate">
                  {basicInfo?.name}
                </span>
              </div>
            )}
            <div className="flex items-center gap-2">
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  padding: '6px',
                  borderRadius: '10px',
                  border: '1px solid var(--grey-200)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'var(--grey-100)'
                }}
              >
                <ProjectIcon className="mt-1 size-4 text-grey-400" />
              </div>
              <div className="flex flex-col w-full">
                <div className="flex items-center w-full gap-2">
                  <div className="w-full">
                    <div className="flex items-center">
                      <p
                        className="m-0 text-lg font-medium leading-5  gap-1 text-capitalize ml-1.5"
                        style={{
                          maxHeight: '40px',
                          width: '80%',
                          overflow: 'hidden'
                        }}
                      >
                        {basicInfo?.name}
                      </p>
                      {!isUserViewer && (
                        <Button2
                          secondary
                          style={{
                            marginLeft: 'auto',
                            gap: '6px'
                          }}
                          onClick={() => {
                            setEditProfile(true)
                          }}
                        >
                          <PencilIcon className="size-3 text-grey-700" />
                          Edit
                        </Button2>
                      )}
                    </div>

                    {/* {basicInfo.projectType !== 'unknown' && (
                      <div className="px-2 mb-1 rounded-full bg-grey-100 text-xxs w-fit">
                        {basicInfo.projectType}
                      </div>
                    )} */}

                    {basicInfo && (
                      <div className="flex items-center gap-1 twp">
                        <StatusDropdown
                          showLabel={true}
                          groupType="opportunity"
                          initialStatus={
                            basicInfo?.properties?.opportunity_status?.value
                          }
                          changeStatus={(status) => {
                            updateOpportunityInfo('opportunity_status', status)
                          }}
                        />

                        <div className="rounded-full size-1 bg-zinc-200"> </div>

                        <ModernTooltip
                          content={
                            basicInfo?.properties?.submission_date?.value ? (
                              <div className="gap-1 m-0 text-xs twp bg-white border border-zinc-200">
                                <p className="text-grey-800">
                                  {' '}
                                  Submission Date{' '}
                                </p>
                                <p className="text-sm font-medium text-grey-800">
                                  {moment(
                                    basicInfo?.properties?.submission_date
                                      ?.value
                                  ).format('DD MMMM, YYYY')}
                                </p>
                              </div>
                            ) : (
                              <p className="m-0 text-xs">
                                Set submission date...
                              </p>
                            )
                          }
                        >
                          <div>
                            <ModernCalendar
                              value={
                                basicInfo?.properties?.submission_date?.value
                              }
                              showLabel={true}
                              label="Set submission date"
                              onChange={(date) => {
                                updateOpportunityInfo('submission_date', date)
                              }}
                            />
                          </div>
                        </ModernTooltip>

                        <div className="rounded-full size-1 bg-zinc-200"> </div>
                        {currentProject?.company &&
                          currentProject?.company?.length > 0 && (
                            <>
                              {currentProject?.company?.map((company) => (
                                <>
                                  <ModernTooltip
                                    content={
                                      <div className="px-1 font-medium bg-white border border-zinc-200">
                                        <div className="flex items-center gap-1 text-xs text-zinc-700">
                                          {/* <ProjectIcon className="size-3 text-zinc-700" />{' '} */}
                                          Company details
                                        </div>

                                        <div
                                          className="flex items-center gap-1 py-1 text-sm font-medium cursor-pointer text-zinc-800"
                                          onClick={() => {
                                            window.open(
                                              `/library/companies/${company?.company_id}`,
                                              '_blank'
                                            )
                                          }}
                                        >
                                          {company?.company_name}{' '}
                                          <RedirectIcon className="size-3 text-zinc-700" />
                                        </div>
                                      </div>
                                    }
                                  >
                                    <div
                                      className="flex items-center gap-1 p-1 px-2 text-xs font-medium rounded-md cursor-pointer text-zinc-800 hover:bg-zinc-100"
                                      onClick={() => {
                                        window.open(
                                          `/library/companies/${company?.company_id}`,
                                          '_blank'
                                        )
                                      }}
                                    >
                                      <ProjectIcon className="size-3.5 text-zinc-500" />
                                      {company?.company_name}
                                    </div>
                                  </ModernTooltip>

                                  <div className="rounded-full size-1 bg-zinc-200">
                                    {' '}
                                  </div>
                                </>
                              ))}
                            </>
                          )}
                        <DisplayTags
                          tags={basicInfo?.tags ?? []}
                          setShowTagDrawer={() => {
                            setShowTagDrawer(true)
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-3">
              <p
                className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
                onClick={() => setActiveTab(0)}
                style={{
                  borderBottom: activeTab === 0 && '2px solid var(--grey-700)'
                }}
              >
                Overview
              </p>
              <p
                className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
                onClick={() => setActiveTab(1)}
                style={{
                  borderBottom: activeTab === 1 && '2px solid var(--grey-700)'
                }}
              >
                Notes
              </p>
              <p
                className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
                onClick={() => setActiveTab(2)}
                style={{
                  borderBottom: activeTab === 2 && '2px solid var(--grey-700)'
                }}
              >
                Files
              </p>
            </div>
          </div>

          {currentProject && (
            <div
              className="p-4 bg-zinc-50"
              style={{
                height: 'calc(100% - 200px)'
              }}
            >
              <div
                className="flex flex-col justify-start border rounded-lg border-zinc-100 twp"
                style={{
                  width: '70%',

                  margin: 'auto',
                  height: 'calc(100% - 20px)',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                  padding: '0 20px'
                }}
              >
                {activeTab === 0 ? (
                  <Overview
                    id={collectionId || opportunityGroupId}
                    uploadedFiles={currentProject?.uploaded_files ?? []}
                    commentsData={commentsData}
                    data={currentProject?.rfx_analysis?.[0] ?? null}
                    attachRFP={attachRFP}
                    refreshOpportunityState={refreshState}
                    setRefreshOpportunityState={setRefreshState}
                  />
                ) : null}

                {activeTab === 1 && (
                  <Notebook
                    notesData={notesData}
                    source={{
                      id: currentProject?.id,
                      type: 'project',
                      name: currentProject?.title
                    }}
                  />
                )}
                {activeTab === 2 && (
                  <AttachFilesUI
                    files={files}
                    setFiles={setFiles}
                    profileId={currentProject?.id}
                    source="project"
                    refreshGroup={() => {
                      getProjectData(collectionId || opportunityGroupId)
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}

      {editProfile && (
        <EditProjectModal
          open={editProfile}
          setOpen={() => setEditProfile(false)}
          project={basicInfo}
          onUpdate={() => {
            setEditProfile(false)
            getProjectData(collectionId || opportunityGroupId)
            // reloc
          }}
        />
      )}

      {ConfirmDialog}
      {renderTagDrawer()}
    </>
  )
}

export default Opportunity
