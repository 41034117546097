import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor()
  return {
    buttonTextWrapper: {
      fontSize: '12px',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      textTransform: 'none'
    },
    button: {
      backgroundColor: selectedColor
    },
    hideMenuListText: {
      fontSize: '12px',
      fontWeight: '500',
      color: '#000000',
      width: '100px',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      overflowWrap: 'break-word'
    },
    hideMenuDivider: {
      margin: '0px !important'
    },
    blackDivider: {
      backgroundColor: '#000000'
    },
    hideMenu: {
      '& .MuiSvgIcon-root': {
        fontSize: '18px'
      },
      '& .MuiButtonBase-root': {
        padding: '3px 5px'
      },
      '& .MuiMenu-paper': {
        border: '1px solid #E5E5E5',
        marginTop: '10px'
      },
      '& .MuiList-root': {
        padding: '0px'
      }
    },
    columnMenu: {
      '& .MuiButtonBase-root': {
        padding: '2px 5px !important'
      }
    },
    sortMenu: {
      '& .MuiButtonBase-root': {
        padding: '4px 5px'
      },
      '& .MuiMenu-paper': {
        border: '1px solid #E5E5E5',
        marginTop: '10px'
      },
      '& .MuiList-root': {
        padding: '0px'
      }
    },
    customResizeHandler: {
      height: '100%',
      position: 'absolute',
      top: 0,
      right: '0px',
      cursor: 'ew-resize',
      boxSizing: 'border-box',
      padding: '0 5px 5px 0',
      zIndex: 2
    },
    tableHeader: {
      padding: '8px 8px',
      textAlign: 'left',
      fontWeight: 700,
      fontSize: '14px',
      color: '#797979',
      backgroundColor: 'white',
      zIndex: 2,
      borderTop: '.5px solid var(--grey-200)',
      borderBottom: '.5px solid var(--grey-200)',
      position: 'relative',
      wordBreak: 'break-word'
    },
    tableRow: {
      borderBottom: '.5px solid var(--grey-200)',
      padding: '4px 8px',
      textAlign: 'left'
    }
  }
})

export { useStyles }
