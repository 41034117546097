import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import { isEmpty } from 'lodash'
import Loader from '../../components/Loader'
import { useStyles } from './styles'
import { $generateHtmlFromNodes } from '@lexical/html'
import {
  createLibraryDocument,
  clearLibraryDocument,
  createLibraryDocumentEditor
} from '../../store/Library/Actions'
import InputTags from '../../components/InputTags'
import Box from '@mui/material/Box'
import LexicalEditor from '../../components/Lexical'
import { Container, Section, SectionFixed } from '../../components/Container'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import AddToCollection from '../../sections/LibraryCard/AddToCollection'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import Button2 from '../../components/Button/Button2'

const CreateLibraryMeta = (props) => {
  const {
    onClose,
    currentDocment = -1,
    redirectOnCreate = false,
    htmlString,
    openEditor = false,
    metaData = {},
    title = '',
    onCreateCallback = () => {},
    setUnSavedChanges = () => {},
    activeCollection = {}
  } = props
  console.log('activeCollection', activeCollection)
  const [currentEditorState, setCurrentEditorState] = useState({})
  const [mentionedDocuments, setMentionedDocuments] = useState([])
  const [editedHtmlString, setEditedHtmlString] = useState('')

  const libraryState = useSelector((state) => state.library)
  const {
    createLibrary = {},
    libraryList = [],
    libraryCollections = {}
  } = libraryState
  const { public_collections = [], private_collections = [] } =
    libraryCollections
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()
  const location = useLocation()
  const path = location?.pathname
  const {
    cancelButton,
    container,
    editWrapper,
    inputLabel,
    libraryEditor,
    inputLabelText,
    tertiaryButton
  } = classes
  const { LIBRARY_KNOWLEDGE } = ROUTES
  const [loading, setLoading] = useState(false)
  const [newCollections, setNewCollections] = useState([])
  const [newCollectionsValues, setNewCollectionsValues] = useState([])

  const pageInput = [
    {
      id: 0,
      label: 'Knowledge Title',
      placeholder: 'Knowledge Title',
      value: title || metaData?.selectedTitle || '',
      error: false,
      errorMessage: 'Knowledge title cannot be empty'
    }
  ]
  const [pageAttributes, setPageAttributes] = useState(pageInput)

  const contentTitle = 'Knowledge Content'

  useEffect(() => {
    if (activeCollection?.id) {
      const tempCollections = {
        [activeCollection.id]: {
          ...activeCollection
        }
      }
      setNewCollectionsValues(tempCollections)
    }
  }, [activeCollection])

  useEffect(() => {
    const responseEmpty = isEmpty(createLibrary)
    if (!responseEmpty) {
      const id = createLibrary.document_id
      setLoading(false)
      onClose()
      const path = `${LIBRARY_KNOWLEDGE}?libraryId=${id}`
      const callback = navigate(path, {
        state: { newDocument: true }
      })
      dispatch(clearLibraryDocument(callback))
    }
  }, [createLibrary])

  useEffect(() => {
    setEditedHtmlString(htmlString)
  }, [htmlString])

  const handleInputChange = (event, i) => {
    const currentPageValues = JSON.parse(JSON.stringify(pageAttributes))
    currentPageValues[i].value = event.target.value
    currentPageValues[i].error = false
    setPageAttributes(currentPageValues)
  }

  const handleCreate = () => {
    let error = false
    const currentPageValues = JSON.parse(JSON.stringify(pageAttributes))
    currentPageValues.map((inputs) => {
      if (inputs.value === '') {
        inputs.error = true
        error = true
      } else {
        inputs.error = false
      }
      return inputs
    })
    if (!error) {
      const params = {
        document_content: currentEditorState,
        document_html: editedHtmlString,
        document_name: currentPageValues[0].value,
        collections: newCollections,
        mentioned_documents: mentionedDocuments,
        metadata: { src: 'user', ...metaData },
        library_type:
          activeCollection.library_type === 'note' ? 'note' : 'knowledge'
      }

      setLoading(true)
      dispatch(
        createLibraryDocument(params, () => {
          setTimeout(() => {
            onCreateCallback()
          }, 500)
        })
      )
    } else setPageAttributes(currentPageValues)
  }

  const handleCreatefromEditor = () => {
    let error = false
    const currentPageValues = JSON.parse(JSON.stringify(pageAttributes))
    currentPageValues.map((inputs) => {
      if (inputs.value === '') {
        inputs.error = true
        error = true
      } else {
        inputs.error = false
      }
      return inputs
    })
    if (!error && editedHtmlString) {
      const params = {
        document_content: currentEditorState,
        document_html: editedHtmlString,
        document_name: currentPageValues[0].value,
        collections: newCollections,
        metadata: metaData
      }
      dispatch(createLibraryDocumentEditor(params))
      onClose()
    } else setPageAttributes(currentPageValues)
  }

  const handleButton = () => {
    trackEvent(mixpanelEvents.KNOWLEDGE_CONTENT_CREATED, 'SUCCESS', {}, {})
    console.log('redirectOnCreate', redirectOnCreate)
    if (redirectOnCreate) {
      handleCreate()
      setUnSavedChanges(false)
    } else {
      handleCreatefromEditor()
    }
  }

  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

  const handleEditorChange = (currentEditorState, editor) => {
    editor.update(() => {
      setUnSavedChanges(true)
      console.log('currentEditorState 2', currentEditorState)
      let mentionedDocsTemp = []
      currentEditorState?._nodeMap?.forEach((node) => {
        if (
          node?.__type === 'mention' &&
          node?.__id &&
          uuidRegex.test(node.__id)
        ) {
          const mention = {
            id: node.__id,
            type: node.__mentionType,
            name: node.__text,
            is_mentioned: true
          }
          mentionedDocsTemp.push(mention)
        }
      })

      mentionedDocsTemp = [...mentionedDocuments, ...mentionedDocsTemp]

      const uniqueMentions = mentionedDocsTemp.filter(
        (thing, index, self) =>
          index ===
          self.findIndex((t) => t.id === thing.id && t.type === thing.type)
      )

      setMentionedDocuments(uniqueMentions)

      setCurrentEditorState(currentEditorState)
      const htmlString = $generateHtmlFromNodes(editor, null)
      setEditedHtmlString(htmlString)
    })
  }

  const handleNewCollections = (e, val) => {
    setNewCollections(e)
    const newVal = {}
    val.forEach((el) => {
      newVal[el.id] = {
        ...el
      }
    })
    setNewCollectionsValues(newVal)
  }

  const collectionKeys = Object.keys(newCollectionsValues || {}).map(
    (key) => newCollectionsValues[key].name
  )

  return (
    <Container>
      <Section>
        {loading ? (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className="search-loader-container"
          >
            <Loader
              loading={loading}
              caption={'creating new knowledge article'}
            />
          </Grid>
        ) : (
          <Container>
            <SectionFixed>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px'
                }}
              >
                <div
                  style={{
                    width: '60%'
                  }}
                >
                  <input
                    type="text"
                    className="editor-title"
                    value={pageAttributes[0].value}
                    onChange={(e) => handleInputChange(e, 0)}
                    placeholder="Untitled"
                  />
                </div>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Box>
                    {!_.isEmpty(newCollectionsValues) && (
                      <>
                        <InputTags
                          showLabel
                          label=""
                          tags={collectionKeys || []}
                          showTagOnly={true}
                          tagClick={() => {}}
                        />
                      </>
                    )}
                  </Box>
                  <AddToCollection
                    collections={newCollectionsValues}
                    onSelect={handleNewCollections}
                    detailed
                  />
                  <Tooltip title="Close Editor">
                    <IconButton
                      disableRipple
                      onClick={() => onClose()}
                      sx={{
                        paddingRight: '0px'
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </SectionFixed>
            <Section
              style={{
                marginBottom: '20px'
              }}
            >
              <Container>
                <Section>
                  {!openEditor ? (
                    <Box
                      className={editWrapper}
                      contentEditable="true"
                      dangerouslySetInnerHTML={{ __html: editedHtmlString }}
                      onBlur={(e) =>
                        setEditedHtmlString(e.currentTarget.innerHTML)
                      }
                    ></Box>
                  ) : (
                    <Box id="library-editor" className={libraryEditor}>
                      <LexicalEditor
                        documentHtml={editedHtmlString}
                        // unSavedChanges={unSavedChanges}
                        // setUnSavedChanges={}
                        initialEditorContent={{}}
                        handleEditorChange={handleEditorChange}
                        enableAI
                      />
                    </Box>
                  )}
                </Section>
              </Container>
            </Section>
            <SectionFixed>
              <Box
                sx={{
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                {/* <Box>
                  {
                    !_.isEmpty(newCollectionsValues) &&
                    <>
                      <InputTags
                        showLabel
                        label={<span style={{ fontStyle: 'italic' }}>Collections:</span>}
                        tags={collectionKeys || []}
                        showTagOnly={true}
                        tagClick={() => { }}
                      />
                    </>
                  }
                </Box> */}
                <div className="flex gap-2">
                  <Button2 secondary noOutline onClick={() => onClose()}>
                    Cancel
                  </Button2>

                  <Button2 primary onClick={() => handleButton()}>
                    Create Content
                  </Button2>
                </div>
              </Box>
            </SectionFixed>
          </Container>
        )}
      </Section>
    </Container>
  )
}

export default CreateLibraryMeta
