import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  wrapperDisabled: {
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    background: '#f5f5f5',
    cursor: 'not-allowed !important'
  },
  wrapper: {
    // border: '1px solid #e0e0e0',
    borderRadius: '4px'
  },
  placeholder: {
    padding: '1px 4px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dateWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    background: 'var(--grey-100)',
    borderRadius: '20px',
    padding: '2px 5px',
    // flexWrap: 'wrap',
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    }
  },
  flexWrapper: {
    display: 'flex',
    gap: '5px',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  iconButton: {
    padding: 0,
    color: '#797979',
    fontSize: '10px'
  },
  placeholderText: {
    fontSize: '12px',
    color: '#797979'
  },
  dateStyle: {
    '& .react-date-picker__wrapper': {
      border: 'none',
      background: 'var(--grey-100)',
      fontFamily: 'PoppinsRegular',
      fontSize: '11px',
      padding: '3px'
    },
    '& .react-date-picker__inputGroup__input:invalid': {
      background: '#0000001a'
    },
    '& .react-calendar': {
      fontFamily: 'PoppinsRegular',
      border: '1px solid #e5e5e5',
      marginTop: '1px',
      padding: '4px'
    },
    '& .react-calendar__tile': {
      padding: '10px 6.6667px !important'
    },
    '& .react-calendar__navigation': {
      borderBottom: '1px solid #e5e5e5'
    },
    '& .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile':
      {
        padding: '2em 0.5em !important'
      },
    '& .react-calendar__viewContainer': {
      marginBottom: '15px'
    },
    '& .react-calendar__navigation button': {
      fontFamily: 'PoppinsRegular'
    },
    '& .react-calendar__tile--now': {
      background: '#e5e5e5'
    },
    '& .react-calendar__tile--active': {
      background: '#E8F0FE',
      color: '#000'
    },
    '& .react-calendar__month-view__days__day--weekend': {
      color: '#000'
    },
    '& .react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury':
      {
        color: '#9c9c9c !important'
      },
    '& .react-calendar__tile--hasActive': {
      background: '#E8F0FE'
    },
    '& .react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus':
      {
        background: '#d3dff5'
      },
    '& .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus':
      {
        background: '#d3dff5'
      },
    '& .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus':
      {
        background: '#dcdcdc'
      }
  }
}))

export { useStyles }
