import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useStyles } from './styles'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import Button2 from '../Button/Button2'
import { WarningIcon } from '../Icons/Icons'

const useConfirmation = () => {
  const [confirmConfig, setConfirmConfig] = useState(null)
  const [isConfirmVisible, setConfirmVisible] = useState(false)
  const classes = useStyles()
  const {
    actionContianer,
    cancelButton,
    submitButton,
    dialogHeader,
    dialogContents,
    contentWrapper
  } = classes

  const showConfirmDialog = (props) => {
    const {
      onConfirm,
      onCancel,
      deleteType = false,
      confirmationMessageTitle = 'Are you sure?',
      confirmationMessage = '',
      confirmButtonText = "Yes, I'm sure",
      cancelButtonText = 'Cancel',
      extraButtons = []
    } = props || {}
    setConfirmConfig({
      onConfirm,
      onCancel,
      deleteType,
      confirmationMessageTitle,
      confirmationMessage,
      confirmButtonText,
      cancelButtonText,
      extraButtons
    })
    setConfirmVisible(true)
  }

  const {
    onConfirm = () => {},
    onCancel = () => {},
    deleteType,
    confirmationMessageTitle = 'Are you sure?',
    confirmationMessage = '',
    confirmButtonText = "Yes, I'm sure",
    cancelButtonText = 'No',
    extraButtons = []
  } = confirmConfig || {}

  const handleConfirm = () => {
    onConfirm()
    setConfirmVisible(false)
  }

  const handleCancel = () => {
    onCancel()
    setConfirmVisible(false)
  }

  return {
    isConfirmVisible,
    showConfirmDialog,
    ConfirmDialog: (
      <Dialog
        open={isConfirmVisible}
        onClose={handleCancel}
        sx={{
          zIndex: 9999,
          borderRadius: '12px'
        }}
      >
        <Grid className={contentWrapper}>
          <DialogTitle id="responsive-dialog-title" className={dialogHeader}>
            {confirmationMessageTitle}
          </DialogTitle>
          {confirmationMessage.length > 0 && (
            <div
              className="p-1 px-3 rounded bg-orange-100 text-sm text-orange-600 flex items-center m-3"
              style={{
                borderLeft: '1.5px solid var(--orange-600)',
                borderRadius: '0 6px 6px 0'
              }}
            >
              <WarningIcon
                className="mr-2 size-4"
                style={{
                  minWidth: '24px'
                }}
              />
              {confirmationMessage}
            </div>
          )}
          <div
            className={actionContianer}
            style={{
              marginTop: '14px',
              display: 'flex',
              gap: '10px'
            }}
          >
            <Button2
              secondary
              noOutline
              onClick={(e) => {
                e.stopPropagation()
                handleCancel()
              }}
            >
              {cancelButtonText}
            </Button2>
            {deleteType ? (
              <Button2
                secondary={deleteType}
                noOutline
                style={{
                  backgroundColor: '#ef4444',
                  color: 'white'
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  handleConfirm()
                }}
              >
                {confirmButtonText}
              </Button2>
            ) : (
              <Button2
                primary={!deleteType}
                secondary={deleteType}
                style={
                  {
                    // background: deleteType ? '#ff5252 important' : '',
                    // color: 'white',
                    // border: 'none !important'
                  }
                }
                onClick={(e) => {
                  e.stopPropagation()
                  handleConfirm()
                }}
              >
                {confirmButtonText}
              </Button2>
            )}
            {extraButtons.map((button, index) => (
              <Button2
                key={index}
                id={button.id}
                onClick={(e) => {
                  e.stopPropagation()
                  button.onClick()
                  setConfirmVisible(false)
                }}
                {...(button?.buttonProps || {})}
              >
                {button.text}
              </Button2>
            ))}
          </div>
        </Grid>
      </Dialog>
    )
  }
}

export default useConfirmation
