import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip'

export function ModernTooltip({
  children,
  content,
  align = 'center'
}: {
  children: React.ReactNode
  content: React.ReactNode
  align?: 'start' | 'center' | 'end'
}) {
  return (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent align={align} asChild>
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
