import React, { forwardRef, useEffect, useMemo, useState } from 'react'

import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'

import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { triggerChatSend, formatChatContext } from '@/utils/Chat'
import { addContentToChat } from '@/store/Chat/Actions'
import {
  Heading1Icon,
  ListIcon,
  PenLineIcon,
  QuoteListIcon,
  TextIcon,
  ListPlusIcon,
  ListMinusIcon,
  ListRestartIcon,
  AlignCenterIcon,
  AlignJustifyIcon
} from '@/components/Icons/Icons'

const defaultPrompt = {
  label: 'Summarizing',
  name: 'Summarize',
  keywords: [
    'summarize',
    'sum up',
    'abridge',
    'condense',
    'encapsulate',
    'outline'
  ],
  prompt: ['Generate an outline for this topic:\n\n%prompt%'],
  placeholderText: ['Summerize this text'],
  placeholder: 'Summerize this text',
  promptType: 'select',
  followUpPrompt: [],
  button_text: 'Generate',
  help_text: 'Generate'
}

const listDisplayOrder = [
  { label: 'Create Bullet Points', icon: <ListIcon className="size-4" /> },
  { label: 'Create Title', icon: <Heading1Icon className="size-4" /> },
  { label: 'Make Longer', icon: <ListPlusIcon className="size-4" /> },
  { label: 'Make Shorter', icon: <ListMinusIcon className="size-4" /> },
  { label: 'Rewrite', icon: <ListRestartIcon className="size-4" /> },
  { label: 'Simplify', icon: <TextIcon className="size-4" /> },
  { label: 'Summarize', icon: <QuoteListIcon className="size-4" /> },
  { label: 'Harmonize', icon: <AlignCenterIcon className="size-4" /> },
  {
    label: 'Remove Line Breaks',
    icon: <AlignJustifyIcon className="size-4" />
  }
]

type AiGenerateOptionsProps = {
  textSelectProps: any
}

export const AiGenerateOptions = forwardRef<
  HTMLInputElement,
  AiGenerateOptionsProps
>(({ textSelectProps }: AiGenerateOptionsProps, ref) => {
  // @ts-ignore
  const common = useSelector((userState) => userState?.common)
  const dispatch = useDispatch()

  const { prompts = [] } = common
  const [queryString, setQueryString] = useState<string | null>(null)
  const [selectedText, setSelectedText] = useState(null)

  useEffect(() => {
    if (textSelectProps?.selectedText)
      setSelectedText(textSelectProps.selectedText)
  }, [textSelectProps])

  const handleGenerate = async (
    inputText: string,
    generateType: any,
    autoTrigger = false
  ) => {
    if (inputText && generateType) {
      const prompt =
        generateType?.prompt[
          Math.floor(Math.random() * generateType?.prompt.length)
        ]
      const userprompt = prompt
        ?.replace('%prompt%', formatChatContext(inputText))
        .trim()

      // @ts-ignore
      dispatch(addContentToChat(userprompt))
      const element = document.activeElement
      // @ts-ignore
      element && element?.blur()
      textSelectProps.cancel?.()

      if (autoTrigger) {
        setTimeout(() => {
          triggerChatSend()
        }, 200)
      }
    }
  }

  // TODO: This is the old logic, can be improved with respect to new requirements
  const options = useMemo(() => {
    let optionsArray = prompts
    if (optionsArray?.length === 0) optionsArray = [defaultPrompt]
    let optionsList = []
    const selectOptions = _.filter(optionsArray, { promptType: 'select' })
    optionsList = [...selectOptions]
    let baseOptions = []
    baseOptions = [
      ...optionsList.map((option) => {
        return {
          key: option.name,
          title: option.name,
          icon: listDisplayOrder.find((order) => order.label === option.name)
            ?.icon ?? <ListPlusIcon className="size-4" />,
          keywords: option.keywords,
          onSelect: () => {
            let randomPlaceHolder =
              option?.placeholderText[
                Math.floor(Math.random() * option?.placeholderText.length)
              ]
            randomPlaceHolder = randomPlaceHolder || 'Enter a prompt'
            const newGen = { ...option, placeholder: randomPlaceHolder }
            // setGenerateType(newGen)
            return newGen
          }
        }
      })
    ]

    const defaultQueryStr = queryString || 'Write your own prompt'
    const defaultpromptStr = `${queryString || ''} %prompt%`
    const defaultKeyword = queryString || ''

    const typeOrderMap = new Map()
    listDisplayOrder.forEach((key, index) => {
      typeOrderMap.set(key.label, index)
    })

    const hiddenList = ['Improve Content', 'Grammer Check']
    // Sort array1 based on the order in array2
    baseOptions.sort((a, b) => {
      const orderA = typeOrderMap.get(a.key)
      const orderB = typeOrderMap.get(b.key)
      return orderA - orderB
    })

    baseOptions = baseOptions.filter((obj) =>
      listDisplayOrder.some((order) => order.label === obj.key)
    )

    baseOptions = [
      ...baseOptions,
      {
        key: defaultQueryStr,
        title: defaultQueryStr,
        icon: <PenLineIcon className="size-4" />,
        keywords: [defaultKeyword],
        onSelect: () => {
          let randomPlaceHolder =
            defaultPrompt?.placeholderText[
              Math.floor(Math.random() * defaultPrompt?.placeholderText.length)
            ]
          randomPlaceHolder = randomPlaceHolder || 'Enter a prompt'
          const newGen = {
            ...defaultPrompt,
            prompt: [defaultpromptStr],
            placeholder: randomPlaceHolder
          }
          // setGenerateType(newGen)
          return newGen
        }
      }
    ]
    return queryString
      ? [
          ...baseOptions.filter((option) => {
            return new RegExp(queryString, 'gi').exec(option.title) ||
              option.keywords != null
              ? option.keywords.some((keyword: any) =>
                  new RegExp(queryString, 'gi').exec(keyword)
                )
              : false
          })
        ]
      : baseOptions
  }, [queryString])

  return (
    <Command loop shouldFilter={false}>
      <CommandInput
        ref={ref}
        autoFocus
        placeholder="Search or Write Prompt"
        value={queryString || ''}
        onValueChange={(value) => setQueryString(value)}
        className="h-9"
      />
      <CommandList className="max-h-[350px]">
        <CommandGroup>
          {options.map((option) => (
            <CommandItem
              className="flex items-center gap-2"
              key={option.title}
              value={option.title}
              onSelect={() => {
                const textSelected = selectedText || ''
                const genSelected = option.onSelect()

                handleGenerate(
                  textSelected,
                  genSelected,
                  option.title !== 'Write your own prompt'
                )

                textSelectProps.cancel?.()
              }}
            >
              {option.icon}
              {option.title}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  )
})
