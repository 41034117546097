import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor() // Get the selected color from the context

  return {
    webviewer: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      // width: "90vh",
      overflow: 'auto'
    },
    infoContainer: {
      borderLeft: '1px solid #E5E5E5',
      display: 'flex',
      height: '100%',
      flexDirection: 'column'
    },
    infoWrapper: {
      height: 'calc(100% - 8px)'
    },
    page: {
      height: '100%'
    },
    container: {
      position: 'centre'
    },
    dot: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      marginRight: '3px',
      minWidth: '10px',
      minHeight: '10px'
    },
    errorWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
      // alignItems: 'baseline'
    },
    keywordWrapper: {
      // maxWidth: "205px",
      // textOverflow: "ellipsis",
      // overflow: 'hidden',
      // whiteSpace: "nowrap",
      display: 'flex',
      fontSize: '16px',
      fontWeight: '500',
      padding: '1px'
    },
    contentWrapper: {
      fontSize: '16px',
      fontWeight: '500'
    },
    typeWrapper: {
      width: '35%',
      fontWeight: '400',
      fontStyle: 'italic'
    },
    pageNoWrapper: {
      fontWeight: '500'
    },
    errorContainer: {
      // width: "100%",
      border: '1px solid #E5E5E5',
      borderRadius: '4px',
      padding: '8px',
      margin: '5px 5px',
      fontFamily: 'PoppinsRegular',
      cursor: 'pointer',
      '&:hover': {
        background: '#9b94941a'
      }
    },
    descWrapper: {
      color: '#949494',
      fontWeight: '500'
    },
    replacementWrapper: {
      fontStyle: 'italic'
    },
    collapseWrapper: {
      marginTop: '10px',
      padding: '0px  10px'
    },
    buttonWrapper: {
      textAlign: 'end',
      '& button': {
        margin: '10px 5px'
      }
    },
    generateDropDown: {
      marginTop: '10px'
    },
    generateText: {
      marginTop: '10px',
      width: '100%'
    },
    generateButton: {
      marginTop: '10px',
      textAlign: 'end'
    },
    buttonGroupWrapper: {
      justifyContent: 'center',
      marginTop: '10px',
      marginBottom: '10px',
      display: 'flex',
      padding: '10px'
    },
    borderBot: {
      borderBottom: '1px solid #E5E5E5'
    },
    pdfTitle: {
      p: 1,
      flex: '1',
      textAlign: 'center'
    },
    icon: {
      height: '16px',
      width: '16px'
    },
    iconButton: {
      float: 'left'
    },
    shareFileIcon: {
      p: 1,
      marginRight: '10px'
    },
    pdfHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    titleContainer: {
      margin: '7px 0px'
    },
    generatedText: {
      marginTop: '20px',
      border: '1px solid #E5E5E5',
      padding: '5px'
    },
    errorOverWrapper: {
      height: '0px',
      padding: '10px'
    },
    paddingWrapper: {
      padding: '10px'
    },
    noResultWrapper: {
      textAlign: 'center'
    },
    accordianTitle: {
      textTransform: 'capitalize',
      fontWeight: 'bold'
    },
    groupCount: {
      width: '25px',
      height: '25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
      fontSize: '12px'
    },
    correctionContainer: {
      width: '100%'
    },
    toggleSwitchScroll: {
      overflow: 'auto'
    },
    buttonHighlight1: {
      background: selectedColor,
      color: '#fff',
      fontWeight: 'bold',
      borderColor: selectedColor,
      '&:hover': {
        background: selectedColor
      }
    },
    buttonHighlight2: {
      background: '#fff',
      color: selectedColor,
      fontWeight: 'bold',
      borderColor: selectedColor,
      '&:hover': {
        background: '#fff'
      }
    },
    groupingErrorCard: {
      paddingRight: '30px'
    },

    iconAiFab: {
      display: 'grid',
      alignContent: 'center',
      '&:before': {
        content: '""',
        position: 'absolute',
        inset: '0',
        borderRadius: '50px',

        padding: '1.4px',
        background: 'linear-gradient(100deg, #471CA8, #FF3465)',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude'
      }
    },

    hideBorder: {
      '&.MuiAccordion-root::before': {
        opacity: 0,
        height: 0
      }
    }
  }
})

export { useStyles }
