import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/react-calender'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { AddCalendarIcon, CalendarIcon } from '../Icons/Icons'
import moment from 'moment'
import { useEffect, useState } from 'react'

export function ModernCalendar({
  value,
  onChange,
  showLabel = false,
  label = 'Set Date',
  format = 'DD, MMM, YYYY',
  disabled = false
}: {
  value: Date | undefined
  onChange: (date: Date | undefined) => void
  showLabel?: boolean
  label?: string
  format?: string
  disabled?: boolean
}) {
  const [date, setDate] = useState<Date | undefined>(value)

  useEffect(() => {
    setDate(value)
  }, [value])

  return (
    <Popover>
      <PopoverTrigger asChild disabled={disabled}>
        <button className="flex items-center gap-1 px-2 py-1 rounded-md outline-none twp hover:bg-zinc-100">
          {!date ? (
            <AddCalendarIcon className="ml-auto h-3.5 w-3.5 text-zinc-500" />
          ) : (
            <CalendarIcon className="ml-auto h-3.5 w-3.5 text-zinc-500" />
          )}
          {date && (
            <p className="text-xs font-medium text-zinc-800">
              {moment(date).format(format)}
            </p>
          )}
          {!date && showLabel && <p className="text-xs">{label}</p>}
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="center">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(day) => {
            setDate(day)
            onChange(day)
          }}
          initialFocus
          disabled={disabled}
        />
      </PopoverContent>
    </Popover>
  )
}
