import { useEffect, useState } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Checkbox,
  IconButton,
  List,
  ListItem,
  Skeleton,
  ListItemButton,
  ListItemText,
  Tabs,
  Tab
} from '@mui/material'
import { useStyles } from './styles'
import { searchProposals } from '../../store/api'
import SearchNew from '../../sections/ListFilter/SearchNew'
import Button2 from '../Button/Button2'
import { getCollectionChildren } from '../../store/Resource/Action'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import StopIcon from '@mui/icons-material/Stop'
import { useDispatch, useSelector } from 'react-redux'

const DocumentAdd = (props) => {
  const {
    isOpen = false,
    handleClose = () => {},
    actionButtons = [],
    prevSelectedCollections = null,
    parent = null,
    showCollectionSelect = false,
    documentAlert = {}
  } = props

  const classes = useStyles()

  const [selectedDocument, setSelectedDocument] = useState(null)

  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [expanded, setExpanded] = useState({})
  const collections = useSelector((state) => state.resource?.collections)
  const dispatch = useDispatch()

  const fetchOptions = async (query) => {
    setLoading(true)
    try {
      const res = await searchProposals(query)
      if (res.status === 200) {
        const { data } = res || {}
        const { results } = data
        const uniqueResults = results.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.value === item.value)
        )
        setOptions(uniqueResults)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      setOptions([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue === '') {
        fetchOptions(null)
      } else {
        fetchOptions(inputValue)
      }
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [inputValue])

  useEffect(() => {
    if (prevSelectedCollections) {
      setSelectedDocument(prevSelectedCollections)
    }
  }, [prevSelectedCollections, isOpen])

  const handleSelect = (item, source) => {
    if (!item) setSelectedDocument(null)
    else {
      const document_id = item?.value ? item?.value : item?.id
      const document_type = item?.type ? item?.type : source
      const document_name = item?.label ? item?.label : item?.name
      setSelectedDocument({ document_name, document_id, document_type, source })
    }
  }

  const closeModel = () => {
    handleClose()
    setTimeout(() => {
      setSelectedDocument(null)
    }, 100)
  }

  const CollectionItem = ({
    item,
    index = '',
    path = [],
    parentSelected = false
  }) => {
    const { id, name, icon, status, type, hasChildren = false, children } = item
    // const IconRender = MUIIcons[icon]
    const isSelected = parentSelected || selectedDocument?.document_id === id
    const currentPath = [...path, item]
    const isExpanded = expanded[id]
    const isCurrent =
      documentAlert?.document_id === id &&
      documentAlert?.document_type === 'collections'
    return status === 'active' ? (
      <Box key={index}>
        <ListItem disablePadding>
          <ListItemButton
            className={classes.listButtonWrapper}
            sx={{
              background: isCurrent ? 'var(--grey-400) !important' : 'inherit'
            }}
            onClick={() => {
              if (!parentSelected) {
                handleSelect(item, 'collections')
              }
            }}
          >
            <ListItemText
              primary={
                <Box className={classes.collectionTitleWrapper}>
                  <Box className={classes.listTextWrapper}>
                    <div
                      className="size-3 rounded-sm"
                      style={{
                        minWidth: '0.75rem',
                        background: isSelected
                          ? 'var(--grey-700)'
                          : 'var(--grey-200)'
                      }}
                    ></div>{' '}
                    {hasChildren ? (
                      isExpanded ? (
                        <IconButton
                          className={classes.iconButton}
                          onClick={(e) => {
                            setExpanded({ ...expanded, [id]: false })
                            e.stopPropagation()
                          }}
                        >
                          <ArrowDropDownIcon className={classes.icon} />
                        </IconButton>
                      ) : (
                        <IconButton
                          className={classes.iconButton}
                          onClick={(e) => {
                            if (!children) {
                              dispatch(getCollectionChildren(id))
                            }
                            setExpanded({ ...expanded, [id]: true })
                            e.stopPropagation()
                          }}
                        >
                          <ArrowRightIcon className={classes.icon} />
                        </IconButton>
                      )
                    ) : (
                      <IconButton disabled className={classes.iconButton}>
                        <StopIcon className={classes.iconEmpty} />
                      </IconButton>
                    )}
                    <p className="m-0 text-xs font">{name}</p>
                  </Box>
                </Box>
              }
            />
          </ListItemButton>
        </ListItem>
        {isExpanded && (
          <Box className={classes.nestedCollection}>
            {children ? (
              children.map((item, index) => {
                return (
                  <CollectionItem
                    key={index}
                    item={item}
                    index={index}
                    path={currentPath}
                    parentSelected={isSelected}
                  />
                )
              })
            ) : (
              <Skeleton
                variant="rectangular"
                width={'100%'}
                height={26.5}
                sx={{ marginBottom: '3px', marginTop: '2px' }}
              />
            )}
          </Box>
        )}
      </Box>
    ) : status === 'loading' ? (
      <ListItem disablePadding>
        <ListItemButton disabled className={classes.listButtonWrapper}>
          <ListItemText
            primary={
              <Box className={classes.listTextWrapper}>
                <IconButton disabled className={classes.iconButton}>
                  <StopIcon className={classes.iconEmpty} />
                </IconButton>
                {/* {icon && <IconRender className={classes.icon} />} */}
                <Box className={classes.listText}>{name}</Box>
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    ) : (
      <></>
    )
  }

  return (
    <Dialog
      data-dialog-parent={parent}
      maxWidth="xs"
      open={isOpen}
      onClose={closeModel}
      sx={{
        zIndex: 9999,
        '& .MuiPaper-root': {
          borderRadius: '15px'
        }
      }}
    >
      <DialogContent sx={{ width: '396px', padding: '5px 7px' }}>
        {
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
          >
            <Tab label="Files" />
            {showCollectionSelect && <Tab label="Collections" />}
          </Tabs>
        }
        {tabValue === 0 && (
          <div className="p-3 px-4 mt-1">
            <p className="m-0 text-sm font-medium">Attach Files</p>
            <p className="m-0 text-xs text-grey-500 mb-2">
              Choose which files you want to chat about.
            </p>
            <SearchNew
              value={inputValue}
              onChange={(value) => {
                setInputValue(value)
              }}
              placeholder="Search files"
              loading={loading}
            />
            <div
              className=""
              style={{
                minHeight: '35vh',
                maxHeight: '35vh',
                overflow: 'auto'
              }}
            >
              <div className="flex flex-col mt-2 gap-1">
                {['rfx', 'proposal', 'analytics'].includes(
                  documentAlert?.document_type
                ) && (
                  <div
                    className="text-sm py-2 bg-grey-200 flex items-center gap-2 hover-bg-grey-100 px-3 rounded-md cursor-pointer overflow-hidden"
                    onClick={() => {
                      setSelectedDocument({ ...documentAlert, source: 'file' })
                    }}
                  >
                    <div
                      className="size-3 rounded-sm"
                      style={{
                        minWidth: '0.75rem',
                        background:
                          selectedDocument?.document_id ===
                          documentAlert?.document_id
                            ? 'var(--grey-700)'
                            : 'var(--grey-200)'
                      }}
                    ></div>
                    <div className="w-full">
                      <p className="m-0 text-xs font">
                        {documentAlert.document_name}
                      </p>
                      <div className="flex gap-1">
                        <div
                          style={{
                            fontSize: '9px',
                            padding: '1.5px 5px'
                          }}
                          className="m-0 w-fit px-1 rounded-md text-grey-100 bg-grey-800 text-xxs"
                        >
                          {_.toUpper(documentAlert.document_type)}
                        </div>
                        <div
                          className="m-0 w-fit px-1 rounded-md text-grey-100 bg-grey-800 text-xxs"
                          style={{
                            fontSize: '9px',
                            padding: '1.5px 5px'
                          }}
                        >
                          CURRENT
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {options.map((file, i) => {
                  return (
                    documentAlert?.document_id !== file?.value && (
                      <div
                        key={i}
                        className="text-sm py-2 flex items-center gap-2 hover-bg-grey-100 px-3 rounded-md cursor-pointer overflow-hidden"
                        onClick={() => {
                          handleSelect(file, 'file')
                        }}
                      >
                        <div
                          className="size-3 rounded-sm flex-shrink-0"
                          style={{
                            minWidth: '0.75rem',
                            background:
                              selectedDocument?.document_id === file?.value
                                ? 'var(--grey-700)'
                                : 'var(--grey-200)'
                          }}
                        ></div>
                        <div className="flex-1 w-full flex justify-between items-center overflow-hidden gap-2">
                          <p className="m-0 text-xs font truncate flex-1">
                            {file.label}
                          </p>
                          <div
                            className="m-0 w-fit px-1 rounded-md text-grey-100 bg-grey-800 text-xxs"
                            style={{
                              fontSize: '9px',
                              padding: '1.5px 5px'
                            }}
                          >
                            {_.toUpper(file.type)}
                          </div>
                        </div>
                      </div>
                    )
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {tabValue === 1 && (
          <div className="p-3 px-4 mt-1">
            <p className="m-0 text-sm font-medium">Attach Collections</p>
            <p className="m-0 text-xs text-grey-500 mb-2">
              Choose which collections you want to chat about.
            </p>
            {collections && !_.isEmpty(collections) ? (
              <Box className={classes.collectionsList}>
                <Box className={classes.collectionListWrapper}>
                  <List className={classes.listWrapper}>
                    {collections.map((item, index) => (
                      <CollectionItem key={index} item={item} index={index} />
                    ))}
                  </List>
                </Box>
              </Box>
            ) : (
              <Box sx={{ padding: '20px' }}>
                No collections Found. Create a collection to add resources.
              </Box>
            )}
          </div>
        )}
        <DialogActions>
          <div className="flex justify-end gap-2">
            <Button2 secondary noOutline onClick={closeModel}>
              Cancel
            </Button2>
            {actionButtons.map((button, index) => {
              const { label = '', action = () => {}, condition = true } = button
              return (
                condition && (
                  <Button2
                    primary
                    key={index}
                    disabled={!selectedDocument}
                    onClick={() => {
                      action(selectedDocument)
                      closeModel()
                    }}
                  >
                    {label}
                  </Button2>
                )
              )
            })}
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default DocumentAdd
