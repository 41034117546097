import React, { useState, useEffect } from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import Tooltip from '@mui/material/Tooltip'
import Popover from '@mui/material/Popover'
import { createFilter } from 'react-select'
import { Button, TextInput, SelectDropDown } from '../../components'
import Box from '@mui/material/Box'
import { useSelector, useDispatch } from 'react-redux'
import _, { set } from 'lodash'
import { Fade, Grid, IconButton } from '@mui/material'
import { extractOptionsValue } from '../../utils/ReactSelect'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Loader from '../../components/Loader'
import { UPDATE_LIBRARY_COLLECTIONS } from '../../store/actionTypes'
import { createCollection } from '../../store/api'
import { setToasterAlert } from '../../store/Common/Actions'
import ReactMultiSelectCheckboxes from '../../components/MultiSelect'
import CloseIcon from '@mui/icons-material/Close'
import { checkUserRoleAdmin } from '../../utils/User'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { useColor } from '../../ThemeContext'
import { toast } from 'react-toastify'
import { BookmarkIcon } from '../../components/Icons/Icons'

const formatOptions = (obj) => {
  const arr = []
  Object.keys(obj).forEach((key) => {
    const temp = { ...obj[key] }
    const { name, visiblity } = obj[key]
    if (visiblity === 'private') {
      temp.label = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px 0px'
          }}
        >
          <Box>{name}</Box>
          <Tooltip title={'Visible to only you'}>
            <LockOutlinedIcon sx={{ strokeWidth: 1, stroke: '#ffffff' }} />
          </Tooltip>
        </Box>
      )
    } else {
      temp.label = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px 0px'
          }}
        >
          <Box>{name}</Box>
          <Tooltip title={'Visible to all'}>
            <LanguageOutlinedIcon sx={{ strokeWidth: 1, stroke: '#ffffff' }} />
          </Tooltip>
        </Box>
      )
    }
    temp.value = key
    arr.push(temp)
  })
  return arr
}

const AddToCollection = (props) => {
  const {
    collections = [],
    onSelect = () => {},
    view = 'icon',
    isFilled = false,
    setShowCollectionFilter = () => {},
    handleNewCollectionData = () => {},
    disabled = false,
    detailed = false,
    showApply = true
  } = props
  const dispatch = useDispatch()
  const isAdmin = checkUserRoleAdmin()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [newCollections, setNewCollections] = useState(
    formatOptions(collections || {})
  )
  const libraryState = useSelector((state) => state.library)
  const { libraryCollections = {} } = libraryState
  const [loading, setLoading] = useState(false)
  const [showCreateCollection, setShowCreateCollection] = useState(false)
  const [collectionName, setCollectionName] = useState('')
  const [collectionType, setCollectionType] = useState('private')
  const [collectionOptions, setCollectionOptions] = useState([])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = (e) => {
    e?.stopPropagation()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (open) {
      setNewCollections(formatOptions(collections || {}))
      setShowCreateCollection(false)
      setCollectionName('')
      setCollectionType('private')
      setLoading(false)
    }
  }, [open, libraryCollections])

  useEffect(() => {
    const options = []
    const { public_collections = [], private_collections = [] } =
      libraryCollections || {}
    private_collections.map((el) =>
      options.push({
        label: (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px 0px'
            }}
          >
            <Box>{el.name}</Box>
            <Tooltip title={'Visible to only you'}>
              <LockOutlinedIcon sx={{ strokeWidth: 1, stroke: '#ffffff' }} />
            </Tooltip>
          </Box>
        ),
        value: el.id,
        ...el
      })
    )
    if (isAdmin) {
      public_collections.map((el) =>
        options.push({
          label: (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '10px 0px'
              }}
            >
              <Box>{el.name}</Box>
              <Tooltip title={'Visible to all'}>
                <LanguageOutlinedIcon
                  sx={{ strokeWidth: 1, stroke: '#ffffff' }}
                />
              </Tooltip>
            </Box>
          ),
          value: el.id,
          ...el
        })
      )
    }
    setCollectionOptions(options)
  }, [libraryCollections])

  const removeLabel = (arr) => {
    return arr.map((el) => {
      const temp = { ...el }
      delete temp.label
      return temp
    })
  }

  const handleApply = (e) => {
    const newCollectionsValues = extractOptionsValue(newCollections)
    const collectionsValues = removeLabel(newCollections)
    const orgValues = Object.keys(collections || {})
    const isEqual = _.isEqual(orgValues?.sort(), newCollectionsValues?.sort())
    if (isEqual) {
      toast.error('No changes made to collections')
    } else {
      onSelect(newCollectionsValues, collectionsValues)
    }
    handleClose()
  }

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: (option) => {
      const { name, visiblity } = option?.data
      return `${name} ${visiblity}`
    },
    trim: true
  }

  const handleNewCollection = async () => {
    setLoading(true)
    if (collectionName && collectionType) {
      const res = await createCollection({
        name: collectionName,
        visibility: collectionType
      })
      if (res.status === 200) {
        dispatch({
          type: UPDATE_LIBRARY_COLLECTIONS,
          payload: res.data
        })
        setShowCreateCollection(false)
        handleNewCollectionData(res.data)
        setShowCollectionFilter(false)
        setCollectionName('')
        setCollectionType('private')
      } else {
        dispatch(
          setToasterAlert({
            message: res.data?.message || 'Error',
            show: true,
            type: 'error'
          })
        )
        setShowCollectionFilter(true)
      }
    } else {
      dispatch(
        setToasterAlert({
          message: 'Collection Title and Access Type is required',
          show: true,
          type: 'error'
        })
      )
      setShowCollectionFilter(true)
    }
    setLoading(false)
  }

  const handleCancelCreatNew = () => {
    setShowCollectionFilter(false)
  }

  const publicOption = {
    label: 'Public',
    value: 'public',
    icon: (
      <Tooltip title={'Visible to all'}>
        <LanguageOutlinedIcon sx={{ strokeWidth: 1, stroke: '#ffffff' }} />
      </Tooltip>
    )
  }
  const privateOption = {
    label: 'Private',
    value: 'private',
    icon: (
      <Tooltip title={'Visible to only you'}>
        <LockOutlinedIcon sx={{ strokeWidth: 1, stroke: '#ffffff' }} />
      </Tooltip>
    )
  }
  const typeOptions = isAdmin ? [publicOption, privateOption] : [privateOption]

  const { selectedColor } = useColor()

  const renderCreateNewCollection = () => {
    return (
      <Box
        sx={{
          padding: '5px'
        }}
      >
        {!showCreateCollection ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              background: 'white',
              alignItems: 'center'
            }}
          >
            <Button
              style={{ boxShadow: 'none', background: 'none', color: 'black' }}
              onClick={(e) => {
                setShowCreateCollection(true)
              }}
            >
              + New Collection
            </Button>
            {showApply && (
              <Button
                sx={{ padding: '0px 20px', fontSize: '12px', height: '30px' }}
                style={{ background: selectedColor }}
                onClick={() => handleApply()}
              >
                Apply
              </Button>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              margin: '5px 0px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {loading ? (
              <Box
                sx={{
                  height: '100%',
                  width: '100',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Loader loading={loading} />
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    gap: '5px'
                  }}
                >
                  <TextInput
                    placeholder="Collection Title"
                    value={collectionName}
                    handleChange={(e) => setCollectionName(e.target.value)}
                  />
                  <Box
                    sx={{ '& .MuiSelect-select': { padding: '8.5px 14px' } }}
                  >
                    <SelectDropDown
                      placeholder={'Access'}
                      options={typeOptions}
                      value={_.capitalize(collectionType)}
                      handleChange={(value) => setCollectionType(value)}
                      menuProps={{ style: { zIndex: 1400 } }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '5px'
                  }}
                >
                  <Button
                    style={{
                      background: 'none',
                      color: 'black',
                      boxShadow: 'none'
                    }}
                    onClick={() => setShowCreateCollection(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ background: selectedColor }}
                    onClick={() => handleNewCollection()}
                  >
                    Create
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    )
  }

  const iconRender = () => {
    return (
      <Box
        className=""
        sx={{
          position: 'relative',
          width: '325px',
          height: '100%'
        }}
      >
        {view === 'icon' && (
          <Box
            sx={{
              color: '#4b5563',
              padding: '5px 10px',
              display: 'flex',
              fontSize: '12px',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box>Add to Collection</Box>
            <IconButton onClick={() => handleClose()} sx={{ padding: '0px' }}>
              <CloseIcon sx={{ color: '#4b5563', height: '16px' }} />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            height: '230px',
            fontSize: '400',
            overflow: 'auto',
            borderBottom: '1px solid #D9D9D9',
            '& .Mui-checked': {
              color: '#0645AD !important'
            }
          }}
        >
          <ReactMultiSelectCheckboxes
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: '#75C2E2 !important',
                margin: '4px'
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                maxHeight: '180px',
                fontSize: '14px ',
                paddingBottom: '0px !important',
                height: '100%',
                overflow: 'auto'
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                borderBottom: '1px solid #D9D9D9 !important',
                padding: '0 10px !important',
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
                color: '#000000 !important'
              })
            }}
            sx={{ width: '100%', height: '100%' }}
            value={newCollections}
            onChange={(e) => {
              setNewCollections(e)
            }}
            isClearable={false}
            isMulti
            placeholder={<Box sx={{ color: '#BCBCBC' }}>Search</Box>}
            options={collectionOptions}
            placeholderButtonLabel={'Collections'}
            menuIsOpen={true}
            disableMenu={true}
            filterOption={createFilter(filterConfig)}
            minMenuHeight={250}
            maxMenuHeight={250}
            controlShouldRenderValue={false}
            noOptionsMessage={() => (
              <Box
                sx={{
                  wordBreak: 'break-word',
                  textAlign: 'center',
                  width: '100%',
                  color: '#BCBCBC'
                }}
              >
                No {isAdmin ? 'collections' : 'private collections'} found
              </Box>
            )}
          />
        </Box>
        {renderCreateNewCollection()}
      </Box>
    )
  }

  switch (view) {
    case 'icon':
      return (
        <>
          <Tooltip title="Add to Collections">
            <IconButton
              disableRipple
              disabled={disabled}
              onClick={(e) => {
                handleClick(e)
              }}
            >
              {!detailed && (
                <>
                  {isFilled ? (
                    <FavoriteIcon sx={{ color: '#EF2B50', fontSize: '18px' }} />
                  ) : (
                    <BookmarkIcon className="size-4 text-grey-600" />
                  )}
                </>
              )}
              {detailed && (
                <>
                  <PlaylistAddIcon sx={{ fontSize: '18px' }} />
                  <span
                    style={{
                      fontSize: '12px',
                      color: '#797979',
                      marginLeft: '5px'
                    }}
                  >
                    Collections
                  </span>
                </>
              )}
            </IconButton>
          </Tooltip>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            sx={{ zIndex: '1400' }}
            onChange={(e) => {
              e.stopPropagation()
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {iconRender()}
          </Popover>
        </>
      )

    case 'dropdown':
      return iconRender()

    case 'addOnly':
      return loading ? (
        <div
          style={{
            border: '1px solid #E5E5E5',
            borderRadius: '5px',
            padding: '20px'
          }}
        >
          <Loader loading={loading} />
        </div>
      ) : (
        <div style={{ border: '1px solid #E5E5E5', borderRadius: '5px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              margin: '10px 0',
              padding: '0 15px'
            }}
          >
            <TextInput
              placeholder="Collection Title"
              value={collectionName}
              handleChange={(e) => setCollectionName(e.target.value)}
            />
            <div>
              <Box
                sx={{
                  '& .MuiSelect-select': { padding: '8.5px 14px' },
                  marginLeft: '10px'
                }}
              >
                <SelectDropDown
                  placeholder={'Access'}
                  options={typeOptions}
                  value={_.capitalize(collectionType)}
                  handleChange={(value) => setCollectionType(value)}
                />
              </Box>
            </div>
          </Box>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{
              borderTop: '1px solid #E5E5E5',
              padding: '10px 15px 15px',
              display: 'flex'
            }}
          >
            <Grid item>
              <Button
                style={{
                  background: 'white',
                  color: '#949494',
                  marginRight: '15px',
                  paddingBottom: '3px',
                  paddingTop: '4px'
                }}
                onClick={() => handleCancelCreatNew()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  background: selectedColor,
                  paddingBottom: '3px',
                  paddingTop: '4px'
                }}
                onClick={() => handleNewCollection()}
              >
                Add New Collection
              </Button>
            </Grid>
          </Grid>
        </div>
      )
  }
}

export default AddToCollection
