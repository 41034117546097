import React from 'react'
import { Label } from '@/components/ui/label'
import { CheckCircleIcon, PencilIcon } from '@/components/Icons/Icons'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { Loader } from '@/components/ui/loader'
import { ContentEditable } from '@/components'
import { ShowMoreLess } from './show-more-less'
type Props = {
  label: string
  value: string
  onChange: (value: string) => void
  onSave: (value: string) => Promise<Response>
}

type State = 'edit' | 'saving' | 'success' | 'default'

const AssetDetailAutoSave = (props: Props) => {
  const [state, setState] = React.useState<State>('default')

  const contentEditableRef = React.useRef<any>(null)

  const ToggleEditMode = () => {
    setState('edit')
    setTimeout(() => {
      if (contentEditableRef.current) {
        contentEditableRef.current.focus()

        // move cursor to the end of the text
        const range = document.createRange()
        const selection = window.getSelection()
        range.selectNodeContents(contentEditableRef.current)
        range.collapse(false)
        selection?.removeAllRanges()
        selection?.addRange(range)
      }
    }, 100)
  }

  const handleSave = async (value: string) => {
    setState('saving')

    props
      .onSave(value)
      .then((res) => {
        if (res.status === 200) {
          setState('success')

          setTimeout(() => {
            setState('default')
          }, 3000)
        } else {
          setState('default')
        }
      })
      .catch((error) => {
        console.error(error)
        setState('default')
      })
  }

  const handleClickInput = (e: React.MouseEvent<HTMLDivElement>) => {
    // double click to focus/edit, single click do nothing
    if (e.detail === 2) {
      ToggleEditMode()
    } else {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <div className="twp group/edit">
      <div className="flex items-center justify-start gap-2 px-2">
        <Label className="truncate">{props.label}</Label>
        <div className="size-6 flex items-center">
          {state === 'saving' && <Loader size="sm" />}
          {state === 'success' && <CheckCircleIcon className="size-4" />}
          {state === 'default' && (
            <Button
              variant="ghost"
              size="icon"
              onClick={ToggleEditMode}
              className={cn(
                'invisible group-hover/edit:visible h-6 w-6 hover:bg-zinc-50'
              )}
            >
              <PencilIcon className="size-4" />
            </Button>
          )}
        </div>
      </div>
      {state === 'edit' ? (
        <ContentEditable
          disabled={state !== 'edit'}
          innerRef={contentEditableRef}
          className={cn(
            // Adjust height relative to line height and number of lines eg: 5 lines ==> 5 * 1.25rem + 8px
            'w-full max-h-[calc(5*1.25rem+8px)] overflow-y-auto rounded-md border border-transparent bg-transparent px-[7px] py-1 text-base placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
            state === 'edit'
              ? 'border-input focus-visible:ring-1 focus-visible:ring-ring'
              : 'hover:bg-zinc-50'
          )}
          html={(props.value || '').toString()}
          onBlur={(e: any) => handleSave((e.target as HTMLElement)?.innerText)}
          onChange={(e: any) => props.onChange(e.target.value)}
        />
      ) : (
        <ShowMoreLess
          text={(props.value || '-').toString()}
          numberOfLines={5}
          className="px-[7px] py-1 text-sm border border-transparent hover:bg-zinc-50 rounded-md"
          onClick={(e) => handleClickInput(e)}
        />
      )}
    </div>
  )
}

export default AssetDetailAutoSave
