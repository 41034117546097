import React, { useEffect, useState } from 'react'
import LexicalEditor from '../../components/Lexical'
import { $generateHtmlFromNodes } from '@lexical/html'
import Button2 from '../../components/Button/Button2'
import { TrashIcon, UpArrowIcon } from '../../components/Icons/Icons'
import { useDispatch } from 'react-redux'
import { createComment, deleteComment, getComments } from '../../store/api'
import { getColorsForInitials } from '../../utils/String'
import { formatTimeAgo } from '../../utils/Date'
import { checkUserRoleViewer } from '../../utils/User'
import { Skeleton } from '@/components/ui/skeleton'
import trackEvent from '@/utils/TrackEvent/TrackEvent'
import mixpanelEvents from '@/config/mixpanelEvents'

const CommentUI = ({ commentsData, source }) => {
  const [currentEditorState, setCurrentEditorState] = useState({})
  const [editedHtmlString, setEditedHtmlString] = useState(`<h1>Testigng</h1>`)
  const [unSavedChanges, setUnSavedChanges] = useState(false)
  const dispatch = useDispatch()
  const [comments, setComments] = useState(commentsData ?? [])
  const [mentionedDocs, setMentionedDocs] = useState([])
  const [selectedNote, setSelectedNote] = useState(null)
  const [selectedComment, setSelectedComment] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [resetEditor, setResetEditor] = useState(false)
  const [disableSave, setDisableSave] = useState(true)
  const [loading, setLoading] = useState(true)
  const isUserViewer = checkUserRoleViewer()

  useEffect(() => {
    getComments(source.id).then((res) => {
      setLoading(false)
      setComments(res.data.data)
    })
  }, [])

  useEffect(() => {
    setResetEditor(false)
  }, [resetEditor])

  useEffect(() => {
    if (selectedComment) {
      const spans = document.querySelectorAll('[data-mention-id]')
      spans.forEach((span) => {
        span.className = 'font-medium cursor-pointer'

        span.addEventListener('mouseenter', () => {
          // console.log('mouseenter', span)
          // get data-mention-id, data-mention-type, data-mention-name
        })
        span.addEventListener('click', () => {
          const mentionId = span.getAttribute('data-mention-id')
          const mentionType = span.getAttribute('data-mention-type')
          const mentionName = span.getAttribute('data-mention-name')

          if (mentionType === 'resume') {
            window.open(`/library/people-resume/${mentionId}`, '_blank')
          }

          if (mentionType === 'project') {
            window.open(`/library/projects/${mentionId}`, '_blank')
          }

          if (mentionType === 'client') {
            window.open(`/library/companies/${mentionId}`, '_blank')
          }

          if (mentionType === 'opportunity') {
            window.open(`/library/opportunity/${mentionId}`, '_blank')
          }
        })
      })
    }

    return () => {
      const spans = document.querySelectorAll('[data-mention-id]')

      spans.forEach((span) => {
        span.removeEventListener('mouseenter', () => {})
      })
    }
  }, [selectedComment])

  const handleCreate = (name) => {
    setResetEditor(true)
    createComment({
      comment: currentEditorState,
      comment_html: editedHtmlString,
      entity_id: source.id,
      mentions: selectedNote.mentioned_documents
    }).then((response) => {
      getComments(source.id).then((res) => {
        setComments(res.data.data)
      })
    })
    trackEvent(
      mixpanelEvents?.OPPORTUNITY_ADD_COMMENT,
      'SUCCESS',
      {},
      {
        opportunity_id: source.id
      }
    )
  }

  const handleEditorChange = (currentEditorState, editor) => {
    editor.update(() => {
      // list MentionNodes
      let mentionedDocsTemp = []
      currentEditorState?._nodeMap?.forEach((node) => {
        // setDisableSave(false)

        if (node?.__type === 'mention' && node?.__id) {
          const mention = {
            id: node.__id,
            type: node.__mentionType,
            name: node.__text,
            is_mentioned: true
          }

          mentionedDocsTemp.push(mention)
        }
      })

      mentionedDocsTemp = [...mentionedDocs, ...mentionedDocsTemp]

      const uniqueMentions = mentionedDocsTemp.filter(
        (thing, index, self) =>
          index ===
          self.findIndex((t) => t.id === thing.id && t.type === thing.type)
      )

      // console.log('uniqueMentions', uniqueMentions)
      setCurrentEditorState(currentEditorState)

      const htmlString = $generateHtmlFromNodes(editor, null)
      setEditedHtmlString(htmlString)

      setSelectedNote((prev) => {
        return {
          ...prev,
          document_html: htmlString,
          // document_content: currentEditorState,
          mentioned_documents: uniqueMentions
        }
      })
    })
    const contentEditor = document.getElementById('comment-editor')
    const plainText = contentEditor.innerText.trim()

    if (contentEditor) {
      if (plainText.length < 1 || plainText.startsWith('Leave a comment')) {
        setDisableSave(true)
      } else {
        setDisableSave(false)
      }
    }
  }

  const handleDeleteComment = (comment) => {
    deleteComment({ id: comment.id }).then((res) => {
      getComments(source.id).then((res) => {
        setComments(res.data.data)
      })
    })
  }

  return (
    <div>
      <div className="flex items-center gap-2 twp">
        <p className="text-sm font-medium">Comments</p>
        <div className="h-[1px] w-full bg-zinc-200"></div>
      </div>

      <div className="flex flex-col gap-2 mt-4">
        {loading ? (
          <div className="flex items-center space-x-4">
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Skeleton className="w-4 h-4 rounded-full" />
                <Skeleton className="h-4 w-[350px]" />
              </div>
              <div className="flex items-center gap-2">
                <Skeleton className="w-4 h-4 rounded-full" />
                <Skeleton className="h-4 w-[280px]" />
              </div>
            </div>
          </div>
        ) : (
          <>
            {comments.map((comment) => (
              <div
                key={comment.id}
                id="comment-editor-readonly"
                className="p-1.5 pt-2 pb-0 border rounded-md border-zinc-100 group"
              >
                <div className="flex items-center gap-1 pl-1.5 xxs">
                  <div
                    className={`min-w-4 h-4 rounded-full grid place-content-center text-xxs text-white`}
                    style={{
                      backgroundColor: getColorsForInitials(
                        comment?.created_by_user?.name
                      )
                    }}
                  >
                    {comment?.created_by_user?.name?.[0] ?? 'A'}
                  </div>
                  <p className="text-xxs text-zinc-800">
                    {comment?.created_by_user?.name ?? 'Joist'}
                  </p>
                  <div className="mx-1 rounded-full size-1 bg-zinc-300"></div>
                  <p className="text-xxs text-zinc-500">
                    {formatTimeAgo(comment?.created_at)}
                  </p>

                  <div className="flex items-center gap-1 ml-auto transition-all duration-300 translate-y-2 opacity-0 group-hover:opacity-100 group-hover:translate-y-0">
                    <button
                      className="p-1 rounded-full hover:bg-zinc-100"
                      onClick={() => {
                        handleDeleteComment(comment)
                      }}
                    >
                      <TrashIcon className="size-3" />
                    </button>
                  </div>
                </div>
                <div
                  className="p-2 py-3 text-xs ContentEditable__root text-zinc-800"
                  onMouseEnter={() => {
                    // add event listener to the span with data-mention-id
                    setSelectedComment(comment)
                  }}
                  dangerouslySetInnerHTML={{
                    __html: comment.comment_html
                  }}
                />
              </div>
            ))}
          </>
        )}
      </div>

      <div className="relative pb-3 pr-4 mt-4 border rounded-md shadow-sm border-zinc-100">
        <div className="relative w-full" id="comment-editor">
          <LexicalEditor
            key={resetEditor ? 'comment' : 'comment-reset'}
            placeholderText="Leave a comment"
            miniEditor={true}
            commentEditor={true}
            initialEditorContent={
              !_.isEmpty(selectedNote?.document_content)
                ? selectedNote?.document_content
                : {}
            }
            unSavedChanges={unSavedChanges}
            // setUnSavedChanges={}
            handleEditorChange={handleEditorChange}
          />
        </div>

        <div className="absolute flex items-center justify-end gap-2 mt-2 bottom-2 right-2">
          <Button2
            primary
            noOutline
            disabled={disableSave || isUserViewer}
            style={{
              borderRadius: '50px',
              aspectRatio: '1/1',
              padding: '6px'
            }}
            onClick={() => {
              // console.log('selectedNote', selectedNote)
              if (selectedNote) {
                handleCreate()
              }
              // handleCreate()
            }}
          >
            <UpArrowIcon className="size-3.5" />
          </Button2>
        </div>
      </div>
    </div>
  )
}

export default CommentUI
