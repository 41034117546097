import store from '../../store/store'
import { setAnalyticsComplianceData } from '../../store/AnalyticsCompliance/Actions'
import { posthog } from 'posthog-js'

const trackEvent = (
  eventName = '',
  eventStatus = '',
  response = {},
  additionalParams = {}
) => {
  const storeValue = store.getState()
  store.dispatch(
    setAnalyticsComplianceData({
      ...(additionalParams?.compliance_id
        ? { rfx_id: additionalParams?.compliance_id }
        : {})
    })
  )
  const {
    authenticate: {
      user: {
        domain: {
          domain_id = '',
          id: userId = '',
          email = '',
          domain_name = '',
          user_name = ''
        } = {}
      } = {}
    } = {}
  } = storeValue

  let eventData = {
    userId,
    'Domain Id': domain_id,
    'Event Status': eventStatus,
    email,
    'Domain Name': domain_name,
    user_name
  }
  eventData = Object.assign(eventData, additionalParams)
  posthog.identify(userId, {
    domain_name
  })

  posthog.capture(eventName, eventData)
}

export default trackEvent
