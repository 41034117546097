import React, { useState, useRef, useEffect } from 'react'
import { Box, Fade, IconButton, Paper } from '@mui/material'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import Draggable from 'react-draggable'
import Popover from '@mui/material/Popover'
import SessionChat from './SessionChat'
import AddBoxIcon from '@mui/icons-material/AddBox'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import {
  ChatIcon,
  MessageSquarePlusIcon,
  ExpandIcon,
  MinimizeIcon3,
  MinusIcon,
  HistoryIcon,
  PinIcon,
  UnpinIcon,
  InfoIcon,
  MaximizeIcon2,
  CirclePlusIcon,
  NewChatIcon
} from '../Icons/Icons'

import Tooltip from '@mui/material/Tooltip'
import Sockette from 'sockette'
import { UPDATE_SESSION_CHAT_PARTIAL } from '../../store/actionTypes'
import {
  updateSessionTitle,
  getSessionChat,
  setChatState,
  attachAlertToChat
} from '../../store/Chat/Actions'
import { useSelector, useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import {
  getChatInstructions,
  getChatDomainPrompts,
  getChatVoiceConfigs,
  generateTitle
} from '../../store/api'
import { defaultChatInstructions } from '../../config/constants'
import Loader from '../../components/Loader'
import Lottie from 'react-lottie'
import AiLoader from '../../assets/lottie/ai.json'
import SleepyLoader from '../../assets/lottie/sleepy.json'
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded'
import Button2 from '../Button/Button2'
import CloseIcon from '@mui/icons-material/Close'
import { ResizableBox } from 'react-resizable'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { toast } from 'react-toastify'
import {
  resetChatContent,
  setChatFullScreen
} from '@/store/Chat/Actions/filter'
import { BorderBeam } from '@/components/ui/border-beam'
import featureGroup from '@/config/feature-group'
import { cn } from '@/lib/utils'
import { Button as NewButton } from '@/components/ui/button'

const getDataFromLocal = (key) => {
  let data = localStorage.getItem(key)
  data = data ? JSON.parse(data) : null
  return data
}

const saveDataToLocal = (data, key) => {
  localStorage.setItem(key, JSON.stringify(data))
}

const BORDER_BEAM_DURATION = 3

const Chat = ({ sidebarWidth = 50 }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const fullScreen = useSelector((state) => state.chat.fullScreen)
  const [isDragging, setIsDragging] = useState(false)
  const threshold = 10
  const startPositionData = getDataFromLocal('chatPosition')
  const pinnedData = getDataFromLocal('chatPinned')
  const sizeData = getDataFromLocal('chatSize')
  const minSizeData = getDataFromLocal('chatMinSize')
  const [pinned, setPinned] = useState(pinnedData !== null ? pinnedData : false)
  const [initEvent, setInitEvent] = useState(false)
  const [startPosition, setStartPosition] = useState(
    startPositionData ?? {
      x: window.innerWidth - 10,
      y: window.innerHeight - 10
    }
  )
  const rightMostX = window.innerWidth - 97
  const [initialPosition, setInitialPosition] = useState({
    x: 0,
    y: rightMostX
  })

  const [size, setSize] = useState(
    minSizeData || sizeData || { width: 400, height: 650 }
  )
  const [minSize, setMinSize] = useState(
    minSizeData || { width: 400, height: 650 }
  )

  const [maxConstraints, setMaxConstraints] = useState([
    window.innerWidth,
    window.innerHeight
  ])

  const [selectedSession, setSelectedSession] = useState('new')
  const [selectedSessionPath, setSelectedSessionPath] = useState({})
  const [sessionChat, setSessionChat] = useState({})
  const [chatInstructions, setChatInstructions] = useState(null)
  const disclaimer = chatInstructions?.disclaimer || ''

  const dispatch = useDispatch()
  const [socket, setSocket] = useState(null)
  const [socketStatus, setSocketStatus] = useState(false)
  const [messageList, setMessageList] = useState([])
  const [history, setHistory] = useState([])
  const [textBox, setTextBox] = useState('')
  const messageListRef = useRef(messageList)
  const streamLoadingRef = useRef(null)
  const currentTrackerRef = useRef(null)
  const historyRef = useRef(null)
  const listRef = useRef(null)
  const scrollLock = useRef(false)
  const currentChatIdRef = useRef(null)

  const [animationId, setAnimationId] = useState('')
  const [animationText, setAnimationText] = useState(null)

  const auth = useSelector((state) => state.authenticate)
  const chatStateData = useSelector((state) => state.chat)
  const chatType = 'session'

  const {
    documentFilter = {},
    documentAlert = {},
    chatContent = '',
    chatState
  } = chatStateData

  const domain = auth?.user?.domain
  const { domain_config } = domain || {}
  const promptFav = domain_config?.prompt_fav || {}
  const {
    domain_prompts: domain_prompts_fav = [],
    default_prompts: default_prompts_fav = []
  } = promptFav

  const [voiceConfigs, setVoiceConfigs] = useState(null)
  const [domainPrompts, setDomainPrompts] = useState(null)
  const [randomPrompts, setRandomPrompts] = useState([])
  const [voiceOptions, setVoiceOptions] = useState([])
  const [selectedVoice, setSelectedVoice] = useState(null)
  const [defaultVoiceIndex, setDefaultVoiceIndex] = useState(0)
  const [initalizing, setInitalizing] = useState(true)
  const [initalizePrompts, setInitalizePrompts] = useState(true)
  const [selectedTags, setSelectedTags] = useState(null)
  const [documentAttached, setDocumentAttached] = useState(false)

  const [chatMode, setChatMode] = useState('chat')
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageData, setImageData] = useState()

  const popoverRef = useRef(null)
  const clearData = useRef(false)

  const [showBorderBeam, setShowBorderBeam] = useState(false)

  useEffect(() => {
    if (chatState === 'open') {
      setShowBorderBeam(true)

      setTimeout(() => {
        setShowBorderBeam(false)
      }, BORDER_BEAM_DURATION * 1000)
    }
  }, [chatState])

  useEffect(() => {
    if (!_.isEmpty(documentFilter)) {
      setDocumentAttached(documentFilter)
      dispatch(
        attachAlertToChat({
          ...documentAlert,
          show: false
        })
      )
    }
  }, [documentFilter])

  useEffect(() => {
    if (!_.isEmpty(chatContent)) {
      setTimeout(() => {
        setTextBox(textBox + ' ' + chatContent)
      }, 100)
      dispatch(setChatState('open'))
      dispatch(resetChatContent())
    }
  }, [chatContent])

  useEffect(() => {
    if (startPosition) {
      saveDataToLocal(startPosition, 'chatPosition')
    }
    if (initEvent) {
      setInitEvent(true)
      const timeout = setTimeout(() => {
        trackEvent(
          mixpanelEvents.CHAT_WINDOW_MOVED,
          'SUCCESS',
          {},
          { 'Feature Group': featureGroup.CHAT_V2, fullScreen }
        )
      }, 1300)

      return () => clearTimeout(timeout)
    } else {
      setInitEvent(true)
    }
  }, [startPosition])

  useEffect(() => {
    saveDataToLocal(pinned, 'chatPinned')
  }, [pinned])

  useEffect(() => {
    saveDataToLocal(size, 'chatSize')
  }, [size])

  useEffect(() => {
    saveDataToLocal(minSize, 'chatMinSize')
  }, [minSize])

  useEffect(() => {
    if (chatState === 'open' && !anchorEl) {
      const leftMostX = 10
      const bottomMostY = window.innerHeight - 10
      const positionData = { x: leftMostX, y: bottomMostY }
      const data = startPositionData || positionData
      setStartPosition(data)
    }
    if (chatState === 'open') {
      establishSocketConnection()
    }
  }, [chatState])

  useEffect(() => {
    const element = document.getElementById('chat-popover-trigger')
    if (element) {
      setAnchorEl(element)
    }
  }, [])

  // useEffect(() => {
  //   if (!_.isEmpty(chatContent) || !_.isEmpty(documentFilter)) {
  //     const element = document.getElementById('chat-popover-trigger')
  //     if (!_.isEqual(documentFilterData.current, documentFilter)) {
  //       clearData.current = true
  //       handleNewChat()
  //       documentFilterData.current = documentFilter
  //     } else {
  //       clearData.current = false
  //     }
  //     if (element) {
  //       setAnchorEl(element)
  //     }
  //   }
  // }, [chatContent, documentFilter])

  useEffect(() => {
    if (clearData.current) {
      handleNewChat()
      clearData.current = false
    }
  }, [anchorEl])

  useEffect(() => {
    if (!socketStatus) {
      connectToWebSocket()
    }
  }, [])

  useEffect(() => {
    const handleFocus = () => {
      establishSocketConnection()
    }
    window.addEventListener('focus', handleFocus)
    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [socketStatus])

  const establishSocketConnection = () => {
    if (socketStatus === 'disconnected') {
      connectToWebSocket()
    }
  }

  const connectToWebSocket = async () => {
    const currentSession = await Auth.currentSession()
    const token = currentSession?.accessToken?.getJwtToken()
    console.log('Connecting to the chat server')
    setSocketStatus('connecting')
    const ws = new Sockette(
      process.env.REACT_APP_WEB_SOCKET_URL + `?token=${token}`,
      {
        timeout: 5e3,
        maxAttempts: 3,
        onopen: (e) => {
          setSocketStatus('connected')
          console.log('connected:', e)
          trackEvent(mixpanelEvents.CHAT_SOCKET_CONNECTED, 'SUCCESS', {}, {})
        },
        onmessage: (e) => {
          const { data } = e
          const messageObj = JSON.parse(data)
          const messageList = messageListRef.current
          const { session_id, chat_id, message, type, referrer_id } =
            messageObj || {}
          currentChatIdRef.current = chat_id
          if (type === 'chat_status' && message === '__END_OF_CHAT__') {
            streamLoadingRef.current = {
              ...streamLoadingRef.current,
              [session_id]: false,
              [selectedSession]: false
            }
          }
          if (
            session_id === currentTrackerRef.current ||
            chatType !== 'session' ||
            referrer_id === currentTrackerRef.current
          ) {
            let messageIndex = messageList.findIndex(
              (item) => item.id === chat_id && item.author === 'Them'
            )
            const newMessageList = [...messageList]
            let filters = {}
            let isUserStopped = false
            if (messageIndex === -1) {
              if (_.isArray(historyRef?.current)) {
                const historyIndex = historyRef.current.findIndex(
                  (item) => item.id === chat_id && item.author === 'Them'
                )
                if (historyIndex !== -1) {
                  const newHistory = [...historyRef.current]
                  filters = newHistory[historyIndex]?.data?.filter || {}
                  isUserStopped = newHistory[historyIndex]?.userstopped || false
                  newHistory.splice(historyIndex, 1)
                  setHistory(newHistory)
                }
              }
              if (!isUserStopped) {
                messageIndex = messageList.length
                newMessageList.push({
                  id: chat_id,
                  type: 'text',
                  author: 'Them',
                  created_at: new Date().toUTCString(),
                  loading: true,
                  data: {
                    text: ' ',
                    systemPrompt: '',
                    inputText: '',
                    filter: filters,
                    error: '',
                    status: [],
                    startedChat: false
                  }
                })
              }
            } else {
              isUserStopped = newMessageList[messageIndex].userstopped || false
            }
            if (!isUserStopped) {
              if (type === 'chat') {
                if (newMessageList[messageIndex].data.text === ' ') {
                  newMessageList[messageIndex].data.text = message
                } else {
                  newMessageList[messageIndex].data.text =
                    newMessageList[messageIndex].data.text + message
                }
              } else if (type === 'status') {
                newMessageList[messageIndex].data.status.push(message)
              } else if (type === 'prompt') {
                newMessageList[messageIndex].data.systemPrompt =
                  newMessageList[messageIndex].data?.systemPrompt + message
              } else if (
                type === 'chat_status' &&
                message === '__START_OF_CHAT__'
              ) {
                newMessageList[messageIndex].data.startedChat = true
              } else if (
                type === 'chat_status' &&
                message === '__END_OF_CHAT__'
              ) {
                newMessageList[messageIndex].loading = false
                setTextBox('')
              }
              setMessageList(newMessageList)
              if (type === 'chat_status' && message === '__END_OF_CHAT__') {
                scrollToBottom(false, 100)
              } else {
                scrollToBottom()
              }
            }
          }
          handleChatAddition(messageObj, false, true)
        },
        onreconnect: (e) => {
          setSocketStatus('reconnecting')
          console.log('Reconnecting...', e)
        },
        onmaximum: (e) => {
          console.log('Stop Attempting!', e)
          trackEvent(mixpanelEvents.CHAT_SOCKET_ERRORED, 'SUCCESS', {}, {})
          setSocketStatus('errored')
        },
        onclose: (e) => {
          console.log('Closed!', e)
          const { wasClean } = e
          if (wasClean) {
            setSocketStatus('disconnected')
            trackEvent(
              mixpanelEvents.CHAT_SOCKET_DISCONNECTED,
              'SUCCESS',
              {},
              {}
            )
          }
        },
        onerror: (e) => {
          console.log('Error:', e)
        }
      }
    )
    setSocket(ws)
  }

  const scrollToBottom = (force = false, delay) => {
    if (force) {
      if (
        listRef.current &&
        listRef.current?.scrollTop < listRef.current?.scrollHeight
      ) {
        setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight
          }
        }, 50)
      }
    } else {
      if (scrollLock.current) {
        if (
          listRef.current &&
          listRef.current?.scrollTop < listRef.current?.scrollHeight
        ) {
          if (delay) {
            setTimeout(() => {
              if (listRef.current) {
                listRef.current.scrollTop = listRef.current.scrollHeight
              }
            }, delay)
          } else {
            listRef.current.scrollTop = listRef.current.scrollHeight
          }
        }
      }
    }
  }

  const handleChatAddition = (
    payload,
    regenerateTitle = false,
    partialUpdate = false,
    callback = () => {}
  ) => {
    const { id, newId, data } = payload
    if (partialUpdate) {
      dispatch({ type: UPDATE_SESSION_CHAT_PARTIAL, payload, callback })
    } else {
      if (id === 'new') {
        setSessionChat(_.cloneDeep(data[newId]))
        setSelectedSessionPath({
          sessionIndex: 0,
          classifier: 'today',
          sessionId: newId
        })
        setSelectedSession(newId)
        handleTitleGeneration(newId, data[newId]?.chat.chats[0]?.data?.text)
      } else if (regenerateTitle) {
        const { chats = [] } = payload || {}
        const { data = {} } = chats[0] || {}
        const { text = '' } = data || {}
        if (text) {
          handleTitleGeneration(id, text)
        }
      }
      dispatch(getSessionChat(payload, callback))
    }
  }

  const handleTitleGeneration = async (id, input, isUserRequested = false) => {
    if (id) {
      const response = await generateTitle({ session_id: id, title: input })
      if (response.status === 200) {
        if (isUserRequested) {
          trackEvent(mixpanelEvents.CHAT_SESSION_REGEN_TITLE, 'SUCCESS', {}, {})
        } else {
          trackEvent(mixpanelEvents.CHAT_SESSION_GEN_TITLE, 'SUCCESS', {}, {})
        }
        const newTitle = response.data.results
        setAnimationText(newTitle)
        setAnimationId(id)
        dispatch(
          updateSessionTitle({ id, title: newTitle, regenerateTitle: false })
        )
      } else {
        if (isUserRequested) {
          trackEvent(mixpanelEvents.CHAT_SESSION_REGEN_TITLE, 'FAILURE', {}, {})
          toast.error(
            'Unable to generator a suitable title. Please try again later'
          )
        } else {
          trackEvent(mixpanelEvents.CHAT_SESSION_GEN_TITLE, 'FAILURE', {}, {})
          dispatch(updateSessionTitle({ id, regenerateTitle: true }))
        }
      }
    }
  }

  const removeAnimation = () => {
    setAnimationId('')
    setAnimationText(null)
  }

  useEffect(() => {
    async function initializeData() {
      setInitalizing(true)
      const res1 = getChatInstructions()
      const res2 = getChatVoiceConfigs()
      const res3 = getChatDomainPrompts()
      const [chatInstructions, voiceConfigs, domainPrompts] = await Promise.all(
        [res1, res2, res3]
      )
      if (
        chatInstructions.status === 200 &&
        !_.isEmpty(chatInstructions.data)
      ) {
        setChatInstructions(chatInstructions.data)
      } else {
        setChatInstructions(defaultChatInstructions)
      }
      if (voiceConfigs.status === 200 && !_.isEmpty(voiceConfigs.data)) {
        const voiceConfigsRes = voiceConfigs.data
        setVoiceConfigs(voiceConfigsRes)
        const voiceOptions = voiceConfigsRes?.map((item, index) => {
          return {
            value: item.id,
            label: item.title
          }
        })
        setVoiceOptions(voiceOptions)
        let defaultVoiceIndex = voiceConfigsRes.findIndex(
          (item) => item?.is_default
        )
        defaultVoiceIndex = defaultVoiceIndex === -1 ? 0 : defaultVoiceIndex
        setDefaultVoiceIndex(defaultVoiceIndex)
        setSelectedVoice(voiceOptions[defaultVoiceIndex])
      }
      if (domainPrompts.status === 200 && !_.isEmpty(domainPrompts.data)) {
        setDomainPrompts(domainPrompts.data)
      }
      setInitalizing(false)
    }
    initializeData()
  }, [])

  const getRandomElements = (combinedArray, num) => {
    const availableElements = Math.min(num, combinedArray.length)
    const result = []
    for (let i = 0; i < availableElements; i++) {
      if (combinedArray.length === 0) {
        break
      }
      const randomIndex = Math.floor(Math.random() * combinedArray.length)
      result.push(combinedArray[randomIndex])
      combinedArray.splice(randomIndex, 1)
    }
    return result
  }

  useEffect(() => {
    if (domainPrompts) {
      const allFavorites = [...default_prompts_fav, ...domain_prompts_fav]
      const { domain_prompts = [], default_prompts = [] } = domainPrompts || {}
      const allPrompts = [...default_prompts, ...domain_prompts]
      if (_.isEmpty(allFavorites) && _.isEmpty(randomPrompts)) {
        const randomSet = getRandomElements(allPrompts, 5)
        setRandomPrompts(randomSet)
      } else {
        const favPrompts = allPrompts.filter((item) =>
          allFavorites.includes(item.id)
        )
        setRandomPrompts(favPrompts)
      }
      setInitalizePrompts(false)
    }
  }, [domain_config, domainPrompts])

  const toggleChat = () => {
    if (chatState === 'open') {
      dispatch(setChatState('closed'))
    } else {
      dispatch(setChatState('open'))
    }
  }

  const openHistory = () => {
    handleToggleFullScreen(true)
    trackEvent(
      mixpanelEvents.CHAT_HISTORY_OPENED,
      'SUCCESS',
      {},
      {
        'Feature Group': featureGroup.CHAT_V2
      }
    )
  }

  const handleNewChat = () => {
    trackEvent(
      mixpanelEvents.CHAT_NEW_SESSION,
      'SUCCESS',
      {},
      {
        'Feature Group': featureGroup.CHAT_V2
      }
    )
    setSelectedSession('new')
    setSessionChat({})
    setChatMode('chat')
    setSelectedTags(null)
    setDocumentAttached(false)
    setSelectedImage(null)
    setImageData(null)
    dispatch(setChatState('open'))
    if (!_.isEmpty(documentAlert)) {
      dispatch(attachAlertToChat({ ...documentAlert, show: true }))
    }
  }

  const loading =
    initalizing ||
    initalizePrompts ||
    socketStatus === 'reconnecting' ||
    socketStatus === false

  const showWakeUp = socketStatus === 'disconnected'

  const handleStart = (e, data) => {
    setInitialPosition({ x: data.x, y: data.y })
  }

  const eventControl = (event, data) => {
    const deltaX = Math.abs(data.x - initialPosition.x)
    const deltaY = Math.abs(data.y - initialPosition.y)
    if (deltaX > threshold || deltaY > threshold) {
      setStartPosition({ x: data.x, y: data.y })
    }
    if (event.type === 'mousemove' || event.type === 'touchmove') {
      setIsDragging(true)
    }
    if (event.type === 'mouseup' || event.type === 'touchend') {
      setTimeout(() => {
        setIsDragging(false)
      }, 100)
    }
  }

  useEffect(() => {
    const shouldCloseChat = (element) => {
      if (
        element.closest('[data-dialog-parent="chat"]') ||
        element.closest('.viewer-container') ||
        element.closest('#radix-portal-container') ||
        element.closest('[role="presentation"]')
      ) {
        return false
      }

      return true
    }

    const handleOutsideClick = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        if ((fullScreen || !pinned) && shouldCloseChat(event.target)) {
          dispatch(setChatState('closed'))
        }
      }
    }

    if (anchorEl) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [anchorEl, pinned, fullScreen])

  useEffect(() => {
    const updateConstraints = () => {
      const maxWidth = fullScreen
        ? window.innerWidth - sidebarWidth
        : window.innerWidth * 0.65
      const maxHeight = fullScreen
        ? window.innerHeight
        : window.innerHeight * 0.97 - 22
      setMaxConstraints([maxWidth, maxHeight])
    }

    updateConstraints()

    window.addEventListener('resize', updateConstraints)
    return () => {
      window.removeEventListener('resize', updateConstraints)
    }
  }, [])

  useEffect(() => {
    if (fullScreen) {
      setSize({
        width: window.innerWidth - sidebarWidth,
        height: window.innerHeight
      })
    }
  }, [sidebarWidth, fullScreen])

  useEffect(() => {
    if (fullScreen && size.height !== window.innerHeight) {
      setMinSize(size)
      setSize({
        width: window.innerWidth - sidebarWidth,
        height: window.innerHeight
      })
    }
  }, [fullScreen])

  const handleToggleFullScreen = (flag) => {
    const fscreen = flag !== undefined || !fullScreen
    dispatch(setChatFullScreen(fscreen))
    if (fscreen) {
      trackEvent(
        mixpanelEvents.CHAT_WINDOW_MAXIMIZED,
        'SUCCESS',
        {},
        {
          'Feature Group': featureGroup.CHAT_V2
        }
      )
      setMinSize(size)
      setSize({
        width: window.innerWidth - sidebarWidth,
        height: window.innerHeight
      })
    } else {
      setSize(minSize)
      trackEvent(
        mixpanelEvents.CHAT_WINDOW_MINIMIZED,
        'SUCCESS',
        {},
        {
          'Feature Group': featureGroup.CHAT_V2
        }
      )
    }
  }

  return (
    startPosition && (
      <Draggable
        bounds="body"
        handle="#chatdraghandle"
        position={startPosition}
        onStart={handleStart}
        onDrag={eventControl}
        onStop={(event, data) => {
          eventControl(event, data)
        }}
        // disabled={anchorEl}
      >
        <Box
          position="fixed"
          zIndex={1301}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Popover
            ref={popoverRef}
            id={'chat-popover'}
            open={chatState === 'open'}
            keepMounted
            TransitionComponent={Fade}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            sx={{
              backgroundColor: 'transparent'
            }}
            hideBackdrop={!pinned}
            slotProps={{
              root: {
                style: {
                  position: 'inherit',
                  zIndex: 'auto'
                }
              },
              paper: {
                className: cn(
                  'p-0 z-[1301] rounded-xl left-0 top-0 overflow-hidden',
                  fullScreen &&
                    '!inset-0 !left-auto !transform-none rounded-none max-h-full'
                )
              }
            }}
            autoFocus={chatState === 'open'}
            disableEnforceFocus={chatState === 'open'}
          >
            <ResizableBox
              width={size.width}
              height={size.height}
              minConstraints={[400, 500]}
              maxConstraints={maxConstraints}
              disabled={fullScreen}
              resizeHandles={fullScreen ? [] : ['se']}
              onResizeStop={(e, data) => {
                setSize({
                  width: data.size.width,
                  height: data.size.height
                })
                trackEvent(
                  mixpanelEvents.CHAT_WINDOW_RESIZED,
                  'SUCCESS',
                  {},
                  {
                    'Feature Group': featureGroup.CHAT_V2,
                    fullScreen,
                    size: data.size
                  }
                )
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  borderRadius: fullScreen ? '0px' : '12px',
                  position: 'relative'
                }}
              >
                <div
                  id="chatdraghandle"
                  className={cn(
                    'twp flex justify-between items-center p-3 bg-background border-b',
                    fullScreen ? 'cursor-default' : 'cursor-move'
                  )}
                >
                  <div className="twp flex w-full gap-1">
                    <Tooltip title={'New Chat'}>
                      <NewButton
                        onClick={() => !isDragging && handleNewChat()}
                        className={cn(
                          'text-xs gap-1',
                          fullScreen ? 'h-[28px]' : 'size-7'
                        )}
                        size={fullScreen ? 'sm' : 'icon'}
                      >
                        <NewChatIcon className="size-4 -mt-px -ml-px" />
                        {fullScreen && 'New Chat'}
                      </NewButton>
                    </Tooltip>

                    {!fullScreen && (
                      <Tooltip
                        title={
                          <div
                            className={cn(
                              'flex items-center gap-1 align-center'
                            )}
                          >
                            {pinned ? 'Unpin' : 'Pin to always keep it open'}
                          </div>
                        }
                      >
                        <NewButton
                          className={cn(
                            'flex items-center gap-1 align-center twp rounded-md size-7',
                            pinned && 'bg-zinc-200 hover:bg-zinc-300'
                          )}
                          onClick={() => setPinned(!pinned)}
                          size="icon"
                          variant={pinned ? 'secondary' : 'ghost'}
                        >
                          <PinIcon
                            className={cn(
                              'size-4 -mb-px',
                              pinned && 'fill-foreground'
                            )}
                          />
                        </NewButton>
                      </Tooltip>
                    )}

                    {!fullScreen && (
                      <Tooltip title={'History'}>
                        <NewButton
                          onClick={(e) => !isDragging && openHistory()}
                          size="icon"
                          variant="ghost"
                          className="size-7"
                        >
                          <HistoryIcon className="size-4" />
                        </NewButton>
                      </Tooltip>
                    )}

                    <div className="flex items-center gap-1 w-fit ml-auto">
                      <Tooltip title={fullScreen ? 'Collapse' : 'Expand'}>
                        <NewButton
                          onClick={() => handleToggleFullScreen()}
                          size="icon"
                          variant="ghost"
                          className="size-7"
                        >
                          {fullScreen ? (
                            <MinimizeIcon3 className="size-4" />
                          ) : (
                            <MaximizeIcon2 className="size-4" />
                          )}
                        </NewButton>
                      </Tooltip>
                    </div>
                    <Tooltip title={'Close Chat'}>
                      <NewButton
                        onClick={(e) => {
                          !isDragging && toggleChat(e)
                          trackEvent(
                            mixpanelEvents.CHAT_WINDOW_CLOSED,
                            'SUCCESS',
                            {},
                            {
                              'Feature Group': featureGroup.CHAT_V2,
                              fullScreen
                            }
                          )
                        }}
                        size="icon"
                        variant="ghost"
                        className="size-7"
                      >
                        <CloseIcon className="size-4" />
                      </NewButton>
                    </Tooltip>
                  </div>
                </div>
                {showWakeUp ? (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={establishSocketConnection}
                  >
                    <Box>
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: SleepyLoader
                        }}
                        height={200}
                      />
                      <Box>Click to Wake up Joist</Box>
                    </Box>
                  </Box>
                ) : loading ? (
                  <Loader
                    loading={loading}
                    caption={'loading your chat'}
                    flex
                  />
                ) : (
                  <SessionChat
                    fullScreen={fullScreen}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                    selectedSessionPath={selectedSessionPath}
                    setSelectedSessionPath={setSelectedSessionPath}
                    sessionChat={sessionChat}
                    setSessionChat={setSessionChat}
                    chatInstructions={chatInstructions}
                    setChatInstructions={setChatInstructions}
                    messageList={messageList}
                    setMessageList={setMessageList}
                    history={history}
                    setHistory={setHistory}
                    textBox={textBox}
                    setTextBox={setTextBox}
                    socket={socket}
                    socketStatus={socketStatus}
                    messageListRef={messageListRef}
                    streamLoadingRef={streamLoadingRef}
                    currentTrackerRef={currentTrackerRef}
                    historyRef={historyRef}
                    listRef={listRef}
                    animationId={animationId}
                    animationText={animationText}
                    scrollToBottom={scrollToBottom}
                    handleChatAddition={handleChatAddition}
                    removeAnimation={removeAnimation}
                    handleTitleGeneration={handleTitleGeneration}
                    scrollLock={scrollLock}
                    currentChatIdRef={currentChatIdRef}
                    voiceConfigs={voiceConfigs}
                    setVoiceConfigs={setVoiceConfigs}
                    domainPrompts={domainPrompts}
                    setDomainPrompts={setDomainPrompts}
                    randomPrompts={randomPrompts}
                    setRandomPrompts={setRandomPrompts}
                    voiceOptions={voiceOptions}
                    setVoiceOptions={setVoiceOptions}
                    selectedVoice={selectedVoice}
                    setSelectedVoice={setSelectedVoice}
                    defaultVoiceIndex={defaultVoiceIndex}
                    setDefaultVoiceIndex={setDefaultVoiceIndex}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    documentAttached={documentAttached}
                    setDocumentAttached={setDocumentAttached}
                    chatMode={chatMode}
                    setChatMode={setChatMode}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    imageData={imageData}
                    setImageData={setImageData}
                  />
                )}
                <BorderBeam
                  size={100}
                  duration={BORDER_BEAM_DURATION}
                  colorFrom="#FF3465"
                  colorTo="#471CA8"
                  containerStyle={{
                    zIndex: 1500,
                    borderWidth: '1.5px'
                  }}
                  hidden={!showBorderBeam}
                />
              </Paper>
            </ResizableBox>
          </Popover>
          <Box id="chat-popover-trigger"></Box>
        </Box>
      </Draggable>
    )
  )
}

export default Chat
