'use client'

import { cn } from '@/lib/utils'
import { AnimatePresence, motion, MotionStyle, Transition } from 'motion/react'

interface BorderBeamProps {
  /**
   * The size of the border beam.
   */
  size?: number
  /**
   * The duration of the border beam.
   */
  duration?: number
  /**
   * The delay of the border beam.
   */
  delay?: number
  /**
   * The color of the border beam from.
   */
  colorFrom?: string
  /**
   * The color of the border beam to.
   */
  colorTo?: string
  /**
   * The motion transition of the border beam.
   */
  transition?: Transition
  /**
   * The class name of the border beam.
   */
  className?: string
  /**
   * The style of the border beam.
   */
  style?: React.CSSProperties
  /**
   * The style of the container of the border beam.
   */
  containerStyle?: React.CSSProperties
  /**
   * Whether to reverse the animation direction.
   */
  reverse?: boolean
  /**
   * The initial offset position (0-100).
   */
  initialOffset?: number
  /**
   * Whether to hide the border beam.
   */
  hidden?: boolean
}

export const BorderBeam = ({
  className,
  size = 50,
  delay = 0,
  duration = 6,
  colorFrom = '#ff3465',
  colorTo = '#471ca8',
  transition,
  style,
  containerStyle,
  reverse = false,
  initialOffset = 0,
  hidden = false
}: BorderBeamProps) => {
  if (hidden) return null

  return (
    <AnimatePresence>
      <motion.div
        className={cn(
          // mask-clip tailwind not working as expected so we are using css
          // 'pointer-events-none absolute inset-0 rounded-[inherit] border border-transparent [mask-clip:padding-box,border-box] [mask-composite:intersect] [mask-image:linear-gradient(transparent,transparent),linear-gradient(#000,#000)]',
          'border-beam-mask'
        )}
        style={containerStyle}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.3,
            ease: 'easeInOut'
          }
        }}
      >
        <motion.div
          className={cn(
            'absolute aspect-square',
            'bg-gradient-to-l from-[var(--color-from)] via-[var(--color-to)] to-transparent',
            className
          )}
          style={
            {
              width: size,
              offsetPath: `rect(0 auto auto 0 round ${size}px)`,
              '--color-from': colorFrom,
              '--color-to': colorTo,
              ...style
            } as MotionStyle
          }
          initial={{ offsetDistance: `${initialOffset}%` }}
          animate={{
            offsetDistance: reverse
              ? [`${100 - initialOffset}%`, `${-initialOffset}%`]
              : [`${initialOffset}%`, `${100 + initialOffset}%`]
          }}
          transition={{
            repeat: Infinity,
            ease: 'linear',
            duration,
            delay: -delay,
            ...transition
          }}
        />
      </motion.div>
    </AnimatePresence>
  )
}
