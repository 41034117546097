import { useState, useEffect } from 'react'
import { decode } from 'blurhash'

interface BlurhashOptions {
  width?: number
  height?: number
}

/**
 * Hook to convert a blurhash string into a data URL for use as an image placeholder
 * @param blurhash - The blurhash string to decode
 * @param options - Configuration options for decoding
 * @param options.width - Width of the decoded image (default: 32)
 * @param options.height - Height of the decoded image (default: 32)
 * @returns The decoded image as a data URL, or an empty string if no blurhash provided
 */

export const useBlurhash = (
  blurhash: string | undefined,
  options: BlurhashOptions = {}
): string => {
  const [dataUrl, setDataUrl] = useState<string>('')
  const { width = 32, height = 32 } = options

  useEffect(() => {
    if (!blurhash) {
      setDataUrl('')
      return
    }

    try {
      const pixels = decode(blurhash, width, height)
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        console.error('Could not get 2d context from canvas')
        return
      }

      const imageData = ctx.createImageData(width, height)
      imageData.data.set(pixels)
      ctx.putImageData(imageData, 0, 0)
      setDataUrl(canvas.toDataURL())
    } catch (error) {
      console.error('Failed to decode blurhash:', error)
      setDataUrl('')
    }
  }, [blurhash, width, height])

  return dataUrl
}
