import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import _, {
  camelCase,
  isEmpty,
  cloneDeep,
  forEach,
  set,
  isArray,
  startCase
} from 'lodash'
import { generateTitle, viewSessionChat, chatPrompt } from '../../store/api'
import { useStyles } from './styles'
import { Container, Section, SectionFixed } from '../../components/Container'
import { htmlToText, textToHtml } from '../../utils/CopyHTML'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { ReactComponent as Robot } from '../../assets/images/robot.svg'
import SvgIcon from '@mui/material/SvgIcon'
import { AvatarIcon } from '../../components/UserAvatar'
import Avatar from '@mui/material/Avatar'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as ChatArrowLeft } from '../../assets/images/chatArrowLeft.svg'
import { ReactComponent as ChatArrowRight } from '../../assets/images/chatArrowRight.svg'
import { checkUserRoleSuperUser } from '../../utils/User'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../config/eventMapping'
import clsx from 'clsx'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import { setToasterAlert } from '../../store/Common/Actions'
import FeedbackIcon from '@mui/icons-material/Feedback'
import mixpanelEvents from '../../config/mixpanelEvents'
import { SystemPrompt } from '../ChatBot/chat'

const CopyButton = ({ text }) => {
  const [isCopying, setIsCopying] = useState(false)
  const handleCopy = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!isCopying) {
      navigator.clipboard.writeText(htmlToText(text))
      trackEvent(mixpanelEvents.CHAT_CONTENT_COPIED, 'SUCCESS', {}, {})
      setIsCopying(true)
      setTimeout(() => {
        setIsCopying(false)
      }, 500)
    }
  }

  return (
    <div
      style={{
        cursor: 'pointer',
        fontSize: '12px',
        textAlign: 'end',
        margin: '0px 5px'
      }}
      disabled={isCopying}
      onClick={(e) => handleCopy(e)}
    >
      <Tooltip title={'Copy Text'}>
        {isCopying ? (
          <DoneOutlinedIcon style={{ fontSize: '15px' }} />
        ) : (
          <ContentCopyIcon style={{ fontSize: '15px' }} />
        )}
      </Tooltip>
    </div>
  )
}

const SessionChatViewer = () => {
  const [history, setHistory] = useState([])
  const [historyReaction, setHistoryReaction] = useState([])
  const [chats, setChats] = useState([])
  const { sessionId = '' } = useParams()
  const classes = useStyles()
  const isSuperUser = checkUserRoleSuperUser()
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [promptOpen, setPromptOpen] = useState(false)
  const [prompt, setPrompt] = useState('')

  const dispatch = useDispatch()

  const closeDialog = () => {
    setFeedbackOpen(false)
  }
  const closePrompt = () => {
    setPrompt('')
    setPromptOpen(false)
  }
  const handlePrompt = async (id) => {
    const res = await chatPrompt(id)
    if (res.status === 200) {
      setPrompt(res.data.prompt_data.prompt)
    } else {
      dispatch(
        setToasterAlert({
          message: 'Error while opening prompt',
          show: true,
          type: 'error'
        })
      )
    }
  }

  useEffect(() => {
    const getSessionChat = async () => {
      const res = await viewSessionChat(sessionId)
      if (res.status === 200) {
        const chats = res?.data?.session_history?.chats
        setChats(chats)
      }
    }
    getSessionChat()
  }, [sessionId])

  useEffect(() => {
    const historyArray = []
    const historyReactionArray = []
    const reverseData = chats.reverse()
    reverseData.forEach((item) => {
      const {
        input_text,
        output_text,
        exception = '',
        feedback = '',
        reaction = '',
        id = ''
      } = item
      const messageThem = {
        type: 'text',
        author: 'Them',
        data: {
          text: exception
            ? textToHtml('Error\nSomething went wrong! Please try again.')
            : textToHtml(output_text),
          error: exception
        },
        feedback: feedback || '',
        reaction: reaction || '',
        id
      }
      const messageMe = {
        type: 'text',
        author: 'Me',
        data: {
          text: textToHtml(input_text)
        }
      }
      historyArray.push(messageMe)
      historyReactionArray.push('')
      historyArray.push(messageThem)
      historyReactionArray.push(reaction || '')
    })
    setHistory(historyArray)
    setHistoryReaction(historyReactionArray)
  }, [chats])

  return (
    <Container>
      <Section>
        <Container>
          <Section className={classes.sessionChatWrapper} overFlow>
            <List
              className={clsx(classes.messageList, classes.listCenter)}
              id="messageList"
              sx={{ padding: '10px' }}
            >
              {history.map((message, index) => (
                <>
                  <ListItem
                    id={'history' + index}
                    key={index}
                    className={
                      classes.message +
                      ' ' +
                      classes[`message${message.author}`]
                    }
                  >
                    <div className={classes.messageWrapper}>
                      {message.author === 'Them' && (
                        <>
                          <div className={classes.avatarWrapper}>
                            <Avatar className={classes.avatar}>
                              <SvgIcon>
                                <Robot />
                              </SvgIcon>
                            </Avatar>
                          </div>
                          <ChatArrowLeft
                            style={{ color: '#eff3fa', minWidth: '8px' }}
                          />
                          <div>
                            <div className={classes.messageText}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: message.data.text.replaceAll('**', '')
                                }}
                                className={
                                  message?.data?.error
                                    ? classes.errorContainer
                                    : ''
                                }
                              />
                            </div>
                            {isSuperUser && message?.data?.systemPrompt && (
                              <SystemPrompt text={message.data.systemPrompt} />
                            )}
                          </div>
                          <div className={classes.funcWrappers}>
                            {
                              <CopyButton
                                text={message.data.text.replaceAll('**', '')}
                              />
                            }
                          </div>
                        </>
                      )}
                      {message.author === 'Me' && (
                        <>
                          <div className={classes.funcWrappers}>
                            {
                              <CopyButton
                                text={message.data.text.replaceAll('**', '')}
                              />
                            }
                          </div>
                          <div>
                            <div className={classes.messageText}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: message.data.text.replaceAll('**', '')
                                }}
                                className={
                                  message?.data?.error
                                    ? classes.errorContainer
                                    : ''
                                }
                              />
                            </div>
                            {isSuperUser && message.data.systemPrompt && (
                              <SystemPrompt text={message.data.systemPrompt} />
                            )}
                          </div>
                          <ChatArrowRight
                            style={{ color: '#eff3fa', minWidth: '8px' }}
                          />
                          <div className={classes.avatarWrapper}>
                            <AvatarIcon />
                          </div>
                        </>
                      )}
                    </div>
                  </ListItem>
                  {message.author === 'Them' && (
                    <div className={classes.feedbackWrappers}>
                      {
                        <>
                          {historyReaction[index] === 'like' && (
                            <Tooltip title="liked">
                              <IconButton>
                                <ThumbUpAltIcon style={{ color: 'green' }} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {historyReaction[index] === 'dislike' && (
                            <Tooltip title="disliked">
                              <IconButton>
                                <ThumbDownAltIcon style={{ color: 'red' }} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {message.feedback && message.feedback !== 'null' && (
                            <>
                              <Tooltip title="feedback">
                                <IconButton
                                  onClick={() => {
                                    setFeedbackOpen(true)
                                    setFeedback(message.feedback)
                                  }}
                                >
                                  {/* <IconButton> */}
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>

                              <Dialog
                                open={feedbackOpen}
                                onClose={closeDialog}
                                fullWidth
                                maxWidth="md"
                              >
                                <DialogTitle>Feedback</DialogTitle>
                                <DialogContent>{feedback}</DialogContent>
                              </Dialog>
                            </>
                          )}
                          <Tooltip title="prompt">
                            <IconButton
                              onClick={() => {
                                setPromptOpen(true)
                                handlePrompt(message.id)
                              }}
                            >
                              <FeedbackIcon />
                            </IconButton>
                          </Tooltip>
                          <Dialog
                            open={promptOpen}
                            onClose={closePrompt}
                            fullWidth
                            maxWidth="md"
                          >
                            <DialogTitle>Prompt</DialogTitle>
                            <DialogContent>{prompt}</DialogContent>
                          </Dialog>
                        </>
                      }
                    </div>
                  )}
                </>
              ))}
            </List>
          </Section>
        </Container>
      </Section>
    </Container>
  )
}

export default SessionChatViewer
