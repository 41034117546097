import { useState } from 'react'
import { SketchPicker } from 'react-color'
import Select from '@mui/material/Select'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'

const ColorPicker = (props) => {
  const {
    color = '#FFFFFF',
    onChange = () => {},
    showLabel = false,
    label = 'Pick a color',
    labelStyle = {},
    showToolTip = false
  } = props
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  return (
    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <Tooltip title={showToolTip && 'Change Color'}>
        <Box
          sx={{
            padding: '5px',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer'
          }}
          onClick={(e) => {
            e.stopPropagation()
            setDisplayColorPicker(!displayColorPicker)
          }}
        >
          <Select
            inputProps={{ IconComponent: () => null }}
            sx={{
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              border: '1px white solid'
            }}
            style={{ background: color }}
          >
            <SketchPicker color={color} onChange={(e) => onChange(e.hex)} />
          </Select>
        </Box>
      </Tooltip>
      {showLabel && <Box sx={labelStyle}>{label}</Box>}
    </Box>
  )
}

export default ColorPicker
