import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  searchInput: {
    marginBottom: '10px',
    '& input': {
      padding: '4px 8px',
      fontSize: '14px',
      color: '#797979'
    }
  },
  dateInput: {
    '& input': {
      padding: '4px 8px',
      fontSize: '14px',
      color: '#797979'
    },
    '& button': {
      padding: '0px'
    }
  },
  button: {
    margin: '5px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      padding: '0px',
      fontWeight: 'normal'
    }
  },
  selectedKey: {
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '4px 8px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center'
  },
  menu: {
    '& .MuiSvgIcon-root': {
      fontSize: '14px'
    },
    '& .MuiListItemIcon-root': {
      minWidth: '0px',
      marginRight: '5px',
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        color: '#797979'
      }
    },
    '& .MuiList-root': {
      padding: '5px',
      fontSize: '12px',
      // color: '#797979',
      width: '230px',
      '& li': {
        fontSize: '12px',
        padding: '4px 8px',
        whiteSpace: 'normal',
        wordBreak: 'break-word'
      }
    }
  },
  listItem: {
    maxHeight: '500px',
    overflow: 'auto'
  },
  tagsContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    '& .MuiSvgIcon-root': {
      fontSize: '14px',
      color: '#797979'
    }
  },
  flexContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  flexShrinkItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexShrink: '1'
  },
  selectedTag: {
    border: '1px solid var(--grey-200)',
    backgroundColor: 'var(--grey-50)',
    borderRadius: '3px',
    color: 'var(--dark-text)',
    padding: '2px 6px',
    fontSize: '12px',
    display: 'flex',
    gap: '7px',
    alignItems: 'center'
  },
  selectedTagDisabled: {
    border: '1px solid #00000042',
    borderRadius: '5px',
    color: '#00000042',
    padding: '2px 4px',
    fontSize: '12px',
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    '& button': {
      color: '#00000042'
    },
    '& .MuiSvgIcon-root': {
      color: '#00000042'
    }
  },
  predefinedDatesWrapper: {
    display: 'flex',
    maxWidth: '214px',
    flexWrap: 'wrap',
    gap: '5px',
    alignContent: 'space-between',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  predefinedDates: {
    border: '1px solid #797979',
    borderRadius: '5px',
    color: '#797979',
    padding: '2px 4px',
    fontSize: '12px',
    marginTop: '5px'
  },
  selectTagButton: {
    padding: '0px',
    fontSize: '12px',
    cursor: 'pointer'
  },
  selectTagButtonDisabled: {
    padding: '0px',
    fontSize: '12px',
    opacity: '0.5'
  },
  disabledWrapper: {
    '& button': {
      color: '#00000042'
    },
    '& .MuiSvgIcon-root': {
      color: '#00000042'
    }
  },
  collectionsList: {
    color: '#797979',
    marginTop: '21px',
    marginBottom: '20px'
  },

  listWrapper: {
    padding: '0px',
    width: '100%',
    '& .Mui-selected': {
      backgroundColor: '#DBEAFF'
    }
  },
  listButtonWrapper: {
    padding: '0px'
  },
  listTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    color: '#18181b'
  },
  listText: {
    marginLeft: '5px',
    fontWeight: 500,
    width: '100%',
    wordBreak: 'break-word',
    fontSize: '15px',
    flex: '1',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical'
  },
  collectionTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  collectionTitle: {
    fontSize: '16px',
    fontWeight: '500'
  },
  iconButton: {
    padding: '0px'
  },
  iconEmpty: {
    fontSize: '8px !important',
    color: '#797979',
    width: '15px !important'
  },
  icon: {
    fontSize: '15px !important',
    color: '#797979 !important'
  },
  collectionListWrapper: {
    border: '1px solid #E5E5E5',
    minHeight: '35vh',
    maxHeight: '35vh',
    overflow: 'auto'
  },
  emptyIcon: {
    width: '24px',
    height: '24px'
  },
  nestedCollection: {
    paddingLeft: '10px'
  }
})

export { useStyles }
