import Loader from '../../components/Loader'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { fetchRFx } from '../../store/RFx/Actions'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from '../ResourceFileView/styles'
import { getRFx } from '../../store/api'
import ComplianceMatrix from '../RfxPDFView/ComplianceMatrix'
import * as React from 'react'
import { checkUserRoleViewer } from '../../utils/User'

const RFxAnalysis = (props) => {
  const { rfxId = null } = props

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [rfx, setRFx] = useState(null)
  const [analysisReport, setAnalysisReport] = useState({})
  const [matrix, setMatrix] = useState([])
  const [complianceMatrix, setComplianceMatrix] = useState(matrix)
  const isUserViewer = checkUserRoleViewer()
  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )
  const classes = useStyles()

  useEffect(() => {
    getRFx(rfxId).then((response) => {
      updateProperties(response.data)
    })
  }, [])

  useEffect(() => {
    if (rfx) {
      setLoading(false)
    }
  }, [rfx])

  const updateProperties = (rfx) => {
    setRFx(rfx)
    setAnalysisReport(rfx?.content[0]?.analysis_report || {})
    setComplianceMatrix(rfx?.content[0]?.analysis_report['Compliance List'])
  }

  return loading ? (
    <Loader loading={true} caption={'Loading analysis'} flex />
  ) : (
    <Box className={classes.sidepaneWrapper}>
      <Box className={classes.inputWrapper}>
        <ComplianceMatrix
          content={analysisReport}
          complianceMatrix={complianceMatrix}
          setComplianceMatrix={setComplianceMatrix}
          id={rfx.id}
          rfxName={rfx.document_name}
          shareType={'private'}
          readOnly={true}
          editingDisabled={
            isUserViewer && currentUserName !== rfx?.created_by_user
          }
        />
      </Box>
    </Box>
  )
}

export default RFxAnalysis
