import { makeStyles } from '@mui/styles'
import { useColor } from '../../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor()

  return {
    outerContainer: {
      height: '100%'
    },
    hideOpenDrawer: {
      fontWeight: 'bold',
      marginLeft: '33px',
      padding: '18px 0'
    },
    showOpenDrawer: {
      fontWeight: 'bold'
    },
    newChatButton: {
      padding: '4px 10px',
      background: selectedColor,
      fontFamily: 'PoppinsRegular',
      fontWeight: '600',
      fontSize: '15px',
      textTransform: 'none',
      borderRadius: '6px',
      color: 'white',
      '&:hover': {
        background: '#195ccb',
        color: 'white'
      }
    },
    disabledButton: {
      padding: '4px 10px',
      background: '#dedede',
      fontFamily: 'PoppinsRegular',
      fontWeight: '600',
      textTransform: 'none',
      borderRadius: '6px',
      color: '#b1b1b1',
      '&:hover': {
        background: '#dedede'
      }
    },
    historySessionTitle: {
      color: '#000000',
      fontFamily: 'PoppinsRegular',
      fontWeight: 'bold',
      fontSize: '15px',
      lineHeight: '15px',
      letterSpacing: '0.5px',
      padding: '10px 0',
      marginTop: '13px'
    },
    selectedHistoryOption: {
      background: '#EEEEEE',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: '#EEEEEE !important'
      },
      '& #menubutton': {
        display: 'block !important'
      }
    },
    newChat: {
      background: '#EEEEEE',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      padding: '10px  0'
    },
    historyChatTitle: {
      display: 'flex',
      alignItems: 'center',
      color: 'var(--grey-700)',
      fontFamily: 'PoppinsRegular',
      fontSize: '13px',
      padding: '5px 10px',
      cursor: 'pointer',
      '&:hover': {
        background: '#f8f8f8',
        '& #menubutton': {
          display: 'block'
        }
      },
      '& input': {
        padding: '0.72px 5px',
        color: '#797979',
        fontFamily: 'PoppinsRegular',
        fontSize: '15px'
      },
      '& #menubutton': {
        display: 'none'
      }
    },
    loaderContainer: {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    },
    titleActions: {
      '& >svg': {
        width: '20px',
        height: '20px'
      }
    },
    tooltip: {
      background: 'black',
      fontSize: '15px'
      // width: "100%",
    },
    iconContainer: {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center'
    },
    iconActive: {
      color: selectedColor,
      display: 'flex',
      alignItems: 'center',
      padding: '3px',
      margin: '5px 8px',
      backgroundColor: '#DBEAFF',
      borderRadius: '5px',
      '& svg': {
        width: '25px',
        height: '15px'
      }
    },
    iconInActive: {
      color: selectedColor,
      display: 'flex',
      alignItems: 'center',
      padding: '3px',
      margin: '5px 8px',
      borderRadius: '5px',
      backgroundColor: '#DBEAFF',
      '& svg': {
        width: '25px',
        height: '15px'
      }
    },
    chatSessionIcon: {
      color: selectedColor,
      display: 'flex',
      width: '30px',
      alignItems: 'center',
      backgroundColor: '#DBEAFF',
      borderRadius: '5px',
      padding: '5px',
      margin: '5px 0px',
      '& svg': {
        width: '25px',
        height: '15px'
      }
    },
    scrollableDiv: {
      padding: '0px 20px 20px 20px',
      width: 'calc(100% - 40px)',
      height: 'calc(100% - 20px)',
      overflow: 'auto',
      marginRight: '0'
    },
    suggestionWrapper: {
      padding: '10px 20px'
    },
    titleContent: {
      fontFamily: 'PoppinsRegular',
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '500px',
      marginBottom: '15px'
    },
    contentWrapper: {
      marginLeft: '20px',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      alignContent: 'stretch',
      flexWrap: 'wrap',
      gap: '15px'
    },

    contentText: {
      background: '#DBEAFF',
      padding: '10px',
      width: '200px',
      fontFamily: 'PoppinsRegular',
      fontSize: '13px',
      fontWeight: '400',
      borderRadius: '4px',
      height: '3.6em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    contextClamp: {
      WebkitLineClamp: 3,
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      lineHeight: 1.2,
      overflow: 'hidden',
      textTransform: 'capitalize'
    },
    avatarWrapper: {
      margin: '0px 15px',
      display: 'flex'
    },
    messageMe: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '20px 0px',
      backgroundColor: '#FFFFFF'
    },
    messageThem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '20px 0px',
      backgroundColor: '#F7F7F8'
    },
    messageWrapper: {
      display: 'flex',
      maxWidth: '58rem',
      margin: 'auto',
      width: 'calc(100% - 20px)',
      justifyContent: 'start',
      cursor: 'text'
    },
    avatar: {
      color: theme.palette.primary.main,
      border: `2px ${theme.palette.primary.main} solid`,
      backgroundColor: 'inherit',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'PoppinsRegular'
    },
    avatarCheckBox: {
      display: 'flex',
      alignItems: 'start'
    },
    messageText: {
      padding: '10px',
      minWidth: '50px',
      color: '#696969',
      maxWidth: '650px',
      fontSize: '15px',
      wordBreak: 'break-word'
    },
    imageContainer: {
      padding: '10px',
      minWidth: '50px',
      color: '#696969',
      maxWidth: '650px',
      fontSize: '15px',
      wordBreak: 'break-word',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      height: 'auto',
      flexWrap: 'wrap',
      gap: '10px',

      '& span': {
        display: 'flex !important'
      }
    },
    imageWrapper: {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      objectFit: 'contain',
      cursor: 'pointer',
      width: '120px',
      minHeight: '120px',
      background: '#e6f1ff',
      position: 'relative',
      '& .overlayButtons': {
        top: 'inherit',
        right: 'inherit',
        backgroundColor: 'transparent'
      },
      '&:hover': {
        '& img': {
          opacity: '0.5 !important'
        },
        '& .overlayButtons': {
          opacity: '1 !important'
        }
      }
    },
    menuOpen: {
      background: '#f8f8f8',
      '& #menubutton': {
        display: 'block !important'
      }
    }
  }
})

export { useStyles }
