// A component that accepts number of lines to show and classname, on the last end show show more if there are more text and on click showmore expand to the entir height

import { cn } from '@/lib/utils'
import React, { useState, useRef, useEffect } from 'react'

interface ShowMoreLessProps {
  text: string
  numberOfLines?: number
  className?: string
  onClick?: (e: React.MouseEvent<HTMLParagraphElement>) => void
}

export const ShowMoreLess: React.FC<ShowMoreLessProps> = ({
  text,
  numberOfLines = 3,
  className = '',
  onClick = () => {}
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const textRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    // Check if content exceeds the specified number of lines
    const checkHeight = () => {
      if (textRef.current) {
        const styles = window.getComputedStyle(textRef.current)
        const lineHeight =
          parseInt(styles.lineHeight) || parseInt(styles.fontSize) * 1.2
        const maxHeight = lineHeight * numberOfLines
        const actualHeight = textRef.current.scrollHeight

        // Add a small buffer to account for rounding
        setShowButton(actualHeight > maxHeight + 2)
      }
    }

    // Run after a short delay to ensure content is rendered
    const timer = setTimeout(checkHeight, 0)
    window.addEventListener('resize', checkHeight)

    return () => {
      clearTimeout(timer)
      window.removeEventListener('resize', checkHeight)
    }
  }, [numberOfLines, text])

  const clampStyle = !isExpanded
    ? ({
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: numberOfLines
      } as React.CSSProperties)
    : {}

  return (
    <div className={cn('relative', className)}>
      <p ref={textRef} style={clampStyle} onClick={onClick}>
        {text}
      </p>

      {showButton && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-blue-600 hover:text-blue-800 text-xs font-medium mt-1 focus:outline-none"
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  )
}
