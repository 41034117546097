import React, { useRef, useState } from 'react'

import {
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger
} from '@/components/ui/dropdown-menu'

import { MenuItem } from './pdf-viewer-context-menu'
import { AiGenerateOptions } from './ai-generate-options'

export const AiGenerateSubMenu = ({
  textSelectProps,
  item
}: {
  textSelectProps: any
  item: MenuItem
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)

  const handleOpenChange = (open: boolean) => {
    setOpen(open)
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 200)
    }
  }

  return (
    <DropdownMenuSub
      open={open}
      key={item.label}
      onOpenChange={handleOpenChange}
    >
      <DropdownMenuSubTrigger className="flex items-center gap-2">
        {item.icon}
        {item.label}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent className="z-[1500] w-56 p-0">
          <AiGenerateOptions ref={inputRef} textSelectProps={textSelectProps} />
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}
