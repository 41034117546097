import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme) => {
  return {
    menuContainer: {
      '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        width: 230,
        color:
          theme.palette.mode === 'light'
            ? 'rgb(55, 65, 81)'
            : theme.palette.grey[300],
        boxShadow:
          'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
          padding: '10px'
        },
        '& .MuiMenuItem-root': {
          color: 'var(--grey-700)',
          fontFamily: 'PoppinsMedium',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          borderRadius: 3,
          padding: '7px 8px',
          '& .MuiListItemIcon-root': {
            minWidth: 0
          },
          '& .MuiSvgIcon-root': {
            fontSize: 16,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1)
          },
          '& .MuiTypography-root': {
            fontSize: '12px'
          },
          '&:active': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity
            )
          }
        }
      }
    },

    menuContainerDark: {
      '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        width: 230,
        backgroundColor: 'var(--grey-900)',
        // color:
        color: 'var(--grey-100)',

        boxShadow:
          'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
          padding: '10px'
        },
        '& .MuiMenuItem-root': {
          color: 'var(--grey-200)',
          fontFamily: 'PoppinsMedium',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          borderRadius: 3,
          padding: '7px 8px',
          '&:hover': {
            background: 'var(--grey-800)'
          },
          '& .MuiListItemIcon-root': {
            minWidth: 0,
            color: 'var(--grey-100)'
          },
          '& .MuiSvgIcon-root': {
            fontSize: 16,
            color: 'var(--grey-100)',
            marginRight: theme.spacing(1)
          },
          '& .MuiTypography-root': {
            fontSize: '12px'
          },
          '&:active': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity
            )
          }
        }
      }
    },

    dividerLine: {
      margin: '2px 0px !important'
    },
    icon: {
      fontSize: '15px',
      color: '#797979'
    },
    tableRowText: {
      fontSize: '12px',
      wordBreak: 'break-word',
      color: '#797979',
      fontWeight: '400',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      '& .MuiTypography-root': {
        fontSize: '14px'
      }
    },
    iconButton: {
      padding: '0px',
      marginLeft: '5px',
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1)
      }
    }
  }
})

export { useStyles }
