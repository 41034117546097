import React, { useEffect, useState, useMemo } from 'react'
import {} from '../../components/Container'
import { useSelector, useDispatch } from 'react-redux'
import { ROUTES } from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles'
import {
  Loader,
  Button,
  Container,
  useConfirmation,
  TextInput
} from '../../components'
import {
  Box,
  ListItemText,
  ListItemIcon,
  Skeleton,
  SvgIcon,
  IconButton,
  // Dialog,
  DialogTitle,
  DialogActions
} from '@mui/material'
import { ReactComponent as SortDown } from '../../assets/svg/SortDown.svg'
import { ReactComponent as SortUp } from '../../assets/svg/SortUp.svg'
import { updateWorkspace } from '../../store/Workspace/Actions'
import { checkUserRoleViewer } from '../../utils/User'
import moment from 'moment'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { v4 as uuid } from 'uuid'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import HeadlessTable from '@/components/TableV2/HeadlessTable'
import { getColorsForIndex } from '@/utils/String'
import SearchNew from '@/sections/ListFilter/SearchNew'
import {
  CompassIcon,
  CrossIcon,
  DraftingCompass,
  PencilIcon,
  PlusIcon,
  RouteIcon,
  SemiCircleIcon,
  ThreeDotsIcon,
  TrashIcon,
  WorkspaceIcons
} from '@/components/Icons/Icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { cn } from '@/lib/utils'
import { FileIcon } from '@react-pdf-viewer/default-layout'
import { editWorkspace, getWorkspaces } from '@/store/api'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger
} from '@/components/ui/dialog'

const getConfigFromLocal = () => {
  let data = localStorage.getItem('workspaceConfigMap')
  data = data ? JSON.parse(data) : null
  return data
}

const WorkspaceList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { WORKSPACE } = ROUTES
  const config = getConfigFromLocal()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const isUserViewer = checkUserRoleViewer()

  const {
    columnWidths: configColumnWidths,
    hiddenColumns: configHiddenColumns,
    sortOrder: configSortOrder
  } = config || {}

  const workspaceState = useSelector((state) => state.workspace)
  const {
    workspaces,
    hasNextPage,
    searchKeyword: searchKeywordState
  } = workspaceState
  const [keyword, setKeyword] = useState('')
  const [open, setOpen] = useState(false)
  const [keywordState, setKeywordState] = useState('')
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState([])

  const [loading, setLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)
  const [sortOrder, setSortOrder] = useState(
    configSortOrder || {
      created_at: 'desc'
    }
  )
  const [searchControllers, setSearchControllers] = useState({})
  const scrollControlDiv = 'scrollableWorkspaceDiv'
  const [columnWidths, setColumnWidths] = useState(configColumnWidths || {})
  const [columns, setColumns] = useState([
    'workspace_name',
    'created_by_user',
    'created_at',
    'updated_at'
  ])
  const [hiddenColumns, setHiddenColumns] = useState(configHiddenColumns || [])
  const [visibleColumns, setVisibleColumns] = useState([
    'workspace_name',
    'created_by_user',
    'created_at',
    'updated_at'
  ])
  const [isResizing, setIsResizing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [isMenuHovering, setIsMenuHovering] = useState(false)

  const visibleColumnPercentageTotal = useMemo(() => {
    let total = 0
    ;[...visibleColumns, 'menu_button'].forEach((column) => {
      total += columnWidths[column]
    })
    return total
  }, [columnWidths, visibleColumns])

  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [workspaceData, setWorkspaceData] = useState({
    name: '',
    description: '',
    id: null
  })

  const [creatingWorkspace, setCreatingWorkspace] = useState(false)
  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )

  const [meta, setMeta] = useState({
    pageSize: 30,
    totalCount: 45,
    page: 1
  })

  useEffect(() => {
    if (searchKeywordState) {
      setKeyword(searchKeywordState)
      setKeywordState(searchKeywordState)
    }
  }, [searchKeywordState])

  const clearSearch = () => {
    setKeyword('')
    setKeywordState('')
    setPage(1)
  }

  const checkHorizontalScrollbar = (target) => {
    const element = document.getElementById(target)
    return element ? element.scrollHeight !== element.clientHeight : false
  }

  // useEffect(() => {
  //   if (workspaces) {
  //     setLoading(false)
  //     // const hasScroll = checkHorizontalScrollbar(scrollControlDiv)
  //     // if (!hasScroll && hasNextPage && !fetchingData) {
  //     //   navigateToPage()
  //     // }
  //   } else {
  //     setLoading(true)
  //   }
  // }, [workspaces, fetchingData])

  useEffect(() => {
    const savedPage = localStorage.getItem('workspacePage', page)

    if (savedPage) {
      const pageNo = parseInt(savedPage)
      if (isNaN(pageNo)) {
        setPage(1)
      } else {
        setPage(pageNo)
      }
    } else {
      localStorage.setItem('workspacePage', 1)
      setPage(1)
    }
  }, [])

  useEffect(() => {
    if (page) {
      fetchData(page)
      localStorage.setItem('workspacePage', page)
    }
  }, [page])

  useEffect(() => {
    if (!workspaces) {
      fetchData()
    }
  }, [])

  const clearController = () => {
    Object.keys(searchControllers).forEach((key) => {
      const cancelRequest = searchControllers[key]
      cancelRequest.abort()
    })
    setSearchControllers({})
  }

  const initColumnsWidth = (visibleColumns) => {
    if (visibleColumns.length === 0) return
    const newWidths = columnWidths
    visibleColumns = [...visibleColumns, 'menu_button']
    visibleColumns.forEach((item) => {
      if (!Object.keys(columnWidths).includes(item)) {
        if (item === 'workspace_name') {
          newWidths[item] = 25
        } else if (item === 'menu_button') {
          newWidths[item] = 5
        } else {
          newWidths[item] = 10
        }
      }
    })
    if (!_.isEmpty(newWidths)) setColumnWidths(newWidths)
  }

  const saveConfigToLocal = () => {
    const dataMap = {
      columnWidths,
      hiddenColumns,
      sortOrder
    }
    localStorage.setItem('knowledgeConfigMap', JSON.stringify(dataMap))
  }

  useEffect(() => {
    saveConfigToLocal()
  }, [columnWidths, hiddenColumns, sortOrder])

  useEffect(() => {
    const visibleColumns = columns.filter(
      (item) => !hiddenColumns.includes(item)
    )
    setVisibleColumns(visibleColumns)
    initColumnsWidth(visibleColumns)
  }, [hiddenColumns, columns])

  const fetchData = async (
    page = 1,
    search = keyword,
    currentSortOrder = sortOrder
  ) => {
    setFetchingData(true)
    setTableLoading(true)

    const newController = new AbortController()
    setSearchControllers((prevControllers) => ({
      ...prevControllers,
      [page]: newController
    }))
    const [sortBy] = Object.keys(currentSortOrder)
    const sortOrder = currentSortOrder[sortBy]

    const data = {
      page_num: page,
      page_size: 30,
      sort_by: sortBy,
      sort_order: sortOrder,
      keyword: search
    }

    const callback = () => {
      setTableLoading(false)
      setFetchingData(false)
    }

    const response = await getWorkspaces(data, { signal: newController.signal })
    if (response.status === 200) {
      setRows(response.data.workspaces)
      setMeta({
        pageSize: response.data.page_size,
        totalCount: response.data.total_count,
        page: response.data.page_num
      })

      callback()
    }
  }

  const handleSort = (column, order) => {
    const newOrder = _.cloneDeep(sortOrder)
    if (newOrder?.[column] && newOrder[column] === order) {
      delete newOrder[column]
    } else {
      newOrder[column] = order
    }
    setSortOrder(newOrder)
    setPage(1)
    fetchData(1, keyword, newOrder)
  }

  const TableLoader = ({ count, hideHeader = false }) => {
    return (
      <table
        className={classes.tableWrapper}
        style={{ width: visibleColumnPercentageTotal + '%' }}
      >
        {!hideHeader && (
          <thead>
            <tr>
              {visibleColumns.map((column, index) => {
                const width = getColWidth(columnWidths[column], column)
                return (
                  <th
                    key={index}
                    style={{ width }}
                    className={classes.tableHeaderLoader}
                  >
                    <Skeleton
                      variant="rectangular"
                      key={index}
                      height={25}
                      sx={{ marginBottom: '5px' }}
                    />
                  </th>
                )
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {[...Array(count)].map((item, index) => (
            <tr key={index}>
              {visibleColumns.map((column, index) => {
                const width = getColWidth(columnWidths[column], column)
                return (
                  <td
                    key={index}
                    className={classes.tableRowLoader}
                    style={{ width }}
                  >
                    <Skeleton
                      variant="rectangular"
                      key={index}
                      width={'100%'}
                      height={25}
                      sx={{ marginBottom: '5px' }}
                    />
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const GetSortIcon = ({ id, isSelected, order }) => {
    return (
      <Box className={classes.sortLabel}>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'asc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'asc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortUp />
          </SvgIcon>
        </IconButton>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'desc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'desc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortDown />
          </SvgIcon>
        </IconButton>
      </Box>
    )
  }

  const handleResize = (column, width) => {
    if (width > 10) {
      setColumnWidths({
        ...columnWidths,
        [column]: width
      })
    }
  }

  const resetView = () => {
    setHiddenColumns([])
    setColumnWidths({})
    const defaultSortOrder = {
      created_at: 'desc'
    }
    setSortOrder(defaultSortOrder)
    setPage(1)
    fetchData(1, keyword, defaultSortOrder)
  }

  const handleMenuOpen = (row) => {
    const { id } = row || {}
    if (id) handleOpenWorkspace(id, true)
  }

  const handleMenuEdit = (row) => {
    const { id, workspace_name, description } = row || {}
    setWorkspaceData({
      name: workspace_name,
      description,
      id
    })
    setOpen(true)
  }

  const handleMenuDelete = (row) => {
    const { id, workspace_name } = row || {}
    showConfirmDialog({
      confirmationMessageTitle: (
        <div>
          Are you sure you want to delete workspace <b>{workspace_name}</b> ?
        </div>
      ),
      confirmationMessage: (
        <div>
          This action will also delete all the drafts associated with this
          workspace
        </div>
      ),
      onConfirm: () => {
        const request = {
          id,
          mode: 'delete'
        }
        dispatch(updateWorkspace(request))
      }
    })
  }

  const menuOptions = [
    {
      label: 'Open',
      icon: <OpenInNewIcon />,
      onClick: handleMenuOpen
    },
    {
      label: 'Edit',
      icon: <DriveFileRenameOutlineIcon />,
      onClick: handleMenuEdit
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: handleMenuDelete
    }
  ]

  const getColWidth = (width, key) => {
    if (width) {
      return width + '%'
    } else return 'auto'
  }

  const formatHeader = (header) => {
    return _.startCase(header)
  }

  const handleOpenWorkspace = (row, newTab) => {
    trackEvent(mixpanelEvents.WORKSPACE_OPENED, 'SUCCESS', {}, {})

    if (newTab) {
      window.open(`${WORKSPACE}/${row.id}`, '_blank')
    } else {
      navigate(`${WORKSPACE}/${row.id}`, {
        state: {
          workspaceId: row.id,
          name: row.workspace_name
        }
      })
    }
  }

  const renderKeyValues = (key, value, isRecent) => {
    if (key === 'created_at' || key === 'updated_at')
      return moment(value).format('MMM D, YYYY')
    else if (key === 'workspace_name') {
      return isRecent ? (
        <div>
          {value}
          <div>(Recently Added)</div>
        </div>
      ) : (
        value
      )
    } else return value
  }

  const handleMenuItems = (option, classes) => {
    return (
      <Box sx={{ display: 'flex' }}>
        <ListItemIcon className={classes.icon}>{option.icon}</ListItemIcon>
        <ListItemText className={classes.tableRowText}>
          {option.name}
        </ListItemText>
      </Box>
    )
  }

  const handleEditButton = async () => {
    setCreatingWorkspace(true)
    const body = {
      id: workspaceData.id,
      workspace_name: workspaceData.name,
      description: workspaceData.description,
      created_by_user: currentUserName,
      mode: 'update'
    }

    if (!body?.id) {
      body.id = uuid()
      body.mode = 'create'
    }

    const response = await editWorkspace(body)

    if (response.status === 200) {
      handleEditClose()
      setCreatingWorkspace(false)

      if (body.mode === 'create') {
        handleOpenWorkspace({
          id: body.id
        })
      }
    }

    // dispatch(updateWorkspace(body))

    //   trackEvent(mixpanelEvents.WORKSPACE_CREATED, 'SUCCESS', {}, {})
    //   const id = uuid()
    //   const request = {
    //     id,
    //     workspace_name: workspaceData.name,
    //     description: workspaceData.description,
    //     created_by_user: currentUserName,
    //     mode: 'create'
    //   }
    //   dispatch(
    //     updateWorkspace(request, (data) => {
    //       handleOpenWorkspace({
    //         id
    //       })
    //     })
    //   )
    // } else {
    //   if (workspaceData.id) {
    //     const request = {
    //       id: workspaceData.id,
    //       workspace_name: workspaceData.name,
    //       description: workspaceData.description,
    //       created_by_user: currentUserName,
    //       mode: 'update'
    //     }
    //     dispatch(updateWorkspace(request))
    //   }
    // }
  }

  const handleEditClose = () => {
    setOpenEditDialog(false)
    setCreatingWorkspace(false)
    setWorkspaceData({
      name: '',
      description: '',
      id: null
    })

    setOpen(false)
  }

  const renderEditDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!openEditDialog}
        onClose={handleEditClose}
      >
        <DialogTitle>{_.startCase(openEditDialog)} Workspace</DialogTitle>
        <DialogContent>
          <TextInput
            placeholder="Name"
            value={workspaceData?.name || ''}
            handleChange={(e) =>
              setWorkspaceData({
                ...workspaceData,
                name: e.target.value
              })
            }
          />
          <TextInput
            style={{ marginTop: '10px' }}
            placeholder="Description"
            value={workspaceData?.description || ''}
            multiLine
            rows={4}
            handleChange={(e) => {
              setWorkspaceData({
                ...workspaceData,
                description: e.target.value
              })
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleEditClose}>
            Cancel
          </Button>
          <Button onClick={handleEditButton}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const columnSchema = [
    {
      id: 'workspace_name',
      label: 'Name',
      accessorKey: 'workspace_name',

      children: (row, i) => {
        return (
          <div className="flex items-center gap-2 twp">
            <div
              className={cn(
                'grid text-sm font-medium rounded-md place-content-center p-2 border border-zinc-100',
                getColorsForIndex(i)
              )}
            >
              {getIconsByIndex(i)}
            </div>
            <div className="flex flex-col">
              <div className="text-sm font-medium capitalize truncate text-zinc-800">
                {row.workspace_name}
              </div>
              <div className="flex items-center gap-2 text-xxs text-zinc-600">
                <span>{row.number_of_drafts} drafts</span>
                <div className="w-1 h-1 rounded-full bg-zinc-300" />
                <span>{row.number_of_files} files</span>
                <div className="w-1 h-1 rounded-full bg-zinc-300" />
                <span>
                  Last modified by{' '}
                  <span className="capitalize">{row.last_updated_by}</span>
                </span>
              </div>
            </div>
          </div>
        )
      }
    },
    {
      id: 'created_by_user',
      label: 'Owner',
      accessorKey: 'created_by_user',
      headerStyle: {
        // maxWidth: '200px',
        gap: '10px',
        minWidth: '130px',
        width: '280px'
      },
      style: {
        minWidth: '130px',
        width: '280px'
      },
      children: (row) => {
        return (
          <div className="capitalize truncate max-w-40">
            {row.created_by_user}
          </div>
        )
      }
    },
    {
      id: 'created_at',
      label: 'Created At',
      accessorKey: 'created_at',
      headerStyle: {
        // maxWidth: '200px',
        gap: '10px',
        minWidth: '130px',
        width: '280px'
      },
      style: {
        minWidth: '130px',
        width: '280px'
      },
      children: (row) => {
        return (
          <div className="truncate max-w-40">
            {moment(row.created_at).format('MMM D, YYYY')}
          </div>
        )
      }
    },
    {
      id: 'updated_at',
      label: 'Last Modified',
      accessorKey: 'updated_at',
      headerStyle: {
        // maxWidth: '200px',
        gap: '10px',
        minWidth: '130px',
        width: '280px'
      },
      style: {
        minWidth: '130px',
        width: '280px'
      },
      children: (row) => {
        return (
          <div className="truncate max-w-40">
            {moment(row.updated_at).format('MMM D, YYYY')}
          </div>
        )
      }
    },
    {
      id: 'updated_at',
      label: '',
      accessorKey: 'updated_at',
      headerStyle: {
        // maxWidth: '200px',
        gap: '10px',
        minWidth: '40px',
        width: '40px'
      },
      style: {
        minWidth: '40px',
        width: '40px'
      },
      children: (row) => {
        return (
          <div
            className="twp"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <DropdownMenu>
              <DropdownMenuTrigger>
                <div className="ml-auto truncate max-w-40">
                  <ThreeDotsIcon className="size-4" />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  onClick={() => {
                    handleMenuEdit(row)
                  }}
                >
                  <div className="flex items-center gap-2 text-xs font-medium">
                    <PencilIcon className="size-3" />
                    Rename
                  </div>
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => {
                    handleMenuDelete(row)
                  }}
                >
                  <div className="flex items-center gap-2 text-xs font-medium">
                    <TrashIcon className="size-3" />
                    Delete
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      }
    }
  ]

  return loading ? (
    <Loader loading={loading} caption="loading your workspace" flex />
  ) : (
    <Container>
      <div className="flex items-center gap-1 p-3.5 px-6 border-b twp border-zinc-100 text-zinc-800">
        <p
          className="flex items-center gap-1 text-sm font-medium cursor-pointer text-zinc-800"
          onClick={() => {}}
        >
          <WorkspaceIcons className="size-4" />
          Workspaces
        </p>

        <span className="px-2.5 rounded-full text-[10px] text-zinc-200 bg-zinc-800 p-1">
          beta
        </span>
      </div>
      {/* <div className="p-4 twp">
        <p className="text-sm font-medium text-zinc-800">Recent drafts</p>

        <div className="grid grid-cols-5 gap-2 mt-2">
          {RecentDrafts.map((item) => (
            <div
              className="flex flex-col justify-between w-full overflow-hidden transition-all duration-200 ease-in-out border rounded-md cursor-pointer h-28 border-zinc-100 bg-zinc-50 hover:border-zinc-200"
              key={item.id}
            >
              <div className="p-3">
                <div className="w-full text-sm font-medium truncate rounded-lg text-zinc-800">
                  {item.document_name}
                </div>

                {item.metadata_.storyboards.length > 0 && (
                  <div className="flex items-center gap-2 mt-1 text-xs rounded-full w-fit text-zinc-500">
                    {item.metadata_.storyboards.length} Storyboards
                  </div>
                )}
              </div>
              <div className="p-3 bg-white">
                <div className="flex items-center gap-2">
                  <p className="text-xs font-medium text-zinc-800">
                    {item.created_by_user_name}
                  </p>
                  <div className="w-1 h-1 rounded-full bg-zinc-300" />
                  <p className="text-xs text-zinc-500">
                    {moment(item.updated_at).format('MMM D, YYYY')}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className="flex items-center justify-end gap-2 p-2 mt-2 twp">
        <SearchNew
          value={keyword}
          onChange={(value) => setKeyword(value)}
          style={{
            padding: '6px 10px'
          }}
          onEnter={() => {
            setPage(1)
            fetchData(1, keyword)
          }}
          onClear={() => {
            console.log('clear')
            setKeyword('')
            setPage(1)
            fetchData(1, '')
          }}
        />

        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger
            className="flex items-center gap-1 px-4 py-2 font-medium text-white rounded-lg text-xxs bg-zinc-800"
            onClick={() => {
              setOpen(true)
              setWorkspaceData({
                name: '',
                description: '',
                id: null
              })
            }}
          >
            <PlusIcon className="size-3" /> New Workspace
          </DialogTrigger>
          <DialogContent className="max-w-sm gap-1 px-3 pb-2.5" hideCloseButton>
            <div>
              <p className="flex items-center gap-1 pl-2 -mt-2 text-xs font-medium">
                <WorkspaceIcons className="size-3.5 text-zinc-600" />
                New Workspace
              </p>
              <DialogClose asChild className={'absolute top-4 right-4'}>
                <button
                  className="IconButton disabled:pointer-events-none"
                  disabled={loading}
                  aria-label="Close"
                >
                  <CrossIcon className="size-4" />
                </button>
              </DialogClose>
            </div>
            <div className="w-full mx-auto">
              <div className="flex flex-col gap-3 py-2">
                <input
                  type="text"
                  autoFocus
                  placeholder="Workspace Name"
                  value={workspaceData.name}
                  onChange={(e) => {
                    setWorkspaceData({
                      ...workspaceData,
                      name: e.target.value
                    })
                  }}
                  className="pl-2 text-sm outline-none placeholder:text-zinc-400 "
                />

                <div className="flex items-center gap-1"></div>
              </div>
              <div className="flex items-center justify-end gap-2 pt-2 border-t border-zinc-200">
                <button
                  className="flex items-center gap-1 px-4 text-xs font-medium text-white rounded-lg bg-zinc-800 p-1-5 disabled:opacity-50"
                  onClick={() => {
                    // createOpportunity()

                    handleEditButton()
                  }}
                  disabled={creatingWorkspace || workspaceData.name === ''}
                  // disabled={!opportunity.group_name || loading || isFileLoading}
                >
                  {creatingWorkspace ? (
                    <SemiCircleIcon className="size-4 animate-spin-fast" />
                  ) : workspaceData.id ? (
                    'Update'
                  ) : (
                    'Create'
                  )}
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div className="p-4 pt-2">
        <HeadlessTable
          // style={{
          //   height: 'calc(90vh - 315px)',
          //   overflow: 'auto'
          // }}
          leftAlign={true}
          hideMultiSelect={true}
          columns={columnSchema}
          pageSize={25}
          totalCount={meta.totalCount}
          data={rows}
          withoutStyles={true}
          loading={tableLoading}
          onRowClick={(row) => {
            handleOpenWorkspace(row)
          }}
          fetchPage={(page) => {
            setPage(page)
          }}
          meta={meta}
        />
      </div>
      {/* {renderEditDialog()} */}
      {ConfirmDialog}
    </Container>
  )
}

export default WorkspaceList

const getIconsByIndex = (index) => {
  const icons = [
    <DraftingCompass className="size-5" key={index} />,
    <CompassIcon className="size-5" key={index} />,
    <RouteIcon className="size-5" key={index} />,
    <FileIcon className="size-5" key={index} />
  ]
  return icons[index % icons.length]
}

const RecentDrafts = [
  {
    id: 'a3f0e8cb-af91-43f3-83d6-3ce2b7597d51',
    document_name: 'Student Housing Draft',
    created_by_user: 'cd6ccedc-2a23-48ac-87e9-7dd44db06866',
    created_at: '2025-03-10T04:28:45.695936',
    updated_by_user: 'cd6ccedc-2a23-48ac-87e9-7dd44db06866',
    updated_at: '2025-03-10T12:58:01.790055',
    active: true,
    type: 'storyboards',
    metadata_: {
      rfx_id: '1d646821-860a-44be-9cfe-5ffcdcbfb99e',
      reference_proposal_ids: ['eeea7477-e2a7-46b0-9714-d0947ff7f287'],
      new_proposal_outline: [
        {
          section_title: 'Cover Letter',
          sub_sections: [],
          section_type: 'cover_letter',
          section_summary:
            "This section includes a cover letter expressing the firm's interest in the project and summarizing the key points of the proposal.",
          number_of_words: 150
        },
        {
          section_title: 'Introduction',
          sub_sections: [
            'About the Firm',
            'Company History',
            "Firm's Mission and Vision"
          ],
          section_type: 'others',
          section_summary:
            'This section provides an introduction to the firm, including its history, mission, and vision.',
          number_of_words: 200
        },
        {
          section_title: 'Project Understanding and Approach',
          sub_sections: [
            'Project Understanding',
            'Proposed Approach',
            'Project Goals'
          ],
          section_type: 'others',
          section_summary:
            "This section outlines the firm's understanding of the project requirements and its proposed approach to meet the project goals.",
          number_of_words: 300
        },
        {
          section_title: 'Scope of Services',
          sub_sections: [
            'Preconstruction Services',
            'Construction Phase Services'
          ],
          section_type: 'others',
          section_summary:
            'This section details the scope of services the firm will provide during the preconstruction and construction phases.',
          number_of_words: 400
        },
        {
          section_title: 'Experience and Past Projects',
          sub_sections: ['Relevant Project Experience', 'Case Studies'],
          section_type: 'project_resume',
          section_summary:
            "This section highlights the firm's experience with similar projects and includes case studies of past successful projects.",
          number_of_words: 500
        },
        {
          section_title: 'Team and Key Personnel',
          sub_sections: [
            'Project Manager',
            'Superintendent',
            'Key Staff Resumes'
          ],
          section_type: 'people_resume',
          section_summary:
            'This section provides resumes and qualifications of the key personnel who will be involved in the project.',
          number_of_words: 350
        },
        {
          section_title: 'Safety and Risk Management',
          sub_sections: ['Safety Record', 'Risk Management Plan'],
          section_type: 'others',
          section_summary:
            "This section outlines the firm's safety record and its plan for managing risks during the project.",
          number_of_words: 250
        },
        {
          section_title: 'Financial Stability and Bonding Capacity',
          sub_sections: ['Financial Overview', 'Bonding Information'],
          section_type: 'others',
          section_summary:
            "This section provides information on the firm's financial stability and its bonding capacity for the project.",
          number_of_words: 200
        },
        {
          section_title: 'References',
          sub_sections: ['Client Testimonials', 'Contact Information'],
          section_type: 'others',
          section_summary:
            'This section includes references and testimonials from past clients, along with contact information.',
          number_of_words: 150
        },
        {
          section_title: 'Conclusion',
          sub_sections: ['Summary of Proposal', 'Call to Action'],
          section_type: 'others',
          section_summary:
            'This section summarizes the proposal and encourages the client to take the next steps in the selection process.',
          number_of_words: 150
        }
      ],
      storyboards: [
        '0188d57c-4d54-40c2-8033-28c42f230b23',
        '1a52d396-cfb7-47cc-a7ba-437fea4c3fab',
        'b5438094-bea3-4cda-8aef-9e3f35fb185c',
        'db0c44ee-44d2-42a1-87d5-45f101d39bf1',
        '2783bb83-66e4-4754-a6f1-ad7df9e59e8d',
        'c498dd3d-e59a-4305-bd69-595320503321',
        '90bf5c0f-5045-4964-8ea8-4d52f7246cea',
        'e8d5a611-549d-4552-bc94-64a6435e0e8f',
        'da67039d-8f62-4722-bf48-35f6776beaa1',
        '00fd5d72-0bb9-408d-9ec7-dea5f9ade5f0'
      ]
    },
    status: 'completed',
    created_by_user_name: 'Vinay'
  },
  {
    id: 'a3f0e8cb-af91-43f3-83d6-3ce2b7597d51',
    document_name: 'Student Housing Draft',
    created_by_user: 'cd6ccedc-2a23-48ac-87e9-7dd44db06866',
    created_at: '2025-03-10T04:28:45.695936',
    updated_by_user: 'cd6ccedc-2a23-48ac-87e9-7dd44db06866',
    updated_at: '2025-03-10T12:58:01.790055',
    active: true,
    type: 'storyboards',
    metadata_: {
      rfx_id: '1d646821-860a-44be-9cfe-5ffcdcbfb99e',
      reference_proposal_ids: ['eeea7477-e2a7-46b0-9714-d0947ff7f287'],
      new_proposal_outline: [
        {
          section_title: 'Cover Letter',
          sub_sections: [],
          section_type: 'cover_letter',
          section_summary:
            "This section includes a cover letter expressing the firm's interest in the project and summarizing the key points of the proposal.",
          number_of_words: 150
        },
        {
          section_title: 'Introduction',
          sub_sections: [
            'About the Firm',
            'Company History',
            "Firm's Mission and Vision"
          ],
          section_type: 'others',
          section_summary:
            'This section provides an introduction to the firm, including its history, mission, and vision.',
          number_of_words: 200
        },
        {
          section_title: 'Project Understanding and Approach',
          sub_sections: [
            'Project Understanding',
            'Proposed Approach',
            'Project Goals'
          ],
          section_type: 'others',
          section_summary:
            "This section outlines the firm's understanding of the project requirements and its proposed approach to meet the project goals.",
          number_of_words: 300
        },
        {
          section_title: 'Scope of Services',
          sub_sections: [
            'Preconstruction Services',
            'Construction Phase Services'
          ],
          section_type: 'others',
          section_summary:
            'This section details the scope of services the firm will provide during the preconstruction and construction phases.',
          number_of_words: 400
        },
        {
          section_title: 'Experience and Past Projects',
          sub_sections: ['Relevant Project Experience', 'Case Studies'],
          section_type: 'project_resume',
          section_summary:
            "This section highlights the firm's experience with similar projects and includes case studies of past successful projects.",
          number_of_words: 500
        },
        {
          section_title: 'Team and Key Personnel',
          sub_sections: [
            'Project Manager',
            'Superintendent',
            'Key Staff Resumes'
          ],
          section_type: 'people_resume',
          section_summary:
            'This section provides resumes and qualifications of the key personnel who will be involved in the project.',
          number_of_words: 350
        },
        {
          section_title: 'Safety and Risk Management',
          sub_sections: ['Safety Record', 'Risk Management Plan'],
          section_type: 'others',
          section_summary:
            "This section outlines the firm's safety record and its plan for managing risks during the project.",
          number_of_words: 250
        },
        {
          section_title: 'Financial Stability and Bonding Capacity',
          sub_sections: ['Financial Overview', 'Bonding Information'],
          section_type: 'others',
          section_summary:
            "This section provides information on the firm's financial stability and its bonding capacity for the project.",
          number_of_words: 200
        },
        {
          section_title: 'References',
          sub_sections: ['Client Testimonials', 'Contact Information'],
          section_type: 'others',
          section_summary:
            'This section includes references and testimonials from past clients, along with contact information.',
          number_of_words: 150
        },
        {
          section_title: 'Conclusion',
          sub_sections: ['Summary of Proposal', 'Call to Action'],
          section_type: 'others',
          section_summary:
            'This section summarizes the proposal and encourages the client to take the next steps in the selection process.',
          number_of_words: 150
        }
      ],
      storyboards: [
        '0188d57c-4d54-40c2-8033-28c42f230b23',
        '1a52d396-cfb7-47cc-a7ba-437fea4c3fab',
        'b5438094-bea3-4cda-8aef-9e3f35fb185c',
        'db0c44ee-44d2-42a1-87d5-45f101d39bf1',
        '2783bb83-66e4-4754-a6f1-ad7df9e59e8d',
        'c498dd3d-e59a-4305-bd69-595320503321',
        '90bf5c0f-5045-4964-8ea8-4d52f7246cea',
        'e8d5a611-549d-4552-bc94-64a6435e0e8f',
        'da67039d-8f62-4722-bf48-35f6776beaa1',
        '00fd5d72-0bb9-408d-9ec7-dea5f9ade5f0'
      ]
    },
    status: 'completed',
    created_by_user_name: 'Vinay'
  },
  {
    id: 'a3f0e8cb-af91-43f3-83d6-3ce2b7597d51',
    document_name: 'Student Housing Draft',
    created_by_user: 'cd6ccedc-2a23-48ac-87e9-7dd44db06866',
    created_at: '2025-03-10T04:28:45.695936',
    updated_by_user: 'cd6ccedc-2a23-48ac-87e9-7dd44db06866',
    updated_at: '2025-03-10T12:58:01.790055',
    active: true,
    type: 'storyboards',
    metadata_: {
      rfx_id: '1d646821-860a-44be-9cfe-5ffcdcbfb99e',
      reference_proposal_ids: ['eeea7477-e2a7-46b0-9714-d0947ff7f287'],
      new_proposal_outline: [
        {
          section_title: 'Cover Letter',
          sub_sections: [],
          section_type: 'cover_letter',
          section_summary:
            "This section includes a cover letter expressing the firm's interest in the project and summarizing the key points of the proposal.",
          number_of_words: 150
        },
        {
          section_title: 'Introduction',
          sub_sections: [
            'About the Firm',
            'Company History',
            "Firm's Mission and Vision"
          ],
          section_type: 'others',
          section_summary:
            'This section provides an introduction to the firm, including its history, mission, and vision.',
          number_of_words: 200
        },
        {
          section_title: 'Project Understanding and Approach',
          sub_sections: [
            'Project Understanding',
            'Proposed Approach',
            'Project Goals'
          ],
          section_type: 'others',
          section_summary:
            "This section outlines the firm's understanding of the project requirements and its proposed approach to meet the project goals.",
          number_of_words: 300
        },
        {
          section_title: 'Scope of Services',
          sub_sections: [
            'Preconstruction Services',
            'Construction Phase Services'
          ],
          section_type: 'others',
          section_summary:
            'This section details the scope of services the firm will provide during the preconstruction and construction phases.',
          number_of_words: 400
        },
        {
          section_title: 'Experience and Past Projects',
          sub_sections: ['Relevant Project Experience', 'Case Studies'],
          section_type: 'project_resume',
          section_summary:
            "This section highlights the firm's experience with similar projects and includes case studies of past successful projects.",
          number_of_words: 500
        },
        {
          section_title: 'Team and Key Personnel',
          sub_sections: [
            'Project Manager',
            'Superintendent',
            'Key Staff Resumes'
          ],
          section_type: 'people_resume',
          section_summary:
            'This section provides resumes and qualifications of the key personnel who will be involved in the project.',
          number_of_words: 350
        },
        {
          section_title: 'Safety and Risk Management',
          sub_sections: ['Safety Record', 'Risk Management Plan'],
          section_type: 'others',
          section_summary:
            "This section outlines the firm's safety record and its plan for managing risks during the project.",
          number_of_words: 250
        },
        {
          section_title: 'Financial Stability and Bonding Capacity',
          sub_sections: ['Financial Overview', 'Bonding Information'],
          section_type: 'others',
          section_summary:
            "This section provides information on the firm's financial stability and its bonding capacity for the project.",
          number_of_words: 200
        },
        {
          section_title: 'References',
          sub_sections: ['Client Testimonials', 'Contact Information'],
          section_type: 'others',
          section_summary:
            'This section includes references and testimonials from past clients, along with contact information.',
          number_of_words: 150
        },
        {
          section_title: 'Conclusion',
          sub_sections: ['Summary of Proposal', 'Call to Action'],
          section_type: 'others',
          section_summary:
            'This section summarizes the proposal and encourages the client to take the next steps in the selection process.',
          number_of_words: 150
        }
      ],
      storyboards: [
        '0188d57c-4d54-40c2-8033-28c42f230b23',
        '1a52d396-cfb7-47cc-a7ba-437fea4c3fab',
        'b5438094-bea3-4cda-8aef-9e3f35fb185c',
        'db0c44ee-44d2-42a1-87d5-45f101d39bf1',
        '2783bb83-66e4-4754-a6f1-ad7df9e59e8d',
        'c498dd3d-e59a-4305-bd69-595320503321',
        '90bf5c0f-5045-4964-8ea8-4d52f7246cea',
        'e8d5a611-549d-4552-bc94-64a6435e0e8f',
        'da67039d-8f62-4722-bf48-35f6776beaa1',
        '00fd5d72-0bb9-408d-9ec7-dea5f9ade5f0'
      ]
    },
    status: 'completed',
    created_by_user_name: 'Vinay'
  },
  {
    id: 'a3f0e8cb-af91-43f3-83d6-3ce2b7597d51',
    document_name: 'Student Housing Draft',
    created_by_user: 'cd6ccedc-2a23-48ac-87e9-7dd44db06866',
    created_at: '2025-03-10T04:28:45.695936',
    updated_by_user: 'cd6ccedc-2a23-48ac-87e9-7dd44db06866',
    updated_at: '2025-03-10T12:58:01.790055',
    active: true,
    type: 'storyboards',
    metadata_: {
      rfx_id: '1d646821-860a-44be-9cfe-5ffcdcbfb99e',
      reference_proposal_ids: ['eeea7477-e2a7-46b0-9714-d0947ff7f287'],
      new_proposal_outline: [
        {
          section_title: 'Cover Letter',
          sub_sections: [],
          section_type: 'cover_letter',
          section_summary:
            "This section includes a cover letter expressing the firm's interest in the project and summarizing the key points of the proposal.",
          number_of_words: 150
        },
        {
          section_title: 'Introduction',
          sub_sections: [
            'About the Firm',
            'Company History',
            "Firm's Mission and Vision"
          ],
          section_type: 'others',
          section_summary:
            'This section provides an introduction to the firm, including its history, mission, and vision.',
          number_of_words: 200
        },
        {
          section_title: 'Project Understanding and Approach',
          sub_sections: [
            'Project Understanding',
            'Proposed Approach',
            'Project Goals'
          ],
          section_type: 'others',
          section_summary:
            "This section outlines the firm's understanding of the project requirements and its proposed approach to meet the project goals.",
          number_of_words: 300
        },
        {
          section_title: 'Scope of Services',
          sub_sections: [
            'Preconstruction Services',
            'Construction Phase Services'
          ],
          section_type: 'others',
          section_summary:
            'This section details the scope of services the firm will provide during the preconstruction and construction phases.',
          number_of_words: 400
        },
        {
          section_title: 'Experience and Past Projects',
          sub_sections: ['Relevant Project Experience', 'Case Studies'],
          section_type: 'project_resume',
          section_summary:
            "This section highlights the firm's experience with similar projects and includes case studies of past successful projects.",
          number_of_words: 500
        },
        {
          section_title: 'Team and Key Personnel',
          sub_sections: [
            'Project Manager',
            'Superintendent',
            'Key Staff Resumes'
          ],
          section_type: 'people_resume',
          section_summary:
            'This section provides resumes and qualifications of the key personnel who will be involved in the project.',
          number_of_words: 350
        },
        {
          section_title: 'Safety and Risk Management',
          sub_sections: ['Safety Record', 'Risk Management Plan'],
          section_type: 'others',
          section_summary:
            "This section outlines the firm's safety record and its plan for managing risks during the project.",
          number_of_words: 250
        },
        {
          section_title: 'Financial Stability and Bonding Capacity',
          sub_sections: ['Financial Overview', 'Bonding Information'],
          section_type: 'others',
          section_summary:
            "This section provides information on the firm's financial stability and its bonding capacity for the project.",
          number_of_words: 200
        },
        {
          section_title: 'References',
          sub_sections: ['Client Testimonials', 'Contact Information'],
          section_type: 'others',
          section_summary:
            'This section includes references and testimonials from past clients, along with contact information.',
          number_of_words: 150
        },
        {
          section_title: 'Conclusion',
          sub_sections: ['Summary of Proposal', 'Call to Action'],
          section_type: 'others',
          section_summary:
            'This section summarizes the proposal and encourages the client to take the next steps in the selection process.',
          number_of_words: 150
        }
      ],
      storyboards: [
        '0188d57c-4d54-40c2-8033-28c42f230b23',
        '1a52d396-cfb7-47cc-a7ba-437fea4c3fab',
        'b5438094-bea3-4cda-8aef-9e3f35fb185c',
        'db0c44ee-44d2-42a1-87d5-45f101d39bf1',
        '2783bb83-66e4-4754-a6f1-ad7df9e59e8d',
        'c498dd3d-e59a-4305-bd69-595320503321',
        '90bf5c0f-5045-4964-8ea8-4d52f7246cea',
        'e8d5a611-549d-4552-bc94-64a6435e0e8f',
        'da67039d-8f62-4722-bf48-35f6776beaa1',
        '00fd5d72-0bb9-408d-9ec7-dea5f9ade5f0'
      ]
    },
    status: 'completed',
    created_by_user_name: 'Vinay'
  }
]
